<ng-container *ngIf="isLoadingData">
  <div class="loader-container">
    <div class="loader"></div>
  </div>
</ng-container>

<ng-container *ngIf="!isLoadingData">
  <!-- BREAD CRUMBS -->
  <div class="col-12 mb-2" hasPermission>
    <p class="bread-crumb">
      <ng-container *ngIf="breadCrumb == 'home'">
        <span class="me-1" i18n="@@position.HOME" role="button" (click)="onNavigationToHome()">position.HOME</span>
        <img class="align-baseline mx-1" src="assets/img/icons/chevron_right.svg">
        <span class="me-1" i18n="@@home.table.POSITION_DETAIL" role="button"
          (click)="onNavigationToPositionDetail()">home.table.POSITION_DETAIL</span>
      </ng-container>
      <ng-container *ngIf="breadCrumb == 'search'">
        <span class="me-1 text-uppercase" i18n="@@home.SEARCH" (click)="redirectToSearch()"
          role="button">home.SEARCH</span>
        <img class="align-baseline mx-1" src="assets/img/icons/chevron_right.svg">
        <span class="me-1" i18n="@@home.table.POSITION_DETAIL" role="button"
          (click)="onNavigationToPositionDetail()">home.table.POSITION_DETAIL</span>
      </ng-container>
    </p>
  </div>
  <!-- CARD CANDIDATO -->
  <div class="overflow-auto card-application-detail-wrapper">
    <card-application-detail [jobApplication]="candidateSheetService.jobApplication"
      [screeningTest]="candidateSheetService.screeningTestCard"
      (onRealizedApplicants)="toggleRealizedApplicants($event)" [isSelectable]="false"
      [screeningTest]="candidateSheetService && candidateSheetService.jobApplication && candidateSheetService.jobApplication.screeningTestInstances">
    </card-application-detail>
  </div>

  <!-- MODIFICA STATUS -->
  <div class="status mb-3" hasPermission>
    <!-- TITOLO -->
    <div class="row mb-3">
      <div class="title">
        <p i18n="@@person.detail.STATUS">person.detail.STATUS</p>
      </div>
    </div>
    <!-- CONTENUTO -->
    <div class="d-flex flex-column flex-md-row gap-3">
      <!-- CHIP PER MODIFICA STATUS -->
      <div class="d-flex flex-wrap flex-fill mb-lg-0 mb-2 gap-3">
        <ng-container *ngFor="let status of candidateSheetService.positionStatus">
          <generali-chip class="d-flex flex-row align-items-center"
            [isSelected]="candidateSheetService.jobApplication?.currentStatus === status.applicationBadge">
            <generali-badge [forceHoverWhite]="false"
              [forceColorWhite]="candidateSheetService.jobApplication?.currentStatus === status.applicationBadge"
              [badgeLabel]="status.label" [badgeType]="status.applicationBadge"
              (click)="onChangeStatus(status.applicationBadge)" badgeCategory="DOT">
            </generali-badge>
          </generali-chip>
        </ng-container>
      </div>
      <!-- PRECEDENTE CANDIADTO -->
      <div class="d-flex align-items-center h-40">
        <p class="text-nowrap txt-red-underline next-applicant" i18n="@@person.detail.PREVIOUS_APPLICANT"
          [class.disabled-next]="candidateSheetService.noPreviousApplicants" (click)="getPreviousApplicant()">
          person.detail.PREVIOUS_APPLICANT
        </p>
      </div>
      <!-- PROSSIMO CANDIADTO -->
      <div class="d-flex align-items-center h-40">
        <p class="text-nowrap txt-red-underline next-applicant" i18n="@@person.detail.NEXT_APPLICANT"
          [class.disabled-next]="candidateSheetService.noMoreApplicants" (click)="getNextApplicant()">
          person.detail.NEXT_APPLICANT
        </p>
      </div>
      <!-- AZIONI -->
      <div class="mb-lg-0">
        <actions-dropdown [actions]="candidateSheetService.actionOptions" (onClick)="onSelectedAction($event)">
        </actions-dropdown>
      </div>
    </div>
  </div>

  <!-- SPLIT SCREEN PER VISAULIZZAZIONE DATI CANDIDATO -->
  <div class="d-flex flex-column flex-lg-row gap-3 mb-5">
    <!-- PRIMA SEZIONE -->
    <div class="d-flex flex-column w-100">
      <div class="steps-tab d-flex gap-5 z-0">
        <!-- STEPPER PER VISUALIZZAZIONE DI CV / LETTERA / DATI PERSONALI VISIBILE SOLO IN MODALITÀ DESKTOP -->
        <div class="ss d-md-flex d-none">
          <generali-status-tab *ngFor="let step of candidateSheetService.stepper; let i=index"
            [item]="{isActive: step.isActive, isCompiled: step.isCompiled, name: step.name, isDisabled: step.isDisabled }"
            (onStepClick)="onNavigateToDesktopMode(step.redirect)">
          </generali-status-tab>
        </div>
        <!-- STEPPER PER VISUALIZZAZIONE DI CV / LETTERA / DATI PERSONALI VISIBILE SOLO IN MODALITÀ MOBILE -->
        <div class="w-75 d-block d-md-none py-2 ps-2">
          <generali-simple-select [dropdownData]="candidateSheetService.stepper" bindLabel="name" bindValue="redirect"
            (onTabClick)="onNavigateToMobileMode($event)" [setSelected]="setStepSelected()">
          </generali-simple-select>
        </div>
        <!-- BOTTONI PER DOWNLOAD CURRICULUM E FULL-SCREEN -->
        <div class="action-button d-flex ms-auto align-items-center pe-4 pe-sm-5">
          <img role="button" *ngIf="candidateSheetService.jobApplication?.cvUpload?.url" (click)="onCvDownload()"
            class="me-3" src="assets/img/icons/download.svg">
          <img role="button" *ngIf="candidateSheetService.jobApplication?.cvUpload?.url && !hasErrorPDF"
            (click)="goFullScreen()" src="assets/img/icons/expand.svg">
        </div>
      </div>
      <router-outlet></router-outlet>
    </div>

    <!-- ACCORDIONS -->
    <div class="cd-flex flex-column w-100 accordion-wrapper">
      <!-- COMMENTI -->
      <div class="d-flex flex-row justify-content-between mb-3" role="button" (click)="toggleComments()">
        <div class="d-flex flex-row flex-fill">
          <p class="accordion-title">{{ 'candidate.COMMENT' | translate }} ({{
            candidateSheetService.fetchData.commentCount ?? 0 }})</p>
        </div>
        <div class="d-flex flex-row">
          <div class="chevron-accordion"
            [ngClass]="candidateSheetService.accordion.showComments ? 'chevron-down' : 'chevron-up'">
          </div>
        </div>
      </div>

      <ng-container>
        <ng-container *ngFor="let comment of candidateSheetService.fetchData.commentList; let last=last">
          <div class="comment d-flex flex-column gap-3" *ngIf="candidateSheetService.accordion.showComments">
            <div class="d-flex flex-row">
              <div class="d-flex flex-row flex-fill user d-flex align-items-start">
                <!-- IMMAGINE DELL'UTENTE -->
                <div class="user-img me-2"></div>
                <!-- DATI ESSENZIALI CANDIDATO -->
                <div class="user-data">
                  <p class="user">
                    {{ comment.authorForename?.[0] }}.
                    {{ comment.authorSurname }}
                    {{ comment.authorRole ? '(' + ('candidate.authorRole.' + comment.authorRole |
                    translate) + ')' :
                    '(BI)' }}
                  </p>
                  <p class="data">
                    {{ comment.commentDate | date:'dd/MM/yyyy' }}
                    <span *ngIf="comment.isModified">{{ 'candidate.MODIFIED' | translate }}</span>
                  </p>
                </div>
              </div>
              <div class="d-flex flex-row user-action align-items-start text-end" *ngIf="comment.isMine">
                <img class="mx-1" src="assets/img/icons/edit.svg" role="button"
                  (click)="prepareCommentForEditing(comment)">
                <img class="mx-1" src="assets/img/icons/trash.svg" role="button"
                  (click)="onDeleteComment(comment.commentId)">
              </div>
            </div>
            <!-- CONTENUTO DEL COMMENTO -->
            <div class="row mb-4" *ngIf="!candidateSheetService.editComment.editMode; else commentEditMode">
              <div class="d-flex flex-row flex-fill">
                <p>{{ comment.commentText }}</p>
              </div>
            </div>
            <ng-template #commentEditMode>
              <div class="row mb-4" *ngIf="candidateSheetService.editComment.commentToUpdateId !== comment.commentId">
                <div class="d-flex flex-row flex-fill">
                  <p>{{ comment.commentText }}</p>
                </div>
              </div>
              <div class="row mb-4" *ngIf="candidateSheetService.editComment.commentToUpdateId === comment.commentId">
                <div class="d-flex flex-row flex-fill align-items-center gap-3">
                  <div class="flex-fill">
                    <generali-text-area txtAreaTitle="{{ 'candidate.EDIT_COMMENT_TITLE' | translate }}"
                      [txtAreaControl]="candidateSheetService.editComment.control" [rows]="2"></generali-text-area>
                  </div>
                  <div class="">
                    <p class="txt-red-underline" (click)="onUpdateComment()" role="button">
                      {{ 'generic.UPDATE' | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>

        </ng-container>
        <!-- AGGIUNGI UN NUOVO COMMENTO -->
        <div class="d-flex flex-row flex-fill align-items-center gap-3 mt-4"
          *ngIf="candidateSheetService.accordion.showComments">
          <div class="d-flex flex-row flex-fill">
            <generali-text-area txtAreaTitle="{{ 'candidate.COMMENT_TITLE' | translate }}"
              placeholder="{{ 'position.comment.PLACEHOLDER' | translate }}"
              [txtAreaControl]="candidateSheetService._commentField" class="flex-fill"></generali-text-area>
          </div>
          <div class="d-flex">
            <p class="txt-red-underline" (click)="onPostComment()" role="button">{{ 'generic.SAVE' | translate | titlecase }}</p>
          </div>
        </div>
      </ng-container>

      <div class="divider my-4"></div>

      <!-- COLLOQUI -->
      <div class="d-flex flex-row justify-content-between mb-3" role="button" (click)="toggleInterviews()">
        <div class="d-flex flex-row flex-fill">
          <p class="accordion-title">{{ 'detail.APPLICANT' | translate }} ({{
            candidateSheetService.jobApplication?.interviewInstances?.length ?? 0 }})
          </p>
        </div>
        <div class="d-flex flex-row">
          <div class="chevron-accordion"
            [ngClass]="candidateSheetService.accordion.showInterviews ? 'chevron-down' : 'chevron-up'">
          </div>
        </div>
      </div>

      <!-- TABELLA COLLOQUI -->
      <div *ngIf="candidateSheetService.accordion.showInterviews">
        <div>
          <table class="table table-striped table-borderless">
            <thead>
              <tr>
                <th class="table-title" i18n="@@person.detail.STAKEHOLDER">person.detail.STAKEHOLDER</th>
                <th class="table-title" i18n="@@candidate.DETAILS">person.detail.DETAILS</th>
                <th class="table-title" i18n="@@person.detail._STATUS">person.detail._STATUS</th>
              </tr>
            </thead>
            <div class="my-3"></div>
            <tbody>

              <tr *ngFor="let interview of candidateSheetService.jobApplication?.interviewInstances">
                <!-- STAKEHOLDER -->
                <td verticalAlignment="middle">
                  <!-- <p class="fw-500" *ngIf="interview.interviewUserRole === 'EBRM'; else noEBRM"> -->
                  <p class="fw-500" *ngIf="!interview.interviewUser?.forename; else showName">
                    {{ interview.interviewUserRole }}
                  </p>
                  <ng-template #showName>
                    <p class="fw-500">
                      {{ interview.interviewUser?.forename?.[0] }}.
                      {{ interview.interviewUser?.surname }}
                      ({{ interview.interviewUserRole === 'LINE_MANAGER' ? 'MNG' :
                      (interview.interviewUserRole ===
                      'BUSINESS_INTERVIEWER' ? 'BI' : interview.interviewUserRole) }})
                    </p>
                  </ng-template>
                </td>
                <!-- DETTAGLIO DEL COLLOQUIO CHE APRE IL RELATIVO DRAWER -->
                <td>
                  <p class="txt-red-underline" (click)="onToggleInterviewDetail(interview)"
                    *ngIf="interview.interviewType?.name; else chooseInterviewType">
                    {{ interview.interviewType?.name }}
                  </p>

                  <ng-template #chooseInterviewType>
                    <p class="txt-red-underline" (click)="onToggleInterviewDetail(interview)">
                      {{ 'generic.CHOOSE' | translate }}
                    </p>
                  </ng-template>
                </td>
                <!-- STATUS -->
                <td>
                  <generali-interview-status *ngIf="interview.currentStatus !== 'TO_BE_PLANNED'" [isApplicant]="true">
                  </generali-interview-status>

                  <generali-interview-status
                    *ngIf="interview.interviewUser?.userId === usrService.user.userId && interview.currentStatus == 'PLANNED'"
                    (click)="onClickInterview(interview)" [isApplicant]="true" interviewStatus="RATE">
                  </generali-interview-status>

                  <generali-interview-status *ngIf="interview.currentStatus == 'DONE'"
                    (click)="onClickInterview(interview)" [isApplicant]="true"
                    [interviewStatus]="interview.finalValuationResult">
                  </generali-interview-status>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style="width: fit-content;" hasPermission>
          <generali-button btnItem="{{ 'candidate.NEW_INTERVIEW' | translate }}" btnSize="auto" btnStyle="red"
            (onClick)="onToggleInterviewDetail()">
          </generali-button>
        </div>
      </div>
      <ng-container
        *ngIf="!candidateSheetService.jobApplication?.jobPositionHasFDDPerimeter || (candidateSheetService.jobApplication?.screeningTestInstances?.length ?? 0  > 0) ">
        <div class="divider my-4"></div>
        <!-- TEST EFFETTUATI -->
        <div class="d-flex flex-row justify-content-between mb-3" role="button" (click)="toggleTest()">
          <div class="d-flex flex-row flex-fill">
            <p class="accordion-title">
              Test ({{ candidateSheetService.jobApplication?.screeningTestInstances?.length ?? 0 }})
            </p>
          </div>
          <div class="d-flex flex-row">
            <div class="chevron-accordion"
              [ngClass]="candidateSheetService.accordion.showTests ? 'chevron-down' : 'chevron-up'">
            </div>
          </div>
        </div>
        <!-- TABELLA -->
        <div *ngIf="candidateSheetService.accordion.showTests">
          <table class="table table-striped table-borderless">
            <thead>
              <tr>
                <th class="table-title" i18n="@@person.detail.TEST">person.detail.STAKEHOLDER</th>
                <th class="table-title" i18n="@@person.detail.DATA">person.detail.DATA</th>
                <th class="table-title" i18n="@@person.detail.RESULT">person.detail.RESULT</th>
              </tr>
            </thead>
            <div class="my-3"></div>
            <tbody>
              <tr *ngFor="let test of candidateSheetService.jobApplication?.screeningTestInstances;let i = index">
                <!-- TIPO TEST -->
                <td>
                  <p class="fw-500">
                    {{test.shortTitle}}{{test.title ? " (" + test.title + ")" : ""}}
                  </p>
                </td>
                <!-- DETTAGLIO TEST CHE APRE RELATIVO DRAWER  -->
                <td>
                  <ng-container>
                    <p *ngIf="test.currentStatus == 'NOT_STARTED'" class="txt-red-underline"
                      (click)="toggleTestsDetail(true,test)" role="button" i18n="@@person.detail.ORGANIZE">
                      person.detail.ORGANIZE
                    </p>
                    <p *ngIf="test.currentStatus == 'STARTED' || (test.currentStatus == 'COMPLETED' && !test.testResultUploadId && !test.testResultUpload)"
                      class="txt-red-underline fs-14" (click)="toggleTestsDetail(true,test)" role="button">
                      {{test.invitedAt | date:'dd/MM/yyyy'}}
                    </p>
                    <p *ngIf="test.currentStatus == 'COMPLETED' && test.testResultUploadId && test.testResultUpload"
                      class="fs-14">
                      {{test.takenAt | date:'dd/MM/yyyy'}}
                    </p>
                  </ng-container>
                </td>
                <td>
                  <ng-container>
                    <p *ngIf="test.currentStatus == 'STARTED' || test.currentStatus == 'COMPLETED' && !test.testResultUploadId && !test.testResultUpload"
                      class="d-align-center">
                      <img class="icon-accordion me-1" src="assets/img/icons/mail.svg">
                      <span i18n="@@person.detail.SENDED">person.detail.SENDED</span>
                    </p>
                    <p *ngIf="test.currentStatus == 'COMPLETED' && test.testResultUploadId && test.testResultUpload"
                      (click)="downloadTest(test)" role="button" class="d-align-center">
                      <img class="icon-accordion" src="assets/img/icons/check.svg">
                      <span i18n="@@person.detail.SHOW_DETAIL"
                        class="txt-red-underline fs-14">person.detail.SHOW_DETAIL</span>
                    </p>
                  </ng-container>
                </td>
              </tr>
            </tbody>
          </table>
          <div style="width: fit-content;" hasPermission>
            <generali-button btnStyle="red" btnSize="auto" btnItem="{{ 'candidate.NEW_TEST' | translate }}"
              (onClick)="toggleTestsDetail(false)">
            </generali-button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>


  <!-- DRAWER DETTAGLIO TEST -->
  <generali-drawer [showDrawer]="candidateSheetService.drawer.showTestsDetail"
    drawerTitle="{{ 'person.detail.TEST_DRAWER_TITLE' | translate }}" (onClosingDrawerTab)="onCloseDrawers()">
    <ng-container main>
      <div class="px-1">
        <!-- TIPO TEST: SELECT VALORIZZATA CON IL TIPO DI TEST -->
        <generali-select placeholder="{{ 'qEs.TEST_TYPE' | translate | lowercase }}"
          title="{{ 'person.detail.SELECT_TEST' | translate }}" control="name" bindLabel="screeningTestName"
          [group]="candidateSheetService.testDetail" [dropdown]="candidateSheetService.fetchData.testList ?? []"
          (onGetItem)="getTestScrenningType($event)">
        </generali-select>
        <ng-container>
          <ng-container
            *ngIf="!testScreening.isNew && itemScreeningTest?.currentStatus == 'STARTED'  || (!testScreening.isNew && itemScreeningTest?.currentStatus == 'COMPLETED'  && !itemScreeningTest.testResultUploadId && !itemScreeningTest.testResult)">
            <div class="px-1 mb-3 mail-sended test">
              {{'candidate.TEXT_EMAIL_TEST_SENDED' | translate}} {{itemScreeningTest?.invitedAt |
              date:'dd/MM/yyyy' }}
            </div>
            <generali-button class="d-block text-center" btnItem="{{ 'candidate.REMINDER_MAIL' | translate }}"
              btnSize="auto" btnStyle="ghost" (onClick)="onSollecitTest(itemScreeningTest)">
            </generali-button>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="isLoadingTest">
          <div class="container-spinner">
            <p class="text-send-test">
              {{ 'candidate.SEND_TEST_PROGRESS' | translate}}
            </p>
            <img appLoader [loading]="isLoadingTest">
          </div>

        </ng-container>
      </div>
    </ng-container>

    <!-- WARN  -->
    <ng-container warnMessage>
      <div class="warn">
        <generali-warn *ngIf="false" icon="assets/img/icons/warning.svg">
          <ng-container warn>
            <p class="m-0">Attenzione</p>
          </ng-container>
          <ng-container message>
            <p class="m-0">Warn</p>
          </ng-container>
        </generali-warn>
      </div>
    </ng-container>

    <!-- BOTTONI DI CONFERMA / ANNULLA -->
    <ng-container footer>
      <div class="d-flex btn-footer">
        <generali-button class="me-1" btnItem="{{ 'generic.CANCEL' | translate }}" btnSize="auto" btnStyle="ghost"
          (onClick)="onCloseDrawers()">
        </generali-button>
        <generali-button class="me-1" btnItem="{{ 'generic.SEND' | translate | uppercase }}" btnSize="auto"
          btnStyle="red"
          [disabled]="isLoadingTest || testScreening.isNew && testScreening.isEmpty || (!testScreening.isNew && itemScreeningTest?.currentStatus == 'STARTED') || (itemScreeningTest?.currentStatus == 'COMPLETED'  && !itemScreeningTest.testResultUploadId && !itemScreeningTest.testResult)"
          (onClick)="onCloseTest()">
        </generali-button>
      </div>
    </ng-container>
  </generali-drawer>


  <!-- DRAWER DETTAGLIO COLLOQUIO -->
  <generali-drawer [showDrawer]="candidateSheetService.drawer.showInterviewDetail" drawerTitle="Dettagli colloquio"
    (onClosingDrawerTab)="onCloseDrawers()">
    <ng-container main>
      <div class="px-1">
        <!-- CAMPO SELECT MONOSELEZIONE CHE PERMETTE DI SCEGLIERE QUALSIASI PERSONA IN AZIENDA -->
        <generali-select title="{{ 'candidate.INTERVIEWER' | translate }}"
          [group]="candidateSheetService.interviewDetail" [searchable]="true" control="interviewer"
          placeholderImg="assets/img/icons/search.svg" bindLabel="fullname"
          [dropdown]="candidateSheetService.interviewerDropdown" placeholder="Cerca"
          (onScroll)="loadAdditionalInterviewers($event)" (onChangeValue)="onInterviewerSearch($event)"
          bindEmail="email">
        </generali-select>

        <div class="mt-3"></div>
        <!-- ELENCO DEI FORM -->
        <generali-select [group]="candidateSheetService.interviewDetail" control="evaluationFormType"
          title="Tipo evaluation form" [dropdown]="candidateSheetService.interviewTypeDropdown" bindLabel="name"
          placeholder="Scegli">
        </generali-select>
      </div>
      <div *ngIf="candidateSheetService.interviewInstance?.invitedAt" class="px-1 mt-2 mb-2 mail-sended">
        {{'candidate.TEXT_EMAIL_SENDED' | translate}} {{candidateSheetService.interviewInstance?.invitedAt |
        date:'dd/MM/yyyy' }}
      </div>
      <generali-button class="d-block text-center px-2" btnItem="{{ 'candidate.REMINDER_EMAIL' | translate }}"
        *ngIf="candidateSheetService.interviewInstance?.currentStatus == 'PLANNED'" btnSize="auto" btnStyle="ghost"
        (onClick)="onSollecitInterview()">
      </generali-button>

      <!-- <pre>{{ candidateSheetService.interviewInstance | json }}</pre> -->
    </ng-container>

    <!-- WARN  -->
    <ng-container warnMessage>
      <div class="warn">
        <generali-warn icon="assets/img/icons/warning.svg">
          <ng-container warn>
            <p class="m-0">{{ 'generic.WARNING' | translate }}</p>
          </ng-container>
          <ng-container message>
            <p class="m-0">{{ 'candidate.INTERVIEW_WARN' | translate }}</p>
          </ng-container>
        </generali-warn>
      </div>
    </ng-container>

    <!-- BOTTONI DI CONFERMA / ANNULLA -->
    <ng-container footer>
      <div class="d-flex btn-footer">
        <generali-button class="me-1" btnItem="{{ 'generic.CANCEL' | translate }}" btnSize="auto" btnStyle="ghost"
          (onClick)="onCloseDrawers()">
        </generali-button>
        <generali-button class="ms-1" btnItem="{{ askForAssesmentLabel | translate | uppercase }}" btnSize="auto"
          btnStyle="red"
          [disabled]="!evaluationFormType.controls.length || !interviewer.controls.length || candidateSheetService.interviewInstance?.currentStatus === 'PLANNED'"
          (onClick)="onOrganizeInterview()">
        </generali-button>
      </div>
    </ng-container>
  </generali-drawer>

  <!-- DRAWER TIMELINE -->
  <generali-drawer [showDrawer]="candidateSheetService.drawer.showTimeline"
    drawerTitle="{{ 'person.detail.TIMELINE' | translate }}" (onClosingDrawerTab)="onCloseDrawers()">
    <ng-container main>
      <div class="timeline-wrapper">
        <div *ngFor="let status of candidateSheetService.listAllStatus; let last=last; let first=first"
          class="timeline-row">
          <div class="dot-container">
            <div class="dot" [class.full]="first"></div>
            <div [class.line]="!last"></div>
          </div>


          <div class="timeline-content">
            <p class="mb-1 t-date">
              {{ status.statusDate | date:'dd/MM/yyyy HH:mm' }} - {{ status.userForname?.[0] }}.
              {{ status.userSurname }}
              <ng-container *ngIf="status.userRole !== undefined && status.userRole != ''">({{ status.userRole
                }})</ng-container>
            </p>
            <div class="d-flex align-items-center" *ngIf="!last; else Last">
              <p class="mb-0 me-2 t-stat">{{ 'person.detail.STATUS_CHANGE_IN' | translate }}</p>
              <generali-badge badgeCategory="DOT" [badgeType]="status.statusType"></generali-badge>
            </div>

            <ng-template #Last>
              <div class="d-flex align-items-center" *ngIf="candidateSheetService.jobApplication?.reassigned">
                <p class="mb-0 me-2 t-stat">
                  {{ 'person.detail.REASSIGNMENT_TIMELINE' | translate: {
                  forename: candidateSheetService.jobApplication?.forename ?? '',
                  surename: candidateSheetService.jobApplication?.surname ?? ''
                  } }}
                </p>
                <generali-badge badgeCategory="DOT" [badgeType]="status.statusType"></generali-badge>
              </div>

              <div class="d-flex align-items-center" *ngIf="!candidateSheetService.jobApplication?.reassigned">
                <p class="mb-0 me-2 t-stat">{{ 'candidate.timeline.FIRST_REASSIGNED' | translate }}</p>
                <generali-badge badgeCategory="DOT" [badgeType]="status.statusType"></generali-badge>
              </div>

            </ng-template>
            <div class="comment">
              <p class="mb-0 t-comment" *ngIf="status.comment">Commento: {{ status.comment }}</p>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </generali-drawer>

  <!-- DRAWER ASSEGNA A ALTRA SELEZIONE -->
  <generali-drawer [showDrawer]="candidateSheetService.drawer.showMoveToOtherSession"
    drawerTitle="{{ 'person.detail.SESSION' | translate }}"
    drawerSubTitle="{{ 'person.detail.SESSION_SUB' | translate }}" (onClosingDrawerTab)="onCloseDrawers()">
    <ng-container main>
      <div class="assigment-search">
        <p innerHTML="{{ 'person.detail.SESSION_LABEL_TITLE' | translate }}" class="assigment-search-title flex-wrap">
        </p>
        <generali-input [inputControl]="candidateSheetService.searchPosition"
          placeholder="{{ 'person.detail.SESSION_PLHOLDER' | translate }}" placeholderImg="assets/img/icons/search.svg"
          (onChange)="onSearchChangeAssignment($event)">
        </generali-input>
      </div>

      <ng-container *ngFor="let jobPosition of candidateSheetService.fetchData?.reassignableJobPositionList">
        <div class="assigned-position d-flex align-items-center">
          <div class="selector me-2">
            <generali-checkbox bindName="jobPosition" [isSelected]="jobPosition?.alreadyReassigned"
              [isDisabled]="jobPosition?.alreadyReassigned" (onSelectcheckbox)="onSelectJobPosition(jobPosition)">
            </generali-checkbox>
          </div>
          <div class="assigned-info">
            <p class="assigned-title">{{ jobPosition.progressiveCode?.generatedCode }} {{ jobPosition?.title }}
            </p>
            <p class="d-flex">
              <span>{{ jobPosition?.currentStatusDate | date:'dd/MM/yyyy' }}</span>
              <span class="mx-1" *ngIf="jobPosition?.currentStatusDate && jobPosition?.currentStatus">-</span>
              <span>
                <generali-badge badgeCategory="DOT" [badgeType]="jobPosition?.currentStatus"
                  *ngIf="jobPosition?.currentStatus">
                </generali-badge>
              </span>
            </p>
          </div>

        </div>
      </ng-container>
      <div class="d-flex flex-column align-items-center mt-5"
        *ngIf="!candidateSheetService.fetchData.reassignableJobPositionList?.length">
        <img class="mb-4" src="assets/img/icons/no-table-data.svg">
        <p i18n="@@person.detail.DEFAULT_TXT" class="txt-default text-center">person.detail.DEFAULT_TXT</p>
      </div>
    </ng-container>

    <!-- WARN + BOTTONI DI CONFERMA / ANNULLA -->
    <ng-container footer>
      <div class="d-flex btn-footer">
        <generali-button class="me-1" btnItem="{{ 'generic.CANCEL' | translate }}" btnSize="auto" btnStyle="ghost"
          (onClick)="onCloseDrawers()">
        </generali-button>
        <generali-button class="ms-1" btnItem="{{ 'candidate.ASSIGN' | translate }}" btnSize="auto" btnStyle="red"
          [disabled]="candidateSheetService.selectedJobPositionToReasign.length === 0"
          (onClick)="onReassigneToJobPositions()">
        </generali-button>
      </div>
    </ng-container>
  </generali-drawer>

  <!-- DRAWER CHE PERMETTE DI METTERE IN STATUS STOP UN CANDIDATO  -->
  <generali-drawer [showDrawer]="candidateSheetService.drawer.showInterruptApplication" drawerTitle="Stop"
    (onClosingDrawerTab)="onCloseDrawers()">
    <ng-container main>
      <div class="stop-wrapper">
        <p i18n="@@person.stop.TITLE">person.stop.TITLE</p>
        <p i18n="@@person.stop.SUB_TITLE">person.stop.SUB_TITLE</p>
        <generali-text-area [txtAreaControl]="interruptionComment" placeholder="{{ 'candidate.STOP_PL' | translate }}"
          [rows]="6">
        </generali-text-area>
        <div class="switcher d-flex justify-content-between align-items-center mt-3">
          <p i18n="@@person.stop.SEND">person.stop.SEND</p>
          <generali-switcher [position]="candidateSheetService.valueApplicationSwitch"
            [switchable]="candidateSheetService.interruptApplicationSwitch"
            (onSwitch)="onSwitchSendCommunication($event)">
          </generali-switcher>
        </div>
        <p i18n="@@person.stop.EMAIL">person.stop.EMAIL</p>

        <div class="txt-area p-1">

          <generali-text-area txtAreaTitle="{{ 'person.stop.EMAIL_TXT_IT' | translate:{ forename: candidateSheetService.jobApplication?.forename || '',
          surename: candidateSheetService.jobApplication?.surname || '' } }}"
            [txtAreaControl]="interruptionDefaultEmail" placeholder="{{ 'candidate.STOP_PL' | translate }}" [rows]="7">
          </generali-text-area>
        </div>

        <div class="m-24 txt-area p-1">
          <generali-text-area txtAreaTitle="{{ 'person.stop.EMAIL_TXT_EN' | translate:{ forename: candidateSheetService.jobApplication?.forename || '',
          surename: candidateSheetService.jobApplication?.surname || '' } }}"
            [txtAreaControl]="interruptionDefaultEmailEn" placeholder="{{ 'candidate.STOP_PL' | translate }}"
            [rows]="7">
          </generali-text-area>
        </div>

      </div>
    </ng-container>

    <!-- WARN + BOTTONI DI CONFERMA / ANNULLA -->
    <ng-container footer>
      <div class="d-flex btn-footer">
        <generali-button class="me-1" btnItem="{{ 'generic.CANCEL' | translate }}" btnSize="auto" btnStyle="ghost"
          (onClick)="onCloseDrawers()">
        </generali-button>
        <generali-button class="ms-1" btnItem="{{ 'generic.CONFIRM' | translate }}" btnSize="auto" btnStyle="red"
          (onClick)="onConfirmInterruption()"></generali-button>
      </div>
    </ng-container>
  </generali-drawer>

  <generali-drawer [showDrawer]="candidateSheetService.drawer.showRealizedApplicants"
    drawerTitle="{{ 'candidate.HISTORY' | translate:{ owner: candidateSheetService.jobApplicationHistoryOwner } }}"
    drawerSubTitle="Seleziona la candidatura" drawerDimension="LG" (onClosingDrawerTab)="onCloseDrawers()">
    <ng-container main>
      <div class="px-1 py-2 gap-3 d-flex flex-column">
        <card-application-detail *ngFor="let history of candidateSheetService.fetchData.jobApplicationHistory" [compactMode]="true" [isSelectable]="true" [jobApplication]="history"
          [singleSelectMode]="true" bindName="candidatura" [canAccessDetails]=" canAccessDetailsAsHrbp(history)"
          (onClick)="onNavigationOtherApplication($event, history.jobPositionId)"
          [screeningTest]="history.screeningTestInstances">
        </card-application-detail>

        <generali-warn *ngIf="usrService.isHRBP" icon="assets/img/icons/warning.svg">
          <ng-container warn>
            <p class="m-0">{{ "generic.WARNING" | translate }}</p>
          </ng-container>
          <ng-container message>
            <p class="m-0">{{ "candidate.warn.HRBP" | translate }}</p>
          </ng-container>
        </generali-warn>
      </div>
    </ng-container>
  </generali-drawer>

  <!-- FITNESS KILLER QUESTION -->
  <!-- <generali-drawer [showDrawer]="true" drawerTitle="{{ 'position.card.FITNES_DRAWER_TITLE' | translate }}"
  drawerSubTitle="{{ 'position.card.FITNES_DRAWER_SUBTITLE' | translate }}">
  <ng-container main>
    <card-application-detail *ngFor="let history of jobApplicationHistory" [compactMode]="true"
      [jobApplication]="history">
    </card-application-detail>
  </ng-container>
</generali-drawer> -->

  <!-- DRAWER EVALUATION FORM -->
  <!-- TODO: impostare titolo con valore corretto-->
  <generali-drawer [showDrawer]="candidateSheetService.drawer.showEvaluationForm"
    drawerTitle="{{ 'candidate.evaluationform.TITLE' | translate  }}  {{ interviewUserInfo?.title }}"
    (onClosingDrawerTab)="onCloseEvalutionForm()">
    <ng-container main>

      <!-- Evalutuation form wrapper -->
      <div class="evalution-form d-flex flex-column gap-4">

        <!-- Status -->
        <div class="d-flex flex-column gap-2">

          <div class="mb-2">
            <generali-badge *ngIf="interviewUserInfo?.formCompiledAt" badgeCategory="FULL" badgeType="OPEN"
              badgeLabel="{{ 'candidate.evaluationform.TO_COMPLETED' | translate }} {{interviewUserInfo.formCompiledAt | date:'shortDate' }}">
            </generali-badge>

            <generali-badge *ngIf="interviewUserInfo?.formCompiledAt == undefined" badgeCategory="FULL"
              badgeType="TO_BE_APPROVED" badgeLabel="{{ 'candidate.evaluationform.TO_COMPLETE' | translate }}">
            </generali-badge>
          </div>

          <div class="text">
            <span i18n="@@candidate.evaluationform.POSITION">candidate.evaluationform.POSITION</span>
            <span>: {{ candidateSheetService.jobApplication?.jobPositionProgressiveCode }} {{
              candidateSheetService.jobApplication?.jobPositionTitle }}</span>
          </div>
          <div class="text">
            <span i18n="@@candidate.evaluationform.EVALUATOR">candidate.evaluationform.POSITION</span>
            <span>: {{interviewUserInfo?.forename}} {{interviewUserInfo?.surname}}
              ({{interviewUserInfo?.role}})</span>
          </div>
          <div class="text">
            <span i18n="@@candidate.evaluationform.CANDIDATE">candidate.evaluationform.CANDIDATE</span>
            <span>: {{ candidateSheetService.jobApplication?.forename}} {{
              candidateSheetService.jobApplication?.surname}}</span>
          </div>
        </div>

        <form class="d-flex flex-column gap-4" [formGroup]="efForm">

          <!-- Interview date -->
          <div>
            <div class="text date  mb-1">
              <span
                i18n="@@candidate.evaluationform.DATE_INTERVIEW">candidate.evaluationform.DATE_INTERVIEW</span><span>*</span>
            </div>

            <generali-datapicker *ngIf="candidateSheetService.drawer.showEvaluationForm"
              [preselectedDate]="interviewInstance?.scheduledAt" [inputControl]="efForm.get('scheduledAt')"
              placeholder="{{ 'process.pl.DATE' | translate }}" (onDateSelect)="onSaveDate($event)"
              [readOnly]="formReadOnly"
              [ngClass]="{ 'focus-form' : efForm.get('scheduledAt')?.invalid && efForm.dirty }">
            </generali-datapicker>
            <div *ngIf="efForm.get('scheduledAt')?.invalid && efForm.dirty" class="w-100 d-flex flex-column">
              <p class="err">Compila il campo obbligatorio!</p>
            </div>
          </div>

          <!-- Conflicts interest checkbox -->
          <div>
            <generali-checkbox (onSelectcheckbox)="onSaveConflict($event)"
              [isSelected]="interviewInstance?.noConflictOfInterest" [isDisabled]="formReadOnly"
              [ngClass]="{ 'focus-form' : efForm.get('noConflictOfInterest')?.invalid && efForm.dirty }">
              <p i18n="@@candidate.evaluationform.CONFLICTS_INTEREST">
                candidate.evaluationform.CONFLICTS_INTEREST</p>
            </generali-checkbox>
            <div
              *ngIf="efForm.get('noConflictOfInterest')?.invalid && efForm.dirty  && efForm.get('noConflictOfInterest')?.touched"
              class="w-100 d-flex flex-column">
              <p class="err mt-4">Compila il campo obbligatorio!</p>
            </div>

          </div>

          <!-- EBRM -->
          <ng-container *ngIf="interviewUserInfo?.type == 'EBRM'">
            <div class="d-flex flex-column gap-4">

              <!-- SYNTHETIC_EVALUATION -->
              <div class="d-flex flex-column gap-2">
                <div class="index-title">{{ 'candidate.evaluationform.FIRST' | translate}} {{
                  'candidate.evaluationform.SYNTHETIC_EVALUATION' | translate}}</div>

                <star-rating name="interests" [readOnly]="formReadOnly" [value]="this.propertiesForm.ebrm.interests"
                  [label]="'candidate.evaluationform.INTEREST_COMPANY'" [error]="errorStar.interests"
                  (onClickStar)="onsaveStar($event)" [ngClass]="{ 'focus-form' : errorStar.interests }"></star-rating>
                <star-rating name="compatibility" [readOnly]="formReadOnly" [error]="errorStar.compatibility"
                  [value]="this.propertiesForm.ebrm.compatibility"
                  [label]="'candidate.evaluationform.COMPATIBLE_FOR_RULE_ORGANIZATION'"
                  (onClickStar)="onsaveStar($event)"
                  [ngClass]="{ 'focus-form' : errorStar.compatibility }"></star-rating>
                <star-rating name="motivation" [readOnly]="formReadOnly" [error]="errorStar.motivation"
                  [value]="this.propertiesForm.ebrm.motivation" [label]="'candidate.evaluationform.MOTIVATION_FOR_RULE'"
                  (onClickStar)="onsaveStar($event)" [ngClass]="{ 'focus-form' : errorStar.motivation }">
                </star-rating>
              </div>

              <!-- HUMAN_TOUCH -->
              <div class="d-flex flex-column gap-2">
                <div class="index-title">{{ 'candidate.evaluationform.TWO' | translate}} {{
                  'candidate.evaluationform.HUMAN_TOUCH' | translate}}</div>
                <div class="index-subtitle" i18n="@@candidate.evaluationform.HUMAN_TOUCH_SUBTITLE">
                  candidate.evaluationform.HUMAN_TOUCH_SUBTITLE</div>

                <star-rating name="trust" [readOnly]="formReadOnly" [error]="errorStar.trust"
                  [value]="this.propertiesForm.ebrm.trust" [label]="'candidate.evaluationform.BUILDING_TRUST'"
                  (onClickStar)="onsaveStar($event)" [ngClass]="{ 'focus-form' : errorStar.trust }"></star-rating>
                <star-rating name="teamwork" [readOnly]="formReadOnly" [error]="errorStar.teamwork"
                  [value]="this.propertiesForm.ebrm.teamwork" [label]="'candidate.evaluationform.TEAMWORK'"
                  (onClickStar)="onsaveStar($event)" [ngClass]="{ 'focus-form' : errorStar.teamwork }">
                </star-rating>
              </div>

              <!-- INNOVATION_SUBTITLE -->
              <div class="d-flex flex-column gap-2">
                <div class="index-title">{{ 'candidate.evaluationform.THREE' | translate}} {{
                  'candidate.evaluationform.INNOVATION' | translate}}</div>
                <div class="index-subtitle" i18n="@@candidate.evaluationform.INNOVATION_SUBTITLE">
                  candidate.evaluationform.INNOVATION_SUBTITLE</div>

                <star-rating name="innovatitve" [readOnly]="formReadOnly" [error]="errorStar.innovatitve"
                  [value]="this.propertiesForm.ebrm.innovatitve"
                  [label]="'candidate.evaluationform.INNOVATIVE_SOLUTION'" (onClickStar)="onsaveStar($event)"
                  [ngClass]="{ 'focus-form' : errorStar.innovatitve }">
                </star-rating>
                <star-rating name="openess" [readOnly]="formReadOnly" [error]="errorStar.openess"
                  [value]="this.propertiesForm.ebrm.openess" [label]="'candidate.evaluationform.OPENNESS'"
                  (onClickStar)="onsaveStar($event)" [ngClass]="{ 'focus-form' : errorStar.openess }">
                </star-rating>
              </div>

              <!-- OWNERSHIP -->
              <div class="d-flex flex-column gap-2">
                <div class="index-title">{{ 'candidate.evaluationform.FOUR' | translate}} {{
                  'candidate.evaluationform.OWNERSHIP' | translate}}</div>
                <div class="index-subtitle" i18n="@@candidate.evaluationform.OWNERSHIP_SUBTITLE">
                  candidate.evaluationform.OWNERSHIP_SUBTITLE</div>

                <star-rating name="proactivity" [readOnly]="formReadOnly" [error]="errorStar.proactivity"
                  [value]="this.propertiesForm.ebrm.proactivity" [label]="'candidate.evaluationform.PROACTIVITY'"
                  (onClickStar)="onsaveStar($event)" [ngClass]="{ 'focus-form' : errorStar.proactivity }"></star-rating>
                <star-rating name="execution" [readOnly]="formReadOnly" [error]="errorStar.execution"
                  [value]="this.propertiesForm.ebrm.execution" [label]="'candidate.evaluationform.EXECUTION'"
                  (onClickStar)="onsaveStar($event)" [ngClass]="{ 'focus-form' : errorStar.execution }"></star-rating>
              </div>

              <!-- SIMPLIFICATION -->
              <div class="d-flex flex-column gap-2">
                <div class="index-title">{{ 'candidate.evaluationform.FIVE' | translate}} {{
                  'candidate.evaluationform.SIMPLIFICATION' | translate}}</div>
                <div class="index-subtitle" i18n="@@candidate.evaluationform.OWNERSHIP_SUBTITLE">
                  candidate.evaluationform.SIMPLIFICATION_SUBTITLE</div>

                <star-rating name="makingSimple" [readOnly]="formReadOnly" [error]="errorStar.makingSimple"
                  [value]="this.propertiesForm.ebrm.makingSimple"
                  [label]="'candidate.evaluationform.MAKING_THINGS_SIMPLE'" (onClickStar)="onsaveStar($event)"
                  [ngClass]="{ 'focus-form' : errorStar.makingSimple }">
                </star-rating>
                <star-rating name="adaptability" [readOnly]="formReadOnly" [error]="errorStar.adaptability"
                  [value]="this.propertiesForm.ebrm.adaptability" [label]="'candidate.evaluationform.ADAPTABILITY'"
                  (onClickStar)="onsaveStar($event)"
                  [ngClass]="{ 'focus-form' : errorStar.adaptability }"></star-rating>
              </div>

              <generali-text-area
                txtAreaTitle="{{'candidate.evaluationform.SIX' | translate }} {{'candidate.evaluationform.FINAL_COMMMENT' | translate }}"
                placeholder="{{ 'generic.INSERT' | translate }}" [txtAreaControl]="efForm.get('finalCommentResult')"
                [ngClass]="{ 'focus-form' : efForm.get('finalCommentResult')?.invalid && efForm.dirty }">
              </generali-text-area>

              <generali-select placeholder="{{ 'generic.CHOOSE' | translate }}"
                title="{{ 'candidate.evaluationform.SEVEN' | translate }} {{ 'candidate.evaluationform.FINAL_HIRING_RECOMMENDATION' | translate }}"
                control="finalValuationResult" [dropdown]="finalValuationResultArray" bindLabel="label" position="top"
                [group]="efForm"
                [ngClass]="{ 'focus-form' : efForm.get('finalValuationResult')?.invalid && efForm.dirty }"
                (onGetItem)="onChangefinalValuationResult($event)">
              </generali-select>

            </div>
          </ng-container>

          <!-- EBRM_FI -->
          <ng-container *ngIf="interviewUserInfo?.type == 'EBRM_FI'">
            <div class="d-flex flex-column gap-4">

              <!-- SYNTHETIC_EVALUATION_OPTIONAL -->
              <div class="d-flex flex-column gap-2">
                <div class="index-title">{{ 'candidate.evaluationform.FIRST' | translate}} {{
                  'candidate.evaluationform.SYNTHETIC_EVALUATION_OPTIONAL' | translate}}</div>

                <star-rating name="interests" [readOnly]="formReadOnly" [value]="this.propertiesForm.ebrm.interests"
                  [label]="'candidate.evaluationform.INTEREST_COMPANY'"
                  (onClickStar)="onsaveStar($event)"></star-rating>
              </div>

              <generali-text-area
                txtAreaTitle="{{ 'candidate.evaluationform.TWO' | translate}} {{ 'candidate.evaluationform.POI' | translate }}"
                placeholder="{{ 'generic.INSERT' | translate }}" [txtAreaControl]="efForm.get('wantedPosition')">
              </generali-text-area>

              <div class="d-flex flex-column gap-1">
                <div class="index-title">{{ 'candidate.evaluationform.THREE' | translate}} {{
                  'candidate.evaluationform.SALARY_ACTUAL_OPTIONAL' | translate}}</div>

                <generali-input type="currency" placeholder="{{ 'generic.INSERT' | translate }}"
                  placeholderImg="assets/img/icons/icon-euro.svg" [inputControl]="actualRetributionEBRMFI"
                  (onChange)="onActualRetributionChangeEvFormEbrmFi($event)">
                </generali-input>
                <div class="index-number" i18n="@@candidate.evaluationform.INSERT_NUMBER">
                  candidate.evaluationform.INSERT_NUMBER</div>
              </div>

              <generali-text-area
                txtAreaTitle="{{ 'candidate.evaluationform.FOUR' | translate}} {{ 'candidate.evaluationform.CONTRACT_AND_LEVEL_ACTUAL_OPTIONAL' | translate }}"
                placeholder="{{ 'generic.INSERT' | translate }}" [txtAreaControl]="efForm.get('actualContract')">
              </generali-text-area>

              <generali-text-area
                txtAreaTitle="{{ 'candidate.evaluationform.FIVE' | translate}} {{ 'candidate.evaluationform.BENEFIT_ACTUAL_OPTIONAL' | translate }}"
                placeholder="{{ 'generic.INSERT' | translate }}" [txtAreaControl]="efForm.get('actualBenefit')">
              </generali-text-area>

              <generali-text-area
                txtAreaTitle="{{ 'candidate.evaluationform.SIX' | translate}} {{ 'candidate.evaluationform.SALARY_DESIRED' | translate }}"
                placeholder="{{ 'generic.INSERT' | translate }}" [txtAreaControl]="efForm.get('wantedRetribution')">
              </generali-text-area>

              <generali-text-area
                txtAreaTitle="{{ 'candidate.evaluationform.SEVEN' | translate}} {{ 'candidate.evaluationform.CONTRACT_AND_LEVEL_DESIRED_OPTIONAL' | translate }}"
                placeholder="{{ 'generic.INSERT' | translate }}" [txtAreaControl]="efForm.get('wantedContract')">
              </generali-text-area>

              <generali-text-area
                txtAreaTitle="{{ 'candidate.evaluationform.EIGHT' | translate}} {{ 'candidate.evaluationform.AVAILABLE_TRANSFERT_OPTIONAL' | translate }}"
                placeholder="{{ 'generic.INSERT' | translate }}" [txtAreaControl]="efForm.get('mobility')">
              </generali-text-area>

              <generali-text-area
                txtAreaTitle="{{ 'candidate.evaluationform.NINE' | translate}} {{ 'candidate.evaluationform.NOTICE_OPTIONAL' | translate }}"
                placeholder="{{ 'generic.INSERT' | translate }}" [txtAreaControl]="efForm.get('dismissalNotice')">
              </generali-text-area>


              <div class="d-flex flex-column gap-2">
                <div class="index-title">{{ 'candidate.evaluationform.TEN' | translate}} {{
                  'candidate.evaluationform.STRENGTH_BEHAVIOR_OPTIONAL' | translate}}</div>
                <div class="index-subtitle" i18n="@@candidate.evaluationform.STRENGTH_BEHAVIOR_SUBTITLE">
                  candidate.evaluationform.STRENGTH_BEHAVIOR_SUBTITLE</div>
              </div>

              <div class="w-100 d-flex flex-row justify-content-center container-behavior">
                <div class="behavior" (click)="changeBehavior('innovation')"
                  [ngClass]="this.behavior.innovation ? 'behavior-red' : 'behavior-grey'">
                  <img class="behavior-contents" src="assets/img/icons/icon_innovation.svg" />
                  <div class="behavior-contents title" i18n="@@candidate.evaluationform.INNOVATION">
                    candidate.evaluationform.INNOVATION</div>
                </div>
                <div class="behavior" (click)="changeBehavior('ownership')"
                  [ngClass]="this.behavior.ownership ? 'behavior-red' : 'behavior-grey'">
                  <img class="behavior-contents" src="assets/img/icons/icon_ownership.svg" />
                  <div class="behavior-contents title" i18n="@@candidate.evaluationform.OWNERSHIP">
                    candidate.evaluationform.OWNERSHIP</div>
                </div>
              </div>
              <div class="w-100 d-flex flex-row justify-content-center container-behavior">
                <div class="behavior" (click)="changeBehavior('humanTouch')"
                  [ngClass]="this.behavior.humanTouch ? 'behavior-red' : 'behavior-grey'">
                  <img class="behavior-contents" src="assets/img/icons/icon_human_touch.svg" />
                  <div class="behavior-contents title" i18n="@@candidate.evaluationform.HUMAN_TOUCH">
                    candidate.evaluationform.HUMAN_TOUCH</div>
                </div>
                <div class="behavior" (click)="changeBehavior('simplification')"
                  [ngClass]="this.behavior.simplification ? 'behavior-red' : 'behavior-grey'">
                  <img class="behavior-contents" src="assets/img/icons/icon_simplification.svg" />
                  <div class="behavior-contents title" i18n="@@candidate.evaluationform.SIMPLIFICATION">
                    candidate.evaluationform.SIMPLIFICATION</div>
                </div>
              </div>

              <div>
                <generali-text-area
                  txtAreaTitle="{{'candidate.evaluationform.FOUR' | translate }} {{'candidate.evaluationform.FINAL_COMMMENT' | translate }}"
                  placeholder="{{ 'generic.INSERT' | translate }}" [txtAreaControl]="efForm.get('finalCommentResult')"
                  [ngClass]="{ 'focus-form' : efForm.get('finalCommentResult')?.invalid && efForm.dirty }">
                </generali-text-area>
              </div>

              <div>
                <generali-select placeholder="{{ 'generic.CHOOSE' | translate }}"
                  title="{{ 'candidate.evaluationform.FIVE' | translate }} {{ 'candidate.evaluationform.FINAL_HIRING_RECOMMENDATION' | translate }}"
                  control="finalValuationResult" [dropdown]="finalValuationResultArray" bindLabel="label" position="top"
                  [group]="efForm"
                  [ngClass]="{ 'focus-form' : efForm.get('finalValuationResult')?.invalid && efForm.dirty }"
                  (onGetItem)="onChangefinalValuationResult($event)">
                </generali-select>
              </div>
            </div>
          </ng-container>

          <!-- HRBP -->
          <ng-container *ngIf="interviewUserInfo?.type == 'HRBP'">
            <div class="d-flex flex-column gap-4">

              <!-- SYNTHETIC_EVALUATION -->
              <div class="d-flex flex-column gap-2">
                <div class="index-title">{{ 'candidate.evaluationform.FIRST' | translate}} {{
                  'candidate.evaluationform.SYNTHETIC_EVALUATION' | translate}}</div>
                <star-rating name="motivation" [readOnly]="formReadOnly" [error]="errorStar.motivation"
                  [value]="this.propertiesForm.hrbp.motivation" [label]="'candidate.evaluationform.MOTIVATION_FOR_RULE'"
                  (onClickStar)="onsaveStar($event)" [ngClass]="{ 'focus-form' : errorStar.motivation }">
                </star-rating>
                <star-rating name="interests" [readOnly]="formReadOnly" [error]="errorStar.interests"
                  [value]="this.propertiesForm.hrbp.interests" [label]="'candidate.evaluationform.INTEREST_COMPANY'"
                  (onClickStar)="onsaveStar($event)" [ngClass]="{ 'focus-form' : errorStar.interests }"></star-rating>
                <star-rating name="compatibility" [value]="this.propertiesForm.hrbp.compatibility"
                  [label]="'candidate.evaluationform.COMPATIBLE_WITH_RULE_ORGANIZATION'"
                  [error]="errorStar.compatibility" (onClickStar)="onsaveStar($event)"
                  [ngClass]="{ 'focus-form' : errorStar.compatibility }"></star-rating>
              </div>

              <div class="d-flex flex-column gap-2">
                <div class="index-title">{{ 'candidate.evaluationform.TWO' | translate}} {{
                  'candidate.evaluationform.SALARY_ACTUAL' | translate}}</div>
                <generali-input type="currency" [inputControl]="actualRetributionHBRP" [placeholder]="'Inserisci'"
                  placeholderImg="assets/img/icons/icon-euro.svg"
                  (onChange)="onActualRetributionChangeEvFormHBRP($event)"
                  [ngClass]="{ 'focus-form' : (efForm.get('actualRetributionHBRP')?.value == undefined || efForm.get('actualRetributionHBRP')?.value == 0 || propertiesForm.actualRetribution == 0) && efForm.dirty }">
                </generali-input>
                <div
                  *ngIf="(efForm.get('actualRetributionHBRP')?.value == undefined || efForm.get('actualRetributionHBRP')?.value == 0 || propertiesForm.actualRetribution == 0) && efForm.dirty"
                  class="w-100 d-flex flex-column">
                  <p class="err">Compila il campo obbligatorio!</p>
                </div>
                <div class="index-number" i18n="@@candidate.evaluationform.INSERT_NUMBER">
                  candidate.evaluationform.INSERT_NUMBER</div>
              </div>

              <generali-text-area
                txtAreaTitle="{{ 'candidate.evaluationform.THREE' | translate}} {{ 'candidate.evaluationform.AVAILABLE_DIFFERENT_POSITION_JOB' | translate }}"
                placeholder="{{ 'generic.INSERT' | translate }}" [txtAreaControl]="efForm.get('otherPosition')"
                [ngClass]="{ 'focus-form' : efForm.get('otherPosition')?.invalid && efForm.dirty }">
              </generali-text-area>

              <generali-text-area
                txtAreaTitle="{{ 'candidate.evaluationform.FOUR' | translate}} {{ 'candidate.evaluationform.CONTRACT_AND_LEVEL_ACTUAL' | translate }}"
                placeholder="{{ 'generic.INSERT' | translate }}" [txtAreaControl]="efForm.get('actualContract')"
                [ngClass]="{ 'focus-form' : efForm.get('actualContract')?.invalid && efForm.dirty }">
              </generali-text-area>

              <generali-text-area
                txtAreaTitle="{{ 'candidate.evaluationform.FIVE' | translate}} {{ 'candidate.evaluationform.BENEFIT_ACTUAL' | translate }}"
                placeholder="{{ 'generic.INSERT' | translate }}" [txtAreaControl]="efForm.get('actualBenefit')"
                [ngClass]="{ 'focus-form' : efForm.get('actualBenefit')?.invalid && efForm.dirty }">
              </generali-text-area>

              <generali-text-area
                txtAreaTitle="{{ 'candidate.evaluationform.SIX' | translate}} {{ 'candidate.evaluationform.SALARY_DESIRED' | translate }}"
                placeholder="{{ 'generic.INSERT' | translate }}" [txtAreaControl]="efForm.get('wantedRetribution')">
              </generali-text-area>

              <generali-text-area
                txtAreaTitle="{{ 'candidate.evaluationform.SEVEN' | translate}}  {{ 'candidate.evaluationform.CONTRACT_AND_LEVEL_DESIRED_HRBP' | translate }}"
                placeholder="{{ 'generic.INSERT' | translate }}" [txtAreaControl]="efForm.get('wantedContract')"
                [ngClass]="{ 'focus-form' : efForm.get('wantedContract')?.invalid && efForm.dirty }">
              </generali-text-area>

              <generali-text-area
                txtAreaTitle="{{ 'candidate.evaluationform.EIGHT' | translate}} {{ 'candidate.evaluationform.AVAILABLE_TRANSFERT' | translate }}"
                placeholder="{{ 'generic.INSERT' | translate }}" [txtAreaControl]="efForm.get('mobility')"
                [ngClass]="{ 'focus-form' : efForm.get('mobility')?.invalid && efForm.dirty }">
              </generali-text-area>

              <generali-text-area
                txtAreaTitle="{{ 'candidate.evaluationform.NINE' | translate}} {{ 'candidate.evaluationform.NOTICE' | translate }}"
                placeholder="{{ 'generic.INSERT' | translate }}" [txtAreaControl]="efForm.get('dismissalNotice')"
                [ngClass]="{ 'focus-form' : efForm.get('dismissalNotice')?.invalid && efForm.dirty }">
              </generali-text-area>

              <div class="d-flex flex-column gap-2">
                <div class="index-title">{{ 'candidate.evaluationform.TEN' | translate}} {{
                  'candidate.evaluationform.STRENGTH_BEHAVIOR_OPTIONAL' | translate}}</div>
                <div class="index-subtitle" i18n="@@candidate.evaluationform.STRENGTH_BEHAVIOR_SUBTITLE">
                  candidate.evaluationform.STRENGTH_BEHAVIOR_SUBTITLE</div>
              </div>

              <div class="w-100 d-flex flex-row justify-content-center container-behavior">
                <div class="behavior" (click)="changeBehavior('innovation')"
                  [ngClass]="this.behavior.innovation ? 'behavior-red' : 'behavior-grey'">
                  <img class="behavior-contents" src="assets/img/icons/icon_innovation.svg" />
                  <div class="behavior-contents title" i18n="@@candidate.evaluationform.INNOVATION">
                    candidate.evaluationform.INNOVATION</div>
                </div>
                <div class="behavior" (click)="changeBehavior('ownership')"
                  [ngClass]="this.behavior.ownership ? 'behavior-red' : 'behavior-grey'">
                  <img class="behavior-contents" src="assets/img/icons/icon_ownership.svg" />
                  <div class="behavior-contents title" i18n="@@candidate.evaluationform.OWNERSHIP">
                    candidate.evaluationform.OWNERSHIP</div>
                </div>
              </div>
              <div class="w-100 d-flex flex-row justify-content-center container-behavior">
                <div class="behavior" (click)="changeBehavior('humanTouch')"
                  [ngClass]="this.behavior.humanTouch ? 'behavior-red' : 'behavior-grey'">
                  <img class="behavior-contents" src="assets/img/icons/icon_human_touch.svg" />
                  <div class="behavior-contents title" i18n="@@candidate.evaluationform.HUMAN_TOUCH">
                    candidate.evaluationform.HUMAN_TOUCH</div>
                </div>
                <div class="behavior" (click)="changeBehavior('simplification')"
                  [ngClass]="this.behavior.simplification ? 'behavior-red' : 'behavior-grey'">
                  <img class="behavior-contents" src="assets/img/icons/icon_simplification.svg" />
                  <div class="behavior-contents title" i18n="@@candidate.evaluationform.SIMPLIFICATION">
                    candidate.evaluationform.SIMPLIFICATION</div>
                </div>
              </div>

              <div>
                <generali-text-area
                  txtAreaTitle="{{'candidate.evaluationform.TEN' | translate }} {{'candidate.evaluationform.FINAL_COMMMENT' | translate }}"
                  placeholder="{{ 'generic.INSERT' | translate }}" [txtAreaControl]="efForm.get('finalCommentResult')"
                  [ngClass]="{ 'focus-form' : efForm.get('finalCommentResult')?.invalid && efForm.dirty }">
                </generali-text-area>
              </div>

              <div>
                <generali-select placeholder="{{ 'generic.INSERT' | translate }}"
                  title="{{ 'candidate.evaluationform.SIX' | translate }} {{ 'candidate.evaluationform.FINAL_HIRING_RECOMMENDATION' | translate }}"
                  control="finalValuationResult" [dropdown]="finalValuationResultArray" bindLabel="label" position="top"
                  [group]="efForm"
                  [ngClass]="{ 'focus-form' : efForm.get('finalValuationResult')?.invalid && efForm.dirty }"
                  (onGetItem)="onChangefinalValuationResult($event)">
                </generali-select>
              </div>
            </div>
          </ng-container>

          <!-- LINE_MANAGER-->
          <ng-container *ngIf="interviewUserInfo?.type == 'LINE_MANAGER'">
            <div class="d-flex flex-column gap-2">

              <ng-container *ngFor="let item of competences;let i = index">
                <b class="mt-3">{{item.label}}</b>
                <star-rating name="{{item.property}}" [value]="item.evaluation" [readOnly]="formReadOnly"
                  [label]="'candidate.evaluationform.SYNTHETIC_EVALUATION'" [error]="errorStar[item.property]"
                  (onClickStar)="onsaveStar($event)"
                  [ngClass]="{ 'focus-form' : errorStar[item.property] }"></star-rating>

                <generali-text-area txtAreaTitle="{{item.subtitle}} {{'candidate.evaluationform.COMMENTS' | translate}}"
                  placeholder="{{ 'generic.INSERT' | translate }}"
                  [txtAreaControl]="efForm.get(item.property)"></generali-text-area>

              </ng-container>

              <div class="mt-2">
                <generali-text-area
                  txtAreaTitle="{{ 'candidate.evaluationform.THREE' | translate }} {{ 'candidate.evaluationform.AVAILABLE_DIFFERENT_POSITION_JOB_OPTIONAL' | translate }}"
                  placeholder="{{ 'generic.INSERT' | translate }}"
                  [txtAreaControl]="efForm.get('otherPosition')"></generali-text-area>
              </div>


              <div class="mt-2">
                <generali-text-area
                  txtAreaTitle="{{'candidate.evaluationform.FOUR' | translate }} {{'candidate.evaluationform.FINAL_COMMMENT' | translate }}"
                  placeholder="{{ 'generic.INSERT' | translate }}" [txtAreaControl]="efForm.get('finalCommentResult')"
                  [ngClass]="{ 'focus-form' : efForm.get('finalCommentResult')?.invalid && efForm.dirty }">
                </generali-text-area>
              </div>

              <div class="mt-2">
                <generali-select placeholder="{{ 'generic.SELECT' | translate }}"
                  title="{{ 'candidate.evaluationform.FIVE' | translate }} {{ 'candidate.evaluationform.FINAL_HIRING_RECOMMENDATION' | translate }}"
                  control="finalValuationResult" [dropdown]="finalValuationResultArray" bindLabel="label" position="top"
                  [group]="efForm"
                  [ngClass]="{ 'focus-form' : efForm.get('finalValuationResult')?.invalid && efForm.dirty }"
                  (onGetItem)="onChangefinalValuationResult($event)">
                </generali-select>
              </div>
            </div>
          </ng-container>


          <!-- BUSINESS_INTERVIEWER  -->
          <ng-container *ngIf="interviewUserInfo?.type == 'BUSINESS_INTERVIEWER'">
            <div class="d-flex flex-column gap-4">

              <div class="d-flex flex-column gap-2">
                <div class="index-title">{{ 'candidate.evaluationform.FIRST' | translate}} {{
                  'candidate.evaluationform.SYNTHETIC_EVALUATION' | translate}}</div>

                <star-rating name="interests" [readOnly]="formReadOnly" [error]="errorStar.interests"
                  [value]="this.propertiesForm.businessInterview.interests"
                  [label]="'candidate.evaluationform.INTEREST_COMPANY'" (onClickStar)="onsaveStar($event)"
                  [ngClass]="{ 'focus-form' : errorStar.interests }"></star-rating>

                <star-rating name="motivation" [readOnly]="formReadOnly" [error]="errorStar.motivation"
                  [value]="this.propertiesForm.businessInterview.motivation"
                  [label]="'candidate.evaluationform.MOTIVATION_FOR_RULE'" (onClickStar)="onsaveStar($event)"
                  [ngClass]="{ 'focus-form' : errorStar.motivation }"></star-rating>
              </div>

              <div class="d-flex flex-column gap-2">
                <div class="index-title">{{ 'candidate.evaluationform.TWO' | translate}} {{
                  'candidate.evaluationform.HUMAN_TOUCH' | translate}}</div>
                <div class="index-subtitle" i18n="@@candidate.evaluationform.HUMAN_TOUCH_SUBTITLE">
                  candidate.evaluationform.HUMAN_TOUCH_SUBTITLE</div>

                <star-rating name="trust" [readOnly]="formReadOnly" [error]="errorStar.trust"
                  [value]="this.propertiesForm.businessInterview.trust"
                  [label]="'candidate.evaluationform.BUILDING_TRUST'" (onClickStar)="onsaveStar($event)"
                  [ngClass]="{ 'focus-form' : errorStar.trust }"></star-rating>

                <star-rating name="teamwork" [readOnly]="formReadOnly" [error]="errorStar.teamwork"
                  [value]="this.propertiesForm.businessInterview.teamwork" [label]="'candidate.evaluationform.TEAMWORK'"
                  (onClickStar)="onsaveStar($event)" [ngClass]="{ 'focus-form' : errorStar.teamwork }">
                </star-rating>
              </div>

              <div class="d-flex flex-column gap-2">
                <div class="index-title">{{ 'candidate.evaluationform.THREE' | translate}} {{
                  'candidate.evaluationform.INNOVATION' | translate}}</div>
                <div class="index-subtitle" i18n="@@candidate.evaluationform.HUMAN_TOUCH_SUBTITLE">
                  candidate.evaluationform.INNOVATION_SUBTITLE</div>

                <star-rating name="innovatitve" [readOnly]="formReadOnly"
                  [value]="this.propertiesForm.businessInterview.innovatitve"
                  [label]="'candidate.evaluationform.INNOVATIVE_SOLUTION'" [error]="errorStar.innovatitve"
                  (onClickStar)="onsaveStar($event)"></star-rating>
                <star-rating name="openess" [readOnly]="formReadOnly"
                  [value]="this.propertiesForm.businessInterview.openess" [label]="'candidate.evaluationform.OPENNESS'"
                  [error]="errorStar.openess" (onClickStar)="onsaveStar($event)">
                </star-rating>
              </div>

              <div class="d-flex flex-column gap-2">
                <div class="index-title">{{ 'candidate.evaluationform.FOUR' | translate}} {{
                  'candidate.evaluationform.OWNERSHIP' | translate}}</div>
                <div class="index-subtitle" i18n="@@candidate.evaluationform.HUMAN_TOUCH_SUBTITLE">
                  candidate.evaluationform.OWNERSHIP_SUBTITLE</div>

                <star-rating name="proactivity" [readOnly]="formReadOnly"
                  [value]="this.propertiesForm.businessInterview.proactivity" [error]="errorStar.proactivity"
                  [label]="'candidate.evaluationform.PROACTIVITY'" (onClickStar)="onsaveStar($event)"
                  [ngClass]="{ 'focus-form' : errorStar.proactivity }"></star-rating>
                <star-rating name="execution" [readOnly]="formReadOnly" [error]="errorStar.execution"
                  [value]="this.propertiesForm.businessInterview.execution"
                  [label]="'candidate.evaluationform.EXECUTION'" (onClickStar)="onsaveStar($event)"
                  [ngClass]="{ 'focus-form' : errorStar.execution }"></star-rating>

              </div>

              <div class="d-flex flex-column gap-2">
                <div class="index-title">{{ 'candidate.evaluationform.FIVE' | translate}} {{
                  'candidate.evaluationform.SIMPLIFICATION' | translate}}</div>
                <div class="index-subtitle" i18n="@@candidate.evaluationform.HUMAN_TOUCH_SUBTITLE">
                  candidate.evaluationform.SIMPLIFICATION</div>

                <star-rating name="makingSimple" [readOnly]="formReadOnly"
                  [value]="this.propertiesForm.businessInterview.makingSimple"
                  [label]="'candidate.evaluationform.MAKING_THINGS_SIMPLE'" [error]="errorStar.makingSimple"
                  (onClickStar)="onsaveStar($event)"
                  [ngClass]="{ 'focus-form' : errorStar.makingSimple }"></star-rating>
                <star-rating name="adaptability" [readOnly]="formReadOnly"
                  [value]="this.propertiesForm.businessInterview.adaptability" [error]="errorStar.adaptability"
                  [label]="'candidate.evaluationform.ADAPTABILITY'" (onClickStar)="onsaveStar($event)"
                  [ngClass]="{ 'focus-form' : errorStar.adaptability }"></star-rating>
              </div>

              <div>
                <generali-text-area
                  txtAreaTitle="{{'candidate.evaluationform.FOUR' | translate }} {{'candidate.evaluationform.FINAL_COMMMENT' | translate }}"
                  placeholder="{{ 'generic.INSERT' | translate }}" [txtAreaControl]="efForm.get('finalCommentResult')"
                  [ngClass]="{ 'focus-form' : efForm.get('finalCommentResult')?.invalid && efForm.dirty }">
                </generali-text-area>
              </div>

              <div>
                <generali-select placeholder="{{ 'generic.CHOOSE' | translate }}"
                  title="{{ 'candidate.evaluationform.FIVE' | translate }} {{ 'candidate.evaluationform.FINAL_HIRING_RECOMMENDATION' | translate }}"
                  control="finalValuationResult" [dropdown]="finalValuationResultArray" bindLabel="label" position="top"
                  [group]="efForm"
                  [ngClass]="{ 'focus-form' : efForm.get('finalValuationResult')?.invalid && efForm.dirty }"
                  (onGetItem)="onChangefinalValuationResult($event)">
                </generali-select>
              </div>

            </div>
          </ng-container>


          <!-- HRBP_SALE-->
          <ng-container *ngIf="interviewUserInfo?.type == 'HRBP_SALE'">
            <div class="d-flex flex-column gap-4">

              <div class="d-flex flex-column gap-1">
                <div class="index-title"></div>
                <generali-input
                  titleLabel="{{ 'candidate.evaluationform.FIRST' | translate }}  {{ 'candidate.evaluationform.REFERENCE_LOCATION' | translate }}"
                  [inputControl]="efForm.get('heading')" [placeholder]="'Inserisci'"
                  [ngClass]="{ 'focus-form' : efForm.get('heading')?.invalid && efForm.dirty }"></generali-input>
              </div>

              <div class="d-flex flex-column gap-1">
                <generali-input
                  titleLabel="{{ 'candidate.evaluationform.TWO' | translate }}  {{ 'candidate.evaluationform.SENIORITY' | translate }}"
                  [inputControl]="efForm.get('professionalSeniority')" [placeholder]="'Inserisci'"
                  [ngClass]="{ 'focus-form' : efForm.get('professionalSeniority')?.invalid && efForm.dirty }"></generali-input>
              </div>

              <div class="d-flex flex-column gap-1">
                <generali-text-area
                  txtAreaTitle="{{ 'candidate.evaluationform.THREE' | translate}} {{ 'candidate.evaluationform.BANKING_EXPERIENCE' | translate }}"
                  placeholder="{{ 'generic.INSERT' | translate }}"
                  [txtAreaControl]="efForm.get('previousWorkExperience')"
                  [ngClass]="{ 'focus-form' : efForm.get('previousWorkExperience')?.invalid && efForm.dirty }">
                </generali-text-area>
                <div class="index-number" i18n="@@candidate.evaluationform.BANKING_EXPERIENCE_FOOTER">
                  candidate.evaluationform.BANKING_EXPERIENCE_FOOTER</div>
              </div>


              <div class="d-flex flex-column gap-1">
                <div class="index-title">{{ 'candidate.evaluationform.FOUR' | translate}} {{
                  'candidate.evaluationform.SALARY_ACTUAL_OPTIONAL' | translate}}</div>

                <generali-input type="currency" [inputControl]="actualRetributionHRBPSALES" [placeholder]="'Inserisci'"
                  placeholderImg="assets/img/icons/icon-euro.svg"
                  (onChange)="onActualRetributionChangeEvFormHBRPSales($event)">
                </generali-input>
                <div class="index-number" i18n="@@candidate.evaluationform.INSERT_NUMBER">
                  candidate.evaluationform.INSERT_NUMBER</div>
              </div>

              <generali-text-area
                txtAreaTitle="{{ 'candidate.evaluationform.FIVE' | translate}} {{ 'candidate.evaluationform.CONTRACT_AND_LEVEL_ACTUAL_OPTIONAL' | translate }}"
                placeholder="{{ 'generic.INSERT' | translate }}" [txtAreaControl]="efForm.get('actualContract')">
              </generali-text-area>

              <generali-text-area
                txtAreaTitle="{{ 'candidate.evaluationform.SIX' | translate}} {{ 'candidate.evaluationform.BENEFIT_ACTUAL_OPTIONAL' | translate }}"
                placeholder="{{ 'generic.INSERT' | translate }}" [txtAreaControl]="efForm.get('actualBenefit')">
              </generali-text-area>

              <div class="d-flex flex-column gap-1">
                <generali-input
                  titleLabel="{{ 'candidate.evaluationform.SEVEN' | translate }}  {{ 'candidate.evaluationform.NOTICE_OPTIONAL' | translate }}"
                  [inputControl]="efForm.get('dismissalNotice')" [placeholder]="'Inserisci'"
                  [ngClass]="{ 'focus-form' : efForm.get('dismissalNotice')?.invalid && efForm.dirty }"></generali-input>
              </div>

              <div class="d-flex flex-column gap-2">
                <div class="index-title">{{ 'candidate.evaluationform.EIGHT' | translate}} {{
                  'candidate.evaluationform.COMMUNICATION' | translate}}</div>
                <star-rating name="communication" [readOnly]="formReadOnly"
                  [value]="this.propertiesForm.hrbpSale.communication"
                  [label]="'candidate.evaluationform.COMMUNICATION_TEXT'" [error]="errorStar.communication"
                  (onClickStar)="onsaveStar($event)"
                  [ngClass]="{ 'focus-form' : errorStar.communication }"></star-rating>
              </div>

              <div class="d-flex flex-column gap-2">
                <div class="index-title">{{ 'candidate.evaluationform.NINE' | translate}} {{
                  'candidate.evaluationform.GOAL_ORIENTED' | translate}}</div>
                <star-rating name="entrepreneurship" [readOnly]="formReadOnly"
                  [value]="this.propertiesForm.hrbpSale.entrepreneurship"
                  [label]="'candidate.evaluationform.GOAL_ORIENTED_TEXT'" [error]="errorStar.entrepreneurship"
                  (onClickStar)="onsaveStar($event)"
                  [ngClass]="{ 'focus-form' : errorStar.entrepreneurship }"></star-rating>
              </div>

              <div>
                <generali-text-area
                  txtAreaTitle="{{'candidate.evaluationform.NINE_A' | translate }} {{'candidate.evaluationform.COMMENT' | translate }}"
                  placeholder="{{ 'generic.INSERT' | translate }}"
                  [txtAreaControl]="efForm.get('entrepreneurshipComment')"
                  [ngClass]="{ 'focus-form' : efForm.get('entrepreneurshipComment')?.invalid && efForm.dirty }">
                </generali-text-area>
              </div>

              <div class="d-flex flex-column gap-2">
                <div class="index-title">{{ 'candidate.evaluationform.TEN' | translate}} {{
                  'candidate.evaluationform.ROLE' | translate}}</div>
                <star-rating name="motivation" [readOnly]="formReadOnly"
                  [value]="this.propertiesForm.hrbpSale.motivation" [label]="'candidate.evaluationform.ROLE_TEXT'"
                  [error]="errorStar.motivation" (onClickStar)="onsaveStar($event)"
                  [ngClass]="{ 'focus-form' : errorStar.motivation }"></star-rating>
              </div>

              <div>
                <generali-text-area
                  txtAreaTitle="{{'candidate.evaluationform.TEN_A' | translate }} {{'candidate.evaluationform.COMMENT' | translate }}"
                  placeholder="{{ 'generic.INSERT' | translate }}" [txtAreaControl]="efForm.get('motivationComment')"
                  [ngClass]="{ 'focus-form' : efForm.get('motivationComment')?.invalid && efForm.dirty }">
                </generali-text-area>
              </div>

              <div class="d-flex flex-column gap-2">
                <div class="index-title">{{ 'candidate.evaluationform.ELEVEN' | translate}} {{
                  'candidate.evaluationform.CUSTOMER_ORIENTED' | translate}}</div>
                <star-rating name="customerOrientation" [readOnly]="formReadOnly"
                  [value]="this.propertiesForm.hrbpSale.customerOrientation"
                  [label]="'candidate.evaluationform.CUSTOMER_ORIENTED_TEXT'" [error]="errorStar.customerOrientation"
                  (onClickStar)="onsaveStar($event)"
                  [ngClass]="{ 'focus-form' : errorStar.customerOrientation }"></star-rating>
              </div>

              <div class="d-flex flex-column gap-2">
                <div class="index-title">{{ 'candidate.evaluationform.TWELVE' | translate}} {{
                  'candidate.evaluationform.ADAPTABILITY_FORM' | translate}}</div>
                <star-rating name="adaptability" [readOnly]="formReadOnly"
                  [value]="this.propertiesForm.hrbpSale.adaptability"
                  [label]="'candidate.evaluationform.ADAPTABILITY_FORM_TEXT'" [error]="errorStar.adaptability"
                  (onClickStar)="onsaveStar($event)"
                  [ngClass]="{ 'focus-form' : errorStar.adaptability }"></star-rating>
              </div>

              <div>
                <generali-text-area
                  txtAreaTitle="{{'candidate.evaluationform.THIRTEEN' | translate }} {{'candidate.evaluationform.FINAL_COMMMENT' | translate }}"
                  placeholder="{{ 'generic.INSERT' | translate }}" [txtAreaControl]="efForm.get('finalCommentResult')"
                  [ngClass]="{ 'focus-form' : efForm.get('finalCommentResult')?.invalid && efForm.dirty }">
                </generali-text-area>
              </div>

              <div>
                <generali-select placeholder="{{ 'generic.CHOOSE' | translate }}"
                  title="{{ 'candidate.evaluationform.FOURTEEN' | translate }} {{ 'candidate.evaluationform.FINAL_HIRING_RECOMMENDATION' | translate }}"
                  control="finalValuationResult" [dropdown]="finalValuationResultArray" bindLabel="label" position="top"
                  [group]="efForm"
                  [ngClass]="{ 'focus-form' : efForm.get('finalValuationResult')?.invalid && efForm.dirty }"
                  (onGetItem)="onChangefinalValuationResult($event)">
                </generali-select>
              </div>

            </div>
          </ng-container>
        </form>
      </div>
    </ng-container>

    <!-- BTN  ANNULLA / SALVA BOZZA /SUBMIT -->
    <ng-container footer>
      <div class="ef-footer">
        <ng-container *ngIf="interviewUserInfo?.formCompiledAt">
          <div class="d-flex btn-footer">
            <generali-button class="me-1" btnItem="{{ 'generic.CANCEL' | translate }}" btnSize="auto" btnStyle="ghost"
              (onClick)="onCloseEvalutionForm()"></generali-button>

            <generali-button class="me-1" btnItem="{{ 'generic.DELETE' | translate | uppercase}}" btnSize="auto"
              btnStyle="red" (onClick)="onCancelEvalutionForm()" [disabled]="!usrService.isEBRM"></generali-button>
          </div>
        </ng-container>

        <ng-container *ngIf="interviewUserInfo?.formCompiledAt == undefined">
          <div class="d-flex btn-footer">
            <generali-button class="me-1" btnItem="{{ 'generic.CANCEL' | translate }}" btnSize="auto" btnStyle="ghost"
              (onClick)="onCloseEvalutionForm()"></generali-button>
            <generali-button class="me-1" btnItem="{{ 'generic.SAVE_DRAFT' | translate | uppercase}}" btnSize="auto"
              btnStyle="red" (onClick)="onSumbitEvalutionForm(false)"></generali-button>
            <generali-button btnItem="{{ 'generic.SUBMIT' | translate | uppercase }}" btnSize="auto" btnStyle="green"
              (onClick)="onSumbitEvalutionForm(true)"></generali-button>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </generali-drawer>

  <!-- MODALE CHE TI FA VISUALIZZARE FULL-SCREEN IL CV DI UN CANDIDATO -->
  <generali-modal id="full-screen-cv">
    <pdf-viewer [render-text]="true" [original-size]="false" *ngIf="candidateSheetService.jobApplication?.cvUpload?.url"
      [src]="candidateSheetService.jobApplication?.cvUpload?.url ?? ''"
      style="width: 100%; min-height: 600px; height: 100%;" (error)="onPdfError()">
    </pdf-viewer>
  </generali-modal>

  <confirm-cancel-modal id="evaluationForm" modalText="{{ 'modal.NO_SAVE_DATA' | translate }}"
    (onCloseNavigateAwayModal)="onCancelLeavingEvaluationFormSave()"
    (onConfirm)="onConfirmLeavingEvaluationFormWithoutSave()" (onCancel)="onCancelLeavingEvaluationFormSave()"
    btnApply="{{ 'modal.APPLY' | translate }}" btnCancel="{{ 'modal.CANCEL' | translate }}">
  </confirm-cancel-modal>
</ng-container>


<confirm-cancel-modal id="confirm-accept-application" modalText="{{ confirmCloseModalInfo | confirmCloseModalTxt }}"
  (onCloseNavigateAwayModal)="onCloseModal('confirm-accept-application')" (onConfirm)="onChangeStatus(statusToChange, true)"
  (onCancel)="onCloseModal('confirm-accept-application')" btnApply="{{ 'generic.CONTINUE' | translate }}"
  btnCancel="{{ 'generic.CANCEL' | translate }}" [alignLeft]="true">
</confirm-cancel-modal>
