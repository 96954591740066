<div class="row mt-3">
  <div class="title col-12 mb-4">
    <p i18n="@@qEs.LOAD_QUESTION_TITLE">qEs.LOAD_QUESTION_TITLE</p>
  </div>
  <div class="col-12 d-flex">
    <p class="link enable" i18n="@@qEs.DOWNLOAD_QUESTION" role="button" (click)="onDownloadSurveyTemplate()">
      qEs.DOWNLOAD_QUESTION
    </p>
    <label for="load-file" class="link" [class.enable]="!createPositionService.jobPosition?.surveyTemplate"
      i18n="@@qEs.LOAD_QUESTION" [class.disabled]="createPositionService.jobPosition?.surveyTemplate"
      role="button">qEs.LOAD_QUESTION</label>
    <input [disabled]="createPositionService.jobPosition?.surveyTemplate" id="load-file" class="d-none" type="file"
      formEnctype="multipart/form-data" (change)="onUploadSurveyTemplate($event)">
  </div>
</div>

<div class="divider my-4"></div>

<div class="row">
  <div class="title col-12 mb-4">
    <p i18n="@@qEs.LOAD_FILE">qEs.LOAD_FILE</p>
  </div>
  <div class="col-12 d-flex">
    <generali-chip *ngIf="createPositionService.jobPosition?.surveyTemplate?.fileName; else noData" [clearable]="true"
      [isSelected]="true" [labelItem]="createPositionService.jobPosition?.surveyTemplate?.fileName ?? ''"
      (onChipExclude)="onRemoveSurveyTemplate()"></generali-chip>
    <ng-template #noData>
      <p class="no-data" i18n="@@generic.NO_DATA">generic.NO_DATA</p>
    </ng-template>
  </div>
</div>

<!-- SCREENING TEST-->
<div class="my-4" [ngClass]="{ 'divider' : createPositionService.includeRowByPerimeter }"></div>
<ng-container *ngIf="createPositionService.includeRowByPerimeter">
  <div class="row mb-5">
    <div class="title col-12 mb-4">
      <p i18n="@@qEs.SCREENING">qEs.SCREENING</p>
    </div>
    <div class="col-12 col-md-6 col-lg-6">
      <generali-select [control]="createPositionService.formCl.SCREENING_TEST_COLLECTION"
        [group]="createPositionService.questionScreeningForm" title="{{ 'qEs.TEST_TYPE' | translate }}"
        [dropdown]="screeningDropdown" bindLabel="collectionName" placeholder="{{ 'generic.CHOOSE' | translate }}">
      </generali-select>
    </div>
  </div>
</ng-container>

<!-- <pre>{{ createPositionService.jobPosition?.surveyTemplate | json }}</pre> -->