import { Component, Input } from "@angular/core";

@Component({
  selector: "search-title",
  templateUrl: "./search-title.component.html",
  styleUrls: ["./search-title.component.scss"]
})
export class SearchTitleComponent {
  @Input() count?: number;
  @Input() searchedText?: string;
}
