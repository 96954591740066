<ng-container *ngIf="!isLoading">
  <div class="flex-row ps-1 pe-1">
    <!-- BREAD CRUMBS -->
    <div class="col-12">
      <p class="bread-crumb">
        <ng-container *ngIf="breadCrumb == 'home'">
          <span class="me-1" i18n="@@position.HOME" (click)="redirectToHome()" role="button">position.HOME</span>
          <img class="align-baseline mx-1" src="assets/img/icons/chevron_right.svg">
        </ng-container>
        <ng-container *ngIf="breadCrumb == 'search'">
          <span class="me-1 text-uppercase" i18n="@@home.SEARCH" (click)="redirectToSearch()"
            role="button">home.SEARCH</span>
          <img class="align-baseline mx-1" src="assets/img/icons/chevron_right.svg">
        </ng-container>
      </p>
    </div>
  </div>

  <div class="d-flex flex-column flex-sm-row justify-content-between mb-3 ps-1 pe-1 gap-3 gap-sm-0">
    <!-- TITOLO -->
    <p class="position-title">
      <span class="trunc">
        <!-- {{ positionDetailService.fetchData.filteredJobPosition?.currentStatusDate | date:'yy' }}-{{ -->
        {{ positionDetailService.fetchData.filteredJobPosition?.progressiveCodeDecoded }}
        {{ positionDetailService.fetchData.filteredJobPosition?.title }}
      </span>
    </p>
    <!-- SELECT AZIONI -->
    <actions-dropdown [actions]="positionDetailService.actionOptions" (onClick)="onSimpleSelectClick($event)">
    </actions-dropdown>
  </div>

  <!-- TABELLA DETTAGLIO -->
  <generali-table [jobPosition]="positionDetailService.fetchData.filteredJobPosition">
  </generali-table>

  <div class="flex-row mt-3 ps-1 pe-1">
    <p class="applicant-count d-inline-block mb-3">{{ positionDetailService.fetchData.jobApplicationCount }}</p>
    <p class="applicant d-inline-block ms-2" i18n="@@position.detail.APPLICANT">position.detail.APPLICANT</p>
  </div>


  <!-- Filtri selezionati e attivi -->
  <div class="active-filter ps-1 pe-1" *ngIf="positionDetailService.filterIsApplied">
    <p i18n="@@home.ACTIVE_FILTER">home.ACTIVE_FILTER</p>
    <div class="active-filter_list d-flex flex-wrap gap-2">

      <!-- STATUS -->
      <ng-container
        *ngFor="let chip of positionDetailService.appliedPositionFilter.jobApplicationStatus; let idx=index">
        <generali-chip [clearable]="true" [labelItem]="chip | renderingStatus" [isSelected]="true"
          (onChipExclude)="onStatusExclude(idx)">
        </generali-chip>
      </ng-container>

      <!-- KEYWORD -->
      <!-- <ng-container *ngFor="let keyword of appliedKeywords; let i='index'"> -->

      <generali-chip [clearable]="true" *ngIf="positionDetailService.appliedPositionFilter.keyword"
        [labelItem]="positionDetailService.appliedPositionFilter.keyword" [isSelected]="true"
        (onChipExclude)="removeKeyWSelected()">
      </generali-chip>
      <!-- </ng-container> -->

      <!-- REASSIGNMENT -->
      <generali-chip [clearable]="true" *ngIf="positionDetailService.appliedPositionFilter.reassigned !== undefined"
        (onChipExclude)="onReasignedExclude()"
        labelItem="{{ 'position.detail.REASSIGNMENT' | translate }} {{ positionDetailService.appliedPositionFilter.reassigned | RenderBool }}"
        [isSelected]="true">
      </generali-chip>

      <!-- REFERRAL -->
      <generali-chip [clearable]="true" *ngIf="positionDetailService.appliedPositionFilter.referral != undefined"
        (onChipExclude)="onReferralExclude()"
        labelItem="{{ 'position.REFERRAL' | translate }} {{ positionDetailService.appliedPositionFilter.referral | RenderBool }}"
        [isSelected]="true">
      </generali-chip>

      <!-- TALENT -->
      <generali-chip [clearable]="true" *ngIf="positionDetailService.appliedPositionFilter.talent != undefined"
        (onChipExclude)="onTalentExclude()"
        labelItem="{{ 'position.detail.TALENT' | translate }} {{ positionDetailService.appliedPositionFilter.talent | RenderBool }}"
        [isSelected]="true">
      </generali-chip>

      <!-- FEEDBACK EMAIL -->
      <generali-chip [clearable]="true"
        *ngIf="positionDetailService.appliedPositionFilter.feedbackMailSent != undefined"
        (onChipExclude)="onFeedBackEmailExclude()"
        labelItem="{{ 'filter.FEEDBACK_EMAIL' | translate }}: {{ positionDetailService.appliedPositionFilter.feedbackMailSent | RenderBool }}"
        [isSelected]="true">
      </generali-chip>

      <!-- GENRE -->
      <ng-container *ngIf="positionDetailService.appliedPositionFilter.gender != undefined">
        <generali-chip [clearable]="true" [labelItem]="positionDetailService.appliedPositionFilter.gender | RenderBool"
          (onChipExclude)="onGenreExclude()" [isSelected]="true">
        </generali-chip>
      </ng-container>

      <!-- NUOVO ITALIANO -->
      <ng-container *ngIf="positionDetailService.appliedPositionFilter.newItalian !== undefined">
        <generali-chip [clearable]="true" (onChipExclude)="onNewItalianExclude()"
          labelItem="{{ 'position.detail.NEW_ITA' | translate }} {{ positionDetailService.appliedPositionFilter.newItalian | RenderBool }}"
          [isSelected]="true">
        </generali-chip>
      </ng-container>

      <!-- CATEGORIA PROTETTA -->
      <ng-container *ngIf="positionDetailService.appliedPositionFilter.legallyProtected != undefined">
        <generali-chip [clearable]="true"
          labelItem="{{ 'position.detail.PROTECTED_CATEGORY' | translate }} {{ positionDetailService.appliedPositionFilter.legallyProtected | RenderBool }}"
          [isSelected]="true" (onChipExclude)="onProtectedCategoryExclude()">
        </generali-chip>
      </ng-container>

      <!-- FASCIA DI ETÀ -->
      <ng-container *ngFor="let age of positionDetailService.appliedPositionFilter.ageRange; let idx=index">
        <generali-chip [clearable]="true" labelItem="{{ 'filter.AGE_RANGE' | translate }}: {{ age }} "
          (onChipExclude)="onAgeRangeExclude(idx)" [isSelected]="true">
        </generali-chip>
      </ng-container>

    </div>
  </div>


  <!-- Filtri -->
  <div class="d-flex flex-column flex-sm-row mt-4 mb-4 ps-1 pe-1">
    <div class="d-flex flex-fill">
      <generali-checkbox bindName="select-all" class="d-flex flex-row align-items-center"
        (onSelectcheckbox)="onSelectAll($event)"
        [isDisabled]="positionDetailService.fetchData.jobApplicationList?.length === 0">
        <p i18n="@@position.detail.SELECT_ALL">position.detail.SELECT_ALL</p>
      </generali-checkbox>
    </div>

    <div class="d-flex justify-content-sm-end justify-content-start mt-3 mt-sm-0 filter">
      <p class="m-0 mx-2 d-flex justify-content-center align-items-center" role="button"
        (click)="positionDetailService.openOrderTab()">
        <img class="me-2" src="assets/img/icons/arrow_small.svg">
        <span class="heading" i18n="@@generic.ORDER">generic.ORDER</span>
      </p>

      <p class="m-0 mx-2 d-flex justify-content-center align-items-center" role="button"
        (click)="positionDetailService.openFilterTab()">
        <img class="me-2" src="assets/img/icons/burger-small.svg">
        <span class="heading" i18n="@@generic.FILTER">@@generic.FILTER</span>
      </p>
    </div>
  </div>

  <!-- CARDS -->
  <ng-container
    *ngIf="!isLoadingJobApplicationList && positionDetailService.fetchData.jobApplicationList?.length; else noData">
    <div class="overflow-auto p-1" id="ancor-tag">
      <div *ngFor="let jobApplication of positionDetailService.fetchData.jobApplicationList; let i=index" class="mb-2">
        <card-application-detail [jobApplication]="jobApplication"
          [isSelected]="positionDetailService.cardsSelected.includes(jobApplication.jobApplicationId)"
          (onClick)="redirectToCandidate($event)" (cardSelected)="onCardSelection(jobApplication.jobApplicationId)"
          (onRealizedApplicants)="toggleRealizedApplicants($event, jobApplication)"
          [screeningTest]="jobApplication.screeningTestInstances">
        </card-application-detail>
      </div>
    </div>

    <!-- Paginazione -->
    <div class="pagination-container d-flex flex-column flex-sm-row justify-content-sm-center my-5">
      <!-- Paginazione -->
      <div class="d-flex justify-content-center flex-grow-1 pag">
        <ngb-pagination class="align-self-center"
          [collectionSize]="positionDetailService.fetchData.jobApplicationCount || 0" [maxSize]="3" [rotate]="true"
          [(page)]="positionDetailService.currentPage" [pageSize]="positionDetailService.numRecord"
          (pageChange)="onPageChange()">
          <ng-template ngbPaginationFirst>First</ng-template>
        </ngb-pagination>
      </div>
      <!-- <div class="col-lg-1 col-md-2 col-4 flex-grow-0 align-self-center mt-sm-0 d-flex"> -->
      <div class="d-flex align-self-center mt-sm-0 ms-sm-auto mt-3">
        <div class="pagination-counter">
          <generali-select control="pagination" [group]="positionDetailService.genericSetup" bindLabel="numRecord"
            position="top" [dropdown]="positionDetailService.perPageOption" (onGetValue)="onChangePaginationCount()">
          </generali-select>
        </div>
        <span class="d-flex align-items-center ps-2" i18n="@@generic.ELEMENTS">generic.ELEMENTS</span>
      </div>
    </div>

    <div class="last" *ngIf="positionDetailService.cardsSelected.length > 0"></div>
  </ng-container>

  <!-- LOADER -->
  <div appLoader [loading]="isLoadingJobApplicationList"></div>

  <ng-template #noData>
    <div *ngIf="!isLoadingJobApplicationList" class="d-flex justify-content-center align-items-center flex-column p-5">
      <img src="assets/img/icons/no-table-data.svg" alt="">
      <p class="mt-4 no-data-found" i18n="@@home.NO_TABLE_DATA">home.NO_TABLE_DATA</p>
    </div>
  </ng-template>

  <!-- SEZIONE STATUS CHANGE BOTTONI -->
  <ng-container *ngIf="positionDetailService.cardsSelected.length > 0">
    <div class="massive-status-change row">
      <div class="selected-applicant col-12 col-md-4">
        <p class="mb-0" *ngIf="positionDetailService.cardsSelected.length === 1">1 Candidato selezionato</p>
        <p class="mb-0" *ngIf="positionDetailService.cardsSelected.length > 1">{{
          positionDetailService.cardsSelected.length }} Candidati selezionati</p>
      </div>
      <div class="action-button d-flex col-12 col-md-8 justify-content-end">
        <div class="btn-container">
          <generali-button class="me-2 w-100" btnStyle="ghost" btnSize="auto"
            btnItem="{{ 'generic.CANCEL' | translate }}" (onClick)="onClearCardSelected()"></generali-button>
        </div>
        <div class="btn-container">
          <generali-button class="ms-2 w-100" btnStyle="red" btnSize="auto"
            btnItem="{{ 'create.CHANGE_STATUS' | translate }}"
            (onClick)="positionDetailService.onOpenMassiveStausChange()"></generali-button>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<generali-drawer [showDrawer]="positionDetailService.drawer.showRealizedApplicants"
  drawerTitle="{{ 'candidate.HISTORY' | translate: { owner: positionDetailService.jobApplicationHistoryOwner } }}"
  drawerSubTitle="Seleziona la candidatura" drawerDimension="LG" (onClosingDrawerTab)="onClosingFilterDrawer()">
  <ng-container main>
    <div class="d-flex flex-column gap-3">
      <ng-container *ngIf="!sidebarLoader">
        <card-application-detail *ngFor="let history of positionDetailService.fetchData.jobApplicationHistory"
        [canAccessDetails]="canAccessDetailsAsHrbp(history)" [compactMode]="true" [isSelectable]="true"
        [jobApplication]="history" [singleSelectMode]="true" bindName="candidatura"
        (onClick)="redirectToCandidate($event)" [screeningTest]="history.screeningTestInstances">
      </card-application-detail>
      </ng-container>
      <!-- LOADER -->
      <div appLoader [loading]="sidebarLoader"></div>
      <generali-warn *ngIf="usrService.isHRBP" icon="assets/img/icons/warning.svg">
        <ng-container warn>
          <p class="m-0">{{ "generic.WARNING" | translate }}</p>
        </ng-container>
        <ng-container message>
          <p class="m-0">{{ "candidate.warn.HRBP" | translate }}</p>
        </ng-container>
      </generali-warn>
    </div>
  </ng-container>
</generali-drawer>

<!-- SELEZIONE ORDINAMENTO -->
<generali-drawer drawerImg="assets/img/icons/arrow_small.svg" drawerTitle="Ordina"
  drawerSubTitle="{{ 'position.order.TITLE' | translate }}" (onClosingDrawerTab)="onClosingFilterDrawer()"
  [showDrawer]="positionDetailService.drawer.showPositionOrders">
  <ng-container main>
    <div class="filter_tab" *ngFor="let order of positionDetailService.positionOrdersOptions">
      <div class="tab_name">{{ order.sortingField | renderingOrder }}</div>
      <div class="tab_status">

        <div class="tab_img" (click)="positionDetailService.selectedUp(order)">
          <!-- Non selezionato -->
          <img *ngIf="order.sortingField !== positionDetailService.selectedOrder?.sortingField" class="tab_img-up"
            src="assets/img/icons/arrow-up.svg">
          <!-- Selezionato e attivo -->
          <img
            *ngIf="order.sortingField === positionDetailService.selectedOrder?.sortingField && !positionDetailService.selectedOrder?.sortingDesc"
            class="tab_img-up" src="assets/img/icons/arrow-up-selected.svg">
          <!-- Selezione attiva ma altra direction -->
          <img
            *ngIf="order.sortingField === positionDetailService.selectedOrder?.sortingField && positionDetailService.selectedOrder?.sortingDesc"
            class="tab_img-up" src="assets/img/icons/arrow-up.svg">
        </div>
        <div class="tab_img" (click)="positionDetailService.selectedDown(order)">
          <!-- Nessuna selezione (DEFAULT) -->
          <img *ngIf="order.sortingField !== positionDetailService.selectedOrder?.sortingField" class="tab_img-down"
            src="assets/img/icons/arrow-down.svg">
          <!-- Selezione attiva -->
          <img
            *ngIf="order.sortingField === positionDetailService.selectedOrder?.sortingField && positionDetailService.selectedOrder?.sortingDesc"
            class="tab_img-down" src="assets/img/icons/arrow-down-selected.svg">
          <!-- Selezione attiva ma altra direction -->
          <img
            *ngIf="order.sortingField === positionDetailService.selectedOrder?.sortingField && !positionDetailService.selectedOrder?.sortingDesc"
            class="tab_img-down" src="assets/img/icons/arrow-down.svg">
        </div>

      </div>
    </div>
  </ng-container>

  <ng-container footer>
    <div class="filter_footer d-flex justify-content-center align-items-center flex-column">
      <generali-button btnItem="{{ 'generic.APPLY' | translate }}" btnStyle="red" btnSize="sm" (click)="onApplyOrder()">
      </generali-button>
      <div class="filter_reset" (click)="onResetAppliedOrder()">
        <p class="mt-3" role="button" i18n="@@generic.DEFAULT">generic.DEFAULT</p>
      </div>
    </div>
  </ng-container>
</generali-drawer>

<!-- SELEZIONE FILTRI -->
<generali-drawer [drawerSubTitle]="" filterImg="assets/img/icons/filter-big.svg"
  [showDrawer]="positionDetailService.drawer.showPositionFilter" drawerTitle="Filtra"
  (onClosingDrawerTab)="onClosingFilterDrawer()" drawerImg="assets/img/icons/burger-small.svg">

  <ng-container main>
    <div class="filter_tab border-bottom p-4 flex-wrap"
      *ngFor="let filter of positionDetailService.positionFilter?.controls | keyvalue: appService.originalOrder; let i='index'">
      <div class="d-flex justify-content-between align-items-center w-100" (click)="onSelectedFilter(i)" role="button">
        <div class="tab_name">{{ filter.key | renderingFilter }}</div>
        <div class="tab-status">
          <div class="tab_img" *ngIf="selectedFilterIdx !== i">
            <img src="assets/img/icons/add.svg">
          </div>
          <div class="tab_img" *ngIf="selectedFilterIdx === i">
            <img src="assets/img/icons/minus.svg">
          </div>
        </div>
      </div>
      <div class="w-100 d-flex flex-wrap" *ngIf="selectedFilterIdx === i">
        <!-- STATUS -->
        <ng-container *ngIf="filter.key === formCl.STATUS">
          <div class="p-1" *ngFor="let jobPosition of positionDetailService.jobApplicationEnumMapped">
            <generali-chip [isSelected]="setSelectedChip(positionDetailService.status, jobPosition)"
              [labelItem]="jobPosition | renderingStatus" (onChipClicked)="onChipSelection(jobPosition)">
            </generali-chip>
          </div>
        </ng-container>

        <!-- KEYWORD -->
        <ng-container *ngIf="filter.key === formCl.KEYWORD">
          <p class="keyword-txt">{{ "position.detail.KEYWORD" | translate }}</p>
          <generali-input class="w-100 mt-2" [inputControl]="positionDetailService.positionFilter.get(formCl.KEYWORD)"
            placeholder="{{ 'filter.KEYWORD_PL' | translate }}"></generali-input>
        </ng-container>

        <!-- RIASSEGNAMENTO -->
        <ng-container *ngIf="filter.key === formCl.REASSIGNMENT">
          <div>
            <div class="my-2">
              <generali-radio (onSelectRadio)="toggleReassignment()" bindName="reassignment"
                [isSelected]="positionDetailService.appliedPositionFilter.reassigned === undefined">
                <p class="mb-0 ms-2">{{ 'generic.ALL' | translate }}</p>
              </generali-radio>
            </div>
            <div class="my-2">
              <generali-radio (onSelectRadio)="toggleReassignment(true)" bindName="reassignment"
                [isSelected]="positionDetailService.appliedPositionFilter.reassigned === true">
                <p class="mb-0 ms-2">{{ 'generic.YES' | translate }}</p>
              </generali-radio>
            </div>
            <div class="my-2">
              <generali-radio (onSelectRadio)="toggleReassignment(false)" bindName="reassignment"
                [isSelected]="positionDetailService.appliedPositionFilter.reassigned === false">
                <p class="mb-0 ms-2">{{ 'generic.NO' | translate }}</p>
              </generali-radio>
            </div>
          </div>
        </ng-container>

        <!-- REFERRAL -->
        <ng-container *ngIf="filter.key === formCl.REFERRAL">
          <div>
            <div class="my-2">
              <generali-radio bindName="referral" (onSelectRadio)="toggleReferral()"
                [isSelected]="positionDetailService.appliedPositionFilter.referral === undefined">
                <p class="mb-0 ms-2">{{ 'generic.ALL' | translate }}</p>
              </generali-radio>
            </div>
            <div class="my-2">
              <generali-radio bindName="referral" (onSelectRadio)="toggleReferral(true)"
                [isSelected]="positionDetailService.appliedPositionFilter.referral === true">
                <p class="mb-0 ms-2">{{ 'generic.YES' | translate }}</p>
              </generali-radio>
            </div>
            <div class="my-2">
              <generali-radio bindName="referral" (onSelectRadio)="toggleReferral(false)"
                [isSelected]="positionDetailService.appliedPositionFilter.referral === false">
                <p class="mb-0 ms-2">{{ 'generic.NO' | translate }}</p>
              </generali-radio>
            </div>
          </div>

        </ng-container>

        <!-- TALENTO -->
        <ng-container *ngIf="filter.key === formCl.TALENT">
          <div>
            <div class="my-2">
              <generali-radio bindName="talent" (onSelectRadio)="toggleTalent()"
                [isSelected]="positionDetailService.appliedPositionFilter.talent === undefined">
                <p class="mb-0 ms-2">{{ 'generic.ALL' | translate }}</p>
              </generali-radio>
            </div>
            <div class="my-2">
              <generali-radio bindName="talent" (onSelectRadio)="toggleTalent(true)"
                [isSelected]="positionDetailService.appliedPositionFilter.talent === true">
                <p class="mb-0 ms-2">{{ 'generic.YES' | translate }}</p>
              </generali-radio>
            </div>
            <div class="my-2">
              <generali-radio bindName="talent" (onSelectRadio)="toggleTalent(false)"
                [isSelected]="positionDetailService.appliedPositionFilter.talent === false">
                <p class="mb-0 ms-2">{{ 'generic.NO' | translate }}</p>
              </generali-radio>
            </div>
          </div>
        </ng-container>

        <!-- EMAIL DI FEEDBACK -->
        <ng-container *ngIf="filter.key === formCl.FEEDBACK_EMAIL">
          <div>
            <div class="my-2">
              <generali-radio bindName="feedbackEmail" (onSelectRadio)="toggleFeedbackEmail()"
                [isSelected]="positionDetailService.appliedPositionFilter.feedbackMailSent === undefined">
                <p class="mb-0 ms-2">{{ 'generic.ALL' | translate }}</p>
              </generali-radio>
            </div>
            <div class="my-2">
              <generali-radio bindName="feedbackEmail" (onSelectRadio)="toggleFeedbackEmail(true)"
                [isSelected]="positionDetailService.appliedPositionFilter.feedbackMailSent === true">
                <p class="mb-0 ms-2">{{ 'generic.YES' | translate }}</p>
              </generali-radio>
            </div>
            <div class="my-2">
              <generali-radio bindName="feedbackEmail" (onSelectRadio)="toggleFeedbackEmail(false)"
                [isSelected]="positionDetailService.appliedPositionFilter.feedbackMailSent === false">
                <p class="mb-0 ms-2">{{ 'generic.NO' | translate }}</p>
              </generali-radio>
            </div>
          </div>
        </ng-container>

        <!-- DETTAGLI UTENTE -->
        <ng-container *ngIf="filter.key === formCl.GENRE">
          <div>
            <div class="my-2">
              <generali-radio bindName="newItalian" (onSelectRadio)="toggleGenre()"
                [isSelected]="positionDetailService.appliedPositionFilter.newItalian === undefined">
                <p class="mb-0 ms-2">{{ 'generic.ALL' | translate }}</p>
              </generali-radio>
            </div>
            <div class="my-2">
              <generali-radio bindName="newItalian" (onSelectRadio)="toggleGenre('M')"
                [isSelected]="positionDetailService.appliedPositionFilter.newItalian === true">
                <p class="mb-0 ms-2">{{ 'generic.MAN' | translate }}</p>
              </generali-radio>
            </div>
            <div class="my-2">
              <generali-radio bindName="newItalian" (onSelectRadio)="toggleGenre('F')"
                [isSelected]="positionDetailService.appliedPositionFilter.newItalian === false">
                <p class="mb-0 ms-2">{{ 'generic.WOMAN' | translate }}</p>
              </generali-radio>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="filter.key === formCl.NEW_ITALIAN">
          <div>
            <div class="my-2">
              <generali-radio bindName="newItalian" (onSelectRadio)="toggleNewItalian()"
                [isSelected]="positionDetailService.appliedPositionFilter.newItalian === undefined">
                <p class="mb-0 ms-2">{{ 'generic.ALL' | translate }}</p>
              </generali-radio>
            </div>
            <div class="my-2">
              <generali-radio bindName="newItalian" (onSelectRadio)="toggleNewItalian(true)"
                [isSelected]="positionDetailService.appliedPositionFilter.newItalian === true">
                <p class="mb-0 ms-2">{{ 'generic.YES' | translate }}</p>
              </generali-radio>
            </div>
            <div class="my-2">
              <generali-radio bindName="newItalian" (onSelectRadio)="toggleNewItalian(false)"
                [isSelected]="positionDetailService.appliedPositionFilter.newItalian === false">
                <p class="mb-0 ms-2">{{ 'generic.NO' | translate }}</p>
              </generali-radio>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="filter.key === formCl.PROTECTED_CATEGORY">
          <div>
            <div class="my-2">
              <generali-radio bindName="protectedCategory" (onSelectRadio)="toggleProtectedCategory()"
                [isSelected]="positionDetailService.appliedPositionFilter.legallyProtected === undefined">
                <p class="mb-0 ms-2">{{ 'generic.ALL' | translate }}</p>
              </generali-radio>
            </div>
            <div class="my-2">
              <generali-radio bindName="protectedCategory" (onSelectRadio)="toggleProtectedCategory(true)"
                [isSelected]="positionDetailService.appliedPositionFilter.legallyProtected === true">
                <p class="mb-0 ms-2">{{ 'generic.YES' | translate }}</p>
              </generali-radio>
            </div>
            <div class="my-2">
              <generali-radio bindName="protectedCategory" (onSelectRadio)="toggleProtectedCategory(false)"
                [isSelected]="positionDetailService.appliedPositionFilter.legallyProtected === false">
                <p class="mb-0 ms-2">{{ 'generic.NO' | translate }}</p>
              </generali-radio>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="filter.key === formCl.AGE_RANGE">
          <generali-select class="w-100 mb-3" [control]="formCl.AGE_RANGE"
            [group]="positionDetailService.positionFilter" [dropdown]="positionDetailService.ageDropdown"
            bindLabel="label" [multiSelection]="true" selectorType="checkbox"
            placeholder="{{ 'generic.INSERT' | translate }} {{ 'candidate.AGE' | translate | lowercase }}">
          </generali-select>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <ng-container footer>
    <div class="filter_footer d-flex justify-content-center align-items-center flex-column">
      <generali-button btnItem="{{ 'generic.APPLY' | translate }}" btnStyle="red" btnSize="sm"
        (click)="onApplyFilter()"></generali-button>
      <div class="filter_reset">
        <p class="mt-3" role="button" i18n="@@generic.RESET" (click)="onResetFilterDrawer()">
          generic.RESET
        </p>
      </div>
    </div>
  </ng-container>
</generali-drawer>

<!-- SEZIONE STATUS JobPostion CHANGE -->
<generali-drawer [showDrawer]="positionDetailService.drawer.showPositionStatus"
  drawerTitle="{{ 'position.detail.CHANGE_STATUS_TITLE' | translate }}"
  drawerSubTitle="{{ 'position.detail.POSITION_DRAWER' | translate }}" (onClosingDrawerTab)="onClosingFilterDrawer()">
  <ng-container main>
    <div class="ps-2">
      <div class="d-flex">
        <generali-radio bindName="status" [isDisabled]="positionDetailService._status.statusDraft"
          [isSelected]="positionDetailService.fetchData.filteredJobPosition?.currentStatus === positionStatusOptions.DRAFT || !positionDetailService.fetchData.filteredJobPosition"
          (onSelectRadio)="positionDetailService.setChangeStatusDrawer({ positionStatus: positionStatusOptions.DRAFT })">
          <generali-badge class="ms-3" badgeCategory="FULL" [badgeType]="positionStatusOptions.DRAFT"></generali-badge>
        </generali-radio>
      </div>

      <div class="d-flex">
        <generali-radio bindName="status" [isDisabled]="positionDetailService._status.statusToBeAproved"
          [isSelected]="positionDetailService.fetchData.filteredJobPosition?.currentStatus === positionStatusOptions.TO_BE_APPROVED"
          (onSelectRadio)="positionDetailService.setChangeStatusDrawer({ positionStatus: positionStatusOptions.TO_BE_APPROVED })">
          <generali-badge class="ms-3" badgeCategory="FULL" [badgeType]="positionStatusOptions.TO_BE_APPROVED">
          </generali-badge>
        </generali-radio>
      </div>

      <div class="d-flex">
        <generali-radio bindName="status" [isDisabled]="positionDetailService._status.statusOpen"
          [isSelected]="positionDetailService.fetchData.filteredJobPosition?.currentStatus === positionStatusOptions.OPEN"
          (onSelectRadio)="positionDetailService.setChangeStatusDrawer({ positionStatus: positionStatusOptions.OPEN })">
          <generali-badge class="ms-3" badgeCategory="FULL" [badgeType]="positionStatusOptions.OPEN"></generali-badge>
        </generali-radio>
      </div>

      <div class="d-flex">
        <generali-radio bindName="status" [isDisabled]="positionDetailService._status.statusPublished"
          [isSelected]="positionDetailService.fetchData.filteredJobPosition?.currentStatus === positionStatusOptions.PUBLISHED"
          (onSelectRadio)="positionDetailService.setChangeStatusDrawer({ positionStatus: positionStatusOptions.PUBLISHED })">
          <generali-badge class="ms-3" badgeCategory="FULL" [badgeType]="positionStatusOptions.PUBLISHED">
          </generali-badge>
        </generali-radio>
      </div>

      <div class="d-flex">
        <generali-radio bindName="status" [isDisabled]="positionDetailService._status.statusCloseFilled"
          [isSelected]="positionDetailService.fetchData.filteredJobPosition?.currentStatus === positionStatusOptions.CLOSED_FILLED"
          (onSelectRadio)="positionDetailService.setChangeStatusDrawer({ positionStatus: positionStatusOptions.CLOSED_FILLED })">
          <generali-badge class="ms-3" badgeCategory="FULL" [badgeType]="positionStatusOptions.CLOSED_FILLED">
          </generali-badge>
        </generali-radio>
      </div>

      <div class="d-flex">
        <generali-radio bindName="status" [isDisabled]="positionDetailService._status.statusCloseEmpty"
          [isSelected]="positionDetailService.fetchData.filteredJobPosition?.currentStatus === positionStatusOptions.CLOSED_EMPTY"
          (onSelectRadio)="positionDetailService.setChangeStatusDrawer({ positionStatus: positionStatusOptions.CLOSED_EMPTY })">
          <generali-badge class="ms-3" badgeCategory="FULL" [badgeType]="positionStatusOptions.CLOSED_EMPTY">
          </generali-badge>
        </generali-radio>
      </div>

      <div class="d-flex">
        <generali-radio bindName="status" [isDisabled]="positionDetailService._status.statusSuspended"
          [isSelected]="positionDetailService.fetchData.filteredJobPosition?.currentStatus === positionStatusOptions.SUSPENDED"
          (onSelectRadio)="positionDetailService.setChangeStatusDrawer({ positionStatus: positionStatusOptions.SUSPENDED })">
          <generali-badge class="ms-3" badgeCategory="FULL" [badgeType]="positionStatusOptions.SUSPENDED">
          </generali-badge>
        </generali-radio>
      </div>

      <div class="my-5 mx-1" *ngIf="positionDetailService.textAreaIsVisible">
        <generali-text-area txtAreaTitle="{{ 'position.detail.TXT_AREA_TITLE' | translate }}"
          placeholder="{{ 'position.detail.TXT_AREA_PL' | translate }}"
          [txtAreaControl]="positionDetailService.jobPositionComment">
        </generali-text-area>
      </div>
    </div>
  </ng-container>

  <!-- WARN  -->
  <ng-container warnMessage>
    <div class="warn">
      <generali-warn icon="assets/img/icons/warning.svg" *ngIf="positionDetailService.positionWarnMessage">
        <ng-container warn>
          <p class="m-0 fw-bold" i18n="@@generic.WARNING">generic.WARNING</p>
        </ng-container>
        <ng-container message>
          <p class="m-0" [innerHTML]="positionDetailService.positionWarnMessage"></p>
        </ng-container>
      </generali-warn>
    </div>
  </ng-container>

  <ng-container footer>
    <div class="d-flex justify-content-center">
      <generali-button class="w-100 pe-1" btnItem="{{ 'generic.CANCEL' | translate }}" btnSize="auto" btnStyle="ghost"
        (onClick)="onClosingFilterDrawer()">
      </generali-button>
      <generali-button class="w-100 ps-1" btnItem="{{ 'generic.CONFIRM' | translate }}" btnSize="auto" btnStyle="red"
        (onClick)="onConfirmPositionStatusChange()">
      </generali-button>
    </div>
  </ng-container>
</generali-drawer>

<!-- SEZIONE STATUS JobApplication CHANGE -->
<generali-drawer [showDrawer]="positionDetailService.drawer.showPersonaStatus"
  drawerTitle="{{ 'position.detail.CHANGE_STATUS_TITLE' | translate }}"
  drawerSubTitle="{{ 'position.detail.APPLICANT_DRAWER' | translate }}" (onClosingDrawerTab)="onClosingFilterDrawer()">
  <ng-container main>
    <div class="ps-2">

      <div class="d-flex" *ngFor="let badge of positionDetailService.personaStatus">
        <generali-radio bindName="status" (onSelectRadio)="onPersonStatusSelection(badge)"
          [isSelected]="selectedJobApplicationStatus === badge.applicationBadge">
          <generali-badge class="ms-3" badgeCategory="DOT" [badgeType]="badge.applicationBadge">
          </generali-badge>
        </generali-radio>
      </div>

      <div class="my-5 mx-1" *ngIf="positionDetailService.selectetStatus.jobApplicationStaus">
        <generali-text-area txtAreaTitle="{{ 'position.detail.TXT_AREA_TITLE' | translate }}" [rows]="3"
          placeholder="{{ 'position.detail.TXT_AREA_PL' | translate }}"
          [txtAreaControl]="positionDetailService.jobApplicationComment">
        </generali-text-area>
      </div>
    </div>
  </ng-container>

  <!-- WARN  -->
  <ng-container warnMessage>
    <div class="warn">
      <generali-warn icon="assets/img/icons/warning.svg"
        *ngIf="positionDetailService.selectetStatus.jobApplicationStaus?.applicationBadge === 'STOP' && (selectedJobApplicationStatus != 'STOP' || positionDetailService.cardsSelected.length > 1)">
        <ng-container warn>
          <p class="m-0 fw-bold" i18n="@@generic.WARNING">generic.WARNING</p>
        </ng-container>
        <ng-container message>
          <p class="m-0" innerHTML="{{ 'position.detail.STOP_WARN' | translate }}"></p>
        </ng-container>
      </generali-warn>
    </div>
  </ng-container>

  <ng-container footer>
    <div class="d-flex justify-content-center">
      <generali-button class="w-100 pe-1" btnItem="{{ 'generic.CANCEL' | translate }}" btnSize="auto" btnStyle="ghost"
        (onClick)="onClosingFilterDrawer()"></generali-button>
      <generali-button class="w-100 ps-1" btnItem="{{ 'generic.CONFIRM' | translate }}" btnSize="auto" btnStyle="red"
        (onClick)="onMassiveChangeStatus()">
      </generali-button>
    </div>
  </ng-container>
</generali-drawer>

<!-- DRAWER TIMELINE -->
<generali-drawer [showDrawer]="positionDetailService.drawer.showTimeline"
  drawerTitle="{{ 'person.detail.TIMELINE' | translate }}" (onClosingDrawerTab)="onClosingFilterDrawer()">
  <ng-container main>
    <div class="timeline-wrapper">
      <div *ngFor="let status of positionDetailService.fetchData.allStatusForTimeline; let last=last; let first=first"
        class="timeline-row">
        <div class="dot-container">
          <div class="dot" [class.full]="first"></div>
          <div [class.line]="!last"></div>
        </div>
        <div class="timeline-content">
          <p class="mb-1 t-date">{{ status.statusDate | date:'dd/MM/yyyy HH:mm' }}
            {{ status.userForname }}
            {{ status.userSurname }}
            <ng-container *ngIf="status.userRole != ''">({{ status.userRole }})</ng-container>
          </p>
          <div class="d-flex align-items-center">
            <p class="mb-0 me-2 t-stat d-flex">
              <span i18n="@@position.timeline.CHANGE_IN">position.timeline.CHANGE_IN</span>
            </p>
            <generali-badge badgeCategory="FULL" [badgeType]="status.statusType"></generali-badge>
          </div>
          <p class="mb-0 t-comment" *ngIf="status.comment">{{ 'generic.COMMENT' | translate }}: {{
            status.comment }}</p>
        </div>
      </div>
    </div>
  </ng-container>
</generali-drawer>

<confirm-cancel-modal id="no-status-warning" modalText="{{ 'modal.MASSIVE_STATUS' | translate }}"
  (onCloseNavigateAwayModal)="onCloseModal('no-status-warning')" (onConfirm)="onCloseModal('no-status-warning')"
  btnApply="{{ 'genric.UNDERSTAND' | translate }}">
</confirm-cancel-modal>

<confirm-cancel-modal id="confirm-closing-position" modalText="{{ confirmCloseModalInfo | confirmCloseModalTxt }}"
  (onCloseNavigateAwayModal)="onCloseModal('confirm-closing-position')"
  (onConfirm)="onConfirmPositionStatusChange(true)" (onCancel)="onCloseModal('confirm-closing-position')"
  btnApply="{{ 'generic.CONTINUE' | translate }}" btnCancel="{{ 'generic.CANCEL' | translate }}" [alignLeft]="true">
</confirm-cancel-modal>

<confirm-cancel-modal id="confirm-accept-application" modalText="{{ confirmCloseModalInfo | confirmCloseModalTxt }}"
  (onCloseNavigateAwayModal)="onCloseModal('confirm-accept-application')" (onConfirm)="onMassiveChangeStatus(true)"
  (onCancel)="onCloseModal('confirm-accept-application')" btnApply="{{ 'generic.CONTINUE' | translate }}"
  btnCancel="{{ 'generic.CANCEL' | translate }}" [alignLeft]="true">
</confirm-cancel-modal>

<!-- LOADER -->
<div appLoader [loading]="isLoading"></div>
