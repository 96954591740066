<ng-container *ngIf="true">
  <div>
    <input name="datepicker" class="form-control opacity-0" ngbDatepicker #datepicker="ngbDatepicker"
      [autoClose]="'outside'" (dateSelect)="onDateSelection($event, datepicker)" [displayMonths]="2" [dayTemplate]="t"
      outsideDays="hidden" [weekdays]="weekday.Narrow" [startDate]="fromDate?.value" tabindex="-1" />

    <ng-template #t let-date let-focused="focused">
      <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="_hoveredDate = date"
        (mouseleave)="_hoveredDate = null">
        {{ date.day }}
      </span>
    </ng-template>
  </div>



  <div class="d-flex">
    <div class="me-1">
      <label class="dp-title">{{ dpTitleFrom }}</label>
      <div class="input-group custom-group d-flex align-items-center">
        <img src="assets/img/icons/close-icon.svg" (click)="resetDate()" role="button">
        <input readonly #dpFromDate class="form-control ats-height-input rounded rounded-start" name="dpFromDate"
          [placeholder]="placeholder" [value]="formatter.format(_fromDate) | date:'dd/MM/yyyy' "
          (click)="datepicker.toggle()" />
      </div>
    </div>

    <div class="ms-1">
      <label class="dp-title">{{ dpTitleTo }}</label>
      <div class="input-group custom-group">
        <img src="assets/img/icons/close-icon.svg" (click)="resetDate()" role="button">
        <input readonly #dpToDate class="form-control ats-height-input rounded rounded-start" name="dpToDate"
          [value]="formatter.format(_toDate) | date:'dd/MM/yyyy' " (click)="datepicker.toggle(); _toDateMode = true"
          [placeholder]="placeholder" />
        <!-- <p class="btn btn-outline-secondary m-0" (click)="resetDate()">X</p> -->
      </div>
    </div>
  </div>
</ng-container>