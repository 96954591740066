<generali-accordion [accordionName]="accordionName">
  <div class="chip-container" [formGroup]="group">
    <ng-container *ngFor="let status of jobStatus">
      <div class="status-chip" [formArrayName]="control">
        <generali-chip [isSelected]="isSelected(status)" [labelItem]="$any(status)" [bindLabel]="bindLabel"
          (onChipClicked)="onSelection(status)">
        </generali-chip>
      </div>
    </ng-container>
  </div>
</generali-accordion>