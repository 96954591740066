<div class="dp d-flex flex-column gap-1 w-100">
  <label class="dp-title">{{ dpTitle }}</label>
  <input readonly class="form-datepicker" [placeholder]="placeholder" ngbDatepicker [disabled]="readOnly || false"
    (click)="dTo.toggle()" #dTo="ngbDatepicker" outsideDays="hidden" navigation="select" [weekdays]="weekday.Narrow"
    (dateSelect)="dateSelect($event)" [markDisabled]="markDisable"
    [value]="formatter.format(selectedDate) | date:'dd/MM/yyyy' " [dayTemplate]="dt" [minDate]="minDate" />

  <ng-template #dt let-date>
    <div class="p-2 custom-day" [class.selected]="date.equals(selectedDate)">
      {{ date.day }}
    </div>
  </ng-template>
</div>