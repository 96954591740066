<div class="error-403-wrapper text-center">
  <div class="logo-container">
    <img placement="bottom" class="logoRegular cursor-pointer" src="./assets/img/logo-generali.svg">
  </div>
  <h2 tabindex="0">
    Applicant Tracking System
  </h2>
  <h1 taindex="0" class="error-403 text-inline center lighter">403</h1>
  <h2 class="no-margin">OOOOOOOOPS!</h2>
  <h4 tabindex="0" class="center"><span i18n="@@error.PAGE.403">error.PAGE.403</span>
  </h4>
</div>