import { ErrorHandler, Injectable, inject } from "@angular/core";
import { I18n } from "src/ancestors/i18n/i18n.module";

@Injectable({ providedIn: "root" })
export class TranslateService {
  private i18n = inject(I18n);
  private errorHandler = inject(ErrorHandler);
  private _translation?: Record<string, string>;

  constructor() {
    this.loadTranslation();
  }

  public loadTranslation(): void {
    this.i18n.getLocale().then((t) => {
      this._translation = t.default as Record<string, string>; // eslint-disable-line
    }).catch((error: unknown) => {
      this.errorHandler.handleError(error);
    });
  }

  /**
   * Passando una stringa o un array di stringhe ritorna la/le traduzioni
   * 
   * @param translateKey Chiave della traduzione
   * @returns Traduzione
   */
  public instant<T>(translateKey: string | string[]): T {
    if (Array.isArray(translateKey)) {
      const t = translateKey.map((key: string) => this._translation?.[key]);
      return t.filter(Boolean) as T;
    }
    return this._translation?.[translateKey] as T;
  }

  /**
   * Oggetto traduzioni da usare nelle pipe
   */
  public get translation() {
    return this._translation;
  }
}