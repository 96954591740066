import { ChangeDetectorRef, inject, Injectable } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import {
  JobPositionStatusEnumTypes,
  JobApplication,
  JobPositionsWithCountersResponse,
  HomepageSearchFilters,
  UserFiltersTypeEnumTypes
} from "applicanttrackingsystem-cl";
import { BaseDataService } from "src/ancestors/base-data.service";
import { TranslateService } from "src/app/shared/util/translate.service";
import { GeneraliStatuTab } from "generali-shared-components/dist";
import { BaseStatus } from "../position-detail/position-detail.data.service";
import { JobPositionFilterService } from "src/app/shared/service/filter/job-position-filter.service";
import { JobPositionOrderService } from "src/app/shared/service/order/job-position-order.service";
import { JobApplicationFilterService } from "src/app/shared/service/filter/job-application-filter.service";
import { JobApplicationOrderService } from "src/app/shared/service/order/job-application-order.service";

export interface JobPositioEnum {
  name: string;
  statusId: string
}

Injectable();
export class SearchService extends BaseDataService {
  constructor() {
    super();
  }

  private fb = inject(FormBuilder);
  private traslateService = inject(TranslateService);
  public cdr = inject(ChangeDetectorRef);
  private positionFilterService = inject(JobPositionFilterService);
  private positionOrderService = inject(JobPositionOrderService);
  private applicationFilterService = inject(JobApplicationFilterService);
  private applicationOrderService = inject(JobApplicationOrderService);

  public showRealizedApplicants = false;
  public selectedJobApplicationHistory!: JobApplication[];
  public applicationList: JobApplication[] = [];
  public positionList: JobPositionsWithCountersResponse[] = [];
  public selectedApplicatntsForDrawer?: JobApplication;
  public applicationCount = 0;
  public positionCount = 0;
  public isLoading = false;

  public isDrawerLoading = false;
  public searchForm = this.fb.group({
    searchedText: [null || "", Validators.minLength(1)]
  });

  get searchedText() {
    return this.searchForm.get("searchedText") as FormControl;
  }

  public lastSerchText?: string;  /** */
  public someFilterIsApplied?: boolean;
  /** */
  public selectedFilterIdx?: number;
  /** Toggle menu dei filtri */
  public showPositionFilter = false;
  /** Toggle menu ordinamento */
  public showPositionOrders = false;
  /** Opzioni per la paginazione */
  public perPageOption = [
    { numRecord: 5 },
    { numRecord: 10 },
    { numRecord: 20 },
    { numRecord: 50 }
  ];
  public appliedFilter: HomepageSearchFilters = {};
  /** */
  public genericSetup: FormGroup = this.fb.group({
    pagination: this.fb.array([
      this.fb.group({
        numRecord: [5]
      })
    ]),
    applicationCurrentPage: [1],
    positionCurrentPage: [1]
  });

  /** Array di indici delle card selezionate */
  public cardsSelected: string[] = [];

  public set applicationCurrentPage(page: number) {
    this.genericSetup.get("applicationCurrentPage")?.setValue(page);
  }
  public get applicationCurrentPage() {
    return this.genericSetup.get("applicationCurrentPage")?.value as number;
  }
  public set positionCurrentPage(page: number) {
    this.genericSetup.get("positionCurrentPage")?.setValue(page);
  }
  public get positionCurrentPage() {
    return this.genericSetup.get("positionCurrentPage")?.value as number;
  }
  public get numRecord() {
    return (this.genericSetup.get("pagination") as FormArray).controls.at(0)?.get("numRecord")?.value as number;
  }

  /** Nome di un determinato candidato che ha effettuato le candidature */
  public jobApplicationHistoryOwner = "";

  /** DRAWER */
  public drawer: {
    /** */
    showRealizedApplicants?: boolean;
    /** Toggle menu ordinamento */
    positionOrders?: boolean,
    /** Toggle menu dei filtri */
    positionFilter?: boolean
    /** Toggle menu ordinamento */
    applicationOrders?: boolean,
    /** Toggle menu dei filtri */
    applicationFilter?: boolean
    /** Toggle status */
    showApplicationStatus?: boolean;
  } = {};

  /** Status selezionati */
  public selectetStatus: {
    /** Status della posizione per determinata persona selezionato */
    jobApplicationStaus?: BaseStatus
  } = {};

  /**
   * Ricavo i nomi dei JobPositionStatus e li salvo in un array per poterli usare in pagina
   */
  private jobPositionEnumMapped = Object.keys(JobPositionStatusEnumTypes)
    .map(status => Object.assign({}, { jobPositionName: status }));

  /**
   * Ritorna gli status tradotti per filtri
   */
  public get jobPositionStatusEnum(): JobPositioEnum[] {
    return this.jobPositionEnumMapped.map(translations => {
      return {
        name: this.traslateService.instant("position.status.label." + translations.jobPositionName),
        statusId: translations.jobPositionName
      };
    });
  }

  /** Tab della ricerca */
  public tabItems: GeneraliStatuTab.TabStruct[] = [
    { isActive: true, isCompiled: false, name: $localize`:@@search.CANDIDATES:search.CANDIDATES`, redirect: "job-application", nameOption: `(${this.applicationCount})` },
    { isActive: false, isCompiled: false, name: $localize`:@@search.POSITIONS:search.POSITIONS`, redirect: "job-position", nameOption: `(${this.positionCount})` }
  ];

  protected jobComment = this.fb.group({
    jobApplicationComment: ""
  });

  public get jobApplicationComment() {
    return this.jobComment.get("jobApplicationComment") as FormControl;
  }

  public async updateJobPositionList() {
    const params = {};
    Object.assign(params, this.positionFilterService.appliedFiltersForList);
    Object.assign(params, this.positionOrderService.selectedOrder);
    Object.assign(params, { numRecords: this.numRecord, fromRecord: (this.positionCurrentPage - 1) * this.numRecord });

    const searchedText = this.appService.stringify(this.searchedText.value);
    const [positionList, positionCount] = await Promise.all([
      this.backendService.jobPositionSearchV2(searchedText, params),
      this.backendService.jobPositionCountV2(searchedText, params)
    ]);
    this.positionCount = positionCount;
    this.positionList = positionList;

    this.updateTabCounter("job-position", positionCount);
  }

  public async updateJobApplicationList() {
    this.isLoading = true;
    const params = {};
    Object.assign(params, this.applicationFilterService.applieJobApplicationFilter);
    Object.assign(params, this.applicationOrderService.selectedOrder);
    
    // In caso di ageRange ho bisogno di estrarre solo il valore e non la label del filtro applicato
    if (this.applicationFilterService.applieJobApplicationFilter.ageRange) {
      Object.assign(params, { ageRange: this.applicationFilterService.applieJobApplicationFilter.ageRange.map((age: any) => age.value) });
    }
    
    Object.assign(params, { numRecords: this.numRecord, fromRecord: (this.applicationCurrentPage - 1) * this.numRecord });

    const searchedText = this.appService.stringify(this.searchedText.value);
    const [applicationList, applicationCount] = await Promise.all([
      this.backendService.jobApplicationSearchV2(searchedText, params),
      this.backendService.jobApplicationCountV2(searchedText, params)
    ]);
    this.applicationCount = applicationCount;
    this.applicationList = applicationList;

    this.updateTabCounter("job-application", applicationCount);
    this.isLoading = false;
  }

  public updateTabCounter(counterToUpdate: "job-position" | "job-application", count: number) {
    const tabSelected = this.tabItems.find(tab => tab.redirect === counterToUpdate);
    if (tabSelected) {
      tabSelected.nameOption = `(${count})`;
    }
  }

}
