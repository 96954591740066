import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { BaseComponent } from "src/ancestors/base/base.component";

@Component({
  selector: "app-preview",
  templateUrl: "./preview.component.html",
  styleUrls: ["./preview.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class PreviewComponent extends BaseComponent implements OnInit {
  constructor() {
    super();
  }

  override async ngOnInit() { }
}
