import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from "@angular/router";
import { UrlCollection } from "../app-routing.module";
import { CreatePosition } from "../pages/create-position/create-position-routing.module";

@Injectable({
  providedIn: "root"
})
export class DeleteSessionDataGuard implements CanDeactivate<unknown> {
  canDeactivate(component: unknown, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean {
    const SESSION_KEY = "CREATE_POSITION_PARAMS";
    if (nextState?.url.includes(UrlCollection.preview) && currentState?.url.includes(CreatePosition.createPosition)) {
      return true;
    }

    if (nextState?.url.includes(CreatePosition.createPosition) && currentState?.url.includes(UrlCollection.preview)) {
      return true;
    }

    sessionStorage.removeItem(SESSION_KEY);
    return true;
  }
}
