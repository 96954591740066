import { ErrorHandler, inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  ApplicationsCountResponse,
  CanSendCommunicationAndDefaultTextResponse,
  Company,
  CompanyPerimeter,
  ContractType,
  EmploymentLevel,
  FAQEntry,
  HomepageSearchFilters,
  InterviewInstance,
  InterviewStatusEnum,
  InterviewType,
  JobApplication,
  JobApplicationComment,
  JobApplicationsByPositionFilters,
  JobApplicationStatus,
  JobApplicationStatusEnum,
  JobField,
  JobPosition,
  JobPositionForFilter,
  JobPositionsCountResponse,
  JobPositionStatus,
  JobPositionStatusEnum,
  JobPositionsWithCountersResponse,
  Location,
  ATSLoginInfo,
  ProgressiveCode,
  PublicationChannel,
  ScreeningTestCollection,
  SearchReassignableJobPositionsResponse,
  SimpleUser,
  SurveyInstance,
  UserFiltersTypeEnum
} from "applicanttrackingsystem-cl";
import { GeneraliToastService } from "generali-shared-components/dist";

import { API_REQUEST_METHODS, APICallService } from "src/ancestors/base-api.service";
import { TokenStorageService } from "src/app/global-service/token-storage.service";
import { ConfirmCloseModalInfoInterface } from "../shared/components/confirm-cancel-modal/confirm-cancel-modal.component";

export interface UserParams {
  email: string;
  password: string;
  langCode?: string;
  deviceType?: string;
  userAgent?: string;
  createPersistentUserAuth?: string;
}

@Injectable()
export class BackendService extends APICallService {
  toast = inject(GeneraliToastService);
  constructor() {
    super();
    this.logger.setCaller("BackendService");
  }

  private tkStorage: TokenStorageService = inject(TokenStorageService);
  private router: Router = inject(Router);
  private errorsHandler = inject(ErrorHandler);

  /** AUTH */

  /**
   * @param loginParam parametri per eseguire il login.
   */
  async localLogin(loginParam: UserParams): Promise<ATSLoginInfo | undefined> {
    try {
      const loginRes = await this.callAPI<ATSLoginInfo>(API_REQUEST_METHODS.POST, this.env.apiBaseUrl, "authn/login", {
        bodyParam: {
          email: loginParam.email,
          password: loginParam.password
        }
      });

      if (loginRes?.response) {
        return loginRes.response;
      }


    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  /**
   * Rinnova il token di autenticazione ed invalida il precedente.
   */
  async refreshToken(): Promise<ATSLoginInfo | undefined> {
    try {
      const refreshToken = await this.callAPI<ATSLoginInfo>("post", this.env.apiBaseUrl, "authn/refreshToken");

      if (refreshToken?.response) {
        return refreshToken.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  async decodeSingleUseToken(singleUseToken: string): Promise<ATSLoginInfo | undefined> {
    try {
      const decodeSingleUseToken = await this.callAPI<ATSLoginInfo>("get", this.env.apiBaseUrl, "authn/decodeSingleUseToken", {
        queryParam: { singleUseToken }
      });

      if (decodeSingleUseToken?.response) {
        return decodeSingleUseToken.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  /**
   * @returns Invalida il token dell'utente e ne impedisce l'uso e il rinnovo.
   */
  public async logout(redirectToLogin?: string): Promise<boolean> {
    try {
      const logoutRes = await this.callAPI<ATSLoginInfo>("post", this.env.apiBaseUrl, "authn/logout");

      if (logoutRes?.response) {
        this.tkStorage.deleteToken(this.env.localTokenKey);
        await this.router.navigate([redirectToLogin ? redirectToLogin : this.env.loginUrl]);
        return true;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return false;
  }

  public async faqList(): Promise<FAQEntry[]> {
    try {
      const faqRes = await this.callAPI<FAQEntry[]>("get", this.env.apiBaseUrl, "faq/list");

      if (faqRes?.response) {
        return faqRes.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return [] as FAQEntry[];
  }

  /**
   * Contatore di tutte le posizioni nel range temporale
   */
  public async kpiJobPositionsCount(params?: { fromDate?: Date, toDate?: Date, companyId?: string[], hrbpId?: string[], locationId?: string[], jobFieldId?: string | string[], contractTypeId?: string | string[], id?: string | string[], jobPositionStatus?: string | string[] }): Promise<JobPositionsCountResponse | undefined> {
    try {
      const jobPositionsCountRes =
        await this.callAPI<JobPositionsCountResponse>("get", this.env.apiBaseUrl, "kpi/jobPositionsCount", {
          queryParam: params
        });

      if (jobPositionsCountRes?.response) {
        return jobPositionsCountRes.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  /**
   * Contatore di tutte le candidature nel range temporale
   */
  public async kpiApplicationsCount(params?: { fromDate?: Date, toDate?: Date, jobFieldId?: string | string[], contractTypeId?: string | string[], id?: string | string[], jobPositionStatus?: string | string[], companyId?: string | string[], hrbpId?: string | string[], locationId?: string | string[] }): Promise<ApplicationsCountResponse | undefined> {
    try {
      const applicationsCountRes =
        await this.callAPI<ApplicationsCountResponse>("get", this.env.apiBaseUrl, "kpi/applicationsCount", {
          queryParam: params
        });

      if (applicationsCountRes?.response) {
        return applicationsCountRes.response;
      }

    } catch (error: unknown) {
      this.errorsHandler.handleError(error);
    }

    return;
  }

  /**
   * Conteggio delle posizioni, filtrabile per molteplici criteri
   */
  public async jobPositionCountWithCounters(params?: { fromDate?: Date, toDate?: Date, jobFieldId?: string | string[], contractTypeId?: string | string[], jobPositionStatusInDateRange?: string, id?: string | string[], jobPositionStatus?: string | string[], companyId?: string | string[], hrbpId?: string | string[] }): Promise<number> {
    try {
      const countWithCountersRes =
        await this.callAPI<number>("get", this.env.apiBaseUrl, "jobPosition/countWithCounters", {
          queryParam: params
        });

      if (countWithCountersRes?.response) {
        return countWithCountersRes?.response;
      }

    } catch (error: unknown) {
      this.errorsHandler.handleError(error);
    }

    return 0;
  }

  /**
   * Elenco delle posizioni, filtrabile per molteplici criteri, che riporta anche i contatori principali
   */
  public async jobPositionListWithCounters(params?: { fromDate?: Date, toDate?: Date, jobFieldId?: string, contractTypeId?: string, id?: string | string[], jobPositionStatus?: string | string[], companyId?: string | string[], hrbpId?: string | string[], sortingField?: string | string[], sortingDesc?: boolean, fromRecord?: number, numRecords?: number }): Promise<JobPositionsWithCountersResponse[]> {
    try {
      const listWithCountersRes =
        await this.callAPI<JobPositionsWithCountersResponse[]>("get", this.env.apiBaseUrl, "jobPosition/listWithCounters", {
          queryParam: params
        });

      if (listWithCountersRes?.response) {
        return listWithCountersRes.response;
      }

    } catch (error: unknown) {
      this.errorsHandler.handleError(error);
    }

    return [] as JobPositionsWithCountersResponse[];
  }

  /**
   * Elenco dei perimetri delle compagnie
   */
  public async companyListPerimeter(params?: { name?: string, fromRecord?: number, numRecord?: number }): Promise<CompanyPerimeter[]> {
    try {
      const companyListPerimeterRes = await this.callAPI<CompanyPerimeter[]>("get", this.env.apiBaseUrl, "company/listPerimeter", {
        bodyParam: params
      });

      if (companyListPerimeterRes?.response) {
        return companyListPerimeterRes.response;
      }

    } catch (error: unknown) {
      this.errorsHandler.handleError(error);
    }

    return [] as CompanyPerimeter[];
  }

  /**
   * Elenco delle compagnie
   *
   * @param perimeterId
   */
  public async companyList(params?: { perimeterId?: string, name?: string, fromRecord?: string, numRecord?: string }): Promise<Company[]> {
    try {
      const companyListRes = await this.callAPI<Company[]>("get", this.env.apiBaseUrl, "company/list", {
        queryParam: params
      });

      if (companyListRes?.response) {
        return companyListRes.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return [] as Company[];
  }

  /**
   * Elenco dei job field
   */
  public async jobFieldList(params?: { name?: string, fromRecord?: string, numRecord?: string }): Promise<JobField[]> {
    try {
      const jobFieldListres = await this.callAPI<JobField[]>("get", this.env.apiBaseUrl, "jobField/list", {
        queryParam: params
      });

      if (jobFieldListres?.response) {
        return jobFieldListres.response;
      }

    } catch (error: unknown) {
      this.errorsHandler.handleError(error);
    }

    return [] as JobField[];
  }

  /**
   * Elenco dei job field
   */
  public async jobPositiondHasEverBeenOpened(jobPositionId: string): Promise<boolean> {
    try {
      const jobFieldRes = await this.callAPI<boolean>("get", this.env.apiBaseUrl, "jobPosition/hasEverBeenOpened", {
        urlParam: [jobPositionId]
      });

      if (jobFieldRes?.response) {
        return typeof jobFieldRes.response === "boolean" ? true : false;
      }

    } catch (error: unknown) {
      this.errorsHandler.handleError(error);
    }
    return false;
  }

  /**
   * Elenco dei tipi di contratto
   */
  public async contractTypeList(params?: { name?: string, fromRecord?: string, numRecord?: string }): Promise<ContractType[]> {
    try {
      const contractTypeRes = await this.callAPI<ContractType[]>("get", this.env.apiBaseUrl, "contractType/list", {
        queryParam: params
      });

      if (contractTypeRes?.response) {
        return contractTypeRes.response;
      }

    } catch (error: unknown) {
      this.errorsHandler.handleError(error);
    }

    return [] as ContractType[];
  }

  /**
   * Elenco delle sedi
   */
  public async locationList(params?: { name?: string, fromRecord?: string, numRecord?: string }): Promise<Location[]> {
    try {
      const locationRes = await this.callAPI<Location[]>("get", this.env.apiBaseUrl, "location/list", {
        queryParam: params
      });

      if (locationRes?.response) {
        return locationRes.response;
      }

    } catch (error: unknown) {
      this.errorsHandler.handleError(error);
    }

    return [] as Location[];
  }

  /**
   * Elenco dei livello di inquadramento
   */
  public async employLevelList(params?: { name?: string, fromRecord?: string, numRecord?: string }): Promise<EmploymentLevel[]> {
    try {
      const employLevelRes = await this.callAPI<EmploymentLevel[]>("get", this.env.apiBaseUrl, "employmentLevel/list", {
        queryParam: params
      });

      if (employLevelRes?.response) {
        return employLevelRes.response;
      }

    } catch (error: unknown) {
      this.errorsHandler.handleError(error);
    }

    return [] as EmploymentLevel[];
  }

  /**
   * Elenco degli HRBP
   */
  public async hrbpUsersList(params?: { perimeterId?: string, name?: string, fromRecord?: number, numRecord?: string }): Promise<SimpleUser[]> {
    try {
      const hrbpUsersListRes = await this.callAPI<SimpleUser[]>("get", this.env.apiBaseUrl, "hrbp/list", {
        queryParam: params
      });

      if (hrbpUsersListRes?.response) {
        return hrbpUsersListRes.response;
      }

    } catch (error: unknown) {
      this.errorsHandler.handleError(error);
    }

    return [] as SimpleUser[];
  }

  /**
   * Elenco dei Line Manager
   */
  public async lineManagerList(params?: { perimeterId?: string, name?: string, fromRecord?: string, numRecord?: string }): Promise<SimpleUser[]> {
    try {
      const lineManagerListRes = await this.callAPI<SimpleUser[]>("get", this.env.apiBaseUrl, "lineManager/list", {
        queryParam: params
      });

      if (lineManagerListRes?.response) {
        return lineManagerListRes.response;
      }

    } catch (error: unknown) {
      this.errorsHandler.handleError(error);
    }

    return [] as SimpleUser[];
  }

  public async jobPositionChangeStatus(params: { jobPositionId: string, newStatus: JobPositionStatusEnum, comment?: string }): Promise<JobPosition | undefined> {
    try {
      const changeStatusRes = await this.callAPI<JobPosition>("post", this.env.apiBaseUrl, "jobPosition/changeStatus", {
        bodyParam: params
      });

      if (changeStatusRes?.response) {
        return changeStatusRes.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  public async jobPositionChangeStatusRestricted(params: { jobPositionId: string, newStatus: JobPositionStatusEnum, comment?: string }): Promise<JobPosition | undefined> {
    try {
      const changeStatusRes = await this.callAPI<JobPosition>("post", this.env.apiBaseUrl, "jobPosition/changeStatus/restricted", {
        bodyParam: params
      });

      if (changeStatusRes?.response) {
        return changeStatusRes.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  public async jobPositionUpdate(jobPosition: JobPosition): Promise<JobPosition | undefined> {
    try {
      const jobPositionUpdateRes = await this.callAPI<JobPosition>("post", this.env.apiBaseUrl, "jobPosition/update", {
        bodyParam: { jobPosition: { ...jobPosition } }
      });

      if (jobPositionUpdateRes?.response) {
        return jobPositionUpdateRes.response;
      }


    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  public async jobPositionNew(jobPosition: JobPosition): Promise<JobPosition | undefined> {
    try {
      const jobPositionNewRes = await this.callAPI<JobPosition>("post", this.env.apiBaseUrl, "jobPosition/new", {
        bodyParam: { jobPosition }
      });

      if (jobPositionNewRes?.response) {
        return jobPositionNewRes.response;
      }


    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  public async jobPositionFiltersGet<T>(filtersType: UserFiltersTypeEnum): Promise<T> {
    try {
      const homeFilterRes = await this.callAPI<HomepageSearchFilters>(API_REQUEST_METHODS.GET, this.env.apiBaseUrl, "filters/get", {
        urlParam: [filtersType]
      });

      if (homeFilterRes?.response) {
        return homeFilterRes.response as T;
      }


    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return {} as T;
  }

  public async jobPositionFiltersSet(filtersType: UserFiltersTypeEnum, filters: HomepageSearchFilters | JobApplicationsByPositionFilters): Promise<HomepageSearchFilters | undefined> {
    try {
      const homeFilterRes = await this.callAPI<HomepageSearchFilters>(API_REQUEST_METHODS.POST, this.env.apiBaseUrl, "filters/set", {
        bodyParam: { filtersType, filters }
      });

      if (homeFilterRes?.response) {
        return homeFilterRes.response;
      }


    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return {} as HomepageSearchFilters;
  }

  public async jobPositionFiltersDelete(filtersType: UserFiltersTypeEnum): Promise<boolean | undefined> {
    try {
      const homeFilterRes = await this.callAPI<boolean>(API_REQUEST_METHODS.DELETE, this.env.apiBaseUrl, "filters/delete", {
        urlParam: [filtersType]
      });

      if (homeFilterRes?.response) {
        return homeFilterRes.response;
      }


    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return false;
  }

  public async jobPositionEditable(jobPositionId: string): Promise<boolean | undefined> {
    try {
      const jobPositionEditable = await this.callAPI<boolean>(API_REQUEST_METHODS.GET, this.env.apiBaseUrl, "jobPosition/isJobPositionEditable", {
        urlParam: [jobPositionId]
      });

      if (jobPositionEditable?.response) {
        return jobPositionEditable.response;
      }


    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return false;
  }

  public async hypoteticalProgressiveCode(): Promise<ProgressiveCode | undefined> {
    try {
      const gethypoteticalProgressiveCode = await this.callAPI<ProgressiveCode>("get", this.env.apiBaseUrl, "jobPosition/hypoteticalProgressiveCode");

      if (gethypoteticalProgressiveCode?.response) {
        return gethypoteticalProgressiveCode.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  public async screeningTestList(params?: { shlOnly?: boolean, nonShlOnly?: boolean, name?: string, fromRecord?: string, numRecord?: string }): Promise<ScreeningTestCollection[]> {
    try {
      const screeningTestCollectionRes = await this.callAPI<ScreeningTestCollection[]>("get", this.env.apiBaseUrl, "screeningTest/list", {
        queryParam: params
      });

      if (screeningTestCollectionRes?.response) {
        return screeningTestCollectionRes.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return [] as ScreeningTestCollection[];
  }

  public async screeningTestListCollections(params?: { name?: string, fromRecord?: string, numRecord?: string }): Promise<ScreeningTestCollection[]> {
    try {
      const screeningTestCollectionRes = await this.callAPI<ScreeningTestCollection[]>("get", this.env.apiBaseUrl, "screeningTest/listCollections", {
        queryParam: params
      });

      if (screeningTestCollectionRes?.response) {
        return screeningTestCollectionRes.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return [] as ScreeningTestCollection[];
  }

  public async screeningTestCreate(formData: FormData): Promise<void> {
    try {

      await this.callAPI<ScreeningTestCollection[]>(API_REQUEST_METHODS.POST, this.env.apiBaseUrl, "screeningTest/instance/create", {
        bodyParam: formData,
        withFormData: true
      });


    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }
  }

  public async screeningTestUpdate(formData: FormData): Promise<void> {
    try {

      await this.callAPI<ScreeningTestCollection[]>(API_REQUEST_METHODS.POST, this.env.apiBaseUrl, "screeningTest/instance/update", {
        bodyParam: formData,
        withFormData: true
      });

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }
  }

  public async screeningTestDelete(screeningTestInstanceId: string): Promise<void> {
    try {

      await this.callAPI<ScreeningTestCollection[]>(API_REQUEST_METHODS.DELETE, this.env.apiBaseUrl, "screeningTest/instance/deleteTestResultUpload", {
        urlParam: [screeningTestInstanceId]
      });

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }
  }

  public async uploadSurveyTemplate(formData: FormData): Promise<JobPosition | undefined> {
    try {
      const jobPositionRes = await this.callAPI<JobPosition>("post", this.env.apiBaseUrl, "jobPosition/uploadSurveyTemplate", {
        bodyParam: formData,
        withFormData: true
      });

      if (jobPositionRes?.response) {
        return jobPositionRes?.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }
    return;
  }

  public async removeSurveyTemplate(params: { jobPositionId: string }): Promise<JobPosition | undefined> {
    try {
      const jobPositionRes = await this.callAPI<JobPosition>("delete", this.env.apiBaseUrl, "jobPosition/removeSurveyTemplate", {
        queryParam: params
      });

      if (jobPositionRes?.response) {
        return jobPositionRes?.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  /**
   * @param params
   */
  public async publicationChannelList(params?: { name?: string, fromRecord?: number, numRecord?: number }): Promise<PublicationChannel[]> {
    try {
      const publicationChannelRes = await this.callAPI<PublicationChannel[]>("get", this.env.apiBaseUrl, "publicationChannel/list", {
        bodyParam: params
      });

      if (publicationChannelRes?.response) {
        return publicationChannelRes.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return [] as PublicationChannel[];
  }

  /**
   * Richiede l'approvazione alla pubblicazione di una job position
   *
   * @param jobPositionId
   */
  public async requestApprovalToPublish(jobPositionId: string): Promise<JobPosition | undefined> {
    try {
      const requestApprovalRes = await this.callAPI<JobPosition>("post", this.env.apiBaseUrl, "jobPosition/requestApprovalToPublish", {
        bodyParam: { jobPositionId }
      });

      if (requestApprovalRes?.response) {
        return requestApprovalRes.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  /** POSITION DETAIL PUNTO N.7 */
  /**
   * @param jobPositionId
   *
   * @returns Recupera una job position con tutti i suoi dati
   */
  public async getJobPositionById(jobPositionId: string) {
    try {
      const jobPositionRes = await this.callAPI<JobPosition | undefined>("get", this.env.apiBaseUrl, "jobPosition/get", {
        urlParam: [jobPositionId]
      });

      if (jobPositionRes?.response) {
        return jobPositionRes?.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  /**
   * @param jobPositionId
   *
   * @returns Ritorna il link alla piattaforma di visione (GoGenerali) della job position
   */
  public async getPublicationURL(jobPositionId: string) {
    try {
      const publicationUrlRes = await this.callAPI<string | null>("get", this.env.apiBaseUrl, "jobPosition/getPublicationURL/", {
        urlParam: [jobPositionId]
      });

      if (publicationUrlRes?.response) {
        return publicationUrlRes?.response;
      }

    } catch (error: unknown) {
      this.errorsHandler.handleError(error);
    }
    return null;
  }

  /**
   * @param jobPositionId
   *
   * @returns Ritorna l'elenco degli stati storici (incluso l'attuale) di una job position
   */
  public async listAllStatuses(jobPositionId: string): Promise<JobPositionStatus[]> {
    try {
      const listAllStatusRes = await this.callAPI<JobPositionStatus[]>("get", this.env.apiBaseUrl, "jobPosition/listAllStatuses/", {
        urlParam: [jobPositionId]
      });

      if (listAllStatusRes?.response) {
        return listAllStatusRes.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return [] as JobPositionStatus[];
  }

  /**
   * @param params
   *
   * @returns Ritorna il conteggio di tutt i candidati di una job position
   */
  public async jobApplicationCount(jobPositionId: string, params?: { sortingField?: string, sortingDesc?: boolean, id?: string, jobApplicationStatus?: string[], keyword?: string, reassigned?: boolean, referral?: boolean, talent?: boolean, newItalian?: boolean, legallyProtected?: boolean, feedbackMailSent?: boolean, ageRange?: string[], gender?: string, fromRecord?: number, numRecord?: number }): Promise<number | undefined> {
    try {
      const jobApplicationCountRes = await this.callAPI<number>("get", this.env.apiBaseUrl, "jobApplication/countByPosition", {
        urlParam: [jobPositionId],
        queryParam: params
      });

      if (jobApplicationCountRes?.response) {
        return jobApplicationCountRes?.response;
      }

    } catch (error: unknown) {
      this.errorsHandler.handleError(error);
    }
    return 0;
  }

  /**
   * @param params
   *
   * @returns Ritorna l'elenco di tutt i candidati di una job position
   */
  public async jobApplicationListByPosition(jobPositionId: string, params?: { sortingField?: string, sortingDesc?: boolean, id?: string, jobApplicationStatus?: string[], keyword?: string, reassigned?: boolean, referral?: boolean, talent?: boolean, newItalian?: boolean, legallyProtected?: boolean, feedbackMailSent?: boolean, ageRange?: string[], gender?: string, fromRecord?: number, numRecord?: number }): Promise<JobApplication[]> {
    try {
      const jobApplicationListRes = await this.callAPI<JobApplication[]>("get", this.env.apiBaseUrl, "jobApplication/listByPosition", {
        urlParam: [jobPositionId],
        queryParam: params
      });

      if (jobApplicationListRes?.response) {
        return jobApplicationListRes.response;
      }

    } catch (error: unknown) {
      this.errorsHandler.handleError(error);
    }

    return [] as JobApplication[];
  }

  /**
   * @param params
   *
   * @returns Ritorna l'elenco degli id di tutti candidati di una job position
   */
  public async jobApplicationListIdsByPosition(jobPositionId: string, params?: { sortingField?: string, sortingDesc?: boolean, id?: string, jobApplicationStatus?: string[], keyword?: string, reassigned?: boolean, referral?: boolean, talent?: boolean, newItalian?: boolean, legallyProtected?: boolean, feedbackMailSent?: boolean, ageRange?: string[], gender?: string, fromRecord?: number, numRecord?: number }): Promise<string[]> {
    try {
      const jobApplicationListRes = await this.callAPI<string[]>("get", this.env.apiBaseUrl, "jobApplication/listIdsByPosition", {
        urlParam: [jobPositionId],
        queryParam: params
      });

      if (jobApplicationListRes?.response) {
        return jobApplicationListRes.response;
      }

    } catch (error: unknown) {
      this.errorsHandler.handleError(error);
    }

    return [];
  }

  /**
 * @param params
 *
 * @returns Ritorna l'elenco degli id di tutti candidati di una job position in base ai filtri
 */
  public async jobApplicationSearchIds(params?: { searchedText: string, sortingField?: string, sortingDesc?: boolean, jobApplicationStatus?: string[], keyword?: string, reassigned?: boolean, referral?: boolean, talent?: boolean, newItalian?: boolean, legallyProtected?: boolean, feedbackMailSent?: boolean, ageRange?: string[], gender?: string, fromRecord?: number, numRecord?: number }): Promise<string[]> {
    try {
      const jobApplicationListRes = await this.callAPI<string[]>("get", this.env.apiBaseUrl, "jobApplication/searchIds", {
        queryParam: params
      });

      if (jobApplicationListRes?.response) {
        return jobApplicationListRes.response;
      }

    } catch (error: unknown) {
      this.errorsHandler.handleError(error);
    }

    return [];
  }

  public async jobApplicationChangeStatusMassive(jobApplicationIds: string[], newStatus: JobApplicationStatusEnum, comment?: string): Promise<number | undefined> {
    try {
      const jobApplicationChangeStatusMassiveRes = await this.callAPI<number>("post", this.env.apiBaseUrl, "jobApplication/changeStatusMassive", {
        bodyParam: { jobApplicationIds, newStatus, comment }
      });

      if (jobApplicationChangeStatusMassiveRes?.response) {
        return jobApplicationChangeStatusMassiveRes.response;
      }

    } catch (error: unknown) {
      this.errorsHandler.handleError(error);
    }

    return undefined;
  }

  public async jobApplicationChangeStatus(params: { jobApplicationId: string, newStatus: JobApplicationStatusEnum, sendCommunication?: boolean, communicationText?: string, communicationTextEn?: string, comment?: string }): Promise<number | undefined> {
    try {
      const jobApplicationChangeStatusRes = await this.callAPI<number>("post", this.env.apiBaseUrl, "jobApplication/changeStatus", {
        bodyParam: params
      });

      if (jobApplicationChangeStatusRes?.response) {
        return jobApplicationChangeStatusRes.response;
      }

    } catch (error: unknown) {
      this.errorsHandler.handleError(error);
    }

    return undefined;
  }

  public async jobPositionClone(jobPositionId: string) {
    try {
      const jobApplicationCloneRes = await this.callAPI<string>("put", this.env.apiBaseUrl, "jobPosition/clone", {
        bodyParam: { jobPositionId }
      });

      if (jobApplicationCloneRes?.response) {
        return jobApplicationCloneRes.response;
      }

    } catch (error: unknown) {
      this.errorsHandler.handleError(error);
    }

    return undefined;
  }

  public async jobApplicationGetById(jobApplicationId: string) {
    try {
      const jobApplicationRes = await this.callAPI<JobApplication>(API_REQUEST_METHODS.GET, this.env.apiBaseUrl, "jobApplication/get", {
        urlParam: [jobApplicationId]
      });

      if (jobApplicationRes?.response) {
        return jobApplicationRes.response;
      }

    } catch (error: unknown) {
      this.errorsHandler.handleError(error);
    }

    return {} as JobApplication;
  }

  public async canSendCommunicationAndDefaultText(jobApplicationId: string): Promise<CanSendCommunicationAndDefaultTextResponse | undefined> {
    try {
      const sendCommunicationAndTxtRes = await this.callAPI<CanSendCommunicationAndDefaultTextResponse>(API_REQUEST_METHODS.GET, this.env.apiBaseUrl, "jobApplication/canSendCommunicationAndDefaultText", {
        urlParam: [jobApplicationId]
      });

      if (sendCommunicationAndTxtRes?.response) {
        return sendCommunicationAndTxtRes.response;
      }

    } catch (error: unknown) {
      this.errorsHandler.handleError(error);
    }

    return;
  }

  public async jobApplicationSetUserTalent(userId: string, isTalent: boolean): Promise<boolean> {
    try {
      const isTalentRes = await this.callAPI<boolean>(API_REQUEST_METHODS.POST, this.env.apiBaseUrl, "jobApplication/setUserTalent", {
        bodyParam: { userId, isTalent }
      });

      if (isTalentRes?.response) {
        return isTalentRes.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return false;
  }

  public async jobApplicationAskForAssessment(jobApplicationId: string): Promise<boolean> {
    try {
      const askForAssessmentRes = await this.callAPI<boolean>(API_REQUEST_METHODS.POST, this.env.apiBaseUrl, "jobApplication/askForAssessment", {
        bodyParam: { jobApplicationId }
      });

      if (askForAssessmentRes?.response) {
        return askForAssessmentRes.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return false;
  }


  public async jobApplicationSolicitTestInstance(jobApplicationId: string, screeningTestInstanceId: string): Promise<boolean> {
    try {
      const askForAssessmentRes = await this.callAPI<boolean>(API_REQUEST_METHODS.POST, this.env.apiBaseUrl, "jobApplication/solicitTestInstance", {
        bodyParam: { jobApplicationId, screeningTestInstanceId }
      });

      if (askForAssessmentRes?.response) {
        return askForAssessmentRes.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return false;
  }

  public async jobApplicationListAllStatuses(jobApplicationId: string): Promise<JobApplicationStatus[]> {
    try {
      const askForAssessmentRes = await this.callAPI<JobApplicationStatus[]>(API_REQUEST_METHODS.GET, this.env.apiBaseUrl, "jobApplication/listAllStatuses", {
        urlParam: [jobApplicationId]
      });

      if (askForAssessmentRes?.response) {
        return askForAssessmentRes.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return [] as JobApplicationStatus[];
  }

  public async jobApplicationSetUserGender(userId: string, gender: string): Promise<boolean> {
    try {
      const userGenderIsSet = await this.callAPI<boolean>(API_REQUEST_METHODS.POST, this.env.apiBaseUrl, "jobApplication/setUserGender", {
        bodyParam: { userId, gender }
      });

      if (userGenderIsSet?.response) {
        return userGenderIsSet.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return false;
  }

  public async jobApplicationSetUserNewItalian(userId: string, isNewItalian?: boolean): Promise<boolean> {
    try {
      const userNewItalianIsSet = await this.callAPI<boolean>(API_REQUEST_METHODS.POST, this.env.apiBaseUrl, "jobApplication/setUserNewItalian", {
        bodyParam: { userId, isNewItalian }
      });

      if (userNewItalianIsSet?.response) {
        return userNewItalianIsSet.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return false;
  }

  public async jobApplicationSetUserLegalyProtected(userId: string, legallyProtected?: boolean): Promise<boolean> {
    try {
      const userLegalyProtectedIsSet = await this.callAPI<boolean>(API_REQUEST_METHODS.POST, this.env.apiBaseUrl, "jobApplication/setUserLegallyProtected", {
        bodyParam: { userId, legallyProtected }
      });

      if (userLegalyProtectedIsSet?.response) {
        return userLegalyProtectedIsSet.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return false;
  }

  public async jobApplicationSetUserBirthDate(userId: string, dataNascita: string): Promise<string | undefined> {
    try {
      const userBirthDateIsSet = await this.callAPI<string>(API_REQUEST_METHODS.POST, this.env.apiBaseUrl, "jobApplication/setUserBirthDate", {
        bodyParam: { userId, dataNascita }
      });

      if (userBirthDateIsSet?.response) {
        return userBirthDateIsSet.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;

  }

  public async jobApplicationCountComments(jobApplicationId: string): Promise<number | undefined> {
    try {
      const countCommentsRes = await this.callAPI<number>(API_REQUEST_METHODS.GET, this.env.apiBaseUrl, "jobApplication/countComments", {
        urlParam: [jobApplicationId]
      });

      if (countCommentsRes?.response) {
        return countCommentsRes.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  public async jobApplicationListComments(jobApplicationId: string, params?: { sortingDesc?: boolean, fromRecord?: number, numRecord?: number }): Promise<JobApplicationComment[]> {
    try {
      const listCommentsRes = await this.callAPI<JobApplicationComment[]>(API_REQUEST_METHODS.GET, this.env.apiBaseUrl, "jobApplication/listComments", {
        urlParam: [jobApplicationId],
        queryParam: params
      });

      if (listCommentsRes?.response) {
        return listCommentsRes.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return [] as JobApplicationComment[];
  }

  public async jobApplicationPostComment(params: { jobApplicationId: string, commentText: string, userId?: SimpleUser }): Promise<JobApplicationComment | undefined> {
    try {
      const postCommentsRes = await this.callAPI<JobApplicationComment>(API_REQUEST_METHODS.POST, this.env.apiBaseUrl, "jobApplication/postComment", {
        bodyParam: params
      });

      if (postCommentsRes?.response) {
        return postCommentsRes.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  public async jobApplicationUpdateComment(commentId: string, params: { commentText: string }): Promise<JobApplicationComment | undefined> {
    try {
      const updateCommentsRes = await this.callAPI<JobApplicationComment>(API_REQUEST_METHODS.POST, this.env.apiBaseUrl, "jobApplication/updateMyComment", {
        urlParam: [commentId],
        bodyParam: params
      });

      if (updateCommentsRes?.response) {
        return updateCommentsRes.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  public async jobApplicationDeleteComment(commentId: string): Promise<JobApplicationComment | undefined> {
    try {
      const deleteCommentsRes = await this.callAPI<JobApplicationComment>(API_REQUEST_METHODS.DELETE, this.env.apiBaseUrl, "jobApplication/deleteMyComment", {
        urlParam: [commentId]
      });

      if (deleteCommentsRes?.response) {
        return deleteCommentsRes.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  public async interviewTypeList(params?: { userId?: string, name?: string, fromRecord?: number, numRecords?: number }): Promise<InterviewType[] | undefined> {
    try {
      const interviewTypeRes = await this.callAPI<InterviewType[]>(API_REQUEST_METHODS.GET, this.env.apiBaseUrl, "interviewType/list", {
        queryParam: params
      });

      if (interviewTypeRes?.response) {
        return interviewTypeRes.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  public async jobApplicationListByUser(userId: string, params?: { fromRecord?: number, numRecords?: number }): Promise<JobApplication[]> {
    try {
      const listByUserRes = await this.callAPI<JobApplication[]>(API_REQUEST_METHODS.GET, this.env.apiBaseUrl, "jobApplication/listByUser", {
        urlParam: [userId],
        queryParam: params
      });

      if (listByUserRes?.response) {
        return listByUserRes.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return [] as JobApplication[];
  }

  public async jobApplicationAskForInterview(params: { interviewUserId: string, jobApplicationId: string, interviewTypeId: string }): Promise<InterviewInstance> {
    try {
      const interviewInstanceRes = await this.callAPI<InterviewInstance>(API_REQUEST_METHODS.POST, this.env.apiBaseUrl, "jobApplication/askForInterview", {
        bodyParam: params
      });

      if (interviewInstanceRes?.response) {
        return interviewInstanceRes.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return {} as InterviewInstance;
  }

  public async jobApplicationUpdateInterview(interviewInstanceId: string, params: { interviewStatus?: InterviewStatusEnum, interviewUserId: string, interviewTypeId: string }): Promise<InterviewInstance> {
    try {
      const interviewInstanceRes = await this.callAPI<InterviewInstance>(API_REQUEST_METHODS.POST, this.env.apiBaseUrl, "jobApplication/updateInterview", {
        bodyParam: { interviewUserId: params.interviewUserId, interviewTypeId: params.interviewTypeId, interviewStatus: params.interviewStatus },
        urlParam: [interviewInstanceId]
      });

      if (interviewInstanceRes?.response) {
        return interviewInstanceRes.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return {} as InterviewInstance;
  }

  public async jobApplicationSollecitInterview(interviewInstanceId: string): Promise<boolean> {
    try {
      const interviewInstanceRes = await this.callAPI<boolean>(API_REQUEST_METHODS.POST, this.env.apiBaseUrl, "jobApplication/solicitInterview", {
        urlParam: [interviewInstanceId]
      });

      if (interviewInstanceRes?.response) {
        return interviewInstanceRes.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return false;
  }

  public async interviewersCount(params?: { name?: string, fromRecord?: number, numRecords?: number }): Promise<number> {
    try {
      const interviewCountRes = await this.callAPI<number>(API_REQUEST_METHODS.GET, this.env.apiBaseUrl, "interviewers/count", {
        queryParam: params
      });

      if (interviewCountRes?.response) {
        return interviewCountRes.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return 0;
  }

  public async interviewersList(params?: { name?: string, fromRecord?: number, numRecords?: number }): Promise<SimpleUser[]> {
    try {
      const interviewListRes = await this.callAPI<SimpleUser[]>(API_REQUEST_METHODS.GET, this.env.apiBaseUrl, "interviewers/list", {
        queryParam: params
      });

      if (interviewListRes?.response) {
        return interviewListRes.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return [] as SimpleUser[];
  }

  /**
   * Update existing evalution
   *
   * @param params  Object woth properties of evaluationForm
   */
  public async updateFormInterviewAsDraft(params: { "interviewInstance": InterviewInstance }) {
    try {
      await this.callAPI<InterviewInstance>(API_REQUEST_METHODS.POST, this.env.apiBaseUrl, "jobApplication/updateFormInterviewAsDraft", {
        bodyParam: params
      });
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }
  }

  /**
   * Save the completatiomn of evalution
   *
   * @param params  Object woth properties of evaluationForm
   */
  public async confirmFormInterview(params: { "interviewInstance": InterviewInstance }) {
    try {
      await this.callAPI<InterviewInstance>(API_REQUEST_METHODS.POST, this.env.apiBaseUrl, "jobApplication/confirmFormInterview", {
        bodyParam: params
      });
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }
  }

  /**
   * Delete form interview evalution
   *
   * @param interviewInstanceId identifier of interview
   */
  public async deleteFormInterview(interviewInstanceId: string) {
    try {
      await this.callAPI<string>(API_REQUEST_METHODS.DELETE, this.env.apiBaseUrl, `jobApplication/deleteFormInterview/${interviewInstanceId}`);
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }
  }

  /*
  *
  *   Count della ricerca delle jobPosition
  *
  */
  public async jobPositionCount(params?: { fromDate?: Date, toDate?: Date, jobPositionStatusInDateRange?: string, id?: string | string[], jobPositionStatus?: string | string[], companyId?: string | string[], hrbpId?: string | string[] }): Promise<number> {
    try {
      const countWithCountersRes =
        await this.callAPI<number>("get", this.env.apiBaseUrl, "jobPosition/count", {
          queryParam: params
        });

      if (countWithCountersRes?.response) {
        return countWithCountersRes?.response;
      }

    } catch (error: unknown) {
      this.errorsHandler.handleError(error);
    }

    return 0;
  }

  /*
  *
  *   Lista dei risultati di ricerca delle jobPosition
  *
  */
  public async jobPositionList(params?: { fromDate?: Date, toDate?: Date, jobPositionStatusInDateRange?: string, id?: string | string[], jobPositionStatus?: string | string[], companyId?: string | string[], hrbpId?: string | string[] }): Promise<JobPositionsWithCountersResponse[]> {
    try {
      const listRes =
        await this.callAPI<JobPositionsWithCountersResponse[]>("get", this.env.apiBaseUrl, "jobPosition/search", {
          queryParam: params
        });

      if (listRes?.response) {
        return listRes?.response;
      }

    } catch (error: unknown) {
      this.errorsHandler.handleError(error);
    }

    return [];
  }

  /**
   * Ritorna l'elenco delle possibili job positions a cui questa candidatura può essere riassegnata.
   * Questa ricerca deve sfruttare il servizio /jobPosition/search e poi arricchire la risposta con le informazioni necessarie.
   * NB: la ricerca sarà basata sull’id originale, quindi se il jobApplicationId è di una candidatura riassegnata, si dovrà risalire all’id corretto e quindi se esiste utilizzare reassignedFromJobApplicationId della candidatura passata, in alternativa direttamente il jobApplicationId passato
   *
   * @param jobApplicationId
   */
  public async joApplicationSearchReassignableJobPositions(jobApplicationId: string, searchedText?: string) {
    try {
      const searchReassignableJobPositionsRes = await this.callAPI<SearchReassignableJobPositionsResponse[]>(API_REQUEST_METHODS.GET, this.env.apiBaseUrl, "jobApplication/searchReassignableJobPositions", {
        urlParam: [jobApplicationId],
        queryParam: { searchedText }
      });

      if (searchReassignableJobPositionsRes?.response) {
        return searchReassignableJobPositionsRes.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return [] as SearchReassignableJobPositionsResponse[];
  }

  public async joApplicationReassignableToJobPositions(params: { jobApplicationId: string, jobPositionId: string[] }) {
    try {
      const reassignableToJobPositionsRes = await this.callAPI<boolean>(API_REQUEST_METHODS.POST, this.env.apiBaseUrl, "jobApplication/reassigneToJobPositions", {
        bodyParam: params
      });

      if (reassignableToJobPositionsRes?.response) {
        return reassignableToJobPositionsRes.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return false;
  }

  /*
  *
  *   Count della ricerca delle jobPosition
  *
  */
  public async jobApplicationGenericCount(params?: { fromDate?: Date, toDate?: Date, jobPositionStatusInDateRange?: string, id?: string | string[], jobPositionStatus?: string | string[], companyId?: string | string[], hrbpId?: string | string[] }): Promise<number> {
    try {
      const countWithCountersRes =
        await this.callAPI<number>("get", this.env.apiBaseUrl, "jobApplication/count", {
          queryParam: params
        });

      if (countWithCountersRes?.response) {
        return countWithCountersRes?.response;
      }

    } catch (error: unknown) {
      this.errorsHandler.handleError(error);
    }

    return 0;
  }
  public async jobPositionCountV2(searchedText: string, params?: { fromDate?: Date, toDate?: Date, jobFieldId?: string, contractTypeId?: string, id?: string | string[], jobPositionStatus?: string | string[], companyId?: string | string[], hrbpId?: string | string[], sortingField?: string | string[], sortingDesc?: boolean, fromRecord?: number, numRecords?: number }): Promise<number> {
    try {
      const jobPositionCountRes =
        await this.callAPI<number>("get", this.env.apiBaseUrl, "jobPosition/count", {
          queryParam: { ...params, searchedText }
        });

      if (jobPositionCountRes?.response) {
        return jobPositionCountRes?.response;
      }

    } catch (error: unknown) {
      this.errorsHandler.handleError(error);
    }

    return 0;
  }

  public async jobPositionSearchV2(searchedText: string, params?: { fromDate?: Date, toDate?: Date, jobFieldId?: string, contractTypeId?: string, id?: string | string[], jobPositionStatus?: string | string[], companyId?: string | string[], hrbpId?: string | string[], sortingField?: string | string[], sortingDesc?: boolean, fromRecord?: number, numRecords?: number }): Promise<JobPositionsWithCountersResponse[]> {
    try {
      const jobPositionSearchRes =
        await this.callAPI<JobPositionsWithCountersResponse[]>("get", this.env.apiBaseUrl, "jobPosition/search", {
          queryParam: { ...params, searchedText }
        });

      if (jobPositionSearchRes?.response) {
        return jobPositionSearchRes?.response;
      }

    } catch (error: unknown) {
      this.errorsHandler.handleError(error);
    }

    return [];
  }


  public async jobApplicationCountV2(searchedText: string, params?: JobApplicationsByPositionFilters): Promise<number> {
    try {
      const jobApplicationCountRes =
        await this.callAPI<number>("get", this.env.apiBaseUrl, "jobApplication/count", {
          queryParam: { ...params, searchedText }
        });

      if (jobApplicationCountRes?.response) {
        return jobApplicationCountRes?.response;
      }

    } catch (error: unknown) {
      this.errorsHandler.handleError(error);
    }

    return 0;
  }

  public async jobApplicationSearchV2(searchedText: string, params?: JobApplicationsByPositionFilters): Promise<JobApplication[]> {
    try {
      const jobApplicationSearchRes = await this.callAPI<JobApplication[]>("get", this.env.apiBaseUrl, "jobApplication/search", {
        queryParam: { ...params, searchedText }
      });

      if (jobApplicationSearchRes?.response) {
        return jobApplicationSearchRes?.response;
      }

    } catch (error: unknown) {
      this.errorsHandler.handleError(error);
    }

    return [] as JobApplication[];
  }
  /*
  *
  *   Lista dei risultati di ricerca delle jobPosition
  *
  */
  public async jobApplicationList(params?: {
    fromDate?: Date, toDate?: Date, jobPositionStatusInDateRange?: string, id?: string | string[], jobPositionStatus?: string | string[], companyId?: string | string[], hrbpId?: string | string[],
    sortingDesc?: boolean | undefined,
    sortingField?: string,
  }): Promise<JobApplication[]> {
    try {
      const listRes =
        await this.callAPI<JobApplication[]>("get", this.env.apiBaseUrl, "jobApplication/search", {
          queryParam: params
        });

      if (listRes?.response) {
        return listRes?.response;
      }

    } catch (error: unknown) {
      this.errorsHandler.handleError(error);
    }

    return [];
  }

  public async surveyGetByApplication(jobApplicationId: string) {
    try {
      const surveyGetByApplicationRes = await this.callAPI<SurveyInstance>(API_REQUEST_METHODS.GET, this.env.apiBaseUrl, "survey/instance/getByApplication", {
        urlParam: [jobApplicationId]
      });

      if (surveyGetByApplicationRes?.response) {
        return surveyGetByApplicationRes.response;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return {} as SurveyInstance;
  }


  /**
   * TODOAF
   */
  public async getConfirmClosePositionModalInfo(params?: { jobPositionId?: string, isAcceptingApplication?: boolean, jobApplicationIdsToAccept?: string[] }): Promise<ConfirmCloseModalInfoInterface> {
    try {
      const closePositionModalInfoRes = await this.callAPI<ConfirmCloseModalInfoInterface>("get", this.env.apiBaseUrl, "jobPosition/getConfirmClosePositionModalInfo", {
        queryParam: params
      });

      if (closePositionModalInfoRes?.response) {
        return closePositionModalInfoRes.response;
      }

    } catch (error: unknown) {
      this.errorsHandler.handleError(error);
    }
    return {};
  }

}
