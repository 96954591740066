<div class="actions-wrapper">
  <div class="action-btn" role="button" (click)="onToggleActionsDropdown()" (clickOutside)="showActions = false">
    <p class="d-flex align-items-center">
      <img [class.rotation]="showActions" src="assets/img/icons/add-grey.svg">
      <span>{{ 'generic.ACTION' | translate }}</span>
    </p>
  </div>

  <div class="actions-dropdown" *ngIf="showActions">
    <div class="action-field" *ngFor="let action of actions" (click)="onSelectedAction(action.action)">
      <img [src]="action.icon">
      <p class="text-nowrap">{{ action.label }}</p>
    </div>
  </div>
</div>