import { Injectable, inject } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { BaseDataService } from "src/ancestors/base-data.service";
import { FormService } from "../../util/form.service";
import { JobApplicationStatusEnum, JobApplicationsByPositionFilters } from "applicanttrackingsystem-cl";
import { isEqual } from "lodash";
import { BaseStatus } from "src/app/pages/position-detail/position-detail.data.service";

@Injectable()
export class JobApplicationFilterService extends BaseDataService {
  constructor() {
    super();
  }

  private fb = inject(FormBuilder);
  private fs = inject(FormService);

  public applieJobApplicationFilter: JobApplicationsByPositionFilters = {};
  public someFilterIsApplied?: boolean;
  public jobApplicationFiltersGroup: FormGroup = this.fb.group({
    jobApplicationStatus: this.fb.array([]),
    ageRange: this.fb.array([]),
    keyword: "",
    reassigned: undefined,
    referral: undefined,
    talent: undefined,
    feedbackMailSent: undefined,
    gender: undefined,
    newItalian: undefined,
    legallyProtected: undefined
  });

  public personaStatus: BaseStatus[] = [
    { applicationBadge: "STOP", label: "STOP" },
    { applicationBadge: "TO_EVALUATE", label: "DA VALUTARE" },
    { applicationBadge: "LONG_LIST", label: "LONG LIST" },
    { applicationBadge: "SHORT_LIST", label: "SHORT LIST" },
    { applicationBadge: "OFFER", label: "OFFERTA" },
    { applicationBadge: "ACCEPT", label: "ACCETTA" },
    { applicationBadge: "REFUSE", label: "RIFIUTA" }
  ];

  public ageDropdown = [
    { label: "Tutti", value: undefined },
    { label: "Under 27", value: "0-27" },
    { label: "Tra 28 e 30", value: "28-30" },
    { label: "Tra 31 e 35", value: "31-35" },
    { label: "Tra 36 e 40", value: "36-40" },
    { label: "Tra 41 e 45", value: "41-45" },
    { label: "Tra 46 e 50", value: "46-50" },
    { label: "Over 50", value: "50-999" }
  ];

  public onApplyFilters(filtersToApply: Record<string, unknown>) {
    const onlyFill: Record<string, unknown> = {  };
    for (const [key, value] of Object.entries({ ...this.applieJobApplicationFilter, ...filtersToApply })) {
      if (!this.appService.isEmpty(value)) {
        onlyFill[key] = value;
      }
    }

    this.applieJobApplicationFilter = onlyFill;
    this.activateFilterSectionIfActiveFilter();
  }

  // public get applicationFilter() {
  //   return {
  //     ageRange: this.fs.getMultiValuesOutOfArray<string[]>(this.ageRange, "value"),
  //     talent: this.fs.getSingleValueOutOfControl<boolean>({ control: this.talent }),
  //     reassigned: this.fs.getSingleValueOutOfControl<boolean>({ control: this.reassigned }),
  //     feedbackMailSent: this.fs.getSingleValueOutOfControl<boolean>({ control: this.feedbackMailSent }),
  //     referral: this.fs.getSingleValueOutOfControl<boolean>({ control: this.referral }),
  //     gender: this.fs.getSingleValueOutOfControl<string>({ control: this.gender }),
  //     newItalian: this.fs.getSingleValueOutOfControl<boolean>({ control: this.newItalian }),
  //     legallyProtected: this.fs.getSingleValueOutOfControl<boolean>({ control: this.legallyProtected }),
  //     jobApplicationStatus: this.fs.getMultiValuesOutOfArray<JobApplicationStatusEnum[]>(this.jobApplicationStatus, "label"),
  //     keyword: this.fs.getSingleValueOutOfControl<string>({ control: this.keyword })
  //   };
  // }

  // public set applicationFilter(appliedFilter: JobApplicationsByPositionFilters) {
  //   // this.applieJobApplicationFilter = appliedFilter;
  //   //Imposta i valori dei campi dei filtri utilizzando i valori presenti nel filtro applicato
  //   appliedFilter.jobApplicationStatus?.forEach(status => {
  //     const stat = typeof status == "string" ? { label: status } : status;
  //     this.fs.setValueOnArray(this.jobApplicationStatus, stat);
  //   });


  //   appliedFilter.ageRange?.forEach(ageRange => {
  //     if (typeof ageRange == "string") {
  //       const ageRangeObj = this.ageDropdown.find(val => val.value == ageRange);
  //       this.fs.setValueOnArray(this.ageRange, ageRangeObj );
  //     } else {
  //       this.fs.setValueOnArray(this.ageRange, ageRange );
  //     }
  //   });

  //   this.fs.setValueOnControl(this.reassigned, appliedFilter.reassigned);
  //   this.fs.setValueOnControl(this.referral, appliedFilter.referral);
  //   this.fs.setValueOnControl(this.talent, appliedFilter.talent);
  //   this.fs.setValueOnControl(this.newItalian, appliedFilter.newItalian);
  //   this.fs.setValueOnControl(this.gender, appliedFilter.gender);
  //   this.fs.setValueOnControl(this.legallyProtected, appliedFilter.legallyProtected);
  //   this.fs.setValueOnControl(this.feedbackMailSent, appliedFilter.feedbackMailSent);
  //   this.fs.setValueOnControl(this.keyword, appliedFilter.keyword);
  // }

  public onResetFilters() {
    this.applieJobApplicationFilter = {};

    Object.keys(this.jobApplicationFiltersGroup.controls).forEach(key => {
      const formField = this.jobApplicationFiltersGroup.get(key);

      if (formField instanceof FormArray) {
        formField?.clear();
      }

      if (formField instanceof FormControl) {
        formField?.reset();
      }
    });

    this.activateFilterSectionIfActiveFilter();
  }


  public onRemoveFilter(param: { chipValue: unknown, control: string }) {
    const filterForm = this.jobApplicationFiltersGroup.get(param.control);
    const filterApplied = this.applieJobApplicationFilter[param.control as keyof JobApplicationsByPositionFilters];

    if (Array.isArray(filterApplied) && filterForm instanceof FormArray) {
      this.handleRemoveAppliedFilterFromArray(filterForm, filterApplied, param);
    }

    if (filterForm instanceof FormControl) {
      filterForm.reset();
      this.applieJobApplicationFilter[param.control as keyof JobApplicationsByPositionFilters] = undefined;
    }

    this.activateFilterSectionIfActiveFilter();
  }


  private handleRemoveAppliedFilterFromArray(filterForm: FormArray, filterApplied: unknown[], param: { chipValue: unknown, control: string }) {
    const findIdxToRemoveFromForm = filterForm.controls.findIndex(filterToRemove => isEqual(filterToRemove.value, param.chipValue));
    const findIdxToRemoveFromApplied = filterApplied.findIndex(filterToRemove => isEqual(filterToRemove, param.chipValue));

    if (findIdxToRemoveFromApplied === -1 || findIdxToRemoveFromForm === -1) {
      return;
    }

    filterApplied.splice(findIdxToRemoveFromApplied, 1);
    filterForm.removeAt(findIdxToRemoveFromForm);
  }

  /**
   * In caso ci siano dei filtri applicati in pagina setta "someSelected" a true in modo da visualizzare la sezione dei filtri con la lista dei filtri attivi
   */
  public activateFilterSectionIfActiveFilter() {
    this.someFilterIsApplied = false;

    for (const filter of Object.values(this.applieJobApplicationFilter)) {
      if (this.appService.isEmpty(filter) || !filter) {
        continue;
      } else {
        this.someFilterIsApplied = true;
        break;
      }
    }
  }

  public set jobApplicationFilters(appliedFilter: JobApplicationsByPositionFilters) {
    this.applieJobApplicationFilter = appliedFilter;
    for (const [key, value] of Object.entries(appliedFilter)) {
      if (!Array.isArray(value)) {
        this.fs.setValueOnControl(this.jobApplicationFiltersGroup.get(key) as FormControl, value);
        continue;
      }

      const strinArrayOnly = value.every(v => value.length && typeof v === "string");
      if (strinArrayOnly) {
        value
          ?.map(v => this.fb.control(v))
          ?.forEach(v => (this.jobApplicationFiltersGroup.get(key) as FormArray).push(v));
        continue;
      }

      this.fs.setMultiValuesOnArray(this.jobApplicationFiltersGroup.get(key) as FormArray, value);
    }
  }

  public get jobApplicationStatus() {
    return this.jobApplicationFiltersGroup.get("jobApplicationStatus") as FormArray;
  }
  public get keyword() {
    return this.jobApplicationFiltersGroup.get("keyword") as FormControl;
  }
  public get ageRange() {
    return this.jobApplicationFiltersGroup.get("ageRange") as FormArray;
  }
  public get reassigned() {
    return this.jobApplicationFiltersGroup.get("reassigned") as FormControl;
  }
  public get referral() {
    return this.jobApplicationFiltersGroup.get("referral") as FormControl;
  }
  public get talent() {
    return this.jobApplicationFiltersGroup.get("talent") as FormControl;
  }
  public get feedbackMailSent() {
    return this.jobApplicationFiltersGroup.get("feedbackMailSent") as FormControl;
  }
  public get gender() {
    return this.jobApplicationFiltersGroup.get("gender") as FormControl;
  }
  public get newItalian() {
    return this.jobApplicationFiltersGroup.get("newItalian") as FormControl;
  }
  public get legallyProtected() {
    return this.jobApplicationFiltersGroup.get("legallyProtected") as FormControl;
  }
}
