import { Component, OnInit, inject } from "@angular/core";
import { FormArray, FormBuilder, FormControl } from "@angular/forms";
import { GeneraliModalService, GeneraliToastService } from "generali-shared-components/dist";
import { BaseComponent } from "src/ancestors/base/base.component";
import { PositionDetailService, BaseStatus } from "./position-detail.data.service";
import { JobApplication, JobApplicationStatusEnum, JobApplicationStatusEnumTypes, JobApplicationsByPositionFilters, JobPositionStatusEnumTypes } from "applicanttrackingsystem-cl";
import { FormCommonClasses as FormCL } from "src/app/shared/util/form.classes";
import { UrlCollection } from "src/app/app-routing.module";
import { FormService } from "src/app/shared/util/form.service";
import { CreatePosition } from "../create-position/create-position-routing.module";
import { TranslateService } from "src/app/shared/util/translate.service";
import { AnalyticsService, EventNameEnum } from "src/app/shared/service/analytics.service";
import { ConfirmCloseModalInfoInterface } from "src/app/shared/components/confirm-cancel-modal/confirm-cancel-modal.component";

export namespace ActionSelect {
  export type ActionType = "STATUS" | "SHARE" | "SHOW" | "TIMELINE" | "CLONE" | "MODIFY" | "TALENT" | "ASSESSMENT" | "OTHER" | "ONBOARDING";
  export interface ActionInterface {
    action: ActionType;
    label: string;
    icon?: string;
  }
  export const STATUS = "STATUS";
  export const SHARE = "SHARE";
  export const SHOW = "SHOW";
  export const TIMELINE = "TIMELINE";
  export const CLONE = "CLONE";
  export const MODIFY = "MODIFY";

  export const TALENT = "TALENT";
  export const ASSESSMENT = "ASSESSMENT";
  export const OTHER = "OTHER";
  export const ONBOARDING = "ONBOARDING";
}

@Component({
  selector: "position-detail",
  templateUrl: "./position-detail.component.html",
  styleUrls: ["./position-detail.component.scss"],
  providers: [PositionDetailService, AnalyticsService]

})
export class PositionDetailComponent extends BaseComponent implements OnInit {
  constructor() {
    super();
    this.analyticsService.sendVirtualPageViewEvent(this.router.url, this.pageTitle);
  }

  protected toastServiece = inject(GeneraliToastService);
  protected modalService = inject(GeneraliModalService);
  protected positionDetailService = inject(PositionDetailService);
  protected fb = inject(FormBuilder);
  protected fs = inject(FormService);
  protected translateService = inject(TranslateService);
  protected analyticsService = inject(AnalyticsService);
  protected isLoading = false;
  protected isLoadingJobApplicationList = false;
  protected sidebarLoader = false;

  protected confirmCloseModalInfo: ConfirmCloseModalInfoInterface = {};

  /** Indice della tipologia di filtro selezionato, ha lo scopo di aprire e chiudere il dropdown della tab filtri */
  protected selectedFilterIdx?: number;

  protected breadCrumb?: string;

  private pageTitle = "Dettaglio posizione | ATS";

  public override async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this.breadCrumb = (this.route.snapshot.queryParamMap.get("from") != undefined) ?
      this.route.snapshot.queryParamMap.get("from")! : "home";

    const JOB_POSITION_ID = this.appService.stringify(this.route.snapshot.params["jobPositionId"]);

    /** Carica la Job Position */
    const [filteredJobPosition, hasBeenOpen, appliedPositionFilter] = await Promise.all([
      this.backendService.jobPositionListWithCounters({ id: JOB_POSITION_ID }),
      this.backendService.jobPositiondHasEverBeenOpened(JOB_POSITION_ID),
      this.backendService.jobPositionFiltersGet<JobApplicationsByPositionFilters>("JOB_APPLICATIONS_BY_POSITION_LIST")
    ]);
    this.positionDetailService.fetchData.filteredJobPosition = filteredJobPosition.at(0);
    this.positionDetailService.hasBeenOpen = hasBeenOpen;
    this.positionDetailService.appliedPositionFilter = appliedPositionFilter;

    await this.positionDetailService.canAddModifyOptionToActions(this.route.snapshot.params["jobPositionId"] as string);

    this.positionDetailService.setFiltersFields();

    this.activateFilterSectionIfActiveFilter();

    await this.parallelFetchListAndCounter();
    this.isLoading = false;
  }
  protected canAccessDetailsAsHrbp(jobApplication: JobApplication): boolean { 
    return jobApplication?.canAccessDetails ? true : false;
  }
  /**
   * Refresha lista candidati e conteggio candidati
   */
  protected async parallelFetchListAndCounter() {
    this.isLoadingJobApplicationList = true;
    const JOB_POSITION_ID = this.appService.stringify(this.route.snapshot.params["jobPositionId"]);
    if (this.positionDetailService.selectedOrder?.sortingField != undefined)
      this.positionDetailService.appliedOrder = { ...this.positionDetailService.selectedOrder };

    const APPLIED_FILTER = {
      sortingField: this.positionDetailService.appliedOrder?.sortingField,
      sortingDesc: this.positionDetailService.appliedOrder?.sortingDesc,
      jobApplicationStatus: this.positionDetailService.appliedPositionFilter.jobApplicationStatus?.length ? this.positionDetailService.appliedPositionFilter.jobApplicationStatus : undefined,
      keyword: this.positionDetailService.appliedPositionFilter.keyword,
      reassigned: this.positionDetailService.appliedPositionFilter.reassigned,
      referral: this.positionDetailService.appliedPositionFilter.referral,
      talent: this.positionDetailService.appliedPositionFilter.talent,
      feedbackMailSent: this.positionDetailService.appliedPositionFilter.feedbackMailSent,
      newItalian: this.positionDetailService.appliedPositionFilter.newItalian,
      legallyProtected: this.positionDetailService.appliedPositionFilter.legallyProtected,
      ageRange: this.fs.getMultiValuesOutOfArray<string[]>(this.positionDetailService.ageRange, "value"),
      gender: this.positionDetailService.appliedPositionFilter.gender,
      numRecords: this.positionDetailService.numRecord,
      fromRecord: (this.positionDetailService.currentPage - 1) * this.positionDetailService.numRecord
    };


    const [_, jobApplicationCount, jobApplicationList] = await Promise.all([
      this.saveFilter(),
      this.backendService.jobApplicationCount(JOB_POSITION_ID, APPLIED_FILTER),
      this.backendService.jobApplicationListByPosition(JOB_POSITION_ID, APPLIED_FILTER)
    ]);

    this.positionDetailService.fetchData.jobApplicationCount = jobApplicationCount;
    this.positionDetailService.fetchData.jobApplicationList = jobApplicationList;
    this.isLoadingJobApplicationList = false;
  }

  /**
   * Pero ora apre solo la tab di cambio status ma si dovranno gestire tutte lo opzioni di click
   *
   * @param action
   */
  async onSimpleSelectClick(action: string) {
    const JOB_POSITION_ID = this.appService.stringify(this.route.snapshot.params["jobPositionId"]);

    switch (action) {
      case ActionSelect.STATUS:
        this.positionDetailService.drawer.showPositionStatus = true;
        this.positionDetailService.setPositionStatus();

        this.positionDetailService.setChangeStatusDrawer({ positionStatus: this.positionDetailService.fetchData.filteredJobPosition?.currentStatus });
        break;


      case ActionSelect.SHARE:
        if (JOB_POSITION_ID) {
          const pubblicationUrl = await this.backendService.getPublicationURL(JOB_POSITION_ID);
          if (pubblicationUrl) {
            await navigator.clipboard.writeText(pubblicationUrl);
            this.toastServiece.success(this.translateService.instant<string>("position.detail.COPY_ON_CLIPBOARD"));
          }
        } else {
          this.toastServiece.error("Non esiste alcuna job position");
        }
        break;

      case ActionSelect.TIMELINE:
        this.positionDetailService.drawer.showTimeline = true;
        try {
          if (JOB_POSITION_ID) {
            this.positionDetailService.fetchData.allStatusForTimeline = await this.backendService.listAllStatuses(JOB_POSITION_ID);
          } else {
            this.toastServiece.error("Non esiste alcuna job position");
          }
        } catch (error: unknown) {
          this.errorHandler.handleError(error);
        }
        break;

      case ActionSelect.SHOW:
        await this.router.navigate([UrlCollection.preview, JOB_POSITION_ID], { queryParams: { from: UrlCollection.positionDetail } });
        break;

      case ActionSelect.CLONE: {
        try {
          const jobPositionIdClone = await this.backendService.jobPositionClone(JOB_POSITION_ID);
          await this.router.navigate([CreatePosition.createPosition, CreatePosition.process], { queryParams: { clone: jobPositionIdClone } });
        } catch (error: unknown) {
          this.errorHandler.handleError(error);
        }
        break;
      }

      case ActionSelect.MODIFY:
        await this.router.navigate([CreatePosition.createPosition, CreatePosition.process], { queryParams: { edit: JOB_POSITION_ID } });
        break;

        case ActionSelect.ONBOARDING:
          window.open(this.env.loginUrlOnboardingUp);
          break;
      
      default:
        break;
    }
  }

  protected onClosingFilterDrawer() {
    this.positionDetailService.drawer = {};
    if (!this.positionDetailService.selectedOrder?.applied) {
      this.onResetSelectedOrder();
    }
  }

  /**
   * Resetta (se selezionato) campo applicato selectedOrder
   */
  protected onResetSelectedOrder() {
    this.positionDetailService.selectedOrder = undefined;
    this.positionDetailService.appliedOrder = undefined;
    this.positionDetailService.appliedPositionFilter.sortingDesc = undefined;
    this.positionDetailService.appliedPositionFilter.sortingField = undefined;
  }

  /**
   * Resetta (se selezionato) campo applicato selectedOrder
   */
  protected async onResetAppliedOrder() {
    this.onResetSelectedOrder();

    await this.backendService.jobPositionFiltersDelete("JOB_APPLICATIONS_BY_POSITION_SORT");

    await this.parallelFetchListAndCounter();
  }

  protected async onResetFilterDrawer() {
    this.onClosingFilterDrawer();
    this.positionDetailService.appliedPositionFilter = {};
    this.positionDetailService.positionFilter.reset();

    this.selectedFilterIdx = undefined;
    Object.keys(this.positionDetailService.positionFilter.controls).forEach(key => {
      const formField = this.positionDetailService.positionFilter.get(key);

      if (formField instanceof FormArray) {
        formField?.clear();
      }

      if (formField instanceof FormControl) {
        formField?.reset();
      }
    });
    
    await this.backendService.jobPositionFiltersDelete("JOB_APPLICATIONS_BY_POSITION_LIST");

    await this.parallelFetchListAndCounter();
  }

  protected onClearCardSelected() {
    this.positionDetailService.cardsSelected = [];
  }

  /**
   *
   * Applica l'ordine se selezionato
   */
  protected async onApplyOrder() {
    if (this.positionDetailService.selectedOrder) {
      this.positionDetailService.appliedOrder = { ...this.positionDetailService.selectedOrder };
      this.positionDetailService.selectedOrder.applied = true;

      this.positionDetailService.appliedPositionFilter.sortingDesc = this.positionDetailService.appliedOrder.sortingDesc;
      this.positionDetailService.appliedPositionFilter.sortingField = this.positionDetailService.appliedOrder.sortingField;
    }

    this.activateFilterSectionIfActiveFilter();
    this.analyticsService.sendVirtualEvent(EventNameEnum.order, this.router.url, this.pageTitle, { topic: "Job Application" });
    await this.parallelFetchListAndCounter();

    this.onClosingFilterDrawer();
  }

  protected onPositionStatusSelection(badge: BaseStatus) {
    this.positionDetailService.selectetStatus.jobPositionStatus = badge;
  }

  protected onPersonStatusSelection(badge: BaseStatus) {
    this.positionDetailService.selectetStatus.jobApplicationStaus = badge;
  }

  /**
   * @param idx
   *
   * Apre e chiude il dropdown del dei filtri in base all'index
   */
  protected onSelectedFilter(idx: number) {
    if (this.selectedFilterIdx !== undefined && this.selectedFilterIdx === idx) {
      this.selectedFilterIdx = undefined;
    } else {
      this.selectedFilterIdx = idx;
    }
  }

  protected onChipSelection(chip: string) {
    /** Verifica se il valore selezionato dall'utente è un duplicato */
    const duplicateFound = this.checkDuplicate(this.positionDetailService.status, chip);
    /** Se non è un duplicato, aggiunge un nuovo gruppo al form con le informazioni del chip selezionato */
    if (!duplicateFound) {
      this.positionDetailService.status.push(this.fb.group({ label: chip }));
    }

    if (duplicateFound) {
      /** Recupera l'indice del valore duplicato */
      const duplicateIdx = this.getDuplicate(this.positionDetailService.status, chip);

      this.positionDetailService.status.removeAt(duplicateIdx);
    }
  }

  /**
   * @param arr Array di partenza.
   * @param chip Valore da ricarcare.
   *
   * True se trova un duplicato altrimenti false.
   */
  private checkDuplicate(arr: FormArray, chip: string) {
    return arr.controls.some((value) => value.get("label")?.value === chip);
  }

  /**
   * @param arr Array di partenza
   * @param chip Valore da ricarcare
   *
   * Index del duplicato
   */
  private getDuplicate(arr: FormArray, chip: string) {
    return arr.controls.findIndex((value) => value.get("label")?.value === chip);
  }

  /**
   * @param arr Array di partenza
   * @param chip Valore da ricarcare
   *
   * True se il chip ricercato è presente nell'array altrimenti false
   */
  protected setSelectedChip(arr: FormArray, chip: string) {
    return arr.controls.some(val => val.get("label")?.value === chip);
  }

  /**
   * Applica e rimuove i filtri in base alla selezione
   */
  protected async onApplyFilter() {
    this.isLoading = true;
    this.onClosingFilterDrawer();

    /** FILTRI GRAFICI */
    this.positionDetailService.appliedPositionFilter.talent = this.fs.getSingleValueOutOfControl<boolean>({ control: this.positionDetailService.talent });
    this.positionDetailService.appliedPositionFilter.reassigned = this.fs.getSingleValueOutOfControl<boolean>({ control: this.positionDetailService.reassignment });
    this.positionDetailService.appliedPositionFilter.feedbackMailSent = this.fs.getSingleValueOutOfControl<boolean>({ control: this.positionDetailService.feedbackEmail });
    this.positionDetailService.appliedPositionFilter.referral = this.fs.getSingleValueOutOfControl<boolean>({ control: this.positionDetailService.referral });
    this.positionDetailService.appliedPositionFilter.gender = this.fs.getSingleValueOutOfControl<string>({ control: this.positionDetailService.genre });
    this.positionDetailService.appliedPositionFilter.newItalian = this.fs.getSingleValueOutOfControl<boolean>({ control: this.positionDetailService.newIta });
    this.positionDetailService.appliedPositionFilter.legallyProtected = this.fs.getSingleValueOutOfControl<boolean>({ control: this.positionDetailService.protectedCategory });
    this.positionDetailService.appliedPositionFilter.ageRange = this.fs.getMultiValuesOutOfArray<string[]>(this.positionDetailService.ageRange, "label");
    this.positionDetailService.appliedPositionFilter.jobApplicationStatus = this.fs.getMultiValuesOutOfArray<JobApplicationStatusEnum[]>(this.positionDetailService.status, "label");
    this.positionDetailService.appliedPositionFilter.keyword = this.fs.getSingleValueOutOfControl<string>({ control: this.positionDetailService.keyword });
    this.positionDetailService.currentPage = 1;
    this.sendEvent(this.positionDetailService.appliedPositionFilter);
    await this.saveFilter();

    this.activateFilterSectionIfActiveFilter();
    await this.parallelFetchListAndCounter();
    this.isLoading = false;
  }

  protected async saveFilter() {
    await this.backendService.jobPositionFiltersSet("JOB_APPLICATIONS_BY_POSITION_LIST", this.positionDetailService.appliedPositionFilter);
  }


  protected selectedJobApplicationStatus?: JobApplicationStatusEnum;

  /**
   * Imposta lo status della Job Application in base alle selezioni delle carte.
   * Se tutti gli stati selezionati corrispondono, preseleziona lo status comune nel drawer di cambio status.
   */
  protected setCommonJobApplicationStatus() {
    if (!this.positionDetailService.fetchData.jobApplicationList?.length) {
      return;
    }

    const selectedJobApplicationStatus: JobApplicationStatusEnum[] = [];
    for (const jobApplicationId of this.positionDetailService.cardsSelected) {
      this.positionDetailService.fetchData.jobApplicationList.forEach(jobApplication => {
        if (jobApplication.currentStatus && jobApplication.jobApplicationId.includes(jobApplicationId)) {
          selectedJobApplicationStatus.push(jobApplication.currentStatus);
        }
      });
    }

    if (selectedJobApplicationStatus.length) {
      this.selectedJobApplicationStatus = selectedJobApplicationStatus.every(val => selectedJobApplicationStatus[0] === val) ? selectedJobApplicationStatus[0] : undefined;
    } else {
      this.selectedJobApplicationStatus = undefined;
    }
  }

  /** Filtri true/false */
  protected toggleReferral(evt?: boolean) {
    this.positionDetailService.referral.setValue(evt);
  }

  protected toggleTalent(evt?: boolean) {
    this.positionDetailService.talent.setValue(evt);
  }

  protected toggleReassignment(evt?: boolean) {
    this.positionDetailService.reassignment.setValue(evt);
  }

  protected toggleFeedbackEmail(evt?: boolean) {
    this.positionDetailService.feedbackEmail.setValue(evt);
  }

  protected toggleNewItalian(evt?: boolean) {
    this.positionDetailService.newIta.setValue(evt);
  }

  protected toggleProtectedCategory(evt?: boolean) {
    this.positionDetailService.protectedCategory.setValue(evt);
  }

  protected toggleGenre(evt?: string) {
    this.positionDetailService.genre.setValue(evt);
  }

  /** RIMOZIONE DEI CAMPI INSERITI NEI FILTRI */
  protected async onReasignedExclude() {
    this.positionDetailService.appliedPositionFilter.reassigned = undefined;
    this.positionDetailService.reassignment.reset();

    this.activateFilterSectionIfActiveFilter();
    await this.parallelFetchListAndCounter();
  }

  protected async onGenreExclude() {
    this.positionDetailService.appliedPositionFilter.gender = undefined;
    this.positionDetailService.genre.reset();

    this.activateFilterSectionIfActiveFilter();
    await this.parallelFetchListAndCounter();
  }

  protected async onNewItalianExclude() {
    this.positionDetailService.appliedPositionFilter.newItalian = undefined;
    this.positionDetailService.newIta.reset();

    this.activateFilterSectionIfActiveFilter();
    await this.parallelFetchListAndCounter();
  }

  protected async onProtectedCategoryExclude() {
    this.positionDetailService.appliedPositionFilter.legallyProtected = undefined;
    this.positionDetailService.protectedCategory.reset();

    this.activateFilterSectionIfActiveFilter();
    await this.parallelFetchListAndCounter();
  }

  protected async removeKeyWSelected() {
    this.positionDetailService.appliedPositionFilter.keyword = undefined;
    this.positionDetailService.keyword.reset();

    this.activateFilterSectionIfActiveFilter();
    await this.parallelFetchListAndCounter();
  }

  protected async onReferralExclude() {
    this.positionDetailService.appliedPositionFilter.referral = undefined;
    this.positionDetailService.referral.reset();

    this.activateFilterSectionIfActiveFilter();
    await this.parallelFetchListAndCounter();
  }

  protected async onAgeRangeExclude(idxToExclude: number) {
    this.positionDetailService.appliedPositionFilter.ageRange?.splice(idxToExclude, 1);
    this.positionDetailService.ageRange.removeAt(idxToExclude);

    this.activateFilterSectionIfActiveFilter();
    await this.parallelFetchListAndCounter();
  }

  protected async onStatusExclude(idxToExclude: number) {
    this.positionDetailService.appliedPositionFilter.jobApplicationStatus?.splice(idxToExclude, 1);
    this.positionDetailService.status.removeAt(idxToExclude);

    this.activateFilterSectionIfActiveFilter();
    await this.parallelFetchListAndCounter();
  }

  protected async onTalentExclude() {
    this.positionDetailService.appliedPositionFilter.talent = undefined;
    this.positionDetailService.talent.reset();

    this.activateFilterSectionIfActiveFilter();
    await this.parallelFetchListAndCounter();
  }

  protected async onFeedBackEmailExclude() {
    this.positionDetailService.appliedPositionFilter.feedbackMailSent = undefined;
    this.positionDetailService.feedbackEmail.reset();

    this.activateFilterSectionIfActiveFilter();
    await this.parallelFetchListAndCounter();
  }

  /**
   * Attiva la sezione dei filtri se è presente almeno un filtro attivo.
   */
  protected activateFilterSectionIfActiveFilter() {
    for (const filter in this.positionDetailService.appliedPositionFilter) {
      if (this.appService.isEmpty(this.positionDetailService.appliedPositionFilter[filter as keyof JobApplicationsByPositionFilters])) {
        this.positionDetailService.filterIsApplied = false;
      } else {
        this.positionDetailService.filterIsApplied = true;
        break;
      }
    }
  }

  /**
   * @param isChecked boolean - il componente checkbox ritorna true o false in base a se l'elemento è selezionato o meno
   *
   * Quindi se è selezionato Seleziona tutte le cards altrimenti le deseleziona
   *
   */
  protected onSelectAll(isChecked: boolean) {
    if (isChecked) {
      this.positionDetailService.cardsSelected = this.positionDetailService.fetchData.jobApplicationList?.map(jobApplication => jobApplication.jobApplicationId) ?? [];
      this.setCommonJobApplicationStatus();
    } else {
      this.positionDetailService.cardsSelected = [];
    }
  }

  /**
   * @param jobApplicationId
   * Selezione della card e salvataggio dell'index delle cards selezionate
   */
  protected onCardSelection(jobApplicationId: string) {
    if (this.positionDetailService.cardsSelected.includes(jobApplicationId)) {
      this.positionDetailService.cardsSelected = this.positionDetailService.cardsSelected.filter(i => i !== jobApplicationId);
    } else {
      this.positionDetailService.cardsSelected.push(jobApplicationId);
      this.setCommonJobApplicationStatus();
    }
  }

  /**
   * Applicazione dello staus selezionato a meno che non sia CLOSED_FILLED,
   * in quel caso è richiesta un'ulteriore conferma da parte dell'utente per accertarsi di non aver premuto inintenzionalmente lo stato CLOSED_FILLED
   */
  protected async onConfirmPositionStatusChange(alreadyConfirmedByModal?: boolean) {

    // Se il nuovo status è chiusa (filled o vuota) e non è ancora stata mostrata la modale di conferma, la mostra
    if ((this.positionDetailService.selectedPositionStatus === JobPositionStatusEnumTypes.CLOSED_FILLED || this.positionDetailService.selectedPositionStatus === JobPositionStatusEnumTypes.CLOSED_EMPTY) && !alreadyConfirmedByModal) {
      await this.updateConfirmCloseModalInfo();
      this.modalService.open("confirm-closing-position");
      return;
    } else {
      this.modalService.close("confirm-closing-position");
    }


    this.positionDetailService.drawer.showPositionStatus = false;
    const JOB_POSITION_ID = this.positionDetailService.fetchData.filteredJobPosition?.jobPositionId;
    this.analyticsService.sendVirtualEvent(EventNameEnum.changeStatusJobPosition, this.router.url, this.pageTitle, { stato: this.getBadgeLabel(this.positionDetailService?.selectedPositionStatus)?.label });

    if (JOB_POSITION_ID && this.positionDetailService.selectedPositionStatus) {
      let statusIsChanged;
      if (this.usrService.isEBRM) {
        statusIsChanged = await this.backendService.jobPositionChangeStatus({
          newStatus: this.positionDetailService.selectedPositionStatus,
          jobPositionId: JOB_POSITION_ID,
          comment: this.appService.stringify(this.positionDetailService.jobPositionComment.value)
        });
      } else {
        statusIsChanged = await this.backendService.jobPositionChangeStatusRestricted({
          newStatus: this.positionDetailService.selectedPositionStatus,
          jobPositionId: JOB_POSITION_ID,
          comment: this.appService.stringify(this.positionDetailService.jobPositionComment.value)
        });
      }
      if (!statusIsChanged) {
        return;
      }
      this.positionDetailService.fetchData.filteredJobPosition = (await this.backendService.jobPositionListWithCounters({ id: JOB_POSITION_ID })).at(0);
      await this.parallelFetchListAndCounter();
      await this.positionDetailService.canAddModifyOptionToActions(this.route.snapshot.params["jobPositionId"] as string);
      this.toastServiece.success($localize`:@@position.detail.TOAST_SUCCESS:position.detail.TOAST_SUCCESS`);
    } else {
      this.toastServiece.error($localize`:@@position.detail.TOAST_ERROR:position.detail.TOAST_ERROR`);
    }

  }

  /**
   * Chiusura della modale di avvertimento per lo status CLOSED_FILLED
   */
  protected onCloseModal(modalId: string) {
    this.modalService.close(modalId);
  }

  /**
   * Esegue la chiamata per il cambio massivo di status se è stato selezionato il nuovo status, altrimenti apri modale di avvertimento
   */
  protected async onMassiveChangeStatus(alreadyConfirmedByModal?: boolean) {
    if (!this.positionDetailService.selectetStatus.jobApplicationStaus) {
      this.modalService.open("no-status-warning");
      return;
    }


    try {
      const jobApplicationIds = this.positionDetailService.cardsSelected.map(jobApplicationId => jobApplicationId);
      const NEW_STATUS = this.positionDetailService.selectetStatus.jobApplicationStaus.applicationBadge;

    /**
     * Se lo stato selezionato è ACCETTA, controllo se le candidature già accettate più quelle da accettare coprono il numero di posizioni aperte.
     * In questo caso, la posizione verrà chiusa (filled) quindi devo mostrare la modale di conferma.
     */
    if (NEW_STATUS === JobApplicationStatusEnumTypes.ACCEPT && !alreadyConfirmedByModal) {

      const alreadyAcceptedApplications = this.positionDetailService.fetchData.filteredJobPosition?.counters?.accept ?? 0;
      const openedPositionsCount = this.positionDetailService.fetchData.filteredJobPosition?.openedPositionsCount ?? 0;
      const applicationsToAccept = this.positionDetailService.cardsSelected.length;

      if ((alreadyAcceptedApplications + applicationsToAccept) >= openedPositionsCount) {
        await this.updateConfirmCloseModalInfo(true, jobApplicationIds);
        this.modalService.open("confirm-accept-application");
        return;
      }

    } else {
      this.modalService.close("confirm-accept-application");
    }


      if (!NEW_STATUS) {
        return;
      }
      if (NEW_STATUS != undefined) {
        this.analyticsService.sendVirtualEvent(EventNameEnum.changeStatusJobApplication, this.router.url, this.pageTitle, { stato: this.getApplicationBadgeLabel(NEW_STATUS)?.label });
      }
      await this.backendService.jobApplicationChangeStatusMassive(jobApplicationIds, NEW_STATUS);
      this.positionDetailService.drawer.showPersonaStatus = false;
      await this.ngOnInit();
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    await this.parallelFetchListAndCounter();
  }

  /**
   * Apre/chiude il drawer candidature realizzate
   */
  protected async toggleRealizedApplicants(canShow: boolean, jobApplication: JobApplication) {
    this.sidebarLoader = true;
    this.positionDetailService.drawer.showRealizedApplicants = canShow;

    if (this.positionDetailService.drawer.showRealizedApplicants) {
      this.positionDetailService.jobApplicationHistoryOwner = `${jobApplication.forename} ${jobApplication.surname}`;
      this.positionDetailService.fetchData.jobApplicationHistory = await this.backendService.jobApplicationListByUser(jobApplication?.userId);
    }
    this.sidebarLoader = false;
  }

  protected async redirectToHome() {
    await this.router.navigate([UrlCollection.generalHomepage]);
  }

  protected async redirectToSearch() {
    await this.router.navigate([UrlCollection.search]);
  }

  protected get positionStatusOptions() {
    return JobPositionStatusEnumTypes;
  }

  protected get formCl() {
    return FormCL;
  }

  /**
   * Evento di cambio pagina
   */
  protected async onPageChange() {
    this.appService.scrollTo("#ancor-tag");
    await this.parallelFetchListAndCounter();
  }

  /**
   * Al cambio di elementi per pagina a prescindere dalla pagina in cui ci si trova, si ritorna alla prima.
  */
  protected async onChangePaginationCount() {
    this.positionDetailService.currentPage = 1;

    await this.parallelFetchListAndCounter();
  }

  protected async redirectToCandidate(jobApplicationId: string) {
    await this.router.navigate([UrlCollection.candidateSheet, jobApplicationId],
      {
        queryParams: {
          ...(delete this.positionDetailService.appliedPositionFilter.fromRecord
            && delete this.positionDetailService.appliedPositionFilter.numRecords
            && this.positionDetailService.appliedPositionFilter)
        }
      });
  }

  private getBadgeLabel(valueApplicationBadge?: string) {
    const status = [
      { applicationBadge: "DRAFT", label: "Bozza" },
      { applicationBadge: "TO_BE_APPROVED", label: "Da approvare" },
      { applicationBadge: "OPEN", label: "Aperta" },
      { applicationBadge: "PUBLISHED", label: "Pubblicata" },
      { applicationBadge: "CLOSED_EMPTY", label: "Chiusa-vuota" },
      { applicationBadge: "CLOSED_FILLED", label: "Chiusa-filled" },
      { applicationBadge: "SUSPENDED", label: "Sospesa" }
    ];


    return status.find((el: any) => {
      if (el?.applicationBadge == valueApplicationBadge) {
        return el;
      }
    });
  }

  private getApplicationBadgeLabel(valueApplicationBadge?: string | undefined) {

    if (valueApplicationBadge == undefined)
      return { label: "" };

    const status: BaseStatus[] = [
      { applicationBadge: "STOP", label: "Stop" },
      { applicationBadge: "TO_EVALUATE", label: "Da valutare" },
      { applicationBadge: "LONG_LIST", label: "Long list" },
      { applicationBadge: "SHORT_LIST", label: "Short list" },
      { applicationBadge: "OFFER", label: "Offerta" },
      { applicationBadge: "ACCEPT", label: "Accetta" },
      { applicationBadge: "REFUSE", label: "Rifiuta" }
    ];

    return status.find((el: any) => {
      if (el?.applicationBadge == valueApplicationBadge) {
        return el;
      }
    });
  }

  private sendEvent(filters: JobApplicationsByPositionFilters) {
    for (const [key, value] of Object.entries(filters)) {
      let eventName!: EventNameEnum;
      if (Array.isArray(value)) {
        if ((value as unknown[]).length > 0) {
          for (const el of value as unknown[]) {
            switch (key) {
              case "jobApplicationStatus":
                eventName = EventNameEnum.filterJobApplicationStatus;
                break;
              case "ageRange":
                eventName = EventNameEnum.filterJobApplicationAgeRange;
                break;
            }
            this.analyticsService.sendVirtualEvent(eventName, this.router.url, this.pageTitle);
          }
        }
      } else {
        if (value == null || value == "")
          continue;
        switch (key) {
          case "keyword":
            eventName = EventNameEnum.filterJobApplicationKeyword;
            break;
          case "reassigned":
            eventName = EventNameEnum.filterJobApplicationReassigned;
            break;
          case "referral":
            eventName = EventNameEnum.filterJobApplicationReferral;
            break;
          case "talent":
            eventName = EventNameEnum.filterJobApplicationTalent;
            break;
          case "newItalian":
            eventName = EventNameEnum.filterJobApplicationNewItalian;
            break;
          case "legallyProtected":
            eventName = EventNameEnum.filterJobApplicationLegallyProtected;
            break;
          case "feedbackMailSent":
            eventName = EventNameEnum.filterJobApplicationFeedbackMailSent;
            break;
          case "gender":
            eventName = EventNameEnum.filterJobApplicationGender;
            break;
        }
        this.analyticsService.sendVirtualEvent(eventName, this.router.url, this.pageTitle);
      }
    }
  }

  /*
  private updateConfirmCloseModalInfo(isAcceptingApplication?: boolean, jobApplicationIds?: string[]) {
    const newObj: ConfirmCloseModalInfoInterface = {};
    newObj.positionName = `${this.positionDetailService.fetchData.filteredJobPosition?.progressiveCodeDecoded ?? ""} ${this.positionDetailService.fetchData.filteredJobPosition?.title ?? ""}`;
    newObj.countLongList = this.positionDetailService.fetchData.filteredJobPosition?.counters?.longList ?? 0;
    newObj.countShortList = this.positionDetailService.fetchData.filteredJobPosition?.counters?.shortList ?? 0;
    newObj.countToEvaluate = this.positionDetailService.fetchData.filteredJobPosition?.counters?.toEvaluate ?? 0;


    // Utenti da escludere dal calcolo
    const usersCountToIgnore = {
      longList: 0,
      shortList: 0,
      toEvaluate: 0
    };

    const reassignedJobApplications = this.positionDetailService.fetchData.jobApplicationList?.filter(ja => ja.reassigned) ?? [];

    // Dal conteggio devo escludere le candidature riassegnate
    const jobApplicationsToIgnoreCount = reassignedJobApplications;

    if (isAcceptingApplication) {
      // Nel caso in cui stia accettando massivamente più candidature devo recuperare tutti i nomi e escludere dal conteggio le candidature da accettare

      const filteredJobApplications = this.positionDetailService.fetchData.jobApplicationList?.filter(ja => jobApplicationIds?.includes(ja.jobApplicationId)) ?? [];

      // Recupera i nomi degli utenti da accettare
      const nameToShow = filteredJobApplications?.map(u => {
        return `${u.forename} ${u.surname}`;
      }).join(", ") ?? undefined;

      // Escludi dal calcolo gli stati degli utenti da accettare (solo se non già esclusi perchè riassegnati)
      jobApplicationsToIgnoreCount.push(...filteredJobApplications.filter(ja => !ja.reassigned));

      // Aggiorna i dati
      newObj.isAcceptingApplication = true;
      newObj.nameToShow = nameToShow;

    } else {
      // Altrimenti devo fare un reset di alcuni campi, che potrebbero essere stati settati in precedenza.
      newObj.isAcceptingApplication = false;
      newObj.nameToShow = undefined;
    }

    // Aggiorno i counter
    jobApplicationsToIgnoreCount?.forEach(ja => {
      switch (ja.currentStatus) {
        case JobApplicationStatusEnumTypes.SHORT_LIST:
          usersCountToIgnore.shortList++;
          break;
        case JobApplicationStatusEnumTypes.LONG_LIST:
          usersCountToIgnore.longList++;
          break;
        case JobApplicationStatusEnumTypes.TO_EVALUATE:
          usersCountToIgnore.toEvaluate++;
          break;
      }
    });

    newObj.countLongList -=  (usersCountToIgnore?.longList ?? 0);
    newObj.countShortList -=  (usersCountToIgnore?.shortList ?? 0);
    newObj.countToEvaluate -=  (usersCountToIgnore?.toEvaluate ?? 0);

    this.confirmCloseModalInfo = newObj;
  }
  */

  private async updateConfirmCloseModalInfo(isAcceptingApplication?: boolean, jobApplicationIds?: string[]) {

    const JOB_POSITION_ID = this.appService.stringify(this.route.snapshot.params["jobPositionId"]);

    const newObj = await this.backendService.getConfirmClosePositionModalInfo({
      jobPositionId: JOB_POSITION_ID,
      isAcceptingApplication: !!isAcceptingApplication,
      jobApplicationIdsToAccept: jobApplicationIds ?? []
    });

    newObj.isAcceptingApplication = !!isAcceptingApplication;

    this.confirmCloseModalInfo = newObj;

  }
}
