/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { GeneraliModalService, GeneraliStatuTab, GeneraliToastService } from "generali-shared-components/dist";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

import { BaseComponent } from "src/ancestors/base/base.component";
import { FormCommonClasses } from "src/app/shared/util/form.classes";
import { CandidateSheetService } from "./candidate-sheet.data.service";
import {
  InterviewInstance,
  InterviewLogicTypeEnumTypes,
  InterviewUserRoleEnumTypes,
  InterviewStatusEnumTypes,
  JobApplicationComment,
  JobApplicationStatusEnum,
  JobApplicationStatusEnumTypes,
  SimpleUser,
  InterviewFinalValuationEnumTypes,
  InterviewLogicTypeEnum,
  InterviewFormEBRM,
  InterviewFormEBRMFirst,
  InterviewFormHRBP,
  InterviewFormLineManager,
  InterviewFormBusiness,
  InterviewStrengthPointEnum,
  SearchReassignableJobPositionsResponse,
  ScreeningTestInstance,
  Upload,
  JobApplication,
  InterviewFinalValuationEnum,
  ScreeningTest,
  ScreeningTestStatusEnumTypes,
  InterviewFormHRBPSale,
  JobApplicationsByPositionFilters,
  UserFiltersTypeEnumTypes
} from "applicanttrackingsystem-cl";
import { ActionSelect } from "../position-detail/position-detail.component";
import { FormService } from "src/app/shared/util/form.service";
import { UrlCollection } from "src/app/app-routing.module";
import { TranslateService } from "src/app/shared/util/translate.service";
import { AnalyticsService, EventNameEnum } from "src/app/shared/service/analytics.service";
import { BaseStatus } from "../position-detail/position-detail.data.service";
import { UserService } from "src/app/global-service/user.service";
import { ConfirmCloseModalInfoInterface } from "src/app/shared/components/confirm-cancel-modal/confirm-cancel-modal.component";

interface InterfaceEBRM {
  interests: number,
  compatibility: number,
  motivation: number,
  trust: number,
  teamwork: number,
  innovatitve: number,
  openess: number,
  proactivity: number,
  execution: number,
  makingSimple: number,
  adaptability: number
}

interface InterfaceHRBP {
  motivation: number,
  interests: number,
  compatibility: number
}

interface InterfaceHRBPSALES {
  communication: number,
  entrepreneurship: number,
  motivation: number,
  customerOrientation: number,
  adaptability: number
}

interface InterfaceBusinessInterview {
  interests: number,
  motivation: number,
  trust: number,
  teamwork: number,
  innovatitve: number,
  openess: number,
  proactivity: number,
  execution: number,
  makingSimple: number,
  adaptability: number
}
interface PropertyInterviewTypeFormInterface {
  ebrm: InterfaceEBRM,
  hrbp: InterfaceHRBP,
  hrbpSale: InterfaceHRBPSALES,
  businessInterview: InterfaceBusinessInterview,
  actualRetribution?: number
}

interface InterviewUserInfo {
  currentStatus: string | undefined,
  forename: string | undefined,
  surname: string | undefined,
  role: string | undefined,
  formCompiledAt: Date | undefined,
  interviewUserId: string | undefined,
  title: string | undefined,
  type: InterviewLogicTypeEnum | undefined
}

interface BehaviorInteface {
  innovation?: boolean;
  ownership?: boolean;
  humanTouch?: boolean;
  simplification?: boolean
}
@Component({
  selector: "app-candidate-sheet",
  templateUrl: "./candidate-sheet.component.html",
  styleUrls: ["./candidate-sheet.component.scss"],
  providers: [CandidateSheetService, AnalyticsService]

})
export class CandidateSheetComponent extends BaseComponent implements OnInit, OnDestroy {
  protected candidateSheetService = inject(CandidateSheetService);
  protected modalService = inject(GeneraliModalService);
  protected fb = inject(FormBuilder);
  protected fs = inject(FormService);
  protected toastService = inject(GeneraliToastService);
  protected translateService = inject(TranslateService);
  protected analyticsService = inject(AnalyticsService);
  protected userService = inject(UserService);




  constructor() {
    super();
    this.analyticsService.sendVirtualPageViewEvent(this.router.url, this.pageTitle);
  }

  public finalValuationResultArray = [
    { label: "Strong hire", value: InterviewFinalValuationEnumTypes.STRONG_HIRE },
    { label: "Hire", value: InterviewFinalValuationEnumTypes.HIRE },
    { label: "Uncertain", value: InterviewFinalValuationEnumTypes.UNCERTAIN },
    { label: "Not to hire", value: InterviewFinalValuationEnumTypes.NOT_HIRE }
  ];

  protected hasErrorPDF = false;
  protected isLoadingData = false;
  protected statusToChange: JobApplicationStatusEnum | undefined;

  protected confirmCloseModalInfo: ConfirmCloseModalInfoInterface = {};

  protected interviewUserInfo!: InterviewUserInfo;
  protected interviewInstance!: InterviewInstance;
  protected propertiesForm: PropertyInterviewTypeFormInterface = {
    "ebrm": {
      "interests": 0,
      "motivation": 0,
      "compatibility": 0,
      "trust": 0,
      "teamwork": 0,
      "innovatitve": 0,
      "openess": 0,
      "proactivity": 0,
      "execution": 0,
      "makingSimple": 0,
      "adaptability": 0
    },
    "hrbp": {
      motivation: 0,
      interests: 0,
      compatibility: 0
    },
    "businessInterview": {
      interests: 0,
      motivation: 0,
      trust: 0,
      teamwork: 0,
      innovatitve: 0,
      openess: 0,
      proactivity: 0,
      execution: 0,
      makingSimple: 0,
      adaptability: 0
    },
    "hrbpSale": {
      communication: 0,
      entrepreneurship: 0,
      motivation: 0,
      customerOrientation: 0,
      adaptability: 0
    },
    actualRetribution: 0
  };
  protected formReadOnly = false;
  protected behavior: any = {};

  //protected actualRetribution = this.fb.control(null);


  /** Campi evaluation form */
  public efForm: FormGroup = this.fb.group({});
  public competences: any = [];
  public efFormDirty = false;
  protected errorStar!: any;
  protected breadCrumb?: string;
  protected testScreeningUpdate = false;
  protected fileUploaded!: Upload | undefined;
  protected fileToUpload: File | undefined;
  protected itemScreeningTest!: ScreeningTestInstance;
  private pageTitle = "Scheda candidato | ATS";
  protected testScreening: { isNew: boolean, isEmpty: boolean } = { isNew: true, isEmpty: true };
  protected isLoadingTest = false;
  @ViewChild("testFile") testFile!: ElementRef;



  override async ngOnInit() {
    this.breadCrumb = (this.route.snapshot.queryParamMap.get("from") != undefined) ?
      this.route.snapshot.queryParamMap.get("from")! : "home";

    const JOB_APPLICATION_ID = this.route.snapshot.paramMap.get("jobApplicationId");
    if (!JOB_APPLICATION_ID) {
      return;
    }


    this.isLoadingData = true;

    /** Recupera l'attuale Job Application */
    await this.candidateSheetService.loadDataUser(JOB_APPLICATION_ID);

    /** Recupera tutte le Job Application relative ad un particolare Job Position, che serviranno per poter navigare tra i vari candidati e salvalo nel service */
    const jobPositionId = this.candidateSheetService.jobApplication?.jobPositionId;
    if (jobPositionId && (this.userService.isEBRM || this.userService.isHRBP)) {
      if (this.route.snapshot.queryParamMap.get("from") != undefined) {
        const [appliedJobApplicationFilterPromise, appliedApplicationOrderPromise] = await Promise.all([
          this.backendService.jobPositionFiltersGet<JobApplicationsByPositionFilters>(UserFiltersTypeEnumTypes.SEARCHPAGE_JOB_APPLICATIONS_SEARCH),
          this.backendService.jobPositionFiltersGet<JobApplicationsByPositionFilters>(UserFiltersTypeEnumTypes.SEARCHPAGE_JOB_APPLICATIONS_SORT)
        ]);

        let obj = Object.assign(appliedJobApplicationFilterPromise, appliedApplicationOrderPromise);
        if (sessionStorage.getItem("searchedText") != null) {
          obj = Object.assign({ searchedText: sessionStorage.getItem("searchedText")! }, obj);
        }
        this.candidateSheetService.jobAplicationIds = await this.backendService.jobApplicationSearchIds(obj as { searchedText: string, sortingField?: string, sortingDesc?: boolean, jobApplicationStatus?: string[], keyword?: string, reassigned?: boolean, referral?: boolean, talent?: boolean, newItalian?: boolean, legallyProtected?: boolean, feedbackMailSent?: boolean, ageRange?: string[], gender?: string, fromRecord?: number, numRecord?: number });
      } else {
        this.candidateSheetService.jobAplicationIds = await this.backendService.jobApplicationListIdsByPosition(
          jobPositionId, this.candidateSheetService.getFilterListByPosition()
        );
      }
      this.candidateSheetService.checkNextJobApplicationIdExist(JOB_APPLICATION_ID);
      this.candidateSheetService.checkPreviousJobApplicationIdExist(JOB_APPLICATION_ID);
    }

    this.isLoadingData = false;

    if (!this.candidateSheetService.jobApplication) {
      return;
    }

    if (this.candidateSheetService.jobApplication.coverletterText) {
      this.candidateSheetService.activateCoverLetter();
    }

    this.candidateSheetService.switchTalentIcon();
  }



  /**
   * Naviga alla pagina di dettaglio della posizione associata alla candidatura.
   */
  protected async onNavigationToPositionDetail() {
    await this.router.navigate([UrlCollection.positionDetail, this.candidateSheetService.jobApplication?.jobPositionId]);
  }

  /**
   * Naviga alla pagina principale.
   */
  protected async onNavigationToHome() {
    await this.router.navigate([UrlCollection.generalHomepage]);
  }

  /**
   * Naviga alla pagina di ricerca.
   */
  protected async redirectToSearch() {
    await this.router.navigate([UrlCollection.search]);
  }

  protected async onNavigationOtherApplication(jobAplicationId: string, jobPositionId: string) {
    if (this.usrService.isUnprofiled) {
      return;
    }

    /** Recupera tutte le Job Application relative ad un particolare Job Position, che serviranno per poter navigare tra i vari candidati e salvalo nel service */
    this.candidateSheetService.jobAplicationIds = await this.backendService.jobApplicationListIdsByPosition(jobPositionId);

    await this.router.navigate([UrlCollection.candidateSheet, jobAplicationId]);
    this.isLoadingData = true;
    await this.candidateSheetService.loadDataUser(jobAplicationId);
    this.isLoadingData = false;

    this.onCloseDrawers();
  }

  /**
   * @param stepSelected
   * @param redirect nome della pagina alla quale si viene reinderizzati
   *
   * In base allo step selezionato viene eseguito il redirect al tab richiesto
   */
  async onNavigateToDesktopMode(redirect?: string) {
    await this.router.navigate([redirect], { relativeTo: this.route, queryParamsHandling: "preserve" });
  }

  /**
   * @param stepSelected
   * @param redirect nome della pagina alla quale si viene reinderizzati
   *
   * In base allo step selezionato viene eseguito il redirect al tab richiesto
   */
  protected async onNavigateToMobileMode(redirect: GeneraliStatuTab.TabStruct) {
    await this.router.navigate([redirect.redirect], { relativeTo: this.route, queryParamsHandling: "preserve" });
  }

  /**
   * Gestisce il cambio di stato di una candidatura.
   *
   * @param newStatus - Tipo di badge corrispondente al nuovo stato della candidatura.
   */
  protected async onChangeStatus(newStatus?: JobApplicationStatusEnum, alreadyConfirmedByModal?: boolean) {
    const jobApplicayionId = this.route.snapshot.paramMap.get("jobApplicationId");

    if (this.candidateSheetService.jobApplication?.currentStatus === newStatus) {
      return;
    }

    if (!jobApplicayionId || !newStatus || this.candidateSheetService.drawer.showInterruptApplication) {
      return;
    }

    /**
     * Se lo stato selezionato è ACCETTA, controllo se le candidature già accettate più quelle da accettare coprono il numero di posizioni aperte.
     * In questo caso, la posizione verrà chiusa (filled) quindi devo mostrare la modale di conferma.
     */
    if (newStatus === JobApplicationStatusEnumTypes.ACCEPT && !alreadyConfirmedByModal) {

      const shouldShowConfirmAcceptModal = await this.shouldShowConfirmAcceptModal(jobApplicayionId);

      if ( shouldShowConfirmAcceptModal ) {
        this.statusToChange = newStatus;
        this.modalService.open("confirm-accept-application");
        return;
      }

    } else {
      this.modalService.close("confirm-accept-application");
    }


    this.analyticsService.sendVirtualEvent(EventNameEnum.changeStatusJobApplication, this.router.url, this.pageTitle, { stato: this.getBadgeLabel(newStatus)?.label });

    switch (newStatus) {
      case JobApplicationStatusEnumTypes.STOP:
        await this.candidateSheetService.handleInterruptApplication(jobApplicayionId);

        break;

      default:
        await this.candidateSheetService.changeJobApplicationStatus(jobApplicayionId, newStatus);
        break;
    }
  }

  /**
   * Gestisce l'azione selezionata dall'utente.
   * In base all'azione selezionata, esegue l'azione corrispondente.
   *
   * @param action L'azione selezionata.
   */
  protected async onSelectedAction(action: string) {
    const JOB_APPLICATION_ID = this.route.snapshot.paramMap.get("jobApplicationId");
    if (!JOB_APPLICATION_ID) {
      return;
    }
    const JOB_POSITION_ID = this.candidateSheetService?.jobApplication?.jobPositionId;

    switch (action) {
      case ActionSelect.TALENT:
        await this.switchTalentAction(JOB_APPLICATION_ID);
        break;

      case ActionSelect.ASSESSMENT:
        this.analyticsService.sendVirtualEvent(EventNameEnum.requestAssessment, this.router.url, this.pageTitle);
        await this.handleAskForAssesment();
        this.candidateSheetService.screeningTestCard = (await this.backendService.jobApplicationListByPosition(JOB_POSITION_ID!, this.candidateSheetService.getFilterListByPosition()))
          .find((el: JobApplication) => el.userId == this.candidateSheetService.jobApplication?.userId)?.screeningTestInstances ?? [];
        break;

      case ActionSelect.TIMELINE:
        await this.handleTimelineAction(JOB_APPLICATION_ID);
        break;

      case ActionSelect.OTHER:
        await this.handleOtherAction(JOB_APPLICATION_ID);
        break;

      default:
        break;
    }
  }

  /**
   * Gestisce l'azione di cambio dello stato talent per una candidatura.
   * Se l'utente è attualmente segnato come talent, lo imposta come non talent e viceversa.
   *
   * Aggiorna l'icona del talento e aggiorna i dati della candidatura.
   *
   * @param jobApplicationId L'ID della candidatura.
   */
  private async switchTalentAction(jobApplicationId: string) {
    const isTalent = this.candidateSheetService.jobApplication?.isTalent ?? false;

    isTalent ? this.analyticsService.sendVirtualEvent(EventNameEnum.removeTalent, this.router.url, this.pageTitle) : this.analyticsService.sendVirtualEvent(EventNameEnum.addTalent, this.router.url, this.pageTitle);

    if (this.candidateSheetService.jobApplication?.userId) {
      await this.backendService.jobApplicationSetUserTalent(this.candidateSheetService.jobApplication?.userId, !isTalent);
    }

    this.isLoadingData = true;
    await this.candidateSheetService.loadDataUser(jobApplicationId);
    this.isLoadingData = false;
  }

  /** Gestisce l'azione ASSESSMENT */
  private async handleAskForAssesment() {
    if (!this.candidateSheetService.jobApplication?.jobApplicationId) {
      return;
    }

    await this.backendService.jobApplicationAskForAssessment(this.candidateSheetService.jobApplication?.jobApplicationId);
    this.candidateSheetService.setAssessment();
  }

  /** Gestisce l'azione TIMELINE */
  private async handleTimelineAction(jobApplicationId: string) {
    this.candidateSheetService.drawer.showTimeline = true;
    this.candidateSheetService.listAllStatus = await this.backendService.jobApplicationListAllStatuses(jobApplicationId);
  }

  /** Gestisce l'azione OTHER */
  private async handleOtherAction(jobApplicationId: string) {
    this.candidateSheetService.drawer.showMoveToOtherSession = true;
    this.candidateSheetService.fetchData.reassignableJobPositionList = await this.backendService.joApplicationSearchReassignableJobPositions(jobApplicationId);
  }

  /**
   * Gestisce la conferma dell'interruzione della Job Application
   * Effettua il cambio di stato dell'applicazione e invia eventuali comunicazioni.
   */
  protected async onConfirmInterruption() {
    this.onCloseDrawers();
    const jobApplicationId = this.route.snapshot.paramMap.get("jobApplicationId");

    if (!jobApplicationId) {
      return;
    }

    const newStatus = JobApplicationStatusEnumTypes.STOP;
    const sendCommunication = this.candidateSheetService.valueApplicationSwitch ?? false;
    const communicationText = this.appService.stringify(this.interruptionDefaultEmail.value);
    const communicationTextEn = this.appService.stringify(this.interruptionDefaultEmailEn.value);
    const comment = this.interruptionComment?.value as string;
    /** Esegui il cambio di stato */

    await this.backendService.jobApplicationChangeStatus({
      jobApplicationId,
      newStatus,
      sendCommunication,
      communicationText,
      communicationTextEn,
      comment
    });

    this.interruptionComment?.reset();
    this.interruptionDefaultEmail.reset();
    this.interruptionDefaultEmailEn.reset();

    this.isLoadingData = true;
    await this.candidateSheetService.loadDataUser(jobApplicationId);
    this.isLoadingData = false;
  }

  /**
   * Recupera il precedente candidato nella lista degli applicanti.
   * Se non ci sono più candidati disponibili, la funzione termina.
   */
  protected async getNextApplicant() {
    const actualJobApplicationId = this.route.snapshot.paramMap.get("jobApplicationId");
    // const { originUserApplicationId, ...qParams } = this.route.snapshot.queryParams;

    if (this.candidateSheetService.noMoreApplicants || !actualJobApplicationId) {
      return;
    }

    this.analyticsService.sendVirtualEvent(EventNameEnum.candidateFollowing, this.router.url, this.pageTitle);
    const nextJobApplicationId = this.candidateSheetService.getNextJobApplicationId(actualJobApplicationId);
    if (nextJobApplicationId) {
      this.candidateSheetService.clearForm();
      await this.router.navigate(["../", nextJobApplicationId], { queryParamsHandling: "preserve", relativeTo: this.route });
      this.isLoadingData = true;
      await this.candidateSheetService.loadDataUser(nextJobApplicationId);
      this.isLoadingData = false;
    }
  }

  /**
  * Recupera il precedente candidato nella lista degli applicanti.
  * Se non ci sono più candidati disponibili, la funzione termina.
  */
  protected async getPreviousApplicant() {
    const actualJobApplicationId = this.route.snapshot.paramMap.get("jobApplicationId");
    // const { originUserApplicationId, ...qParams } = this.route.snapshot.queryParams;

    if (this.candidateSheetService.noPreviousApplicants || !actualJobApplicationId) {
      return;
    }

    this.analyticsService.sendVirtualEvent(EventNameEnum.candidatePrevious, this.router.url, this.pageTitle);
    const previousJobApplicationId = this.candidateSheetService.getPreviousJobApplicationId(actualJobApplicationId);
    if (previousJobApplicationId) {
      this.candidateSheetService.clearForm();
      await this.router.navigate(["../", previousJobApplicationId], {
        queryParamsHandling: "preserve", relativeTo: this.route
      });
      this.isLoadingData = true;
      await this.candidateSheetService.loadDataUser(previousJobApplicationId);
      this.isLoadingData = false;
    }
  }


  /**
   * Azionato dalla ricerca per la riassegnazione delle Job Position.
   * Recupera la lista delle Job Position riassegnabili in base al testo di ricerca fornito e aggiorna i dati nel servizio.
   * @param searchTxt Il testo di ricerca per le Job Position.
   */
  protected async onSearchChangeAssignment(searchTxt: string) {
    const JOB_APPLICATION_ID = this.route.snapshot.paramMap.get("jobApplicationId");

    if (!JOB_APPLICATION_ID) {
      return;
    }

    const jobStatusList = await this.backendService.joApplicationSearchReassignableJobPositions(JOB_APPLICATION_ID, searchTxt);
    this.candidateSheetService.fetchData.reassignableJobPositionList = jobStatusList;
  }

  /**
   * Imposta lo step attivo nella selezione di un elemento all'interno di un simple-select.
   * Restituisce l'indice dello step basato sull'URL corrente della pagina.
   *
   * @returns L'indice dello step attivo.
   */
  protected setStepSelected() {
    return this.candidateSheetService.getTabstructIdxBasedOnUrl(this.router.url);
  }

  /**
   * Apre la modale per visualizzare il CV in modalità full-screen.
   */
  protected goFullScreen() {
    this.modalService.open("full-screen-cv");
  }

  protected onCvDownload() {
    window.open(this.candidateSheetService.jobApplication?.cvUpload?.url);
  }

  protected onPdfError() {
    this.hasErrorPDF = true;
    this.modalService.close("full-screen-cv");
  }

  protected onCloseModal(modalId: string) {
    this.modalService.close(modalId);
  }



  /**
   * Prepara il commento per la modalità di modifica.
   * Imposta le proprietà del commento nel service.
   *
   * @param comment Commento da preparare per la modifica.
   */
  protected prepareCommentForEditing(comment: JobApplicationComment) {
    this.candidateSheetService.editComment.editMode = true;
    this.candidateSheetService.editComment.control.setValue(comment.commentText);
    this.candidateSheetService.editComment.commentToUpdateId = comment.commentId;
  }

  /**
   * Elimina il commento selezionato e aggiorna la lista e il conteggio dei commenti.
   *
   * @param commentId L'ID del commento da eliminare.
   */
  protected async onDeleteComment(commentId: string) {
    const isDeleted = await this.backendService.jobApplicationDeleteComment(commentId);
    if (!isDeleted) {
      return;
    }

    await this.candidateSheetService.updateCommentListAndCount();
  }

  /**
   * Posta il commento, aggiorna lista e il conteggio dei commenti
   */
  protected async onPostComment() {
    if (!this.candidateSheetService.jobApplication?.jobPositionId || !this.candidateSheetService.commentField) {
      return;
    }
    this.analyticsService.sendVirtualEvent(EventNameEnum.addComment, this.router.url, this.pageTitle);
    const commentIsPosted = await this.backendService.jobApplicationPostComment({
      jobApplicationId: this.candidateSheetService.jobApplication?.jobApplicationId,
      commentText: this.candidateSheetService.commentField
    });

    if (!commentIsPosted) {
      return;
    }

    this.candidateSheetService._commentField.reset();

    await this.candidateSheetService.updateCommentListAndCount();
  }

  /**
   * Aggiorna il commento selezionato, aggiorna lista e il conteggio dei commenti.
   */
  protected async onUpdateComment() {
    const { editComment, jobApplication } = this.candidateSheetService;

    if (!editComment.commentToUpdateId || !editComment.control.value || !jobApplication?.jobPositionId) {
      return;
    }

    const { commentToUpdateId, control } = editComment;
    const updatedCommentText = control.value as string;

    await this.backendService.jobApplicationUpdateComment(commentToUpdateId, { commentText: updatedCommentText });

    editComment.editMode = false;
    editComment.commentToUpdateId = undefined;
    control.reset();

    await this.candidateSheetService.updateCommentListAndCount();
  }

  /**
   * Effettua la riassegnazione di una Job Position.
   *
   * Verifica la disponibilità di riassegnazione per la Job Application corrente e le Job Position selezionate, e avvia il processo di riassegnazione.
   *
   * Requisiti:
   * - Job Application.
   * - Devono essere specificate almeno una o più Job Position da riassegnare.
   */
  protected async onReassigneToJobPositions() {
    this.onCloseDrawers();
    const jobApplicationId = this.candidateSheetService.jobApplication?.jobApplicationId;
    const jobPositionId = this.candidateSheetService.jobApplication?.jobPositionId;
    this.analyticsService.sendVirtualEvent(EventNameEnum.assignOtherSelection, this.router.url, this.pageTitle);

    if (!jobApplicationId || !jobPositionId) {
      return;
    }

    const jobPositionIds = this.candidateSheetService.selectedJobPositionToReasign.map(job => job.jobPositionId!).filter(Boolean);
    const isReasign = await this.backendService.joApplicationReassignableToJobPositions({ jobApplicationId, jobPositionId: jobPositionIds });

    if (isReasign) {
      this.toastService.success(this.translateService.instant<string>("candidate.REASSIGN_SUCCESS"));
    }

    this.isLoadingData = true;
    await this.candidateSheetService.loadDataUser(jobApplicationId);
    this.isLoadingData = false;
    this.candidateSheetService.selectedJobPositionToReasign = [];
  }

  /**
   * Seleziona una Job Position per la riassegnazione di una Job Application nel Candidate Sheet.
   * Aggiunge la Job Position selezionata all'elenco delle posizioni di lavoro da riassegnare.
   *
   * @param jobPosition La Job Position selezionata per la riassegnazione.
   */
  protected onSelectJobPosition(jobPosition: SearchReassignableJobPositionsResponse) {
    const isDuplicate = this.candidateSheetService.selectedJobPositionToReasign.some(id => id.jobPositionId === jobPosition.jobPositionId);
    if (!isDuplicate) {
      this.candidateSheetService.selectedJobPositionToReasign.push(jobPosition);
      return;
    }

    const findDupliucateIdx = this.candidateSheetService.selectedJobPositionToReasign.findIndex(id => id.jobPositionId === jobPosition.jobPositionId);
    this.candidateSheetService.selectedJobPositionToReasign.splice(findDupliucateIdx, 1);
  }

  /** Chiude i drawer aperti */
  protected onCloseDrawers() {
    this.candidateSheetService.drawer = {};
  }

  protected async onCloseTest() {
    const screeningTestId = this.fs.getSingleValueOutOfArray<string>(this.testDetailName, "screeningTestId");
    const jobApplicationId = this.route.snapshot.paramMap.get("jobApplicationId");
    if (screeningTestId == undefined || jobApplicationId == undefined)
      return;

    if (this.isLoadingTest)
      return;

    this.isLoadingTest = true;
    this.candidateSheetService.testDetail.disable();
    const formData = new FormData();
    formData.append("screeningTestId", screeningTestId);
    formData.append("jobApplicationId", jobApplicationId);
    if (this.testDetailDate.value != undefined)
      formData.append("takenAt", this.isIsoDate(this.testDetailDate.value as string) ? this.testDetailDate.value as string : (this.testDetailDate.value as Date).toISOString());

    if (this.fileToUpload != undefined)
      formData.append("testResultFile", this.fileToUpload);


    if (this.testScreeningUpdate) {
      formData.append("startRemoteTest", new Date().toISOString());
      formData.append("screeningTestInstanceId", this.itemScreeningTest.screeningTestInstanceId);
      await this.backendService.screeningTestUpdate(formData);
    } else {
      formData.append("startRemoteTest", new Date().toISOString());
      await this.backendService.screeningTestCreate(formData);
    }

    this.candidateSheetService.jobApplication = await this.backendService.jobApplicationGetById(jobApplicationId);
    this.candidateSheetService.screeningTestCard = (await this.backendService.jobApplicationListByPosition(this.candidateSheetService?.jobApplication?.jobPositionId, this.candidateSheetService.getFilterListByPosition())).find((el: JobApplication) => el.userId == this.candidateSheetService.jobApplication?.userId)?.screeningTestInstances ?? [];
    this.fileToUpload = undefined;
    this.fileUploaded = undefined;
    this.candidateSheetService.testDetail.enable();
    this.isLoadingTest = false;
    this.onCloseDrawers();
  }

  /**
   * Apre/chiude la sezione commenti
   */
  protected toggleComments() {
    this.candidateSheetService.accordion.showComments = !this.candidateSheetService.accordion.showComments;
  }

  /**
   * Apre/chiude la sezione colloqui
   */
  protected toggleInterviews() {
    this.candidateSheetService.accordion.showInterviews = !this.candidateSheetService.accordion.showInterviews;
  }

  /**
   * Apre/chiude la sezione test
   */
  protected toggleTest() {
    this.candidateSheetService.accordion.showTests = !this.candidateSheetService.accordion.showTests;
  }

  /**
   * Apre o chiude il drawer dei dettagli dell'intervista e gestisce il caricamento dei dati necessari.
   *
   * @param interview L'istanza dell'intervista da visualizzare, se presente.
   */
  protected async onToggleInterviewDetail(interview?: InterviewInstance) {
    if (this.usrService.isUnprofiled) {
      return;
    }
    const { drawer } = this.candidateSheetService;
    drawer.showInterviewDetail = !drawer.showInterviewDetail;

    if (!drawer.showInterviewDetail) {
      return;
    }

    await this.setInterviewDetailDrawer(interview);
  }

  private async setInterviewDetailDrawer(interview?: InterviewInstance) {
    this.interviewer.reset();
    this.evaluationFormType.reset();

    this.candidateSheetService.interviewInstance = interview;

    const interviewersCount = await this.availableInterviewersCount();

    if (this.isInsufficientInterviewers(interviewersCount)) {
      return;
    }

    await this.loadInterviewDropdowns();

    if (!interview) {
      this.setDefaultInterviewType();
    } else {
      this.setExistingInterviewDetails(interview);
    }
  }

  /**
   * Verifica se il numero di intervistatori disponibili è insufficiente rispetto al numero di record visualizzabili.
   *
   * @param interviewersCount Il numero di intervistatori disponibili.
   * @returns True se il numero di intervistatori è insufficiente, altrimenti False.
   */
  private isInsufficientInterviewers(interviewersCount: number) {
    const { visibleRecordCount } = this.candidateSheetService;
    return visibleRecordCount > interviewersCount;
  }

  private async availableInterviewersCount() {
    const { visibleRecordCount } = this.candidateSheetService;
    const interviewersCount = await this.backendService.interviewersCount({ fromRecord: 0, numRecords: visibleRecordCount });
    return interviewersCount;
  }

  /**
   * Carica i dropdown per i tipi di intervista e gli intervistatori.
   * I risultati vengono salvati nel servizio dei dati del candidato.
   */
  private async loadInterviewDropdowns() {
    const { jobApplication, visibleRecordCount } = this.candidateSheetService;
    const [interviewTypeDropdown, interviewerDropdown] = await Promise.all([
      this.backendService.interviewTypeList({ userId: jobApplication?.jobApplicationId }),
      this.backendService.interviewersList({ fromRecord: 0, numRecords: visibleRecordCount })
    ]);

    this.candidateSheetService.interviewTypeDropdown = interviewTypeDropdown ?? [];
    if (interviewTypeDropdown?.length) {
      this.candidateSheetService.interviewerDropdown = this.appService.addFullNameToUsers(interviewerDropdown);
    }
  }

  /**
   * Imposta il tipo di intervista predefinito selezionando il primo tipo di intervista
   * con il flag "default" impostato su true.
   */
  private setDefaultInterviewType() {
    const { interviewTypeDropdown } = this.candidateSheetService;

    for (const interviewType of interviewTypeDropdown) {
      if (interviewType?.default) {
        this.fs.setValueOnArray(this.evaluationFormType, interviewType, true);
      }
    }
  }

  /**
   * Imposta i dettagli dell'intervista esistente nei campi del form.
   *
   * @param interview L'istanza dell'intervista da visualizzare.
   */
  private setExistingInterviewDetails(interview: InterviewInstance) {
    if (interview.interviewUser) {
      const mappedInterviewUser = this.appService.addFullNameToUsers([interview.interviewUser ?? {} as SimpleUser])[0];
      this.fs.setValueOnArray(this.interviewer, mappedInterviewUser, true);
    }
    this.fs.setValueOnArray(this.evaluationFormType, interview.interviewType, true);
  }

  /**
   * Carica gli intervistatori aggiuntivi in base allo scrollPercentage.
   * Se lo scrollPercentage raggiunge o supera il valore di 99.995, e se ci sono ancora intervistatori,
   * carica ulteriori intervistatori.
   *
   * @param scrollPercentage Percentuale di scorrimento raggiunta durante lo scroll.
   */
  protected async loadAdditionalInterviewers(scrollPercentage: number) {
    const additionalRecords = 100;

    if (scrollPercentage <= 99.995) {
      return;
    }

    if (this.isInsufficientInterviewers(await this.availableInterviewersCount())) {
      return;
    }

    const fromRecord = 0;
    const numRecords = this.candidateSheetService.visibleRecordCount + additionalRecords;
    const interviewerDropdown = await this.backendService.interviewersList({ fromRecord, numRecords });
    this.candidateSheetService.interviewerDropdown = this.appService.addFullNameToUsers(interviewerDropdown);
    this.candidateSheetService.visibleRecordCount = numRecords;
  }

  /**
   * Esegue la ricerca degli intervistatori in base al testo cercato e aggiorna la lista degli intervistatori.
   *
   * @param searchedTxt Il testo da cercare negli intervistatori.
   */
  protected async onInterviewerSearch(searchedTxt: string) {
    const visibleRecordCount = 100;
    const interviewerDropdown = await this.backendService.interviewersList({
      fromRecord: 0,
      name: searchedTxt,
      numRecords: visibleRecordCount
    });
    this.candidateSheetService.visibleRecordCount = visibleRecordCount;
    this.candidateSheetService.interviewerDropdown = this.appService.addFullNameToUsers(interviewerDropdown);
  }

  /**
   * Organizza un colloquio per l'applicazione di lavoro corrente.
   */
  protected async onOrganizeInterview() {
    this.onCloseDrawers();
    const interviewTypeId = this.fs.getSingleValueOutOfArray<string>(this.evaluationFormType, "interviewTypeId");
    const interviewUserId = this.fs.getSingleValueOutOfArray<string>(this.interviewer, FormCommonClasses.USER_ID);
    const jobApplicationId = this.route.snapshot.paramMap.get("jobApplicationId");

    if (!this.candidateSheetService.jobApplication?.jobApplicationId || !interviewTypeId || !interviewUserId || !jobApplicationId) {
      return;
    }

    if (!this.candidateSheetService.interviewInstance) {
      await this.createJobApplicationInterview(interviewTypeId, interviewUserId);
    } else {
      await this.updateJobApplicationInterview(interviewTypeId, interviewUserId);
    }

    /** Resetta i form */
    this.evaluationFormType.clear();
    this.interviewer.reset();

    this.candidateSheetService.jobApplication = await this.backendService.jobApplicationGetById(jobApplicationId);
    this.candidateSheetService.interviewInstance = undefined;
  }

  /**
   * Crea un nuovo colloquio di lavoro.
   *
   * @param interviewTypeId ID del tipo di colloquio
   * @param interviewUserId ID dell'intervistatore
   */
  private async createJobApplicationInterview(interviewTypeId: string, interviewUserId: string) {
    await this.backendService.jobApplicationAskForInterview({
      jobApplicationId: this.candidateSheetService.jobApplication!.jobApplicationId,
      interviewTypeId: interviewTypeId,
      interviewUserId: interviewUserId
    });
  }
  /**
   * Aggiorna un colloquio di lavoro esistente.
   *
   * @param interviewTypeId ID del tipo di colloquio
   * @param interviewUserId ID dell'intervistatore
   */
  private async updateJobApplicationInterview(interviewTypeId: string, interviewUserId: string) {
    const params = {
      interviewUserId,
      interviewTypeId
    };

    // Se lo stato corrente del colloquio è "TO_BE_PLANNED", aggiungi il parametro interviewStatus con il valore PLANNED
    if (this.candidateSheetService.interviewInstance?.currentStatus === "TO_BE_PLANNED") {
      Object.assign(params, { interviewStatus: InterviewStatusEnumTypes.PLANNED });
    }

    await this.backendService.jobApplicationUpdateInterview(this.candidateSheetService.interviewInstance!.interviewInstanceId, params);
  }

  /**
   * Apre/chiude il drawer test
   */
  protected async toggleTestsDetail(update: boolean, item?: ScreeningTestInstance) {
    this.testDetailDate.setValue(undefined);
    this.fs.setValueOnArray(this.testDetailName, undefined, true);
    this.resetPropertiesFile();
    if (this.usrService.isUnprofiled) {
      return;
    }

    this.testScreeningUpdate = update;
    if (this.testScreeningUpdate) {
      this.testDetailDate.setValue(item?.takenAt);
    }
    this.candidateSheetService.drawer.showTestsDetail = !this.candidateSheetService.drawer.showTestsDetail;

    if (this.candidateSheetService.drawer.showTestsDetail) {
      let data: { shlOnly?: boolean, nonShlOnly?: boolean, name?: string, fromRecord?: string, numRecord?: string } = {};
      if (item) {
        item.screeningTestType == "TEST_SHL" ? data = { shlOnly: true } : data = { nonShlOnly: true };
      }

      this.candidateSheetService.fetchData.testList = await this.backendService.screeningTestList(data);
    }

    if (this.testScreeningUpdate) {
      this.itemScreeningTest = item!;
      this.fs.setValueOnArray(this.testDetailName, this.candidateSheetService.fetchData.testList?.find((el: any) => el?.screeningTestId == item?.screeningTestId), true);
      this.fileUploaded = item?.testResultUpload;
      this.testDetailDate.setValue(item?.takenAt);
      this.testScreening.isNew = false;
      this.testScreening.isEmpty = false;
    } else {
      this.testScreening.isNew = true;
      this.testScreening.isEmpty = true;
    }

    if (item?.currentStatus == ScreeningTestStatusEnumTypes.STARTED) {
      this.candidateSheetService.testDetail.disable();
    } else {
      this.candidateSheetService.testDetail.enable();
    }

  }



  /**
   * Apre/chiude il drawer candidature realizzate
   */
  protected async toggleRealizedApplicants(b: boolean) {
    this.candidateSheetService.drawer.showRealizedApplicants = b;

    if (this.candidateSheetService.drawer.showRealizedApplicants && this.candidateSheetService.jobApplication?.jobApplicationId) {
      this.candidateSheetService.jobApplicationHistoryOwner = `${this.candidateSheetService.jobApplication?.forename} ${this.candidateSheetService.jobApplication?.surname}`;
      this.candidateSheetService.fetchData.jobApplicationHistory = await this.backendService.jobApplicationListByUser(this.candidateSheetService.jobApplication?.userId);
    }
  }

  /**
   * @param rawFile
   *
   * Permette di eseguire l'upload dei file
   */
  protected upload(rawFile: Event) {
    const file = (rawFile?.target as HTMLInputElement).files?.item(0);
    if (file) {
      this.fileToUpload = file;
    }
  }


  /**
   * Permette di eseguire il download dei file
   */
  protected downloadFile() {
    window.open(this.fileUploaded?.url);
  }

  /**
   * Permette di eseguire il download dei test
   */
  protected downloadTest(test: ScreeningTestInstance) {
    window.open(test.testResultUpload?.url);
  }

  /**
   * Elimina uno screening test
   */
  protected async deleteScreeningTestResult() {
    await this.backendService.screeningTestDelete(this.itemScreeningTest.screeningTestInstanceId);
    this.resetPropertiesFile();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    this.testFile.nativeElement.value = null;
    this.candidateSheetService.jobApplication = await this.backendService.jobApplicationGetById(this.route.snapshot.paramMap.get("jobApplicationId")!);
  }

  /**
   *
   */
  removeFileToUpload() {
    this.resetPropertiesFile();
  }

  /**
   * Ottiene la label da utilizzare per la CTA del drawer dei colloqui, in base allo stato corrente dell'intervista.
   *
   * @returns {string}
   */
  protected get askForAssesmentLabel(): string {
    if (this.candidateSheetService.interviewInstance?.currentStatus === "DONE") {
      return "generic.UPDATE";
    }
    return "candidate.ORGANIZE";
  }

  public onSwitchSendCommunication(value: boolean): void {
    this.candidateSheetService.updateInterruptApplicationSwitch(value);
  }

  /**
   * Salva i dati inseriti nell'evaluation form
   */
  public async onSumbitEvalutionForm(submit: boolean): Promise<void> {

    if (submit && this.efForm.invalid) {
      for (const field in this.efForm.controls) {
        const control = this.efForm.get(field);

        if (control instanceof FormArray) {
          control.markAsTouched();
        }
        if (control instanceof FormControl) {
          control.markAsTouched();
          control.markAsDirty();
        }
      }
      this.checkStar(this.interviewUserInfo.type!);
      setTimeout(() => {
        document.querySelector(".focus-form")?.scrollIntoView();
      }, 0);
      return;
    }

    const obj: InterviewInstance = {
      interviewInstanceId: this.interviewInstance.interviewInstanceId,
      interviewTypeId: this.interviewInstance.interviewTypeId,
      jobApplicationId: this.route.snapshot.paramMap.get("jobApplicationId")!,
      interviewUserId: this.interviewUserInfo.interviewUserId!,
      interviewUserRole: this.interviewInstance.interviewUserRole,
      isAssegnedToMe: true,
      scheduledAt: this.efForm.get("scheduledAt")?.value as Date,
      currentStatus: submit ? InterviewStatusEnumTypes.DONE : InterviewStatusEnumTypes.PLANNED,
      noConflictOfInterest: this.efForm.get("noConflictOfInterest")?.value as boolean,
      finalCommentResult: this.efForm?.get("finalCommentResult")?.value as string
    };

    if (submit) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
      obj.finalValuationResult = this.efForm?.get("finalValuationResult")?.value.at(0).value;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
      if (this.efForm?.get("finalValuationResult")?.value.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        obj.finalValuationResult = this.efForm?.get("finalValuationResult")?.value.at(0).value;
      }
    }

    let formValid = true;
    switch (this.interviewUserInfo.type) {
      case InterviewLogicTypeEnumTypes.EBRM:
        obj.interviewFormEBRM = this.getObjectForm(this.interviewUserInfo.type) as InterviewFormEBRM;
        for (const [key, value] of Object.entries(obj.interviewFormEBRM)) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          if (isNaN(value))
            formValid = false;
        }
        break;
      case InterviewLogicTypeEnumTypes.EBRM_FI:
        obj.interviewFormEBRMFirst = this.getObjectForm(this.interviewUserInfo.type) as InterviewFormEBRMFirst;
        break;
      case InterviewLogicTypeEnumTypes.HRBP:
        obj.interviewFormHRBP = this.getObjectForm(this.interviewUserInfo.type) as InterviewFormHRBP;
        for (const [key, value] of Object.entries(obj.interviewFormHRBP)) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          if ((key == "motivation" || key == "compatibility" || key == "interests") && isNaN(value))
            formValid = false;
        }
        // In caso il parametro actualRetribution === 0 il form è considerato valido, l`importante è che sia valorizzato e non undefined
        if (this.propertiesForm.actualRetribution == undefined || this.propertiesForm.actualRetribution == null) formValid = false;
        break;
      case InterviewLogicTypeEnumTypes.LINE_MANAGER:
        obj.interviewFormLineManager = this.getObjectForm(this.interviewUserInfo.type) as InterviewFormLineManager;
        for (const [key, value] of Object.entries(obj.interviewFormLineManager as any)) {
          if (key == "comptetences") {
            (value as any).forEach((el: any) => {
              if (el.evaluation == undefined)
                formValid = false;
            });
          }
        }

        break;
      case InterviewLogicTypeEnumTypes.BUSINESS_INTERVIEWER:
        obj.interviewFormBusiness = this.getObjectForm(this.interviewUserInfo.type) as InterviewFormBusiness;
        for (const [key, value] of Object.entries(obj.interviewFormBusiness)) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          if (isNaN(value))
            formValid = false;
        }
        break;
      case InterviewLogicTypeEnumTypes.HRBP_SALE:
        obj.interviewFormHRBPSale = this.getObjectForm(this.interviewUserInfo.type) as InterviewFormHRBPSale;
        for (const [key, value] of Object.entries(obj.interviewFormHRBPSale)) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          if ((key == "communication" || key == "entrepreneurship" || key == "motivation" || key == "customerOrientation" || key == "adaptability") && isNaN(value))
            formValid = false;
        }
        break;
    }

    if (!formValid && submit) {
      this.checkStar(this.interviewUserInfo.type!);
      return;
    }
    submit ? await this.backendService.confirmFormInterview({ "interviewInstance": obj }) : await this.backendService.updateFormInterviewAsDraft({ "interviewInstance": obj });
    if (submit) {
      this.analyticsService.sendVirtualEvent(EventNameEnum.submitEvaluationForm, this.router.url, this.pageTitle);
    }
    this.onCloseDrawers();
    this.onCancelLeavingEvaluationFormSave();
    this.candidateSheetService.jobApplication = await this.backendService.jobApplicationGetById(this.route.snapshot.paramMap.get("jobApplicationId")!);
  }

  getObjectForm(type: string) {
    switch (type) {
      case InterviewLogicTypeEnumTypes.EBRM:
        return {
          interests: Number(this.propertiesForm.ebrm.interests),
          compatibility: Number(this.propertiesForm.ebrm.compatibility),
          motivation: Number(this.propertiesForm.ebrm.motivation),
          trust: Number(this.propertiesForm.ebrm.trust),
          teamwork: Number(this.propertiesForm.ebrm.teamwork),
          innovatitve: Number(this.propertiesForm.ebrm.innovatitve),
          openess: Number(this.propertiesForm.ebrm.openess),
          proactivity: Number(this.propertiesForm.ebrm.proactivity),
          execution: Number(this.propertiesForm.ebrm.execution),
          makingSimple: Number(this.propertiesForm.ebrm.makingSimple),
          adaptability: Number(this.propertiesForm.ebrm.adaptability)
        };
      case InterviewLogicTypeEnumTypes.EBRM_FI:
        return {
          interests: Number(this.propertiesForm.ebrm.interests),
          wantedPosition: this.efForm?.get("wantedPosition")?.value as string,
          actualRetribution: Number(this.propertiesForm.actualRetribution) || 0,
          actualContract: this.efForm?.get("actualContract")?.value as string,
          actualBenefit: this.efForm?.get("actualBenefit")?.value as string,
          wantedRetribution: this.efForm?.get("wantedRetribution")?.value as string,
          wantedContract: this.efForm?.get("wantedContract")?.value as string,
          mobility: this.efForm?.get("mobility")?.value as string,
          strengthPoints: this.getStrengthPoints()
        };
      case InterviewLogicTypeEnumTypes.HRBP:
        return {
          motivation: this.propertiesForm.hrbp.motivation,
          interests: this.propertiesForm.hrbp.interests,
          compatibility: this.propertiesForm.hrbp.compatibility,
          actualRetribution: Number(this.propertiesForm.actualRetribution) ?? undefined,
          otherPosition: this.efForm?.get("otherPosition")?.value as string,
          actualContract: this.efForm?.get("actualContract")?.value as string,
          actualBenefit: this.efForm?.get("actualBenefit")?.value as string,
          wantedRetribution: this.efForm?.get("wantedRetribution")?.value as string,
          wantedContract: this.efForm?.get("wantedContract")?.value as string,
          mobility: this.efForm?.get("mobility")?.value as string,
          dismissalNotice: this.efForm?.get("dismissalNotice")?.value as string,
          strengthPoints: this.getStrengthPoints()
        };
      case InterviewLogicTypeEnumTypes.LINE_MANAGER:
        const tmp: any = [];
        this.competences.forEach((el: any) => {
          tmp.push(
            {
              competenceName: el.property,
              evaluation: el.evaluation,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
              comment: this.efForm.value[el.property] != null ? this.efForm.value[el.property] : ""
            }
          );
        });
        return { competences: tmp, otherPosition: this.efForm?.get("otherPosition")?.value as string };
      case InterviewLogicTypeEnumTypes.BUSINESS_INTERVIEWER:
        return {
          interests: Number(this.propertiesForm.businessInterview.interests),
          motivation: Number(this.propertiesForm.businessInterview.motivation),
          trust: Number(this.propertiesForm.businessInterview.trust),
          teamwork: Number(this.propertiesForm.businessInterview.teamwork),
          innovatitve: Number(this.propertiesForm.businessInterview.innovatitve),
          openess: Number(this.propertiesForm.businessInterview.openess),
          proactivity: Number(this.propertiesForm.businessInterview.proactivity),
          execution: Number(this.propertiesForm.businessInterview.execution),
          makingSimple: Number(this.propertiesForm.businessInterview.makingSimple),
          adaptability: Number(this.propertiesForm.businessInterview.adaptability)
        };
      case InterviewLogicTypeEnumTypes.HRBP_SALE:
        return {
          heading: this.efForm?.get("heading")?.value as string,
          professionalSeniority: this.efForm?.get("professionalSeniority")?.value as string,
          previousWorkExperience: this.efForm?.get("previousWorkExperience")?.value as string,
          actualRetribution: Number(this.propertiesForm.actualRetribution),
          actualContract: this.efForm?.get("actualContract")?.value as string,
          actualBenefit: this.efForm?.get("actualBenefit")?.value as string,
          dismissalNotice: this.efForm?.get("dismissalNotice")?.value as string,
          communication: Number(this.propertiesForm.hrbpSale.communication),
          entrepreneurship: Number(this.propertiesForm.hrbpSale.entrepreneurship),
          entrepreneurshipComment: this.efForm?.get("entrepreneurshipComment")?.value as string,
          motivation: Number(this.propertiesForm.hrbpSale.motivation),
          motivationComment: this.efForm?.get("motivationComment")?.value as string,
          customerOrientation: Number(this.propertiesForm.hrbpSale.customerOrientation),
          adaptability: Number(this.propertiesForm.hrbpSale.adaptability)
        };
      default:
        return;
    }

  }

  getStrengthPoints(): InterviewStrengthPointEnum[] {
    const strengthPoints: InterviewStrengthPointEnum[] = [];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    for (let [key, value] of Object.entries(this.behavior)) {
      if (key == "humanTouch")
        key = "HUMAN_TOUCH";
      if (value) {
        strengthPoints.push(key.toUpperCase() as InterviewStrengthPointEnum);
      }
    }

    return strengthPoints;
  }

  /**
   * Chiude evaluationform
   */
  onCloseEvalutionForm(): void {
    if (this.efForm.dirty || this.efFormDirty)
      this.modalService.open("evaluationForm");

    this.onCloseDrawers();
  }


  public async onConfirmLeavingEvaluationFormWithoutSave() {
    await this.onSumbitEvalutionForm(false);
  }

  async onCancelLeavingEvaluationFormSave() {
    this.candidateSheetService.jobApplication = await this.backendService.jobApplicationGetById(this.route.snapshot.paramMap.get("jobApplicationId")!);
    this.modalService.close("evaluationForm");
  }

  /**
   * Elimina evaluationform
   */
  async onCancelEvalutionForm() {
    await this.backendService.deleteFormInterview(this.interviewInstance.interviewInstanceId);
    window.location.reload();
    this.onCloseDrawers();
  }


  async onClickInterview(interview: InterviewInstance) {
    this.formReadOnly = false;
    this.resetProperties();
    this.interviewInstance = interview;
    this.interviewUserInfo = {
      forename: interview.interviewUser?.forename,
      surname: interview.interviewUser?.surname,
      role: interview.interviewUserRole,
      currentStatus: "",
      formCompiledAt: interview.formCompiledAt,
      interviewUserId: interview.interviewUserId,
      title: "",
      type: interview.interviewType?.interviewLogicTypeEnum
    };

    if (interview.currentStatus == InterviewStatusEnumTypes.DONE) {
      this.formReadOnly = true;
      this.efForm.disable();
    }

    switch (interview.interviewType?.interviewLogicTypeEnum) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      case InterviewLogicTypeEnumTypes.EBRM:
        this.interviewUserInfo.title = InterviewUserRoleEnumTypes.EBRM as string;
        this.efForm = this.fb.group({
          scheduledAt: [null, Validators.required],
          noConflictOfInterest: [false, Validators.requiredTrue],
          motivation: [null],
          interests: [null],
          compatibility: [null],
          trust: [null],
          teamwork: [null],
          innovatitve: [null],
          openess: [null],
          proactivity: [null],
          execution: [null],
          makingSimple: [null],
          adaptability: [null],
          finalCommentResult: [null, Validators.required],
          finalValuationResult: new FormArray([], Validators.required)
        });
        this.propertiesForm.ebrm.motivation = Number(interview.interviewFormEBRM?.motivation);
        this.propertiesForm.ebrm.interests = Number(interview.interviewFormEBRM?.interests);
        this.propertiesForm.ebrm.compatibility = Number(interview.interviewFormEBRM?.compatibility);

        this.propertiesForm.ebrm.trust = Number(interview.interviewFormEBRM?.trust);
        this.propertiesForm.ebrm.teamwork = Number(interview.interviewFormEBRM?.teamwork);

        this.propertiesForm.ebrm.innovatitve = Number(interview.interviewFormEBRM?.innovatitve);
        this.propertiesForm.ebrm.openess = Number(interview.interviewFormEBRM?.openess);

        this.propertiesForm.ebrm.proactivity = Number(interview.interviewFormEBRM?.proactivity);
        this.propertiesForm.ebrm.execution = Number(interview.interviewFormEBRM?.execution);

        this.propertiesForm.ebrm.makingSimple = Number(interview.interviewFormEBRM?.makingSimple);
        this.propertiesForm.ebrm.adaptability = Number(interview.interviewFormEBRM?.adaptability);
        break;
      case InterviewLogicTypeEnumTypes.EBRM_FI:
        this.interviewUserInfo.title = "EBRM - first interview";
        this.efForm = this.fb.group({
          scheduledAt: [null, Validators.required],
          noConflictOfInterest: [false, Validators.requiredTrue],
          interests: [null],
          wantedPosition: [null],
          actualRetributionEBRMFI: [null],
          actualContract: [null],
          actualBenefit: [null],
          wantedRetribution: [null],
          wantedContract: [null],
          mobility: [null],
          dismissalNotice: [null],
          finalCommentResult: [null, Validators.required],
          finalValuationResult: new FormArray([], Validators.required)
        });
        this.propertiesForm.ebrm.interests = Number(interview.interviewFormEBRMFirst?.interests);
        this.efForm.controls?.["interests"].setValue(interview.interviewFormEBRMFirst?.interests);
        this.efForm.controls?.["wantedPosition"].setValue(interview.interviewFormEBRMFirst?.wantedPosition);
        this.efForm.controls?.["actualContract"].setValue(interview.interviewFormEBRMFirst?.actualContract);
        this.efForm.controls?.["wantedRetribution"].setValue(interview.interviewFormEBRMFirst?.wantedRetribution);
        this.efForm.controls?.["wantedContract"].setValue(interview.interviewFormEBRMFirst?.wantedContract);
        this.efForm.controls?.["mobility"].setValue(interview.interviewFormEBRMFirst?.mobility);
        this.efForm.controls?.["dismissalNotice"].setValue(interview.interviewFormEBRMFirst?.dismissalNotice);

        this.efForm.controls?.["actualRetributionEBRMFI"].setValue(String(interview.interviewFormEBRMFirst?.actualRetribution));
        if (interview.interviewFormEBRMFirst?.actualRetribution != undefined && interview.interviewFormEBRMFirst?.actualRetribution >= 0) {
          this.propertiesForm.actualRetribution = interview.interviewFormEBRMFirst?.actualRetribution;
        }

        interview.interviewFormEBRMFirst?.strengthPoints?.forEach((el) => {
          el = (el == "HUMAN_TOUCH") ? "humanTouch" : (el as any).toLowerCase();
          this.behavior[el] = true;
        });
        break;
      case InterviewLogicTypeEnumTypes.HRBP:
        this.interviewUserInfo.title = `- ${InterviewUserRoleEnumTypes.HRBP as string}`;
        this.efForm = this.fb.group({
          scheduledAt: [null, Validators.required],
          noConflictOfInterest: [false, Validators.requiredTrue],
          motivation: [null],
          interests: [null],
          compatibility: [null],
          actualRetributionHBRP: [null, Validators.required],
          otherPosition: [null, Validators.required],
          actualContract: [null, Validators.required],
          actualBenefit: [null, Validators.required],
          wantedRetribution: [null],
          wantedContract: [null, Validators.required],
          mobility: [null, Validators.required],
          dismissalNotice: [null, Validators.required],
          finalCommentResult: [null, Validators.required],
          finalValuationResult: new FormArray([], Validators.required)
        });
        this.propertiesForm.hrbp.motivation = Number(interview.interviewFormHRBP?.motivation);
        this.propertiesForm.hrbp.interests = Number(interview.interviewFormHRBP?.interests);
        this.propertiesForm.hrbp.compatibility = Number(interview.interviewFormHRBP?.compatibility);

        this.efForm.controls?.["motivation"].setValue(interview.interviewFormHRBP?.motivation);
        this.efForm.controls?.["interests"].setValue(interview.interviewFormHRBP?.interests);
        this.efForm.controls?.["compatibility"].setValue(interview.interviewFormHRBP?.compatibility);
        if (interview.interviewFormHRBP?.actualRetribution != undefined)
          this.efForm.controls?.["actualRetributionHBRP"].setValue(String(interview.interviewFormHRBP?.actualRetribution));
        else
          this.efForm.controls?.["actualRetributionHBRP"].setValue(undefined);

        if (interview.interviewFormHRBP?.actualRetribution != undefined && interview.interviewFormHRBP?.actualRetribution >= 0) {
          this.propertiesForm.actualRetribution = interview.interviewFormHRBP?.actualRetribution;
        }
        this.efForm.controls?.["otherPosition"].setValue(interview.interviewFormHRBP?.otherPosition);
        this.efForm.controls?.["actualContract"].setValue(interview.interviewFormHRBP?.actualContract);
        this.efForm.controls?.["actualBenefit"].setValue(interview.interviewFormHRBP?.actualBenefit);
        this.efForm.controls?.["wantedRetribution"].setValue(interview.interviewFormHRBP?.wantedRetribution);
        this.efForm.controls?.["wantedContract"].setValue(interview.interviewFormHRBP?.wantedContract);
        this.efForm.controls?.["mobility"].setValue(interview.interviewFormHRBP?.mobility);
        this.efForm.controls?.["dismissalNotice"].setValue(interview.interviewFormHRBP?.dismissalNotice);

        interview.interviewFormHRBP?.strengthPoints?.forEach((el) => {
          el = (el == "HUMAN_TOUCH") ? "humanTouch" : (el as any).toLowerCase();
          this.behavior[el] = true;
        });
        break;
      case InterviewLogicTypeEnumTypes.LINE_MANAGER:
        this.interviewUserInfo.title = "- Line Manager";
        this.efForm = this.fb.group({
          scheduledAt: [null, Validators.required],
          noConflictOfInterest: [false, Validators.requiredTrue],
          otherPosition: [null],
          finalCommentResult: [null, Validators.required],
          finalValuationResult: new FormArray([], Validators.required)
        });
        const jobPosition = await this.backendService.getJobPositionById(this.candidateSheetService.jobApplication?.jobPositionId!);
        this.competences = [];
        this.errorStar = {};
        if (interview.currentStatus == InterviewStatusEnumTypes.DONE) {
          interview.interviewFormLineManager?.competences?.forEach((el: any, i: number) => {
            this.efForm.addControl(el.competenceName, new FormControl(null));
            this.errorStar[el] = false;
            this.competences.push({ property: el.competenceName, label: i + 1 + "." + el.competenceName + "*", subtitle: i + 1 + this.getChar(i) + ". ", evaluation: 0 });
            this.efForm.controls?.[el.competenceName].setValue(el.comment);
            this.competences[i].evaluation = el.evaluation;

          });
        } else {
          if (jobPosition?.lineManagerCompetences != undefined && jobPosition?.lineManagerCompetences.length > 0) {
            jobPosition?.lineManagerCompetences?.forEach((el, i) => {
              this.competences.push({ property: el, label: i + 1 + "." + el + "*", subtitle: i + 1 + this.getChar(i) + ". ", evaluation: 0 });
              this.errorStar[el] = false;
              this.efForm.addControl(el, new FormControl(null));
              interview.interviewFormLineManager?.competences?.forEach((el1: any) => {
                if (el == el1.competenceName) {
                  this.efForm.controls?.[el1.competenceName].setValue(el1.comment);
                  this.competences[i].evaluation = el1.evaluation;
                }
              });
            });
          }
        }
        this.efForm.controls?.["otherPosition"].setValue(interview.interviewFormLineManager?.otherPosition);
        break;
      case InterviewLogicTypeEnumTypes.BUSINESS_INTERVIEWER:
        this.interviewUserInfo.title;
        this.efForm = this.fb.group({
          scheduledAt: [null, Validators.required],
          noConflictOfInterest: [false, Validators.requiredTrue],
          interests: [null],
          motivation: [null],
          trust: [null],
          teamwork: [null],
          innovatitve: [null],
          openess: [null],
          proactivity: [null],
          execution: [null],
          makingSimple: [null],
          adaptability: [null],
          finalCommentResult: [null, Validators.required],
          finalValuationResult: new FormArray([], Validators.required)
        });
        this.propertiesForm.businessInterview.motivation = Number(interview.interviewFormBusiness?.motivation);
        this.propertiesForm.businessInterview.interests = Number(interview.interviewFormBusiness?.interests);

        this.propertiesForm.businessInterview.trust = Number(interview.interviewFormBusiness?.trust);
        this.propertiesForm.businessInterview.teamwork = Number(interview.interviewFormBusiness?.teamwork);

        this.propertiesForm.businessInterview.innovatitve = Number(interview.interviewFormBusiness?.innovatitve);
        this.propertiesForm.businessInterview.openess = Number(interview.interviewFormBusiness?.openess);

        this.propertiesForm.businessInterview.proactivity = Number(interview.interviewFormBusiness?.proactivity);
        this.propertiesForm.businessInterview.execution = Number(interview.interviewFormBusiness?.execution);

        this.propertiesForm.businessInterview.makingSimple = Number(interview.interviewFormBusiness?.makingSimple);
        this.propertiesForm.businessInterview.adaptability = Number(interview.interviewFormBusiness?.adaptability);
        break;
      case InterviewLogicTypeEnumTypes.HRBP_SALE:
        this.interviewUserInfo.title = " - Sales Force";
        this.efForm = this.fb.group({
          scheduledAt: [null, Validators.required],
          noConflictOfInterest: [false, Validators.requiredTrue],
          heading: [null, Validators.required],
          professionalSeniority: [null, Validators.required],
          previousWorkExperience: [null, Validators.required],
          actualRetributionHRBPSALES: [null],
          actualContract: [null],
          actualBenefit: [null],
          dismissalNotice: [null],
          communication: [null],
          entrepreneurship: [null],
          entrepreneurshipComment: [null],
          motivation: [null],
          motivationComment: [null],
          commentRole: [null],
          customerOrientation: [null],
          adaptability: [null],
          finalCommentResult: [null],
          finalValuationResult: new FormArray([], Validators.required)
        });
        this.efForm.controls?.["heading"].setValue(interview.interviewFormHRBPSale?.heading);
        this.efForm.controls?.["professionalSeniority"].setValue(interview.interviewFormHRBPSale?.professionalSeniority);
        this.efForm.controls?.["previousWorkExperience"].setValue(interview.interviewFormHRBPSale?.previousWorkExperience);
        this.efForm.controls?.["actualContract"].setValue(interview.interviewFormHRBPSale?.actualContract);
        this.efForm.controls?.["actualBenefit"].setValue(interview.interviewFormHRBPSale?.actualBenefit);
        this.efForm.controls?.["dismissalNotice"].setValue(interview.interviewFormHRBPSale?.dismissalNotice);

        this.propertiesForm.hrbpSale.communication = Number(interview.interviewFormHRBPSale?.communication);
        this.propertiesForm.hrbpSale.entrepreneurship = Number(interview.interviewFormHRBPSale?.entrepreneurship);

        this.efForm.controls?.["actualRetributionHRBPSALES"].setValue(String(interview.interviewFormHRBPSale?.actualRetribution));
        this.propertiesForm.actualRetribution = interview.interviewFormHRBPSale?.actualRetribution ?? 0;

        this.efForm.controls?.["entrepreneurshipComment"].setValue(interview.interviewFormHRBPSale?.entrepreneurshipComment);
        this.propertiesForm.hrbpSale.motivation = Number(interview.interviewFormHRBPSale?.motivation); this.efForm.controls?.["entrepreneurshipComment"].setValue(interview.interviewFormHRBPSale?.entrepreneurshipComment);
        this.efForm.controls?.["motivationComment"].setValue(interview.interviewFormHRBPSale?.motivationComment);
        this.propertiesForm.hrbpSale.customerOrientation = Number(interview.interviewFormHRBPSale?.customerOrientation);
        this.propertiesForm.hrbpSale.adaptability = Number(interview.interviewFormHRBPSale?.adaptability);
        break;
      default:
        this.interviewUserInfo.title = undefined;
    }
    if (interview.scheduledAt) {
      this.efForm.controls?.["scheduledAt"].setValue(interview.scheduledAt);
    }
    if (interview.noConflictOfInterest) {
      this.efForm.controls?.["noConflictOfInterest"].setValue(interview.noConflictOfInterest);
    }

    if (interview.finalCommentResult) {
      this.efForm.controls?.["finalCommentResult"].setValue(interview.finalCommentResult);
    }
    if (interview.finalValuationResult != undefined)
      this.fs.setValueOnArray(this.evaluationFormFinalResult, { label: this.finalValuationResultArray.find((el: any) => el.value == interview.finalValuationResult)?.label, value: interview.finalValuationResult }, true);
    if (interview.currentStatus == InterviewStatusEnumTypes.DONE) {
      this.efForm.disable();
    }
    this.setErrorStar(interview.interviewType?.interviewLogicTypeEnum!);
    this.candidateSheetService.drawer.showEvaluationForm = true;
    setTimeout(() => {
      this.setDirtyForm(false);
    }, 250);
  }

  protected onsaveStar(star: { property: string, value: number }) {
    this.setDirtyForm(true);
    switch (this.interviewUserInfo.type) {
      case InterviewLogicTypeEnumTypes.EBRM:
      case InterviewLogicTypeEnumTypes.EBRM_FI:
        this.propertiesForm.ebrm[star.property as keyof InterfaceEBRM] = star.value;
        break;
      case InterviewLogicTypeEnumTypes.HRBP:
        this.propertiesForm.hrbp[star.property as keyof InterfaceHRBP] = star.value;
        break;
      case InterviewLogicTypeEnumTypes.BUSINESS_INTERVIEWER:
        this.propertiesForm.businessInterview[star.property as keyof InterfaceBusinessInterview] = star.value;
        break;
      case InterviewLogicTypeEnumTypes.HRBP_SALE:
        this.propertiesForm.hrbpSale[star.property as keyof InterfaceHRBPSALES] = star.value;
        break;
      default:
      case InterviewLogicTypeEnumTypes.LINE_MANAGER:
        this.competences.forEach((el: any, i: number) => {
          if (star.property == el.property)
            this.competences[i].evaluation = star.value;
        });
        break;
    }

    if (this.interviewInstance.finalValuationResult == undefined && !this.formReadOnly)
      this.setScore(this.getScoreFinalHiringReccomandation(this.interviewUserInfo.type!));
  }

  onSaveDate(date: NgbDateStruct) {
    this.setDirtyForm(true);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call
    setTimeout(() => {
      this.efForm.get("scheduledAt")?.setValue(new Date(date.year, date.month - 1, date.day));
    }, 0);
    //
  }


  onSaveConflict(conflicts: boolean) {
    this.setDirtyForm(true);
    this.efForm.get("noConflictOfInterest")?.setValue(conflicts);
  }

  onActualRetributionChangeEvFormEbrmFi(value: number) {
    this.setDirtyForm(true);
    this.propertiesForm.actualRetribution = value;
  }

  onActualRetributionChangeEvFormHBRP(value: number) {
    this.setDirtyForm(true);
    this.propertiesForm.actualRetribution = value;
  }

  onActualRetributionChangeEvFormHBRPSales(value: number) {
    this.setDirtyForm(true);
    this.propertiesForm.actualRetribution = value;
  }


  onChangeDateTest(date: NgbDateStruct) {
    setTimeout(() => {
      this.testDetailDate.setValue(new Date(date.year, date.month - 1, date.day));
    }, 0);
  }

  protected async onSollecitInterview() {
    if (!this.candidateSheetService.interviewInstance?.interviewInstanceId) {
      return;
    }
    this.onCloseDrawers();

    await this.backendService.jobApplicationSollecitInterview(this.candidateSheetService.interviewInstance?.interviewInstanceId);
    const forename = this.candidateSheetService.interviewInstance.interviewUser?.forename ?? "";
    const surename = this.candidateSheetService.interviewInstance.interviewUser?.surname ?? "";

    this.toastService.success(`${this.translateService.instant<string>("candidate.ON_SOLLECIT_MSG")} ${forename} ${surename}`);
  }

  protected async onSollecitTest(test: ScreeningTestInstance) {
    if (!this.candidateSheetService.jobApplication?.jobApplicationId) {
      return;
    }

    this.onCloseDrawers();
    await this.backendService.jobApplicationSolicitTestInstance(this.candidateSheetService.jobApplication?.jobApplicationId, test.screeningTestInstanceId);
    const forename = this.candidateSheetService.jobApplication?.forename ?? "";
    const surename = this.candidateSheetService.jobApplication?.surname ?? "";

    this.toastService.success(`${this.translateService.instant<string>("candidate.ON_SOLLECIT_MSG")} ${forename} ${surename}`);
  }

  protected get interruptionDefaultEmail() {
    return this.candidateSheetService.interruptionForm.get("defaultEmail") as FormControl;
  }

  protected get interruptionDefaultEmailEn() {
    return this.candidateSheetService.interruptionForm.get("defaultEmailEn") as FormControl;
  }

  protected get interruptionComment() {
    return this.candidateSheetService.interruptionForm.get("comment") as FormControl;
  }
  protected get evaluationFormType() {
    return this.candidateSheetService.interviewDetail.get("evaluationFormType") as FormArray;
  }
  protected get interviewer() {
    return this.candidateSheetService.interviewDetail.get("interviewer") as FormArray;
  }
  protected get testDetailDate() {
    return this.candidateSheetService.testDetail.get("date") as FormControl;
  }
  protected get testDetailName() {
    return this.candidateSheetService.testDetail.get("name") as FormArray;
  }

  public changeBehavior(type: string) {
    if (this.formReadOnly)
      return;
    this.setDirtyForm(true);
    if (Object.values(this.behavior).filter(e => e === true).length >= 2 && !this.behavior[type])
      return;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    this.behavior[type] = !this.behavior[type];
  }

  protected resetProperties() {
    this.propertiesForm = {
      ebrm: {
        interests: 0,
        motivation: 0,
        compatibility: 0,
        trust: 0,
        teamwork: 0,
        innovatitve: 0,
        openess: 0,
        proactivity: 0,
        execution: 0,
        makingSimple: 0,
        adaptability: 0
      },
      hrbp: {
        motivation: 0,
        interests: 0,
        compatibility: 0
      },
      businessInterview: {
        interests: 0,
        motivation: 0,
        trust: 0,
        teamwork: 0,
        innovatitve: 0,
        openess: 0,
        proactivity: 0,
        execution: 0,
        makingSimple: 0,
        adaptability: 0
      },
      hrbpSale: {
        communication: 0,
        entrepreneurship: 0,
        motivation: 0,
        customerOrientation: 0,
        adaptability: 0
      },
      actualRetribution: 0
    };
    this.behavior = [];
    this.setDirtyForm(false);
  }

  private getChar(i: number): string {
    switch (i) {
      case 0:
        return "a";
      case 1:
        return "b";
      case 2:
        return "c";
      case 4:
        return "d";
      default:
        return "";
    }
  }

  private setDirtyForm(value: boolean) {
    this.efFormDirty = value;
  }

  protected get evaluationFormFinalResult() {
    return this.efForm.get("finalValuationResult") as FormArray;
  }

  protected get actualRetributionEBRMFI() {
    return this.efForm.get("actualRetributionEBRMFI") as FormControl;
  }

  protected get actualRetributionHBRP() {
    return this.efForm.get("actualRetributionHBRP") as FormControl;
  }

  protected get actualRetributionHRBPSALES() {
    return this.efForm.get("actualRetributionHRBPSALES") as FormControl;
  }

  protected checkStar(type: InterviewLogicTypeEnum) {
    switch (type) {
      case InterviewLogicTypeEnumTypes.EBRM:
        for (const [key, value] of Object.entries(this.propertiesForm.ebrm)) {
          this.errorStar[key] = (value <= 0 || isNaN(value)) ? true : false;
        }
        break;
      case InterviewLogicTypeEnumTypes.HRBP:
        for (const [key, value] of Object.entries(this.propertiesForm.hrbp)) {
          this.errorStar[key] = (value <= 0 || isNaN(value)) ? true : false;
        }
        break;
      case InterviewLogicTypeEnumTypes.BUSINESS_INTERVIEWER:
        for (const [key, value] of Object.entries(this.propertiesForm.businessInterview)) {
          this.errorStar[key] = (value <= 0 || isNaN(value)) ? true : false;
        }
        break;
      case InterviewLogicTypeEnumTypes.HRBP_SALE:
        for (const [key, value] of Object.entries(this.propertiesForm.hrbpSale)) {
          this.errorStar[key] = (value <= 0 || isNaN(value)) ? true : false;
        }
        break;
      case InterviewLogicTypeEnumTypes.LINE_MANAGER:
        for (const [key, value] of Object.entries(this.competences)) {
          this.errorStar[(value as any).property] = ((value as any).evaluation as number <= 0) ? true : false;
        }
        break;
      default:
        return;
    }
  }

  protected setErrorStar(type: InterviewLogicTypeEnum) {
    this.errorStar = {};
    switch (type) {
      case InterviewLogicTypeEnumTypes.EBRM:

        for (const [key, value] of Object.entries(this.propertiesForm.ebrm)) {
          this.errorStar[key] = false;
        }
        break;
      case InterviewLogicTypeEnumTypes.HRBP:
        for (const [key, value] of Object.entries(this.propertiesForm.hrbp)) {
          this.errorStar[key] = false;
        }
        break;
      case InterviewLogicTypeEnumTypes.BUSINESS_INTERVIEWER:
        for (const [key, value] of Object.entries(this.propertiesForm.businessInterview)) {
          this.errorStar[key] = false;
        }
        break;
    }
  }


  protected getScoreFinalHiringReccomandation(type: InterviewLogicTypeEnum): number | null {
    let score = null;
    switch (type) {
      case InterviewLogicTypeEnumTypes.EBRM:
        if (this.propertiesForm.ebrm.motivation > 0 &&
          this.propertiesForm.ebrm.compatibility > 0 &&
          this.propertiesForm.ebrm.interests > 0 &&
          this.propertiesForm.ebrm.trust > 0 &&
          this.propertiesForm.ebrm.teamwork > 0 &&
          this.propertiesForm.ebrm.innovatitve > 0 &&
          this.propertiesForm.ebrm.openess > 0 &&
          this.propertiesForm.ebrm.proactivity > 0 &&
          this.propertiesForm.ebrm.execution > 0 &&
          this.propertiesForm.ebrm.makingSimple > 0 &&
          this.propertiesForm.ebrm.adaptability > 0) {
          score = Number(this.propertiesForm.ebrm.motivation + this.propertiesForm.ebrm.compatibility + this.propertiesForm.ebrm.interests + this.propertiesForm.ebrm.trust + this.propertiesForm.ebrm.teamwork + this.propertiesForm.ebrm.innovatitve + this.propertiesForm.ebrm.openess + this.propertiesForm.ebrm.proactivity + this.propertiesForm.ebrm.execution + this.propertiesForm.ebrm.makingSimple + this.propertiesForm.ebrm.adaptability) / 11;
        }
        return score;
      case InterviewLogicTypeEnumTypes.HRBP:
        if (this.propertiesForm.hrbp.motivation > 0 && this.propertiesForm.hrbp.compatibility > 0 && this.propertiesForm.hrbp.interests > 0)
          score = Number(this.propertiesForm.hrbp.motivation + this.propertiesForm.hrbp.compatibility + this.propertiesForm.hrbp.interests) / 3;
        return score;
      case InterviewLogicTypeEnumTypes.LINE_MANAGER:
        const bool = this.competences.every((el: any) => {
          return el.evaluation > 0;
        });
        if (bool) {
          let total = 0;
          this.competences.forEach((el: any) => {
            total += el.evaluation;
          });
          score = Number(total) / this.competences.length;
        }
        return score;
      case InterviewLogicTypeEnumTypes.BUSINESS_INTERVIEWER:
        if (this.propertiesForm.businessInterview.motivation > 0 &&
          this.propertiesForm.businessInterview.interests > 0 &&
          this.propertiesForm.businessInterview.trust > 0 &&
          this.propertiesForm.businessInterview.teamwork > 0 &&
          this.propertiesForm.businessInterview.innovatitve > 0 &&
          this.propertiesForm.businessInterview.openess > 0 &&
          this.propertiesForm.businessInterview.proactivity > 0 &&
          this.propertiesForm.businessInterview.execution > 0 &&
          this.propertiesForm.businessInterview.makingSimple > 0 &&
          this.propertiesForm.businessInterview.adaptability > 0) {
          score = Number(
            this.propertiesForm.businessInterview.motivation +
            this.propertiesForm.businessInterview.interests +
            this.propertiesForm.businessInterview.trust +
            this.propertiesForm.businessInterview.teamwork +
            this.propertiesForm.businessInterview.innovatitve +
            this.propertiesForm.businessInterview.openess +
            this.propertiesForm.businessInterview.proactivity +
            this.propertiesForm.businessInterview.execution +
            this.propertiesForm.businessInterview.makingSimple +
            this.propertiesForm.businessInterview.adaptability
          ) / 10;
        }
        return score;
      case InterviewLogicTypeEnumTypes.HRBP_SALE:
        if (this.propertiesForm.hrbpSale.communication > 0 &&
          this.propertiesForm.hrbpSale.entrepreneurship > 0 &&
          this.propertiesForm.hrbpSale.motivation > 0 &&
          this.propertiesForm.hrbpSale.customerOrientation > 0 &&
          this.propertiesForm.hrbpSale.adaptability > 0
        )
          score = Number(this.propertiesForm.hrbpSale.communication + this.propertiesForm.hrbpSale.entrepreneurship + this.propertiesForm.hrbpSale.motivation + this.propertiesForm.hrbpSale.customerOrientation + this.propertiesForm.hrbpSale.adaptability) / 5;
        return score;

    }
    return score;
  }

  protected onChangefinalValuationResult(evt: { label: string, value: InterviewFinalValuationEnum }) {
    this.interviewInstance.finalValuationResult = evt.value;
  }

  /*
  private async shouldShowConfirmAcceptModal() {
    const JOB_POSITION_ID = this.candidateSheetService?.jobApplication?.jobPositionId;

    if (!JOB_POSITION_ID) {
      return;
    }
    const jobPositionData = (await this.backendService.jobPositionListWithCounters({ id: JOB_POSITION_ID }))?.at(0);
    const jobApplicationList = await this.backendService.jobApplicationListByPosition(JOB_POSITION_ID);

    // Controllo se devo mostrare la modale
    //const alreadyAcceptedApplications = jobApplicationList?.filter(ja => ja.currentStatus === JobApplicationStatusEnumTypes.ACCEPT).length;
    const alreadyAcceptedApplications = jobPositionData?.counters?.accept ?? 0;
    const openedPositionsCount = jobPositionData?.openedPositionsCount ?? 0;

      if ((alreadyAcceptedApplications + 1) < openedPositionsCount) {
        return false;
      }

      // Prima di mostrarla aggiorno l'oggetto ConfirmCloseModalInfo

      const newObj: ConfirmCloseModalInfoInterface = {};
      // newObj.positionName = `${this.candidateSheetService.jobApplication?.jobPositionProgressiveCode ?? ""} ${this.candidateSheetService.jobApplication?.jobPositionTitle ?? ""}`;
      // newObj.countLongList = jobApplicationList?.filter(ja => ja.currentStatus === JobApplicationStatusEnumTypes.LONG_LIST).length;
      // newObj.countShortList = jobApplicationList?.filter(ja => ja.currentStatus === JobApplicationStatusEnumTypes.SHORT_LIST).length;
      // newObj.countToEvaluate = jobApplicationList?.filter(ja => ja.currentStatus === JobApplicationStatusEnumTypes.TO_EVALUATE).length;

      newObj.positionName = `${jobPositionData?.progressiveCodeDecoded ?? ""} ${jobPositionData?.title ?? ""}`;
      newObj.countLongList = jobPositionData?.counters?.longList ?? 0;
      newObj.countShortList = jobPositionData?.counters?.shortList ?? 0;
      newObj.countToEvaluate = jobPositionData?.counters?.toEvaluate ?? 0;

      // Utenti da escludere dal calcolo
      const usersCountToIgnore = {
        longList: 0,
        shortList: 0,
        toEvaluate: 0
      };

      const reassignedJobApplications = jobApplicationList?.filter(ja => ja.reassigned) ?? [];

      // Dal conteggio devo escludere le candidature riassegnate
      const jobApplicationsToIgnoreCount = reassignedJobApplications;
      // e quella attuale (se non già esclusa perchè riassegnata)
      if (this.candidateSheetService.jobApplication && !this.candidateSheetService.jobApplication.reassigned) {
        jobApplicationsToIgnoreCount.push(this.candidateSheetService.jobApplication);
      }

      // Aggiorna i dati
      newObj.isAcceptingApplication = true;
      newObj.nameToShow = `${this.candidateSheetService?.jobApplication?.forename ?? ""} ${this.candidateSheetService?.jobApplication?.surname ?? ""}`;


      // Aggiorno i counter
    jobApplicationsToIgnoreCount?.forEach(ja => {
      switch (ja.currentStatus) {
        case JobApplicationStatusEnumTypes.SHORT_LIST:
          usersCountToIgnore.shortList++;
          break;
        case JobApplicationStatusEnumTypes.LONG_LIST:
          usersCountToIgnore.longList++;
          break;
        case JobApplicationStatusEnumTypes.TO_EVALUATE:
          usersCountToIgnore.toEvaluate++;
          break;
      }
    });

    newObj.countLongList -=  (usersCountToIgnore?.longList ?? 0);
    newObj.countShortList -=  (usersCountToIgnore?.shortList ?? 0);
    newObj.countToEvaluate -=  (usersCountToIgnore?.toEvaluate ?? 0);

    this.confirmCloseModalInfo = newObj;

    return true;
  }
  */

  private async shouldShowConfirmAcceptModal(jobApplicationId: string) {
    const JOB_POSITION_ID = this.candidateSheetService?.jobApplication?.jobPositionId;

    if (!JOB_POSITION_ID) {
      return;
    }
    const jobPositionData = (await this.backendService.jobPositionListWithCounters({ id: JOB_POSITION_ID }))?.at(0);

    // Controllo se devo mostrare la modale
    const alreadyAcceptedApplications = jobPositionData?.counters?.accept ?? 0;
    const openedPositionsCount = jobPositionData?.openedPositionsCount ?? 0;

    if ((alreadyAcceptedApplications + 1) < openedPositionsCount) {
      return false;
    }

    // Prima di mostrarla aggiorno l'oggetto ConfirmCloseModalInfo

    const newObj = await this.backendService.getConfirmClosePositionModalInfo({
      jobPositionId: JOB_POSITION_ID,
      isAcceptingApplication: true,
      jobApplicationIdsToAccept: [jobApplicationId] ?? []
    });

    newObj.isAcceptingApplication = true;

    this.confirmCloseModalInfo = newObj;

    return true;
  }

  private setScore(score: number | null) {
    if (score == null || this.interviewInstance.finalValuationResult != undefined)
      return;
    const finalHiringLabel = this.getRange(score);
    const finalHiring = this.finalValuationResultArray.find((el: any) => el.value == finalHiringLabel);
    if (finalHiring != undefined) {
      this.fs.setValueOnArray(this.evaluationFormFinalResult, { label: finalHiring.label, value: finalHiring.value }, true);
      this.interviewInstance.finalValuationResult = finalHiring.value as InterviewFinalValuationEnum;
    }

  }

  protected canAccessDetailsAsHrbp(jobApplication: JobApplication): boolean {
    return jobApplication?.canAccessDetails ? true : false;
  }
  
  private getRange(number: number): string {
    if (number >= 4.51) {
      return InterviewFinalValuationEnumTypes.STRONG_HIRE;
    } else if (number >= 3 && number <= 4.50) {
      return InterviewFinalValuationEnumTypes.HIRE;
    } else if (number >= 2 && number <= 2.99) {
      return InterviewFinalValuationEnumTypes.UNCERTAIN;
    } else {
      return InterviewFinalValuationEnumTypes.NOT_HIRE;
    }
  }

  private isIsoDate(str: string) {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
    const d = new Date(str);
    return d instanceof Date && !isNaN(d.getTime()) && d.toISOString() === str;
  }

  private resetPropertiesFile(): void {
    this.fileUploaded = undefined;
    this.fileToUpload = undefined;
  }


  getTestScrenningType(value: ScreeningTest) {
    if (value == undefined) {
      this.testScreening.isEmpty = true;
      return;
    }
    this.testScreening.isEmpty = false;
  }

  private getBadgeLabel(valueApplicationBadge: string) {
    const status: BaseStatus[] = [
      { applicationBadge: "STOP", label: "Stop" },
      { applicationBadge: "TO_EVALUATE", label: "Da valutare" },
      { applicationBadge: "LONG_LIST", label: "Long list" },
      { applicationBadge: "SHORT_LIST", label: "Short list" },
      { applicationBadge: "OFFER", label: "Offerta" },
      { applicationBadge: "ACCEPT", label: "Accetta" },
      { applicationBadge: "REFUSE", label: "Rifiuta" }
    ];

    return status.find((el: any) => {
      if (el?.applicationBadge == valueApplicationBadge) {
        return el;
      }
    });
  }

}
