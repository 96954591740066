<generali-modal [id]="id">
  <div class="modal-wrapper text-center">
    <div class="modal-close mb-3" role="button" (click)="closeNavigateAwayModal()">
      <img src="assets/img/icons/close.svg" alt="">
      <p i18n="@@generic.CLOSE" class="text-decoration-underline">generic.CLOSE</p>
    </div>
    <div class="modal-content">
      <div class="modal-title text-center">
        <p i18n="@@generic.WARNING">@@generic.WARNING</p>
      </div>
      <div class="modal-txt my-4" [ngClass]="[alignLeft ? 'text-left' : 'text-center']">
        <p [innerHTML]="modalText"></p>
      </div>
      <div class="d-flex flex-column flex-md-row justify-content-center">
        <div class="modal-button mb-4 mb-md-3 me-0 me-md-3">
          <generali-button btnSize="lg" btnStyle="ghost" *ngIf="btnCancel" [btnItem]="btnCancel" (onClick)="cancel()">
          </generali-button>
        </div>
        <div class="modal-button">
          <generali-button btnSize="lg" btnStyle="red" *ngIf="btnApply" [btnItem]="btnApply" (onClick)="confirm()">
          </generali-button>
        </div>
      </div>
    </div>
  </div>
</generali-modal>
