import { Component, EventEmitter, Input, Output, inject } from "@angular/core";
import { TranslateService } from "../../util/translate.service";


@Component({
  selector: "generali-exposition-chip-for-filter",
  templateUrl: "./generali-exposition-chip-for-filter.component.html",
  styleUrls: ["./generali-exposition-chip-for-filter.component.scss"]
})
export class GeneraliExpositionChipForFilterComponent {
  private translateService = inject(TranslateService);

  /** Type possibili: "hrbps" | "locations" | "jobFields" | "jobPositionStatus" | "companies" | "talent" | "referral" */
  @Input() type!: string;
  @Input() labelItem!: unknown;
  @Input() chipValue!: unknown;
  @Input() chipToIgnore?: string[] = ["companyId", "hrbpId", "locationId", "jobFieldId", "contractTypeId", "fromDate", "toDate"];

  @Output() onRemove = new EventEmitter<{ chipValue: unknown, control: string }>();

  protected onChipRemove(chipValue: unknown) {
    this.onRemove.emit({ chipValue, control: this.type });
  }

  protected typeofArray(chip: unknown): chip is [] {
    if (Array.isArray(chip)) {
      return true;
    }

    return false;
  }

  protected get bindLabel() {
    if (this.type === "hrbps") {
      return "fullname";
    }
    if (this.type === "locations") {
      return "name";
    }
    if (this.type === "jobFields") {
      return "name";
    }
    if (this.type === "contractTypes") {
      return "name";
    }
    if (this.type === "companies") {
      return "name";
    }
    if (this.type === "ageRange") {
      return "label";
    }

    return "";
  }

  protected get additionalLabel() {
    if (this.type === "referral"
      || this.type === "talent"
      || this.type === "reassigned"
      || this.type === "newItalian"
      || this.type === "gender"
      || this.type === "legallyProtected"
      || this.type === "ageRange"
      || this.type === "feedbackMailSent") {
      return this.translateService.instant(`chip.additional.${this.type.toUpperCase()}`);
    }
    return;
  }
}
