import { Pipe, PipeTransform, inject } from "@angular/core";
import { JobApplicationStatusEnumTypes, JobPositionStatusEnumTypes } from "applicanttrackingsystem-cl";
import { FormCommonClasses as FormCL } from "src/app/shared/util/form.classes";
import { TranslateService } from "../util/translate.service";

@Pipe({ name: "renderingFilter" })
export class FilterPipe implements PipeTransform {
  private ts = inject(TranslateService);

  /**
   * @param filterKey chiave per la traduzione 
   * 
   * @returns Traduzione dei filtri
   */
  transform(filterKey: string) {
    switch (filterKey) {
      case FormCL.STATUS:
        return $localize`:@@filter.STATUS:filter.STATUS`;
      case FormCL.COMPANY:
        return $localize`:@@filter.COMPANY:filter.COMPANY`;
      case FormCL.CONTRACT_TYPE:
        return $localize`:@@filter.CONTRACT_TYPE:filter.CONTRACT_TYPE`;
      case FormCL.DATE:
        return $localize`:@@filter.DATE:filter.DATE`;
      case FormCL.HRBP:
        return $localize`:@@filter.HRBP:filter.HRBP`;
      case FormCL.JOB_FIELD:
        return $localize`:@@filter.JOB_FIELD:filter.JOB_FIELD`;
      case FormCL.LOCATION:
        return $localize`:@@filter.LOCATION:filter.LOCATION`;
      case FormCL.KEYWORD:
        return $localize`:@@filter.KEYWORD:filter.KEYWORD`;
      case FormCL.REASSIGNMENT:
        return $localize`:@@filter.REASSIGNMENT:filter.REASSIGNMENT`;
      case FormCL.REFERRAL:
        return $localize`:@@filter.REFERRAL:filter.REFERRAL`;
      case FormCL.TALENT:
        return $localize`:@@filter.TALENT:filter.TALENT`;
      case FormCL.FEEDBACK_EMAIL:
        return $localize`:@@filter.FEEDBACK_EMAIL:filter.FEEDBACK_EMAIL`;
      case FormCL.USER_DETAILS:
        return $localize`:@@filter.USER_DETAILS:filter.USER_DETAILS`;
      case FormCL.GENRE:
        return $localize`:@@filter.GENRE:filter.GENRE`;
      case FormCL.NEW_ITALIAN:
        return $localize`:@@filter.NEW_ITALIAN:filter.NEW_ITALIAN`;
      case FormCL.PROTECTED_CATEGORY:
        return $localize`:@@filter.PROTECTED_CATEGORY:filter.PROTECTED_CATEGORY`;
      case FormCL.AGE_RANGE:
        return $localize`:@@filter.AGE_RANGE:filter.AGE_RANGE`;

      default:
        return "ERRORE";
    }
  }
}

@Pipe({ name: "renderingOrder" })
export class OrderPipe implements PipeTransform {
  private ts = inject(TranslateService);

  /**
   * @param filterKey chiave per la traduzione 
   * 
   * @returns Traduzione dei filtri
   */
  transform(filterKey: string) {
    return this.ts.instant<string>(`order.${filterKey}`);
  }
}

@Pipe({ name: "renderingInterview" })
export class InterviewPipe implements PipeTransform {
  private ts = inject(TranslateService);
  public transform(interviewTypeId?: string) {
    if (!interviewTypeId) {
      return;
    }
    return this.ts.instant<string>(`interview.type.${interviewTypeId}`);
  }
}

@Pipe({ name: "renderingStatus" })
export class StatusPipe implements PipeTransform {


  public transform(chip?: string) {
    switch (chip) {
      case JobPositionStatusEnumTypes.DRAFT:
        return $localize`:@@position.status.label.DRAFT:position.status.label.DRAFT`;
      case JobPositionStatusEnumTypes.CLOSED_EMPTY:
        return $localize`:@@position.status.label.CLOSED_EMPTY:position.status.label.CLOSED_EMPTY`;
      case JobPositionStatusEnumTypes.CLOSED_FILLED:
        return $localize`:@@position.status.label.CLOSED_FILLED:position.status.label.CLOSED_FILLED`;
      case JobPositionStatusEnumTypes.OPEN:
        return $localize`:@@position.status.label.OPEN:position.status.label.OPEN`;
      case JobPositionStatusEnumTypes.PUBLISHED:
        return $localize`:@@position.status.label.PUBLISHED:position.status.label.PUBLISHED`;
      case JobPositionStatusEnumTypes.SUSPENDED:
        return $localize`:@@position.status.label.SUSPENDED:position.status.label.SUSPENDED`;
      case JobPositionStatusEnumTypes.TO_BE_APPROVED:
        return $localize`:@@position.status.label.TO_BE_APPROVED:position.status.label.TO_BE_APPROVED`;
      case JobApplicationStatusEnumTypes.STOP:
        return $localize`:@@home.table.STOP:home.table.STOP`[0].toUpperCase() + $localize`:@@home.table.STOP:home.table.STOP`.toLocaleLowerCase().slice(1);
      case JobApplicationStatusEnumTypes.ACCEPT:
        return $localize`:@@home.table.ACCEPT:home.table.ACCEPT`[0].toUpperCase() + $localize`:@@home.table.ACCEPT:home.table.ACCEPT`.toLocaleLowerCase().slice(1);
      case JobApplicationStatusEnumTypes.LONG_LIST:
        return $localize`:@@home.table.LONG_LIST:home.table.LONG_LIST`[0].toUpperCase() + $localize`:@@home.table.LONG_LIST:home.table.LONG_LIST`.toLocaleLowerCase().slice(1);
      case JobApplicationStatusEnumTypes.OFFER:
        return $localize`:@@home.table.OFFER:home.table.OFFER`[0].toUpperCase() + $localize`:@@home.table.OFFER:home.table.OFFER`.toLocaleLowerCase().slice(1);
      case JobApplicationStatusEnumTypes.REFUSE:
        return $localize`:@@home.table.REFUSED:home.table.REFUSED`[0].toUpperCase() + $localize`:@@home.table.REFUSED:home.table.REFUSED`.toLocaleLowerCase().slice(1);
      case JobApplicationStatusEnumTypes.SHORT_LIST:
        return $localize`:@@home.table.SHORT_LIST:home.table.SHORT_LIST`[0].toUpperCase() + $localize`:@@home.table.SHORT_LIST:home.table.SHORT_LIST`.toLocaleLowerCase().slice(1);
      case JobApplicationStatusEnumTypes.TO_EVALUATE:
        return $localize`:@@home.table.TO_EVALUATE:home.table.TO_EVALUATE`[0].toUpperCase() + $localize`:@@home.table.TO_EVALUATE:home.table.TO_EVALUATE`.toLocaleLowerCase().slice(1);



      default:
        return $localize`:@@position.status.label.DRAFT:position.status.label.DRAFT`;
    }
  }
}

@Pipe({ name: "RenderBool" })
export class BoolPipe implements PipeTransform {
  public transform(chip: boolean | undefined | string | null) {
    switch (chip) {
      case true:
        return "Si";
      case false:
        return "No";
      case "M":
        return "Uomo";
      case "F":
        return "Donna";
      case null:
        return "Tutti";
      default:
        return "ERRORE";
    }
  }
}

@Pipe({ name: "translate" })
export class TranslatePipe implements PipeTransform {
  i18n = inject(TranslateService);
  public transform(trKey: string, optValue?: { [k: string]: string }) {
    if (!this.i18n.translation) {
      return undefined;
    }
    if (!optValue) {
      return this.i18n.translation[trKey] ?? trKey;
    }

    let returnMsg = this.i18n.translation[trKey];
    for (const value in optValue) {
      returnMsg = returnMsg?.replace(`{{${value}}}`, optValue[value]);
    }

    return returnMsg;
  }
}

@Pipe({ name: "elapsed" })
export class ElapsedPipe implements PipeTransform {
  private i18n = inject(TranslateService);

  public transform(creationDate?: Date) {
    if (!creationDate) {
      return;
    }
    const pastTime = new Date(creationDate).valueOf();
    const now = new Date().valueOf();

    const elapsedSecond = (now - pastTime);
    const day = Math.floor(elapsedSecond / (1000 * 60 * 60 * 24));
    const hours = Math.floor((elapsedSecond % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((elapsedSecond % (1000 * 60 * 60)) / (1000 * 60));
    const maxDays = 30;

    if (day > maxDays) {
      const date = new Date(creationDate);
      const pastDay = `${date.getDate().toString().length > 1 ? "" : "0"}${date.getDate()}`;
      const pastMonth = `${(date.getMonth() + 1).toString().length > 1 ? "" : "0"}${date.getMonth() + 1}`;
      const pastYear = date.getFullYear();

      return `${pastDay}/${pastMonth}/${pastYear}`;
    }

    if (day > 0) {
      return `${day} ${day === 1 ? this.i18n.instant<string>("preview.DAY") : this.i18n.instant<string>("preview.DAYS")}`;
    }

    if (hours > 0 && hours < 24) {
      return `${hours} ${hours === 1 ? this.i18n.instant<string>("preview.HOUR") : this.i18n.instant<string>("preview.HOURS")}`;
    }

    return `${minutes} ${minutes === 1 ? this.i18n.instant<string>("preview.MINUTE") : this.i18n.instant<string>("preview.MINUTES")}`;
  }
}

@Pipe({ name: "parseInt" })
export class ParseIntPipe implements PipeTransform {
  private i18n = inject(TranslateService);
  public transform(numberToParse?: string | number): number {
    if (numberToParse === undefined) {
      return 0;
    }
    if (typeof numberToParse === "number") {
      return parseInt(numberToParse.toFixed(0), 10);
    }
    return parseInt(parseInt(numberToParse, 10).toFixed(0), 10) ?? 0;
  }
}
