import { inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { UrlCollection } from "../app-routing.module";

import { TokenManagerService } from "./token-manager.service";
import { SimpleUser } from "applicanttrackingsystem-cl";
import { FullName } from "../pages/homepage/homepage.component";

export namespace DoctorWizardStatusList {
  export const progress = "PROGRESS";
  export const stop = null;
}

@Injectable()
export class ApplicationDataService {
  private _tkManager: TokenManagerService = inject(TokenManagerService);
  private router: Router = inject(Router);

  private _showHeader = true;
  private _showNavbar = true;

  public stringify(param: string | number | object | boolean | undefined | unknown) {
    if (!param) {
      return "";
    }
    if (typeof param === "string") {
      return param;
    }

    return JSON.stringify(param);
  }

  /**
   * Utilizzando la pipe keyValue l'ordine con cui i dati vengono esposti non corrisponde all'ordine originale nella quale il parametro si trova all'interno dell'oggezzo.
   *
   * obj = { z: 1, c: 2, l: 3 } ==> 2, 3, 1
   *
   * Se si desidera mantenere l'ordine originale di inserimento passare come parametro alla pipe questo metodo.
   *
   *
   * {{ loop | keyValue: originalOrder }}
   *
   * @param a
   * @param b
   * @returns
   */
  public originalOrder = (a: unknown, b: unknown): number => {
    return 0;
  };

  public addFullNameToUsers(users: SimpleUser[]): (SimpleUser & FullName)[] {
    return users.map(usr => ({
      ...usr,
      fullname: `${usr.forename} ${usr.surname}`
    }));
  }

  /**
   * Scorre la pagina verso il punto specificato.
   * @param ancorTagId L'ID dell'"ancor tag" a cui si desidera scorrere la pagina.
   */
  public scrollTo(ancorTagId: string) {
    const ancor = document.querySelector(ancorTagId);
    const position = ancor?.getBoundingClientRect();
    if (position) {
      window.scrollTo(0, document.body.scrollHeight - position.height - 320);
    }
  }

  public isEmpty(obj: unknown): obj is undefined {
    if (typeof obj === "string") {
      return obj === "" ? true : false;
    }

    if (obj === undefined || obj === null) {
      return true;
    }

    if (JSON.stringify(obj) === "{}") {
      return true;
    }

    if (JSON.stringify(obj) === "[]") {
      return true;
    }

    return false;
  }

  /**
   * Inserire nella condizione tutti gli url delle pagine dove non si vuole visualizzare l'header
   */
  public setHeaderVisibility() {
    if (this.router.url.includes(UrlCollection.localAuthentication) || this.router.url.includes(UrlCollection.samlSso)
      || (this.router.url.includes(UrlCollection.preview) && this.router.url.includes(UrlCollection.detail))) {
      this._showHeader = false;
    } else {
      this._showHeader = true;
    }
  }

  public get showHeader(): boolean {
    return this._showHeader;
  }

  /**
   * Inserire nella condizione tutti gli url delle pagine dove non si vuole visualizzare la navbar
   */
  public setNavbarVisibility() {
    if (this.router.url.includes(UrlCollection.localAuthentication) || this.router.url.includes(UrlCollection.preview) || this.router.url.includes(UrlCollection.samlSso)) {
      this._showNavbar = false;
    } else {
      this._showNavbar = true;
    }
  }

  public handleAge(bDate: Date) {
    // if (!bDate) {
    //   return;
    // }

    const tooday = new Date().valueOf();
    const starDate = new Date(bDate).valueOf();

    const ageRange = Math.floor((tooday - starDate) / (1000 * 60 * 60 * 24 * 364));

    if (ageRange <= 27) {
      return { label: "Under 27", value: "27-" };
    } else if (ageRange >= 28 && ageRange <= 30) {
      return { label: "Tra 28 e 30", value: "28-30" };
    } else if (ageRange >= 31 && ageRange <= 35) {
      return { label: "Tra 31 e 35", value: "31-35" };
    } else if (ageRange >= 36 && ageRange <= 40) {
      return { label: "Tra 36 e 40", value: "36-40" };
    } else if (ageRange >= 41 && ageRange <= 45) {
      return { label: "Tra 41 e 45", value: "41-45" };
    } else if (ageRange >= 46 && ageRange <= 50) {
      return { label: "Tra 46 e 50", value: "46-50" };
    } else {
      return { label: "Over 50", value: "50+" };
    }
  }


  public get showNavbar() {
    return this._showNavbar;
  }


  public mapAgeRange(range: string) {
    if (range.length) {
      let rangeVal;
      let label;
      switch (range) {
        case "0-27":
          return { label: "Under 27", value: "0-27" };
        case "50-99":
          return { label: "Over 50", value: "50-99" };
        default:
          rangeVal = range.split("-");
          label = `Tra ${rangeVal[0]} e ${rangeVal[1]}`;
          return { label: label, value: range };
      }

    } else {
      return {};
    }
  }
}
