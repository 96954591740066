import { TranslationWidth } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output, inject } from "@angular/core";
import { FormControl } from "@angular/forms";
import { NgbDateParserFormatter, NgbCalendar, NgbDateStruct, NgbDate, NgbInputDatepicker } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "generali-range-datepicker",
  templateUrl: "./generali-range-datepicker.component.html",
  styleUrls: ["./generali-range-datepicker.component.scss"]
})
export class GeneraliRangeDatepickerComponent implements OnInit {
  constructor() { }

  protected formatter = inject(NgbDateParserFormatter);
  protected calendar = inject(NgbCalendar);

  protected _hoveredDate: NgbDateStruct | null = null;
  protected _fromDate: NgbDateStruct | null = null;
  protected _toDate: NgbDateStruct | null = null;
  protected _toDateMode?: boolean;

  @Input() fromDate?: FormControl;
  @Input() toDate?: FormControl;
  @Input() placeholder?: string;
  @Input() dpTitleFrom?: string;
  @Input() dpTitleTo?: string;
  @Input() forceDelete?: boolean;

  @Output() onDatesSelection = new EventEmitter();
  @Output() onResetDate = new EventEmitter();

  public ngOnInit() {
    const today = this.calendar.getToday();
    const pastYear = this.calendar.getNext(today, "y", -1);

    this._fromDate = pastYear;
    this._toDate = today;
  }

  private parseDatepickerDate(date: NgbDateStruct, addDay?: number) {
    const day = addDay ? date.day + addDay : date.day;
    return new Date(date.year, date.month - 1, day);
  }


  protected onDateSelection(date: NgbDate, dp: NgbInputDatepicker) {
    if (this._toDateMode && date.after(this._fromDate)) {
      this._toDate = date;
      this._toDateMode = false;

      dp.close();
      this.emitDateSelected();

      return;
    }


    if (!this._fromDate && !this._toDate) {
      this._fromDate = date;
    } else if (this._fromDate && !this._toDate && date.equals(this._fromDate)) {
      this._toDate = date;
      this._fromDate = date;
    } else if (this._fromDate && !this._toDate && date.after(this._fromDate)) {
      this._toDate = date;
    } else {
      this._toDate = null;
      this._fromDate = date;
    }

    if (this._fromDate && this._toDate) {
      dp.close();

      this.emitDateSelected();
    }
  }

  private emitDateSelected() {
    if (!this._fromDate || !this._toDate) {
      this.onResetDate.emit(undefined);

      return;
    }

    const fromDate = this.parseDatepickerDate(this._fromDate);
    const toDate = this.parseDatepickerDate(this._toDate, 1);

    this.fromDate?.setValue(fromDate);
    this.toDate?.setValue(toDate);

    this.onDatesSelection.emit({ fromDate, toDate });
  }

  protected isHovered(date: NgbDate) {
    return (
      this._fromDate && !this._toDate && this._hoveredDate && date.after(this._fromDate) && date.before(this._hoveredDate)
    );
  }

  protected isInside(date: NgbDate) {
    return this._toDate && date.after(this._fromDate) && date.before(this._toDate);
  }

  protected isRange(date: NgbDate) {
    return (
      date.equals(this._fromDate) ||
      (this._toDate && date.equals(this._toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  protected resetDate() {
    this.fromDate?.reset();
    this.toDate?.reset();

    this._fromDate = null;
    this._toDate = null;

    this.emitDateSelected();
  }

  protected get weekday() {
    return TranslationWidth;
  }
}
