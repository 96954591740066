import { Component, EventEmitter, Input, Output, inject } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { GeneraliSelect } from "generali-shared-components/dist";
import { TranslateService } from "../../util/translate.service";


@Component({
  selector: "generali-select-for-filter",
  templateUrl: "./generali-select-for-filter.component.html",
  styleUrls: ["./generali-select-for-filter.component.scss"]
})
export class GeneraliSelectForFilterComponent {
  private translateService = inject(TranslateService);

  @Input() accordionName?: string;
  @Input() control!: string;
  @Input() group!: FormGroup;
  @Input() type!: "hrbps" | "locations" | "jobFields" | "contractTypes" | "ageRange";
  @Input() dropdown?: unknown[];

  @Output() onChange = new EventEmitter<string>();

  protected get bindLabel() {
    if (this.type === "hrbps") {
      return "fullname";
    }

    if (this.type === "locations") {
      return "name";
    }

    if (this.type === "jobFields") {
      return "name";
    }

    if (this.type === "contractTypes") {
      return "name";
    }

    if (this.type === "ageRange") {
      return "label";
    }

    return "";
  }

  protected get searchable() {
    if (this.type === "hrbps") {
      return true;
    }

    if (this.type === "locations") {
      return true;
    }

    if (this.type === "jobFields") {
      return true;
    }

    if (this.type === "contractTypes") {
      return false;
    }

    if (this.type === "ageRange") {
      return false;
    }

    return false;
  }

  protected get placeholder() {
    return this.translateService.instant<string>(`select.placeholder.${this.type?.toUpperCase() ?? ""}`);
  }

  protected get selectorType() {
    if (this.type === "ageRange") {
      return GeneraliSelect.CHECKBOX as GeneraliSelect.SelectorType;
    }

    return undefined;
  }

  protected onChangeValue(txtSearch: string) {
    if (this.searchable) {
      this.onChange.emit(txtSearch);
    }
  }

}
