import { TranslationWidth } from "@angular/common";
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChange, inject } from "@angular/core";
import { FormControl } from "@angular/forms";
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "generali-datapicker",
  templateUrl: "./generali-datapicker.component.html",
  styleUrls: ["./generali-datapicker.component.scss"]
})
export class GeneraliDatapickerComponent implements OnInit {
  protected calendar = inject(NgbCalendar);
  protected formatter = inject(NgbDateParserFormatter);
  private cdr = inject(ChangeDetectorRef);

  constructor() { }

  public ngOnInit(): void {
    if (this.preselectedDate) {
      this.cdr.detectChanges();
      const d = new Date(this.preselectedDate);
      const [day, month, year] = [d.getDate(), d.getMonth() + 1, d.getFullYear()];
      this.selectedDate = { day, month, year };
      this.onDateSelect.emit(this.selectedDate);
    }
  }

  @Input() inputControl!: unknown;
  /** Titolo del datepicker */
  @Input() dpTitle?: string;
  /** Placeholder */
  @Input() placeholder?: string;
  /** Disabilita tutti i giorni preccedenti al corrente se settato a true */
  @Input() disableBeforeTooday?: boolean;
  /** Setta una data preselezionata */
  @Input() preselectedDate?: Date;

  /** Setta se btn readOnly*/
  @Input() readOnly?: boolean;

  @Output() onDateSelect = new EventEmitter();

  protected selectedDate: NgbDateStruct | null = null;

  protected markDisable = (data: NgbDate) => {
    if (this.disableBeforeTooday) {
      return data.before(this.calendar.getToday());
    }
    if (this.readOnly)
      return true;
    return false;
  };

  get dateControl() {
    return this.inputControl as FormControl;
  }
  get weekday() {
    return TranslationWidth;
  }

  protected dateSelect(dateStruct: NgbDateStruct) {
    const date = new NgbDate(dateStruct.year, dateStruct.month, dateStruct.day);
    if (date.equals(this.selectedDate)) {
      this.onDateSelect.emit();
      this.selectedDate = null;
      return;
    }

    this.selectedDate = dateStruct;
    this.onDateSelect.emit(dateStruct);
  }

  get minDate() {
    const today = this.calendar.getToday();
    return new NgbDate(today.year - 100, today.month, today.day);
  }
}
