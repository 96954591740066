import { inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";

import { ATSAuthScopes, ATSLoginInfo } from "applicanttrackingsystem-cl";
import { EnvironmentLoaderService, GeneralConfig } from "src/ancestors/env-config.service";
import { UrlCollection } from "../app-routing.module";
import { BackendService } from "./backend-api.service";
import { TokenManagerService } from "./token-manager.service";
import { TokenStorageService } from "./token-storage.service";

@Injectable({ providedIn: "root" })
export class AuthenticationGuard implements CanActivate {
  constructor() { }
  private router: Router = inject(Router);
  private envConfig: EnvironmentLoaderService = inject(EnvironmentLoaderService);
  private tkManager: TokenManagerService = inject(TokenManagerService);
  private tStorage: TokenStorageService = inject(TokenStorageService);
  private backendService = inject(BackendService);
  private env: GeneralConfig = this.envConfig.getEnvConfig();

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const tokenKey = this.env.loginType === "local" ? this.env.localTokenKey : this.env.ssoTokenKey;
    const actualUrl = new URL(window.location.href.replace("/#/", "/"));
    const tokenExist = JSON.stringify(this.tStorage.retriveToken(tokenKey)) === "{}" ? false : true;
    const ssortkqp = actualUrl.searchParams.get("ssortkqp");

    if (!tokenExist && this.env.loginType === "saml-sso" && ssortkqp) {
      const loginInfo = await this.backendService.decodeSingleUseToken(ssortkqp);
      if (!loginInfo) {
        return this.router.navigate([UrlCollection.samlSso]);
      }

      this.tStorage.saveToken(tokenKey, loginInfo);
      const redirectUrl = this.getRedirectUrl(window.location.href);

      return this.router.navigateByUrl(redirectUrl ?? "homepage");
    }

    const ROUTE_CANDIDATE = "candidate-sheet";
    const token: ATSLoginInfo = this.tStorage.retriveToken(tokenKey);
    const tokenIsValid: boolean = this.tkManager.checkTokenValidity(token);

    /**
     * Se esiste un token di autenticazione valido verrà permesso di eseguire l'accesso
     */
    if (token && tokenIsValid) {
      /** Controllo necessario per bloccare ai line manager l'accesso in tutte le pagine esclusa quella del candidato */
      if (token.jwtPayload.auths.includes(ATSAuthScopes.UNPROFILED_USER as string) && !state.url.includes(ROUTE_CANDIDATE)) {
        await this.router.navigateByUrl("403");
        return false;
      }

      return true;
    }

    /** Il token scaduto verrà eliminato */
    this.tStorage.deleteToken(tokenKey);

    await this.redirectToLoginUrl(state.url);

    return false;
  }

  /**
   * Fa il redirect all'url di login, in base al tipo di login
   * aggiungendo anche l' originalRequestedUrl per tornare all'url originale dopo il login
   *
   * @param urlSnapshot URL alla qualle si tenta di accedere.
   */
  async redirectToLoginUrl(urlSnapshot: string): Promise<void> {
    switch (this.env.loginType) {
      case "local": {
        await this.router.navigate([this.env.loginUrl], {
          queryParams: { originalRequestedUrl: urlSnapshot }
        });
        break;
      }
      case "saml-sso": {
        const actualUrl = new URL(window.location.href.replace("/#/", "/"));
        const redirectUrl = new URL(actualUrl);
        const baseHref = this.env.baseHref;

        /** aggiunge l'url originale in cui tornare dopo il login */
        let originalRequestedUrl = redirectUrl.pathname === "/" ? undefined : redirectUrl.pathname;

        if (baseHref && originalRequestedUrl?.startsWith(baseHref)) {
          originalRequestedUrl = originalRequestedUrl.substring(baseHref.length);
        }

        await this.router.navigate([UrlCollection.samlSso], { queryParams: { originalRequestedUrl } });
        break;
      }
      default: {
        await this.router.navigate([this.env.loginUrl], {
          queryParams: { originalRequestedUrl: urlSnapshot }
        });
      }
    }
  }

  private getRedirectUrl(url: string) {
    const actualUrl = new URL(url.replace("/#/", "/"));
    const baseHref = this.env.baseHref;
    const originalRequestedUrl = actualUrl.searchParams.get("originalRequestedUrl");
    let redirectUrl: string | null = originalRequestedUrl ? originalRequestedUrl : (actualUrl.pathname === "/" ? null : actualUrl.pathname + actualUrl.search.split("ssortkqp")[0]);
    if (baseHref && redirectUrl?.startsWith(baseHref)) {
      redirectUrl = redirectUrl.substring(baseHref.length);
    }

    return redirectUrl;
  }
}
