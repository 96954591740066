<form [formGroup]="createPositionService.jobDescriptionForm">

  <!-- Job description -->
  <div class="row mb-4">
    <div class="col-12 col-sm-10 col-md-10 col-lg-10 mb-3">
      <p class="title mb-3" i18n="@@job-description.JOBS">job-description.JOBS</p>
      <generali-ckeditor [customFormControl]="createPositionService.jobDescription">
      </generali-ckeditor>
    </div>
  </div>

  <div class="break-line mb-4"></div>

  <!-- Requirements -->
  <div class="row mb-4">
    <div class="col-12 col-sm-10 col-md-10 col-lg-10 mb-5">
      <p class="title mb-3" i18n="@@job-description.SKILLS">job-description.SKILLS</p>
      <generali-ckeditor [customFormControl]="createPositionService.competencesDescription">
      </generali-ckeditor>
    </div>
  </div>

  <div class="break-line mb-4"></div>

  <!-- Company profile -->
  <div class="row mb-4">
    <div class="col-12 col-sm-10 col-md-10 col-lg-10 mb-3">
      <p class="title mb-3" i18n="@@job-description.COMPANY_PROFILE">job-description.COMPANY_PROFILE</p>
      <generali-ckeditor [customFormControl]="createPositionService.companyProfileDescription">
      </generali-ckeditor>
    </div>
  </div>
</form>
