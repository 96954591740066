<div class="flex-row px-sm-1">
  <div class="main-title col-auto">
    <p i18n="@@generic.SEARCH">generic.SEARCH</p>
  </div>
</div>

<form [formGroup]="searchService.searchForm"
  class="search-bar-wrapper d-flex flex-column flex-sm-row align-items-start align-items-sm-center px-sm-1">
  <generali-input placeholder="{{ 'search.BAR_PLACEHOLDER' | translate }}" [inputControl]="searchService.searchedText"
    (onClear)="onSearchClear()" [clearable]="searchService.searchedText.value" class="search-bar-width">
  </generali-input>
  <generali-button btnItem="{{ 'generic.SEARCH' | translate | uppercase }}" btnSize="sm" btnStyle="red"
    class="search-btn-height" (onClick)="onSearch()">
  </generali-button>
</form>

<div class="tab-wrapper d-flex justify-content-between align-items-center">
  <div class="d-flex">
    <generali-status-tab *ngFor="let item of searchService.tabItems" [item]="item" class="full-width"
      (onStepClick)="onStepClick($event)">
    </generali-status-tab>
  </div>

  <!-- Filtri -->
  <div class="d-flex justify-content-md-end justify-content-start filter">
    <p class="m-0 mx-2 d-flex justify-content-center align-items-center"
      [ngClass]="{'order-disabled': orderIsDisabled()}" role="button" (click)="onOpenOrderTab()">
      <img class="me-2" src="assets/img/icons/arrow_small.svg">
      <span class="heading">{{ 'generic.ORDER' | translate }}</span>
    </p>

    <p class="m-0 mx-2 d-flex justify-content-center align-items-center" role="button" (click)="onOpenFilterTab()">
      <img class="me-2" src="assets/img/icons/burger-small.svg">
      <span class="heading">{{ 'generic.FILTER' | translate }}</span>
    </p>
  </div>
</div>

<router-outlet></router-outlet>