
import { registerLocaleData } from "@angular/common";
import { APP_INITIALIZER, Injectable, LOCALE_ID } from "@angular/core";
import { loadTranslations } from "@angular/localize";

@Injectable({
    providedIn: "root"
})
export class I18n {
    /** Lingue attive */
    activeLangs = ["it", "en", "de"];

    /*+ Lingua di default */
    locale = "it";

    async setLocale() {
        const userLocale = localStorage.getItem("locale");

        /** Se l'utente ha già una lingua selezionata, controlla se è tra le lingue attive, e quindi la usa. */
        if (userLocale && this.activeLangs.includes(userLocale)) {
            this.locale = userLocale;
        } else {
            /** Se non ha già una lingua settata, controlla se la lingua del browser è tra quelle attive, e quindi la usa. Altrimenti resta quella di default impostata sopra. */
            const browserLang = navigator.language.split("-")[0];
            if (browserLang && this.activeLangs.includes(browserLang)) {
                this.locale = browserLang;
            }
        }


        // Use web pack magic string to only include required locale data
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const localeModule = await import(
            /* webpackInclude: /(it|en|de)\.mjs$/ */
            `/node_modules/@angular/common/locales/${this.locale}.mjs`
        );



        // Set locale for built in pipes, etc.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        registerLocaleData(localeModule.default);

        // Load translation file
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const localeTranslationsModule = await import(
            `src/assets/i18n/${this.locale}.json`
        );
        //console.log(localeTranslationsModule);

        // Load translations for the current locale at run-time
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        loadTranslations(localeTranslationsModule.default);
    }

    async getLocale() {
        return import(
            `src/assets/i18n/${this.locale}.json`
        );
    }
}


// Load locale data at app start-up
function setLocale() {
    return {
        provide: APP_INITIALIZER,
        useFactory: (i18n: I18n) => () => i18n.setLocale(),
        deps: [I18n],
        multi: true
    };
}

// Set the runtime locale for the app
function setLocaleId() {
    return {
        provide: LOCALE_ID,
        useFactory: (i18n: I18n) => i18n.locale,
        deps: [I18n]
    };
}


/** Per aggiungere una lingua:
 * - Creare il file json della lingua in assets/i18n
 * - In i18n.modules.ts nella funzione setLocale aggiornare la direttiva webpackInclude: /(it|en)\.mjs$/ aggiungendo la lingua
 * - Aggiungerla all'array activeLangs
*/
export const i18nModule = {
    setLocale: setLocale,
    setLocaleId: setLocaleId
};