import { Directive, ElementRef, Input, Renderer2 } from "@angular/core";

@Directive({
  selector: "[appLoader]"

})
export class LoaderDirective {
  private loader: HTMLElement;
  @Input() loading?: boolean;
  @Input() width?: string;

  constructor(private renderer: Renderer2, private el: ElementRef) {
    this.loader = this.renderer.createElement("div");
  }
  ngOnInit(): void { }

  ngOnChanges(): void {

    // execute create loader
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
    if (this.loading && this.el) {
      this.createSimpleLoader();

      // hide the first element in the parent div containing directive
      // this should always be a component you want to replace with
      // the loader we are making
      this.renderer.setStyle(this.el.nativeElement, "display", "none");

      this.renderer.appendChild(this.el.nativeElement.parentElement, this.loader);
    } else {
      const loaders = document.querySelectorAll(".global-application-loader");
      loaders.forEach(loader => {
        loader.remove();
      });

      this.renderer.setStyle(this.el?.nativeElement, "display", "block");
    }
  }

  createSimpleLoader() {
    /** add some style to the loader wrapper */
    this.renderer.setStyle(this.loader, "display", "flex");
    this.renderer.setStyle(this.loader, "flex-direction", "column");
    this.renderer.setStyle(this.loader, "justify-content", "center");
    this.renderer.setStyle(this.loader, "align-items", "center");
    // create loader spinner with custom scss
    /** Format of this loader is:
        <div class="loader"></div>
    */
    const ldsRoller = this.renderer.createElement("div");
    this.renderer.addClass(ldsRoller, "global-application-loader");
    if (this.width) {
      this.renderer.setStyle(ldsRoller, "width", this.width);
    } else {
      this.renderer.setStyle(ldsRoller, "width", "30px");
    }

    this.renderer.appendChild(this.loader, ldsRoller);
  }
}
