import { Pipe, PipeTransform, inject } from "@angular/core";
import { TranslateService } from "../util/translate.service";


@Pipe({
  name: "chipValue"
})
export class ChipValuePipe implements PipeTransform {
  private translateSercive = inject(TranslateService);
  transform(value: unknown) {
    if (value === true) {
      return this.translateSercive.instant("generic.YES");
    }

    if (value === false) {
      return this.translateSercive.instant("generic.NO");
    }

    if (value === "M") {
      return this.translateSercive.instant("generic.MAN");
    }

    if (value === "F") {
      return this.translateSercive.instant("generic.WOMAN");
    }

    return value;
  }

}
