<form [formGroup]="createPositionService.stakeholderForm">
  <div class="row">
    <div class="title col-12">
      <p i18n="@@stakeholder.HRBP">stakeholder.HRBP</p>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-8 col-12">
      <generali-select [multiSelection]="true" [group]="createPositionService.stakeholderForm" [searchable]="true"
        bindLabel="fullname" [dropdown]="hrbpUsersList" title="{{ 'stakeholder.HRBP_SEARCH' | translate }}"
        placeholder="{{ 'generic.SEARCH' | translate }}" [control]="createPositionService.formCl.HRBP"
        [labelNone]="true" (onChangeValue)="onSearchHRBP($event)" bindEmail="email">
      </generali-select>
    </div>
    <div class="subtitle">
      <p class="mt-4" i18n="@@stakeholder.HRBP_SELECTED">stakeholder.HRBP_SELECTED</p>
    </div>
    <div *ngIf="createPositionService.hrbps.controls.length; else noData" class="d-flex flex-wrap chip-container">
      <ng-container *ngFor="let item of createPositionService.hrbps.controls; let idx=index">
        <generali-chip class="me-2 mt-1 mb-1" [clearable]="true" [isSelected]="true"
          (onChipExclude)="removeSelectedHRBP(idx)">
          <div class="px-2 py-1">
            {{ item.get('fullname')?.value }}
          </div>
        </generali-chip>
      </ng-container>
    </div>
  </div>

  <div class="divider my-4"></div>

  <!-- LIST EBRM -->
  <ng-container *ngIf="createPositionService.includeRowByPerimeter">
    <div class="row">
      <div class="title col-12">
        <p i18n="@@stakeholder.EBRM">stakeholder.EBRM</p>
      </div>

      <div class="subtitle">
        <p i18n="@@stakeholder.EBRM_SELECTED">stakeholder.EBRM_SELECTED</p>
      </div>
      <div class="d-flex flex-wrap chip-container">
        <ng-container *ngIf="createPositionService.jobPosition?.ebrms?.length; else noEBRM">
          <generali-chip *ngFor="let item of createPositionService.jobPosition?.ebrms" class="me-2 mt-1 mb-1"
            labelItem="{{ item.forename }} {{ item.surname }}">
          </generali-chip>
        </ng-container>
        <ng-template #noEBRM>
          <p class="no-data" i18n="@@stakeholder.NO_EBRM_DATA">stakeholder.NO_EBRM_DATA</p>
        </ng-template>
      </div>
    </div>
    <div class="divider my-4"></div>
  </ng-container>


  <div class="row">
    <div class="title col-12">
      <p i18n="@@stakeholder.LINE_MANAGER">stakeholder.LINE_MANAGER</p>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-8 col-12">
      <generali-select [multiSelection]="true" [group]="createPositionService.stakeholderForm" [searchable]="true"
        bindLabel="fullname" [dropdown]="lineManagerList" title="{{ 'stakeholder.LINE_MANAGER_SEARCH' | translate }}"
        (onChangeValue)="onSearchLineManager($event)" [control]="createPositionService.formCl.LINE_MANAGER"
        [labelNone]="true" placeholder="{{ 'generic.SEARCH' | translate }}" bindEmail="email">
      </generali-select>
    </div>
    <div class="subtitle">
      <p class="mt-4" i18n="@@stakeholder.LINE_MANAGER_SELECTED">stakeholder.LINE_MANAGER_SELECTED</p>
    </div>
    <div *ngIf="createPositionService.lineManagers; else noData" class="d-flex flex-wrap chip-container mb-3">
      <ng-container *ngFor="let item of createPositionService.lineManagers.controls; let idx=index">
        <generali-chip class="me-2 mt-1 mb-1" [clearable]="true" [isSelected]="true"
          (onChipExclude)="removeSelectedLineManager(idx)">
          <div class="px-2 py-1">
            {{ item.get('fullname')?.value }}
          </div>
        </generali-chip>
      </ng-container>
    </div>

    <div class="manager-txt col-lg-6 col-md-8 col-sm-10 col-12">
      <p i18n="@@stakeholder.LINE_MANAGER_DESC">stakeholder.LINE_MANAGER_DESC</p>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4 col-sm-6 col-12">
      <generali-input class="m-2" titleLabel="{{ 'stakeholder.COMPETENCE1' | translate }}"
        [inputControl]="createPositionService.competence1"
        placeholder="{{ 'stakeholder.pl.COMPETENCE' | translate }}"></generali-input>
    </div>
    <div class="col-lg-4 col-sm-6 col-12">
      <generali-input class="m-2" titleLabel="{{ 'stakeholder.COMPETENCE2' | translate }}"
        [inputControl]="createPositionService.competence2"
        placeholder="{{ 'stakeholder.pl.COMPETENCE' | translate }}"></generali-input>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4 col-sm-6 col-12">
      <generali-input class="m-2" titleLabel="{{ 'stakeholder.COMPETENCE3' | translate }}"
        placeholder="{{ 'stakeholder.pl.COMPETENCE' | translate }}"
        [inputControl]="createPositionService.competence3"></generali-input>
    </div>
    <div class="col-lg-4 col-sm-6 col-12">
      <generali-input class="m-2" titleLabel="{{ 'stakeholder.COMPETENCE4' | translate }}"
        placeholder="{{ 'stakeholder.pl.COMPETENCE' | translate }}"
        [inputControl]="createPositionService.competence4"></generali-input>
    </div>

  </div>

  <div class="divider my-4"></div>

  <div class="row">
    <div class="title d-flex flex-wrap col-12">
      <p i18n="@@stakeholder.HEADHUNTER">stakeholder.HEADHUNTER</p>
      <generali-switcher class="mx-4" [position]="createPositionService.headHunterEnabled"
        (onSwitch)="onheadHunterSwitch($event)"></generali-switcher>
      <p class="title-desc" i18n="@@stakeholder.HEADHUNTER_DESC">stakeholder.HEADHUNTER_DESC</p>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-8 col-12 mb-5">
      <generali-input class="m-2" titleLabel="{{ 'stakeholder.HEADHUNTER_SEARCH' | translate }}"
        placeholder="{{ 'stakeholder.pl.HEADHUNTER' | translate }}"
        [inputControl]="createPositionService.headHunter"></generali-input>
    </div>
  </div>
</form>

<ng-template #noData>
  <p class="no-data" i18n="@@generic.NO_DATA">generic.NO_DATA</p>
</ng-template>