import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { conforms } from "lodash";

@Component({
  selector: "generali-drawer-for-filter",
  templateUrl: "./generali-drawer-for-filter.component.html",
  styleUrls: ["./generali-drawer-for-filter.component.scss"]
})
export class GeneraliDrawerForFilterComponent {
  @Input() drawerTitle?: string = "../../assets/scss/img/filter-big.svg";
  @Input() drawerImg?: string;
  @Input() showDrawer?: boolean;

  @Input() group!: FormGroup;

  @Output() onClosingDrawer = new EventEmitter();
  @Output() onApply = new EventEmitter();
  @Output() onReset = new EventEmitter();

  protected onApplyFilter() {
    const filters: Record<string, unknown> = {};

    this.assign({ target: filters, value: "jobPositionStatus" });
    this.assign({ target: filters, value: "fromDate" });
    this.assign({ target: filters, value: "toDate" });
    this.assign({ target: filters, value: "id" });
    this.assign({ target: filters, value: "companyId" });
    this.assign({ target: filters, value: "companies" });
    this.assign({ target: filters, value: "hrbpId" });
    this.assign({ target: filters, value: "hrbps" });
    this.assign({ target: filters, value: "locationId" });
    this.assign({ target: filters, value: "locations" });
    this.assign({ target: filters, value: "jobFieldId" });
    this.assign({ target: filters, value: "jobFields" });
    this.assign({ target: filters, value: "contractTypeId" });
    this.assign({ target: filters, value: "contractTypes" });
    this.assign({ target: filters, value: "sortingField" });
    this.assign({ target: filters, value: "sortingDesc" });
    this.assign({ target: filters, value: "jobPositionId" });
    this.assign({ target: filters, value: "keyword" });
    this.assign({ target: filters, value: "jobApplicationStatus" });
    this.assign({ target: filters, value: "reassigned" });
    this.assign({ target: filters, value: "referral" });
    this.assign({ target: filters, value: "talent" });
    this.assign({ target: filters, value: "newItalian" });
    this.assign({ target: filters, value: "legallyProtected" });
    this.assign({ target: filters, value: "feedbackMailSent" });
    this.assign({ target: filters, value: "ageRange" });
    this.assign({ target: filters, value: "gender" });
    filters["jobApplicationStatus"] = [...new Set(filters["jobApplicationStatus"] as string[])];
    this.onApply.emit(filters);
  }

  private assign(params: { target: Record<string, unknown>, value: string }) {
    if (!this.group) {
      return;
    }
    const source = this.group.value as unknown;
    const { target, value } = params;

    if (typeof source == "object" && source && value in source && value.length) {
      Object.assign(target, { [value]: (source as Record<string, unknown>)[value] });
    }
  }

  protected onResetFilters() {
    this.onClosingDrawerTab();
    this.onReset.emit();
  }
  protected onClosingDrawerTab() {
    this.onClosingDrawer.emit();
  }
}
