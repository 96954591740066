import { Injectable, inject } from "@angular/core";
import { TokenStorageService } from "./token-storage.service";
import { ATSAuthScopes, SimpleUser } from "applicanttrackingsystem-cl";
import { FullName } from "../pages/homepage/homepage.component";
import { BaseDataService } from "src/ancestors/base-data.service";

@Injectable()
export class UserService extends BaseDataService {
    private tkStorage = inject(TokenStorageService);
    constructor() {
      super();
    }


    public get user(): (SimpleUser & FullName) {
        const USER = this.tkStorage.retriveToken(this.tokenKey);
        Object.assign(USER.jwtPayload.user, { fullname: this.userFullName });
        return USER.jwtPayload.user;
    }

    public get isEBRM(): boolean {
        return this.tkStorage.retriveToken(this.tokenKey).jwtPayload?.auths.includes(ATSAuthScopes.EBRM);
    }

    public get isHRBP(): boolean {
        return this.tkStorage.retriveToken(this.tokenKey).jwtPayload?.auths.includes(ATSAuthScopes.HRBP);
    }

    public get isUnprofiled(): boolean {
        return this.tkStorage.retriveToken(this.tokenKey).jwtPayload?.auths.includes(ATSAuthScopes.UNPROFILED_USER as string);
    }

    public get userFullName(): string {
        return `${this.tkStorage.retriveToken(this.tokenKey).jwtPayload.user.forename} ${this.tkStorage.retriveToken(this.tokenKey).jwtPayload.user.surname}`;
    }
}
