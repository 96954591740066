import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgSelectModule } from "@ng-select/ng-select";
import { GeneraliSharedComponentsV1Module } from "generali-shared-components/dist";
import { NgbDatepickerModule } from "@ng-bootstrap/ng-bootstrap";
import { GeneraliDatapickerComponent } from "./components/generali-datapicker/generali-datapicker.component";
import { GenericModalComponent } from "./components/confirm-cancel-modal/confirm-cancel-modal.component";
import { FilterPipe, StatusPipe, BoolPipe, TranslatePipe, ElapsedPipe, OrderPipe, InterviewPipe, ParseIntPipe } from "./pipe/translation.filter.pipe";
import { GeneraliRangeDatepickerComponent } from "./components/generali-range-datepicker/generali-range-datepicker.component";
import { LoaderDirective } from "./directive/loader.directive";
import { ActionsDropdownComponent } from "./components/actions-dropdown/actions-dropdown.component";
import { CardApplicationDetailComponent } from "./components/card-application-detail/card-application-detail.component";
import { StarRatingComponent } from "./components/star/star-rating.component";
import { PermissionDirective } from "./directive/permission.directive";
import { GeneraliTableComponent } from "./components/generali-table/generali-table.component";
import { JobApplicationFilterService } from "./service/filter/job-application-filter.service";
import { JobPositionFilterService } from "./service/filter/job-position-filter.service";
import { JobPositionOrderService } from "./service/order/job-position-order.service";
import { JobApplicationOrderService } from "./service/order/job-application-order.service";
import { SearchTitleComponent } from "./components/search-title/search-title.component";
import { GeneraliChipForFilterComponent } from "./components/generali-chip-for-filter/generali-chip-for-filter.component";
import { GeneraliSelectForFilterComponent } from "./components/generali-select-for-filter/generali-select-for-filter.component";
import { GeneraliRadioForFilterComponent } from "./components/generali-radio-for-filter/generali-radio-for-filter.component";
import { GeneraliExpositionChipForFilterComponent } from "./components/generali-exposition-chip-for-filter/generali-exposition-chip-for-filter.component";
import { ChipValuePipe } from "./pipe/chip-value.pipe";
import { ConfirmCloseModalTxtPipe } from "./pipe/modal.pipe";
import { GeneraliDrawerForFilterComponent } from "./components/generali-drawer-for-filter/generali-drawer-for-filter.component";
import { GroupedTestInstanceMaxDate } from "./pipe/last-test-instace-date.pipe";
import { AnalyticsService } from "./service/analytics.service";
import { WindowRef } from "./service/windowRef.service";


@NgModule({
  declarations: [
    GeneraliDatapickerComponent,
    GeneraliRangeDatepickerComponent,
    GenericModalComponent,
    FilterPipe,
    StatusPipe,
    BoolPipe,
    TranslatePipe,
    ElapsedPipe,
    LoaderDirective,
    ActionsDropdownComponent,
    CardApplicationDetailComponent,
    OrderPipe,
    InterviewPipe,
    ParseIntPipe,
    StarRatingComponent,
    PermissionDirective,
    GeneraliTableComponent,
    SearchTitleComponent,
    GeneraliChipForFilterComponent,
    GeneraliSelectForFilterComponent,
    GeneraliRadioForFilterComponent,
    GeneraliExpositionChipForFilterComponent,
    GeneraliDrawerForFilterComponent,
    ChipValuePipe,
    ConfirmCloseModalTxtPipe,
    GroupedTestInstanceMaxDate
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    RouterModule,
    ReactiveFormsModule,
    GeneraliSharedComponentsV1Module,
    NgbDatepickerModule
  ],
  exports: [
    GeneraliSharedComponentsV1Module,
    GeneraliDatapickerComponent,
    GeneraliRangeDatepickerComponent,
    GenericModalComponent,
    FilterPipe,
    StatusPipe,
    BoolPipe,
    TranslatePipe,
    ElapsedPipe,
    LoaderDirective,
    ActionsDropdownComponent,
    CardApplicationDetailComponent,
    // CountryCodePipe,
    OrderPipe,
    InterviewPipe,
    ParseIntPipe,
    StarRatingComponent,
    PermissionDirective,
    GeneraliTableComponent,
    SearchTitleComponent,
    GeneraliChipForFilterComponent,
    GeneraliSelectForFilterComponent,
    GeneraliRadioForFilterComponent,
    GeneraliExpositionChipForFilterComponent,
    GeneraliDrawerForFilterComponent,
    ChipValuePipe,
    ConfirmCloseModalTxtPipe,
    GroupedTestInstanceMaxDate
  ],
  providers: [JobApplicationFilterService, JobPositionFilterService, JobPositionOrderService, JobApplicationOrderService, AnalyticsService, WindowRef]
})
export class SharedModule { }
