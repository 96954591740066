import { Component, HostListener, Inject, inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";

import { BaseComponent } from "src/ancestors/base/base.component";
import { GeneraliNav } from "generali-shared-components/dist";
import { FAQEntry, ATSLoginInfo } from "applicanttrackingsystem-cl";
import { UrlCollection } from "./app-routing.module";
import { TranslateService } from "./shared/util/translate.service";
import { DOCUMENT } from "@angular/common";
import { EnvironmentLoaderService, GeneralConfig } from "src/ancestors/env-config.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
  protected fb: FormBuilder = inject(FormBuilder);
  protected translate = inject(TranslateService);
  protected envConfig: EnvironmentLoaderService = inject(EnvironmentLoaderService);
  protected config: GeneralConfig = this.envConfig.getEnvConfig();

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {
    super();
    this.logger.setCaller("AppComponent");
    if (!window.location.href.includes(UrlCollection.generalHomepage) && !window.location.href.includes("localLogin")) {
      sessionStorage.setItem("originalRequestedUrl", window.location.href);
    }

    // Creo e carico lo script di Google Tag Manager per Google Analytics
    const script = this.document.createElement("script");
    script.innerHTML = `(function(
              w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${this.envConfig.getEnvConfig().googleTagManagerCode}')`;
    this.document.head.insertBefore(script, null);

  }

  protected activeFaq: string = UrlCollection.faq;
  protected mobileMode!: boolean;
  protected navbarTabs: GeneraliNav.NavTabs[] = [
    { iconPath: "assets/img/icons/homepage.svg", tabName: $localize`:@@home.HOME:home.HOME`, path: UrlCollection.generalHomepage },
    { iconPath: "assets/img/icons/search.svg", tabName: $localize`:@@home.SEARCH:home.SEARCH`, path: UrlCollection.search }
  ];
  protected navbarTabsMobile: GeneraliNav.NavTabs[] = [
    { iconPath: "assets/img/icons/homepage.svg", tabName: $localize`:@@home.HOME:home.HOME`, path: UrlCollection.generalHomepage },
    { iconPath: "assets/img/icons/search.svg", tabName: $localize`:@@home.SEARCH:home.SEARCH`, path: UrlCollection.search },
    { iconPath: "assets/img/icons/search.svg", tabName: $localize`:@@home.HELPER:home.HELPER`, path: UrlCollection.faq }
  ];
  protected helpers?: FAQEntry[];
  protected showUserOption?: boolean;

  // eslint-disable-next-line @typescript-eslint/require-await
  override async ngOnInit(): Promise<void> {
    this.mobileMode = window.innerWidth < 576;
    const loginResposnse: ATSLoginInfo = this.tStorage.retriveToken(this.tokenKey);

    /**
     * 
     * Se esiste un token ed è ancora valido schedula il rinnovo prima della scadenza
    */
    if (!this.appService.isEmpty(loginResposnse) && this.tkManager.checkTokenValidity(loginResposnse)) {
      this.tkManager.refreshTokenScheduler(loginResposnse);
    }

    if (!sessionStorage.getItem("originalRequestedUrl")) {
      await this.router.navigate([UrlCollection.redirectTo]);
    }

    sessionStorage.removeItem("originalRequestedUrl");
  }

  protected toggleUserOption() {
    if (this.showUserOption) {
      this.showUserOption = false;
    }

  }

  protected async onHelperSelection() {
    await this.router.navigate([UrlCollection.faq]);
  }


  /** Questa funzione cambia la lingua a runtime, e fa il reload della pagina */
  private changeLang(locale: string) {

    /** Salva la lingua nel localStorage */
    localStorage.setItem("locale", locale);

    /** Fa il reload della pagina, per caricare il file json corretto */
    location.reload();
  }

  /** Setta o meno la mobileMode per rimuovere secondo necessità il padding dal main */
  private resizeTimeout?: ReturnType<typeof setTimeout>;
  @HostListener("window:resize", ["$event"])
  onResize(e: any) {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout(() => {
      // eslint-disable-next-line
      if (e?.target.innerWidth < 576) {
        this.mobileMode = true;
      } else {
        this.mobileMode = false;
      }
    }, 600);
  }

  protected async onNavTabClick(path: string) {
    await this.router.navigate([path]);
  }

  protected openUserOption() {
    this.showUserOption = !this.showUserOption;
  }

  protected async doLogout() {
    this.showUserOption = false;
    try {
      const redirectToLogin: string = sessionStorage.getItem("SSO_LOGIN") ? "Saml-sso/logoutSaml" : this.env.loginUrl;
      // cancello la variabile che mi dice se ho fatto il login con l'sso 
      sessionStorage.removeItem("SSO_LOGIN");
      sessionStorage.removeItem("searchedText");
      await this.backendService.logout(redirectToLogin);
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }
  }

  protected async redirectToHomepage() {
    await this.router.navigate([UrlCollection.generalHomepage]);
  }
}
