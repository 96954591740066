import { Injectable, inject } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { forOwn, get, isArray, isEmpty, isEqual, isEqualWith, isObject, isString, map, pull } from "lodash";


@Injectable({ providedIn: "root" })
export class FormService {
  private fb = inject(FormBuilder);

  /**
   * Estrae del form passato il valore inserito e lo ritorna
   * Se nessun valore è stato inserito ritorna undefined a meno che non venga passato un valore di ritorno di default
   * Possibilità di eseguire una formattazione del valore di ritorno
   * 
   * @param control Nome del FormControl
   * @param formatter funzione che formatta il risultato prima di essere ritornato
   * @param defaultValue valore di default in caso non ci sia un valore
   * @returns T
   */
  public getSingleValueOutOfControl<T>(params: { control: FormControl, formatter?: (x: any) => unknown, defaultValue?: T }): T {
    const value = params.control.value as T;
    let res;

    if (value === undefined || value === null) {
      return params.defaultValue as T;
    }

    if (isString(value)) {
      res = value.trim();
    } else if (typeof value === "boolean") {
      res = value;
    } else {
      res = JSON.stringify(value);
    }

    if (params.formatter) {
      res = params.formatter(res);
    }

    return res as T;
  }
  /**
   * Estrae del form passato il valore inserito e lo ritorna
   * Se nessun valore è stato inserito ritorna undefined a meno che non venga passato un valore di ritorno di default
   * Possibilità di eseguire una formattazione del valore di ritorno
   * 
   * @param form FormArray
   * @param bindValue Opzionale, da usare per estrarre un determinato valore dal FormArray
   * @param formatter funzione che formatta il risultato prima di essere ritornato
   * @param defaultValue valore di default in caso non ci sia un valore
   * @returns T
   */

  public getSingleValueOutOfArray<T>(form: FormArray, bindValue?: string, formatter?: (x: unknown) => unknown, defaultValue?: T): T | undefined {
    const head = form.controls.at(0) as FormGroup;
    let res;

    if (!head) {
      return defaultValue;
    }

    if (bindValue) {
      res = head?.get(bindValue)?.value as T;
    } else {
      res = head?.value as T;
    }

    if (formatter) {
      return formatter(res) as T;
    }

    return res;
  }

  /**
   * @param formGroup Nome del FormGroup
   * @param formArray Nome del FormArray, se viene passato un array di stringhe verrà letto come path per arrivare ad un controller annidato 
   * @param bindValue Opzionale, da usare per estrarre un determinato valore dal FormArray
   * @returns T[]
   */
  public getMultiValuesOutOfArray<T>(form: FormArray, bindValue?: string): T | undefined {
    let res;

    if (isEmpty(form.controls)) {
      return undefined;
    }

    if (bindValue) {
      res = form.controls.map(control => control.get(bindValue)?.value as T).filter(Boolean);
    } else {
      res = form.controls.map(control => control.value as T).filter(Boolean);
    }

    return res as T;
  }

  /**
   * @param formGroup 
   * @param formControl 
   * @param preselectedValue Valore settato
   */
  public setValueOnControl(formControl: FormControl, preselectedValue?: unknown) {
    const FORM = formControl;

    if (!preselectedValue) {
      return;
    }

    if (isEqual(FORM.value, preselectedValue)) {
      return;
    }

    FORM.setValue(preselectedValue);
  }

  /**
   * @param formGroup 
   * @param formControl 
   * @param preselectedValue Oggetto tipizzato(T) che verrà preselezionato nella select
   */
  public setValueOnArray<T>(formGroup: FormArray, preselectedValue: T, refresh?: boolean) {
    const FORM = formGroup;

    if (refresh) {
      FORM.clear();
    }
    /** Crea il formGroup di ritorno */
    if (JSON.stringify(preselectedValue) === "{}" || !preselectedValue) {
      return;
    }
    if (Array.isArray(preselectedValue)) {
      return;
    }

    const fg: FormGroup = this.fb.group({});
    for (const value in preselectedValue) {
      /** Per ogni campo passato in input crea un form control da aggiungere al form group */
      const fc: FormControl = this.fb.control({});
      fc.setValue(preselectedValue[value]);
      fg.addControl(value, fc);
    }

    const idx = FORM.controls.findIndex(el => isEqual(el.value, fg.value));
    if (idx === -1) {
      /** Pusha formGroup nell'array indicato */
      FORM.push(fg);
    }
  }

  /**
   * @param formGroup 
   * @param formArray 
   * @param preselectedValue Array di valori tipizzati(T) che verranno preselezionati nella select
   */
  public setMultiValuesOnArray<T>(formGroup: FormArray, preselectedValue: T, keyValue?: string) {
    const FORM = formGroup;
    if (!preselectedValue) {
      return;
    }
    if (!Array.isArray(preselectedValue)) {
      return;
    }

    for (const item of preselectedValue) {
      /** Crea il formGroup di ritorno */
      const fg: FormGroup = this.fb.group({});
      for (const value in item) {
        /** Per ogni campo passato in input crea un form control da aggiungere al form group */
        const fc: FormControl = this.fb.control({});
        fc.setValue(item[value]); // eslint-disable-line
        fg.addControl(value, fc);
      }

      /** Cerca se elemento da aggiungere esiste gia nella lista */
      let idx;
      if (keyValue) {
        idx = FORM.controls.findIndex(el => isEqual(el.get(keyValue)?.value, fg.get(keyValue)?.value));
      } else {
        idx = FORM.controls.findIndex(el => isEqual(el.value, fg.value));
      }

      if (idx === -1) {
        /** Pusha formGroup nell'array indicato */
        FORM.push(fg);
      }
    }
  }

  /**
   * Esegue la comparazione tra valori e ritorna true se sono uguali altrimenti false.
   * Da usare quando da un FormArray si cerca di comparare un array di valori
   * 
   * @param formGroup
   * @param target - Path per raggiungere il paramentro in questione o semplice nome del paramentro
   * @param compareObj - Oggetto da comparare
   * @param targetObj - Path per raggiungere il paramentro in questione o semplice nome del paramentro
   * @param bindValue - Eventuale nome del parametro di riferimento
   * 
   * @returns { boolean }
   */
  public compareMultiValues(params: { control: FormArray, compared?: object, target: string | string[], bindValue?: string }): boolean {
    const VALUE_1 = this.getMultiValuesOutOfArray<string[]>(params.control, params.bindValue);
    const VALUE_2 = this.getter<string[]>({ obj: params.compared, target: params.target, bindValue: params.bindValue });

    return isEqual(VALUE_1, VALUE_2);
  }

  /**
   * Esegue la comparazione tra valori e ritorna true se sono uguali altrimenti false.
   * Da usare quando da un FormArray si cerca di comparare un singolo valore (ESCLUSIVAMENTE il primo)
   * 
   * @param formGroup
   * @param target - Path per raggiungere il paramentro in questione o semplice nome del paramentro
   * @param compareObj - Oggetto da comparare
   * @param targetObj - Path per raggiungere il paramentro in questione o semplice nome del paramentro
   * @param bindValue - Eventuale nome del parametro di riferimento
   * 
   * @returns { boolean }
   */
  public compareArraySingleValue(params: { control: FormArray, compared?: object, target: string | string[], bindValue?: string, strictMode?: boolean }): boolean {
    const VALUE_1 = this.getSingleValueOutOfArray<string>(params.control, params.bindValue);
    const VALUE_2 = this.getter<string>({ obj: params.compared, target: params.target });

    const strict = (val1: string | boolean | number, val2: string | number | boolean) => val1 === val2;
    return isEqualWith(VALUE_1, VALUE_2, params.strictMode ? strict : undefined);
  }

  /**
   * Esegue la comparazione tra valori e ritorna true se sono uguali altrimenti false.
   * Da usare quando da un FormControl si cerca di comparare un singolo valore
   * 
   * @param formGroup
   * @param target - Path per raggiungere il paramentro in questione o semplice nome del paramentro
   * @param compareObj - Oggetto da comparare
   * @param targetObj - Path per raggiungere il paramentro in questione o semplice nome del paramentro
   * 
   * @returns { boolean }
   */
  public compareControlSingleValue(params: { control: FormControl, compared?: object, target: string | string[], strictMode?: boolean }): boolean {
    let stripValue;
    const VALUE_1 = this.getSingleValueOutOfControl<string>({ control: params.control });

    if (VALUE_1?.includes("€")) {
      stripValue = parseFloat(VALUE_1.replace("€", "").replace(/\./g, "").trim());
    } else {
      stripValue = VALUE_1;
    }
    const VALUE_2 = this.getter<string>({ obj: params.compared, target: params.target });

    const strict = (val1: string | boolean | number, val2: string | number | boolean) => val1 === val2;
    const notStrict = (val1: string | boolean | number, val2: string | number | boolean) => val1 == val2;
    return isEqualWith(stripValue, VALUE_2, params.strictMode ? strict : notStrict);
  }

  /**
   * @param obj 
   * @param target - Path per raggiungere il paramentro in questione o semplice nome del paramentro
   * @param bindValue - Eventuale nome del parametro di riferimento
   * @returns { T | undefined }
   */
  private getter<T>(p: { obj?: object, target: string | string[], bindValue?: string }): T | undefined {
    const GET = get(p.obj, p.target) as T || undefined;
    let res;

    if ((isObject(GET) || isArray(GET)) && isEmpty(GET)) {
      return undefined;
    }
    if (!GET) {
      return undefined;
    }

    if (p.bindValue) {
      res = map(GET, p.bindValue) as T;
    }

    return res ? res : GET;
  }

  /**
   * Esegue la comparazione tra 2 date ritorna false se ci sono dei cambiamenti altrimenti true
   * 
   * @param params 
   * @param formGroup 
   * @param target  - Path per raggiungere il paramentro in questione o semplice nome del paramentro
   * @param compareObj - Oggetto da comparare
   * @param targetObj  - Path per raggiungere il paramentro in questione o semplice nome del paramentro
   * @returns { boolean }
   */
  public compareDate(params: { date?: Date, compareDate?: Date }): boolean {
    /** Date non settata ne nel form ne nel db */
    if (!params.date && !params.compareDate) {
      return true;
    }

    /** Data non settata nel form ma settata nel db */
    if (!params.date && params.compareDate) {
      return false;
    }


    /** Data settata nel form ma non settata nel db */
    if (params.date && !params.compareDate) {
      return false;
    }

    if (params.date && params.compareDate) {
      const [dd, M, yyyy] = [params.date.getDate(), params.date.getMonth() + 1, params.date.getFullYear()];

      const compare = new Date(params.compareDate);
      const [_dd, _M, _yyyy] = [compare.getDate(), compare.getMonth() + 1, compare.getFullYear()];


      if (!isEqual(dd, _dd)) {
        return false;
      }
      if (!isEqual(M, _M)) {
        return false;
      }
      if (!isEqual(yyyy, _yyyy)) {
        return false;
      }

      return true;
    }

    return false;
  }

  /**
   * Esegue la comparazione tra 2 liste  e in caso ci siano valori null o vuoti questi vengono ignorati
   * 
   * @param list lista 1
   * @param compareObj lista 2
   * @returns { boolean }
   */
  public compareList(list: any[], compareObj?: any[]): boolean {

    pull(list, null);
    pull(list, "");

    if (isEqual(compareObj ?? [], list)) {
      return true;
    }
    return false;
  }
}
