import { Component, EventEmitter, Input, Output } from "@angular/core";
import { JobPositionsWithCountersResponse } from "applicanttrackingsystem-cl";

@Component({
  selector: "generali-table",
  templateUrl: "./generali-table.component.html",
  styleUrls: ["./generali-table.component.scss"]
})
export class GeneraliTableComponent {

  @Input() jobPosition?: JobPositionsWithCountersResponse;
  @Input() jobPositionList?: JobPositionsWithCountersResponse[];
  @Input() multiRow?: boolean;
  @Input() isSelectable?: boolean;
  @Input() isStripped?: boolean;
  @Input() role: "none" | "pointer" = "none";

  @Output() onClick = new EventEmitter<JobPositionsWithCountersResponse>();


  protected get tableList() {
    if (this.jobPosition) {
      return [this.jobPosition];
    }

    if (this.jobPositionList) {
      return this.jobPositionList;
    }

    return [];
  }

  protected onTableClick(jobPosition: JobPositionsWithCountersResponse) {
    this.onClick.emit(jobPosition);
  }

}
