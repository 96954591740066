import { Pipe, PipeTransform } from "@angular/core";
import { ScreeningTestInstance } from "applicanttrackingsystem-cl";

@Pipe({
  name: "groupedTestInstanceMaxDate"
})
export class GroupedTestInstanceMaxDate implements PipeTransform {

  transform(screeningTestInstance?: ScreeningTestInstance[]) {
    if (!screeningTestInstance) return [];

    const screeningTestInstances: ScreeningTestInstance[] = [];
    // Esegui il raggruppamento in base alle chiave screeningTestType
    const screeningTestInstancesMapped = 
      this.groupBy<ScreeningTestInstance, string>(
        screeningTestInstance,
        (el) => el.screeningTestType!
      );

    for (const [, testInstances] of screeningTestInstancesMapped.entries()) {
      // In caso ci sia un solo valore inserisci quel valore all'interno dell'array
      if (testInstances.length === 1) {
        screeningTestInstances.push(...testInstances);
      } else {
        // Alitrimenti cerca la data maggiore tra i risultati 
        const testInstanceMaxDate = testInstances
          .reduce((maxTestDate, currentTestDate) => {
            const actualMaxDate = new Date(maxTestDate?.takenAt ?? 0);
            const currentDate = new Date(currentTestDate?.takenAt ?? 0);

            // In caso non ci siano test svolti, quindi con parametro takenAt valorizzato, ritorna l'ultimo elemento della lista
            return actualMaxDate > currentDate ? maxTestDate : currentTestDate;
          });

        screeningTestInstances.push(testInstanceMaxDate);
      }
    }

    return screeningTestInstances;
  }

  public groupBy<V, K>(list: V[], keyGetter: (input: V) => K): Map<K, V[]> {
    const map = new Map<K, V[]>();
  
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      
      !collection ? map.set(key, [item]) : collection.push(item);
    });
  
    return map;
  }
}


