import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";

import { JobPosition, JobPositionStatusEnum, JobPositionStatusEnumTypes } from "applicanttrackingsystem-cl";
import { GeneraliModalService, GeneraliStatuTab, GeneraliToastService } from "generali-shared-components/dist";
import { BaseComponent } from "src/ancestors/base/base.component";
import { CreatePositionService } from "./create-position.service";
import { TranslateService } from "src/app/shared/util/translate.service";
import { UrlCollection } from "src/app/app-routing.module";
import { CreatePosition } from "./create-position-routing.module";
import { AnalyticsService, EventNameEnum } from "src/app/shared/service/analytics.service";
import { ConfirmCloseModalInfoInterface } from "src/app/shared/components/confirm-cancel-modal/confirm-cancel-modal.component";

@Component({
  selector: "app-create-position",
  templateUrl: "./create-position.component.html",
  styleUrls: ["./create-position.component.scss"],
  providers: [CreatePositionService, AnalyticsService]
})

export class CreatePositionComponent extends BaseComponent implements OnInit, OnDestroy {
  constructor() {
    super();
    this.analyticsService.sendVirtualPageViewEvent(this.router.url, this.pageTitle);
  }

  protected createPositionService = inject(CreatePositionService);
  protected modalService = inject(GeneraliModalService);
  protected toastService = inject(GeneraliToastService);
  private translateService = inject(TranslateService);
  private analyticsService = inject(AnalyticsService);

  protected confirmCloseModalInfo: ConfirmCloseModalInfoInterface = {};

  private pageTitle = "Creazione posizione | ATS";

  override async ngOnInit(): Promise<void> {
    this.createPositionService.company.disable();
    this.createPositionService.employmentLevel.disable();

    const JOB_POSITION_CLONE_ID = this.route.snapshot.queryParamMap.get("clone");
    const JOB_POSITION_EDIT_ID = this.route.snapshot.queryParamMap.get("edit");
    const JOB_POSITION_CREATE_ID = this.route.snapshot.queryParamMap.get("create");

    const JOB_POSITION_ID = JOB_POSITION_CLONE_ID ?? JOB_POSITION_EDIT_ID ?? JOB_POSITION_CREATE_ID;
    const hypoteticalProgressivePositionNumber = await this.backendService.hypoteticalProgressiveCode();

    if (!JOB_POSITION_ID) {
      return;
    }

    if (JOB_POSITION_CLONE_ID) {
      this.createPositionService.mode = "clone";
      this.createPositionService.hypoteticalProgressivePositionNumber = hypoteticalProgressivePositionNumber?.generatedCode;
    }
    if (JOB_POSITION_CREATE_ID) {
      this.createPositionService.mode = "create";
      this.createPositionService.hypoteticalProgressivePositionNumber = hypoteticalProgressivePositionNumber?.generatedCode;
    }
    if (JOB_POSITION_EDIT_ID) {
      this.createPositionService.mode = "edit";
    }
    // if (this.createPositionService.jobPosition?.currentStatus != undefined)
    //   this.createPositionService._activePositionStatus = { badgeType: this.createPositionService.jobPosition?.currentStatus };

    this.createPositionService.jobPosition = await this.backendService.getJobPositionById(JOB_POSITION_ID);
    this.createPositionService.hasBeenOpen = await this.backendService.jobPositiondHasEverBeenOpened(JOB_POSITION_ID);

    const { jobPosition } = this.createPositionService;
    this.createPositionService.preselectedExpectedStartDateSelected = jobPosition?.expectedStartDate;
    this.createPositionService.setFormsFromsFields(jobPosition);

    if (jobPosition?.currentStatus && jobPosition?.currentStatus != JobPositionStatusEnumTypes.DRAFT) {
      this.createPositionService.setFormCompiled(true, CreatePosition.channel);
    }

    await this.createPositionService.checkPerimeter();
    if (!this.createPositionService.includeRowByPerimeter) {
      this.createPositionService.setFormCompiled(true, CreatePosition.qEs);
    }

  }

  protected async homeRedirect() {
    await this.router.navigate([""]);
  }

  protected async onNavBack() {
    let jobPositionId = "";
    const JOB_POSITION_CLONE_ID = this.route.snapshot.queryParamMap.get("clone");
    const JOB_POSITION_EDIT_ID = this.route.snapshot.queryParamMap.get("edit");
    const JOB_POSITION_CREATE_ID = this.route.snapshot.queryParamMap.get("create");


    if (JOB_POSITION_CLONE_ID) {
      jobPositionId = JOB_POSITION_CLONE_ID;
    }
    if (JOB_POSITION_EDIT_ID) {
      jobPositionId = JOB_POSITION_EDIT_ID;
    }

    if (JOB_POSITION_CREATE_ID) {
      jobPositionId = JOB_POSITION_CREATE_ID;
    }

    await this.router.navigate([UrlCollection.positionDetail, jobPositionId]);
  }

  /**
   * Ritorna l'idx del processo attivo
   */
  protected getCurrentStepIdx() {
    return this.createPositionService.getActiveStepperIdxBasedOnUrl(this.router.url);
  }

  /**
   * Apertura/chiusura della tab di cambiamento di status
   *
   * Settaggio elementi visualizabili nel drawer
   *
   * Settaggio status attivi/disattivi
   */
  protected onChangeStatus() {
    this.createPositionService.onChangeStatus();
  }

  /**
   * Viene chiamata quando l'utente seleziona il pulsante "Salva"
   */
  protected async onSave() {
    this.createPositionService.onSaveLoading = true;
    /**
     * Controllo se ci sono i parametri minimi per eseguire un salvataggio
    */
    if (!this.createPositionService.canUpdateCreationProcess()) {
      this.modalService.open("mandatory-param-missing");
      this.createPositionService.onSaveLoading = false;
      return;
    }

    this.createPositionService.checkAndSetFormQes();

    /** Controlla se ci sono cambiamenti */
    if (!this.checkIfFormsValuesChange()) {
      this.createPositionService.onSaveLoading = false;
      /** Disabilita il salvataggio se ci si trova nella pagina di pubblicazione della posizione */
      if (this.router.url.includes(CreatePosition.channel))
        this.createPositionService.setFormCompiled(true, CreatePosition.channel);
      return;
    }



    if (!this.createPositionService.jobPosition) {
      this.createPositionService.jobPosition = await this.backendService.jobPositionNew(this.createPositionService.creationProcess);
    } else {
      const jobPosition: JobPosition = this.createPositionService.creationProcess;
      if (await this.createPositionService.checkisFDD()) {
        delete jobPosition.screeningTestCollection;
        delete jobPosition.screeningTestCollectionId;
      }
      this.createPositionService.jobPosition = await this.backendService.jobPositionUpdate(jobPosition);
    }

    /** Controllo se impostato perimetro specifico, se FDD question&screening diventa opzionale */
    await this.createPositionService.checkPerimeter();

    if (this.createPositionService.mode == "edit") {
      if (!this.createPositionService.questionScreeningForm.valid) {
        this.createPositionService.setFormCompiled(false, CreatePosition.qEs);
      } else {
        this.createPositionService.setFormCompiled(true, CreatePosition.qEs);
      }
    }

    /** Se dopo la creazione o l'update non c'è una Job Position vuol dire che si è verificato un errore */
    if (!this.createPositionService.jobPosition) {
      return;
    }

    /** In caso di successo viene mostrato un toast success */
    this.toastService.success(this.translateService.instant("position.ON_SAVE_SUCCESS"));

    /** Recupera il form attuale */
    const formToSave: FormGroup = this.createPositionService.getFormBasedOnUrl(this.router.url);
    /** Setta i campi obbligatori non inseriti come errori */
    this.createPositionService.onSaveSetErrorOnInvalidFields(formToSave);

    /** Se il form non è completo, viene eseguita la navigazione ma la pagina non verrà segnata come completata */
    if (!formToSave.valid) {
      this.createPositionService.setFormCompiled(false);
    } else {
      this.createPositionService.setFormCompiled(true);
    }

    if (this.createPositionService.mode === "clone") {
      await this.router.navigate([], { relativeTo: this.route, queryParams: { clone: this.createPositionService.jobPosition.jobPositionId ?? "" } });
    } else if (this.createPositionService.mode === "edit") {
      await this.router.navigate([], { relativeTo: this.route, queryParams: { edit: this.createPositionService.jobPosition.jobPositionId ?? "" } });
    } else {
      this.createPositionService.mode = "create";
      await this.router.navigate([], { relativeTo: this.route, queryParams: { create: this.createPositionService.jobPosition.jobPositionId ?? "" } });
    }

    this.createPositionService.onSaveLoading = false;
  }



  /**
   * Al click dello step eseguo alcuni controlli:
   *
   * - Accertarsi che il titolo sia stato inserito
   * - Ricercare modifiche che in caso vengono salvate
   *
   * Se tutto vero viene confermata la navigazione verso lo step richiesto
   *
   * Altrimenti si apre una modale di warning per dati mancanti (in caso manchino date) altrimenti non succede nulla
   *
   * @param step
   */
  public async onStepClick(step: GeneraliStatuTab.TabStruct & unknown) {
    // In caso il salvataggio o l'update non siano ancora completati impedisci all'utente di navigare verso un altra tab
    if (this.createPositionService.onSaveLoading) {
      return;
    }

    if (step.isActive) {
      return;
    }


    if (!this.createPositionService.canUpdateCreationProcess()) {
      this.modalService.open("mandatory-param-missing");
      return;
    }

    this.createPositionService.checkAndSetFormQes();

    const { jobPosition } = this.createPositionService;
    if (this.router.url.includes(CreatePosition.channel) && jobPosition?.currentStatus && jobPosition?.currentStatus != JobPositionStatusEnumTypes.DRAFT) {
      this.createPositionService.setFormCompiled(true, CreatePosition.channel);
    }

    if (this.checkIfFormsValuesChange()) {
      await this.onSave();
    }

    if ("indexSelected" in step) {
      this.createPositionService.mobileActiveStep = step.indexSelected as number;
    }

    const jobPositionCloneId = this.route.snapshot.queryParamMap.get("clone");
    const jobPositionEditId = this.route.snapshot.queryParamMap.get("edit");
    const jobPositionCreate = this.route.snapshot.queryParamMap.get("create");

    if (jobPositionCloneId) {
      await this.router.navigate([step.redirect], { relativeTo: this.route, queryParams: { clone: jobPositionCloneId } });
    } else if (jobPositionEditId) {
      await this.router.navigate([step.redirect], { relativeTo: this.route, queryParams: { edit: jobPositionEditId } });
    } else {
      await this.router.navigate([step.redirect], { relativeTo: this.route, queryParams: { create: jobPositionCreate } });
    }
  }

  /**
   * Viene chiamata quando l'utente conferma lo stato di una posizione
   */
  protected async onStatusConfirm(alreadyConfirmedByModal?: boolean) {
    if (this.createPositionService.formsAreInvalids) {
      this.createPositionService.showChangeStatusDrawer = !this.createPositionService.showChangeStatusDrawer;
      return;
    }

    // Se il nuovo status è chiusa (filled o vuota) e non è ancora stata mostrata la modale di conferma, la mostra
    if ((this.createPositionService.statusSelected === JobPositionStatusEnumTypes.CLOSED_FILLED || this.createPositionService.statusSelected === JobPositionStatusEnumTypes.CLOSED_EMPTY) && !alreadyConfirmedByModal) {
      await this.updateConfirmCloseModalInfo();
      this.modalService.open("confirm-closing-position");
      return;
    } else {
      this.modalService.close("confirm-closing-position");
    }


    this.createPositionService.onChangeStatusLoading = true;

    if (!this.createPositionService.jobPosition) {
      this.createPositionService.onChangeStatusLoading = false;
      return;
    }

    this.analyticsService.sendVirtualEvent(EventNameEnum.changeStatusJobPosition, this.router.url, this.pageTitle, { stato: this.getBadgeLabel(this.createPositionService.statusSelected)?.label });

    if (this.createPositionService.statusSelected === JobPositionStatusEnumTypes.TO_BE_APPROVED) {
      this.onChangeStatus();
      this.createPositionService.jobPosition = await this.backendService.requestApprovalToPublish(this.createPositionService.jobPosition.jobPositionId);
      /** Se non c'è una posiione il cambio di status non è andato a buon fine */
      if (!this.createPositionService.jobPosition) {
        return;
      }

      // this.createPositionService.setActivePositionStatus();
      this.toastService.success($localize`:@@position.status.CHANGE:position.status.CHANGE`);
      this.createPositionService.onChangeStatusLoading = false;
      return;
    }

    const POSITION_STATUS = this.createPositionService.statusSelected;

    this.onChangeStatus();
    if (this.usrService.isEBRM) {
      this.createPositionService.jobPosition = await this.backendService.jobPositionChangeStatus({
        jobPositionId: this.createPositionService.jobPosition?.jobPositionId,
        newStatus: POSITION_STATUS,
        comment: this.appService.stringify(this.createPositionService.changeStatusComment.value)
      });
    } else {
      this.createPositionService.jobPosition = await this.backendService.jobPositionChangeStatusRestricted({
        jobPositionId: this.createPositionService.jobPosition?.jobPositionId,
        newStatus: POSITION_STATUS,
        comment: this.appService.stringify(this.createPositionService.changeStatusComment.value)
      });
    }

    /** Se non c'è una posiione il cambio di status non è andato a buon fine */
    if (!this.createPositionService.jobPosition) {
      return;
    }

    /** Aggiorna lo status */
    // this.createPositionService.setActivePositionStatus();

    /** Viene mostrato un messaggio di conferma all'utente */
    this.toastService.success($localize`:@@position.status.CHANGE:position.status.CHANGE`);

    /** Chiude la tab per il cambio di status */
    this.createPositionService.onChangeStatusLoading = false;

    if (this.router.url.includes(CreatePosition.channel))
      this.createPositionService.setFormCompiled(true, CreatePosition.channel);
  }

  protected onCancel() {
    this.onChangeStatus();
  }

  /**
   * Chiusura modale di mancanza parametri obbligatori
   */
  protected onUnderstand() {
    this.modalService.close("mandatory-param-missing");
  }

  /**
   * Apre la pagina di preview
   */
  protected async onPreview() {
    if (!this.createPositionService.canUpdateCreationProcess()) {
      this.modalService.open("mandatory-param-missing");
      return;
    }

    if (!this.createPositionService.jobPosition?.jobPositionId) {
      this.modalService.open("mandatory-param-missing");
      return;
    }

    await this.redirectToPreview();
  }

  private async redirectToPreview() {
    const stepIdx = this.createPositionService.getActiveStepperIdxBasedOnUrl(this.router.url);
    await this.router.navigate([UrlCollection.preview, this.createPositionService.jobPosition?.jobPositionId], {
      queryParams: {
        from: `${CreatePosition.createPosition}/${this.createPositionService.tabItems[stepIdx].redirect ?? ""}`,
        mode: this.createPositionService.mode
      }
    });
  }

  /**
   * Interrompi il processo di creazione del processo senza salvare
   */
  protected async onConfirmLeavingCreationProcessWithoutSave() {
    this.modalService.close("navigate");

    await this.redirectToPreview();
  }

  /**
   * Interrompi il processo di creazione salvando
   */
  protected async onConfirmLeavingCreationProcessSave() {
    /** Chiuso modale */
    this.modalService.close("navigate");

    if (!this.createPositionService.canUpdateCreationProcess()) {
      this.modalService.open("mandatory-param-missing");
      return;
    }

    await this.onSave();
    await this.redirectToPreview();
  }

  /**
   * Chiudi la modale senza abbandonare la pagina in cui ci si trova
   */
  protected async closeNavigateAwayModal() {
    const JOB_POSITION_CLONE_ID = this.route.snapshot.queryParamMap.get("clone");
    const JOB_POSITION_EDIT_ID = this.route.snapshot.queryParamMap.get("edit");
    this.modalService.close("navigate");

    if (JOB_POSITION_CLONE_ID) {
      return this.router.navigate([], { queryParams: { clone: JOB_POSITION_CLONE_ID } });
    }
    if (JOB_POSITION_EDIT_ID) {
      return this.router.navigate([], { queryParams: { edit: JOB_POSITION_EDIT_ID } });
    }

    return this.router.navigate([], { relativeTo: this.route });
  }

  /**
   * Controlla se ci sono stati cambiamenti nei valori dei form presenti nelle diverse pagine dell'applicazione.
   */
  public checkIfFormsValuesChange() {
    return this.createPositionService.formsValuesIsChanged;
  }

  /**
   * JobPositionStatusEnumTypes da usare nell'HTML per recupare le facilmente classi
   */
  protected get positionStatusOptions() {
    return JobPositionStatusEnumTypes;
  }

  protected async goToNextStep() {

    if (!this.createPositionService.canUpdateCreationProcess()) {
      this.modalService.open("mandatory-param-missing");
      return;
    }

    if (this.checkIfFormsValuesChange()) {
      await this.onSave();
    }

    this.createPositionService.checkAndSetFormQes();

    const nextStep = this.createPositionService.getNextStep(
      this.createPositionService.getActiveStepperIdxBasedOnUrl(this.router.url)
    );



    if (this.createPositionService.mode === "clone") {
      await this.router.navigate([nextStep?.redirect], { relativeTo: this.route, queryParams: { clone: this.createPositionService.jobPosition?.jobPositionId } });
    } else if (this.createPositionService.mode === "edit") {
      await this.router.navigate([nextStep?.redirect], { relativeTo: this.route, queryParams: { edit: this.createPositionService.jobPosition?.jobPositionId } });
    } else {
      this.analyticsService.sendVirtualEvent(EventNameEnum.createPositionForward, this.router.url.split("?")[0], this.pageTitle);
      await this.router.navigate([nextStep?.redirect], { relativeTo: this.route, queryParams: { create: this.createPositionService.jobPosition?.jobPositionId } });
    }
  }


  private async setUrlWithQueryParams(mode: string, jobPositionId: string) {
    if (this.createPositionService.mode === "create") {
      if (this.route.snapshot.queryParamMap.get("create") == undefined)
        await this.router.navigateByUrl(`${this.router.url}?create=${jobPositionId}`);
    }
  }

  private setStatusDrawer(status: JobPositionStatusEnum) {
    this.createPositionService.statusSelected = status!;
  }

  private getBadgeLabel(valueApplicationBadge?: string) {
    const status = [
      { applicationBadge: "DRAFT", label: "Bozza" },
      { applicationBadge: "TO_BE_APPROVED", label: "Da approvare" },
      { applicationBadge: "OPEN", label: "Aperta" },
      { applicationBadge: "PUBLISHED", label: "Pubblicata" },
      { applicationBadge: "CLOSED_EMPTY", label: "Chiusa-vuota" },
      { applicationBadge: "CLOSED_FILLED", label: "Chiusa-filled" },
      { applicationBadge: "SUSPENDED", label: "Sospesa" }
    ];


    return status.find((el: any) => {
      if (el?.applicationBadge == valueApplicationBadge) {
        return el;
      }
    });
  }

  /**
   * Chiusura di una modale
   */
  protected onCloseModal(modalId: string) {
    this.modalService.close(modalId);
  }

  /*
  private updateConfirmCloseModalInfo() {
    const newObj: ConfirmCloseModalInfoInterface = {};
    newObj.positionName = `${this.createPositionService.jobPositionWithCounters?.progressiveCodeDecoded ?? ""} ${this.createPositionService.jobPositionWithCounters?.title ?? ""}`;
    newObj.countLongList = this.createPositionService.jobPositionWithCounters?.counters?.longList ?? 0;
    newObj.countShortList = this.createPositionService.jobPositionWithCounters?.counters?.shortList ?? 0;
    newObj.countToEvaluate = this.createPositionService.jobPositionWithCounters?.counters?.toEvaluate ?? 0;

    this.confirmCloseModalInfo = newObj;
  }
  */

  private async updateConfirmCloseModalInfo() {

    const JOB_POSITION_ID = this.route.snapshot.queryParamMap.get("edit");

    if (!JOB_POSITION_ID) {
      return;
    }

    const newObj = await this.backendService.getConfirmClosePositionModalInfo({
      jobPositionId: JOB_POSITION_ID,
      isAcceptingApplication: false,
      jobApplicationIdsToAccept: []
    });

    newObj.isAcceptingApplication = false;

    this.confirmCloseModalInfo = newObj;
  }
}
