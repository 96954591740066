
import { Component, OnInit, inject } from "@angular/core";
import { BaseComponent } from "src/ancestors/base/base.component";
import { CreatePositionService } from "../create-position.service";

@Component({
  selector: "app-job-description",
  templateUrl: "./job-description.component.html",
  styleUrls: ["./job-description.component.scss"]

})

export class JobDescriptionComponent extends BaseComponent implements OnInit {
  protected createPositionService: CreatePositionService = inject(CreatePositionService);

  constructor() {
    super();

  }

  override async ngOnInit() { // eslint-disable-line

    const activeTab = this.createPositionService.getActiveStepperIdxBasedOnUrl(this.router.url);
    this.createPositionService.setActivaStep(activeTab);
    /** Controllo se impostato perimetro specifico, se FDD question&screening diventa opzionale */
    await this.createPositionService.checkPerimeter();
  }
}