import { Injectable } from "@angular/core";

import { ATSLoginInfo } from "applicanttrackingsystem-cl";

@Injectable()
export class TokenStorageService {
  constructor() { }
  /**
   * 
   * @returns token salvato + data scadenza
   */
  public retriveToken(tokenKey: string): ATSLoginInfo {
    const token: string | null = sessionStorage.getItem(tokenKey);
    // const token: string | undefined = document.cookie.split(";").find((item) => item.trim().startsWith(`${tokenKey}=`))?.split(`${tokenKey}=`)[1];
    // if (document.cookie.split(";").some((item) => item.trim().startsWith(`${tokenKey}=`))) {
    if (token) {
      return JSON.parse(token) as ATSLoginInfo;
    }
    return {} as ATSLoginInfo;

  }
  /**
   * @param token
   * Salva il token nello storage
   */
  public saveToken(tokenKey: string, token: ATSLoginInfo): void {

    sessionStorage.setItem(tokenKey, JSON.stringify(token));

    // document.cookie = `${tokenKey}=${JSON.stringify(token)}; samesite=strict; path=/`;

  }

  /**
   * 
   * Elimina il token dallo storage
   */
  public deleteToken(tokenKey: string): void {
    sessionStorage.removeItem(tokenKey);
    // document.cookie = `${tokenKey}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
  }
}



// export type JSONValue = string | number | boolean | { [x: string]: JSONValue } | JSONValue[];
