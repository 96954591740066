import { inject, Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot } from "@angular/router";

import { GeneraliModalService } from "generali-shared-components/dist";
import { UrlCollection } from "../app-routing.module";
import { CreatePositionComponent } from "../pages/create-position/create-position.component";
import { ATSAuthScopes, ATSLoginInfo } from "applicanttrackingsystem-cl";
import { TokenManagerService } from "./token-manager.service";
import { TokenStorageService } from "./token-storage.service";
import { EnvironmentLoaderService, GeneralConfig } from "src/ancestors/env-config.service";
import { BackendService } from "./backend-api.service";


@Injectable({ providedIn: "root" })
export class AbortPositionCreationGuard implements CanActivate {
  private envConfig: EnvironmentLoaderService = inject(EnvironmentLoaderService);
  private tkManager: TokenManagerService = inject(TokenManagerService);
  private tStorage: TokenStorageService = inject(TokenStorageService);
  private env: GeneralConfig = this.envConfig.getEnvConfig();
  private router: Router = inject(Router);
  private backendService: BackendService = inject(BackendService);


  constructor() { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const ROUTE_EDIT = "?edit=";
    const tokenExist: ATSLoginInfo = this.tStorage.retriveToken(this.env.localTokenKey);
    const tokenIsValid: boolean = this.tkManager.checkTokenValidity(tokenExist);

    /**
   * 
   * Se esiste un token di autenticazione valido verrà permesso di eseguire l'accesso
   */
    if (tokenExist && tokenIsValid) {
      /** Controllo necessario per bloccare ai line manager l'accesso in tutte le pagine esclusa quella del candidato */
      if (state.url.includes(ROUTE_EDIT)) {
        const jobPositionEditalbe = await this.backendService.jobPositionEditable(route.queryParams["edit"] as string);
        if (!jobPositionEditalbe) {
          await this.router.navigateByUrl("homepage");
          return false;
        }
      }
    }

    return true;

  }
}

