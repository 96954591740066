<generali-accordion [accordionName]="accordionName" translate>
  <ng-container *ngIf="type !== 'gender'; else genderRadio">
    <div class="my-2">
      <generali-radio [bindName]="type" [isSelected]="!selectedValue" (onSelectRadio)="onSelection()">
        <p class="mb-0 ms-2">{{ "generic.ALL" | translate }}</p>
      </generali-radio>
    </div>
    <div class="my-2">
      <generali-radio [bindName]="type" [isSelected]="selectedValue === true" (onSelectRadio)="onSelection(true)">
        <p class="mb-0 ms-2">{{ "generic.YES" | translate }}</p>
      </generali-radio>
    </div>
    <div class="my-2">
      <generali-radio [bindName]="type" [isSelected]="selectedValue === false" (onSelectRadio)="onSelection(false)">
        <p class="mb-0 ms-2">{{ "generic.NO" | translate }}</p>
      </generali-radio>
    </div>
  </ng-container>


  <ng-template #genderRadio>
    <div class="my-2">
      <generali-radio [bindName]="type" [isSelected]="!selectedValue" (onSelectRadio)="onSelection()">
        <p class="mb-0 ms-2">{{ "generic.ALL" | translate }}</p>
      </generali-radio>
    </div>
    <div class="my-2">
      <generali-radio [bindName]="type" [isSelected]="selectedValue === 'M'" (onSelectRadio)="onSelection('M')">
        <p class="mb-0 ms-2">{{ "generic.MAN" | translate }}</p>
      </generali-radio>
    </div>
    <div class="my-2">
      <generali-radio [bindName]="type" [isSelected]="selectedValue === 'F'" (onSelectRadio)="onSelection('F')">
        <p class="mb-0 ms-2">{{ "generic.WOMAN" | translate }}</p>
      </generali-radio>
    </div>
  </ng-template>
</generali-accordion>