import { Component, inject, OnInit } from "@angular/core";

import { CompanyPerimeter, SimpleUser } from "applicanttrackingsystem-cl";
import { BaseComponent } from "src/ancestors/base/base.component";
import { CreatePositionService } from "../create-position.service";
import { FullName } from "../../homepage/homepage.component";
import { UserService } from "src/app/global-service/user.service";
import { FormService } from "src/app/shared/util/form.service";
import { FormCommonClasses } from "src/app/shared/util/form.classes";

@Component({
  selector: "app-stakeholder",
  templateUrl: "./stakeholder.component.html",
  styleUrls: ["./stakeholder.component.scss"]
})
export class StakeholderComponent extends BaseComponent implements OnInit {
  protected createPositionService = inject(CreatePositionService);
  protected userService = inject(UserService);
  protected fs = inject(FormService);

  constructor() {
    super();
  }

  protected hrbpUsersList: (SimpleUser & FullName)[] = [];
  protected lineManagerList: (SimpleUser & FullName)[] = [];

  override async ngOnInit(): Promise<void> {
    const activeTab = this.createPositionService.getActiveStepperIdxBasedOnUrl(this.router.url);
    this.createPositionService.setActivaStep(activeTab);

    /** Controllo se impostato perimetro specifico, se FDD question&screening diventa opzionale */
    await this.createPositionService.checkPerimeter();


    const [hrbpUsersList, lineManagerList] = await Promise.all([
      this.backendService.hrbpUsersList(),
      this.backendService.lineManagerList()
    ]);
    this.hrbpUsersList = hrbpUsersList;
    this.lineManagerList = lineManagerList;

    this.hrbpUsersList.forEach(user => user.fullname = `${user.forename} ${user.surname}`);
    this.lineManagerList.forEach(user => user.fullname = `${user.forename} ${user.surname}`);

    if (this.userService.isHRBP && (!this.createPositionService.mode || this.createPositionService.mode === "create")) {
      const loggedUserPreselected = this.fs.getMultiValuesOutOfArray<string[]>(this.createPositionService.hrbps, FormCommonClasses.USER_ID);

      /**
       * 
       * Se l'utente HRBP non è ancora preselezionato setta come selezionato
       */
      if (!loggedUserPreselected?.includes(this.userService.user.userId)) {
        this.fs.setValueOnArray(this.createPositionService.hrbps, this.userService.user);
      }
    }
  }

  /**
   * Rimuove il chip selezionato
   * 
   * @param nameSelected 
   */
  removeSelectedHRBP(idx: number) {
    this.createPositionService.hrbps.removeAt(idx);
  }

  /**
   * Rimuove il chip selezionato
   * 
   * @param nameSelected 
   */
  removeSelectedLineManager(idx: number) {
    this.createPositionService.lineManagers.removeAt(idx);
  }

  /**
   * Esegui la ricerca e rimappa i valori per hrbpUsersList
   * 
   * @param search 
   */
  async onSearchHRBP(search: string) {
    try {
      this.hrbpUsersList = await this.backendService.hrbpUsersList({ name: search });
      this.hrbpUsersList.map(user => user.fullname = `${user.forename} ${user.surname}`);
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }
  }

  /**
   * Esegui la ricerca e rimappa i valori per lineManagerList
   * 
   * @param search 
   */
  async onSearchLineManager(search: string) {
    this.lineManagerList = await this.backendService.lineManagerList({ name: search });
    this.lineManagerList.map(user => user.fullname = `${user.forename} ${user.surname}`);
  }

  /** 
   * Attiva o disattiva il campo headhunter 
   */
  onheadHunterSwitch(value: boolean) {
    this.createPositionService.headHunterEnabled = value;
    /* Quando il campo headhunter viene attivato, viene aggiunto il validatore required e viene levato quando il campo viene disattivato */
    if (value) {
      this.createPositionService.headHunter?.enable();
    } else {
      this.createPositionService.headHunter?.disable();
      this.createPositionService.headHunter?.reset();
    }
    /* Aggiungendo dinamicamente un validatore bisogna fare l'update manuale in modo che il form percepisca l'aggiunta */
    this.createPositionService.headHunter?.updateValueAndValidity();
  }
}
