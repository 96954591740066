import { Injectable } from "@angular/core";

import { GeneralConfig, EnvironmentLoaderService } from "src/ancestors/env-config.service";
import { ApplicationError, HTTPError } from "./exception.service";


/** Definisce i livelli dei log. Ad esempio se il livello scelto è warn, mostra i warn e gli errori ma non info. */
export enum LogLevel {
    off = 0,
    error = 1,
    warn = 2,
    info = 3,
    debug = 4,
    all = 5
}

/**
 * Logger centralizzato.
 * Questa classe crea un oggetto logger per standardizzare ed estendere i log.
 * Mostra i log in base al livello di log scelto nella config
 *
 * Per utilizzarlo in un componente:
 * 1. Importarlo e aggiungerlo a providers
 * 2. Nel costruttore settare la variabile caller, ad es. this.logger.setCaller("ComponentName");
 * 3. Per utilizzarlo usare this.logger.error("error message")
 */


@Injectable()
export class LoggerService {
    constructor(private envConfig: EnvironmentLoaderService) { }
    private env: GeneralConfig = this.envConfig.getEnvConfig();
    /** Nome del chiamante, da mostrare nel log */
    private caller = "default";
    /** Livello di log da mostrare */
    private level: LogLevel = this.env.loggerLevel;

    /** Crea la stringa e la stampa in console */
    private log(severity: string, error: string | Error | ApplicationError, detailMessage?: string): void {
        /** Data e ora del log */
        const logTimestamp: string = new Date().toUTCString();

        /** Stringa da mostrare in console */
        const stringToLog = `${severity}: [Caller: ${this.caller}] [Date: ${logTimestamp}] [${typeof error === "string" ? error : error.message}] ${detailMessage ? "[DetailMessage: " + detailMessage + "]" : ""}`;

        if (severity === "ERROR") {
            console.error(stringToLog);
        } else {
            console.log(stringToLog);
        }
    }
    /** Controlla se mostrare il log in base al livello di log scelto nella config */
    private shouldLog(messageLevel: LogLevel): boolean {
        return messageLevel <= this.level;
    }
    /** Setta il nome del chiamante */
    public setCaller(name: string): void {
        this.caller = name;
    }
    /** Mostra un errore */
    public error(error: ApplicationError | Error | HTTPError, detailMessage?: string): void {
        if (!this.shouldLog(LogLevel.error)) {
            return;
        }

        if (error instanceof ApplicationError) {
            this.log("ERROR", error.defaultHumanMessage ?? error.message, detailMessage);
        } else if (error instanceof HTTPError) {
            this.log("ERROR", error.defaultHumanMessage, detailMessage);
        } else if (error instanceof Error) {
            this.log("ERROR", error.message, detailMessage);
        }
    }
    /** Mostra un warning */
    public warn(error: Error | ApplicationError, detailMessage?: string): void {
        if (this.shouldLog(LogLevel.warn)) {
            this.log("WARN", error, detailMessage);
        }
    }
    /** Mostra una informazione */
    public info(detailMessage: string, error?: Error | ApplicationError): void {
        if (this.shouldLog(LogLevel.info)) {
            if (error) {
                this.log("INFO", error, detailMessage);
            }
            this.log("INFO", detailMessage);
        }
    }
    /** Mostra un messaggio di debug */
    public debug(detailMessage: string, error?: Error | ApplicationError): void {
        if (this.shouldLog(LogLevel.debug)) {
            if (error) {
                this.log("DEBUG", error, detailMessage);
            }
            this.log("DEBUG", detailMessage);
        }
    }
}

