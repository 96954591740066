import { Component, inject, OnInit } from "@angular/core";
import { JobPositionStatusEnumTypes, PublicationChannel } from "applicanttrackingsystem-cl";
import { BaseComponent } from "src/ancestors/base/base.component";
import { CreatePositionService } from "../create-position.service";

@Component({
  selector: "app-channel",
  templateUrl: "./channel.component.html",
  styleUrls: ["./channel.component.scss"]

})
export class ChannelComponent extends BaseComponent implements OnInit {
  protected createPositionService = inject(CreatePositionService);

  constructor() {
    super();
  }

  /** Lista di possibili canali dove poter pubblicare la posizione */
  protected publicationChannelList?: PublicationChannel[];

  override async ngOnInit() {
    const activeTab = this.createPositionService.getActiveStepperIdxBasedOnUrl(this.router.url);
    this.createPositionService.setActivaStep(activeTab);

    /** Controllo se impostato perimetro specifico, se FDD question&screening diventa opzionale */
    await this.createPositionService.checkPerimeter();

    try {
      this.publicationChannelList = await this.backendService.publicationChannelList();
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }
  }

  /**
   * Apre/chiude drawer cambio status con il valore preselezionato TO_BE_APPROVED
   */
  protected openApprovalDrawer() {
    this.createPositionService.statusSelected = JobPositionStatusEnumTypes.TO_BE_APPROVED;
    this.createPositionService.onChangeStatus();
  }

  /**
   * Apre/chiude drawer cambio status con il valore preselezionato PUBLISHED 
   */
  protected openPublishDrawer() {
    this.createPositionService.statusSelected = JobPositionStatusEnumTypes.PUBLISHED;
    this.createPositionService.onChangeStatus();
  }
}
