<div class="d-block d-md-flex align-items-center w-100">

    <div class="d-none d-md-block" *ngIf="isSelectable">
        <generali-checkbox [bindName]="bindName || 'default'" (onSelectcheckbox)="onSelectCard()"
            [isSelected]="isSelected" *ngIf="!compactMode">
        </generali-checkbox>
    </div>
    <div class="card-detail w-100 d-flex flex-column flex-md-row" (click)="canAccessDetails ? onCardClick($event): null"
        [ngClass]="{
      'grid-container': !compactMode,
      'grid-container-compact': compactMode,
      'readOnly': !canAccessDetails
    }">
        <div class="section sct-a d-flex flex-row" *ngIf="!compactMode">
            <div class="d-block d-md-none" *ngIf="isSelectable">
                <generali-checkbox [bindName]="bindName || 'default'" (click)="$event.stopPropagation()"
                    (onSelectcheckbox)="onSelectCard()" *ngIf="!compactMode" [isSelected]="isSelected">
                </generali-checkbox>
            </div>
            <div class="d-flex flex-column justify-content-center" [ngClass]="{ 'w-100': isSelectable }">
                <div class="user-name">
                    <p class="trunc">{{ jobApplication?.forename }} {{ jobApplication?.surname }}</p>
                </div>
                <div class="user-detail d-flex text-nowrap">
                    <div class="phone">
                        <p>{{ jobApplication?.phone }}</p>
                    </div>
                    <span class="px-1">•</span>
                    <div class="nationality">
                        <p>{{ jobApplication?.nationality?.toUpperCase() }}</p>
                    </div>
                    <span class="px-2" *ngIf="jobApplication?.isTalent">•</span>
                    <div class="is-talent d-flex flex-row align-items-center">
                        <generali-interview-status interviewStatus="TALENT" *ngIf="jobApplication?.isTalent">
                        </generali-interview-status>
                    </div>
                </div>
                <div class="user-detail">
                    <div class="email">
                        <p class="trunc">{{ jobApplication?.email }}</p>
                    </div>
                    <div class="referral" *ngIf="jobApplication?.referredByUser?.email">
                        <p class="trunc">REF. {{ jobApplication?.referredByUser?.email }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="section sct-b d-flex flex-row justify-content-start align-items-center">
            <div class="me-3 d-flex flex-row align-content-center" *ngIf="compactMode">
                <generali-radio *ngIf="canAccessDetails else voidRadio"
                    [bindName]="bindName || 'default'"></generali-radio>
                <ng-template #voidRadio>
                    <div class="void-radio"></div>
                </ng-template>
            </div>
            <div>
                <div class="reassigned">
                    <generali-badge badgeCategory="FULL" badgeType="REASSIGNED" *ngIf="jobApplication?.reassigned">
                    </generali-badge>
                </div>
                <div class="application-title">
                    <p class="text-nowrap trunc">
                        {{ jobApplication?.jobPositionProgressiveCode }} {{ jobApplication?.jobPositionTitle }}
                    </p>
                </div>
                <div class="application-badge">
                    <generali-badge badgeCategory="DOT" [badgeType]="jobApplication?.currentStatus"
                        *ngIf="jobApplication?.currentStatus">
                    </generali-badge>
                    <span *ngIf="jobApplication?.feedbackMailSent">
                        <img class="ms-1 mb-1" src="assets/img/icons/mail.svg">
                    </span>
                </div>
                <div class="application-date">
                    <p>
                        {{ jobApplication?.currentStatusDate | date:'dd/MM/yyyy' }}
                    </p>
                </div>
            </div>
        </div>
        <div class="section sct-c d-flex flex-column justify-content-center">
            <ng-container
                *ngIf="jobApplication?.jobFitnessPercentage !== undefined && jobApplication?.jobFitnessPercentage !== null">
                <div
                    class="job-fitness d-flex flex-row flex-md-column gap-4 gap-md-0 align-items-center justify-content-md-between h-100">
                    <div class="fitness-title">
                        <p class="title-col">{{ 'position.card.FITNESS' | translate }}</p>
                    </div>
                    <div role="progressbar" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100"
                        style="--value: {{ jobApplication?.jobFitnessPercentage }}" [ngClass]="{
                            'success': (jobApplication?.jobFitnessPercentage | parseInt) >= 50,
                            'fail': (jobApplication?.jobFitnessPercentage | parseInt) < 50
                        }">
                        <p>{{ jobApplication?.jobFitnessPercentage | parseInt }}%</p>
                    </div>
                    <div class="fitness-info ms-auto ms-md-0" (click)="onCardClick($event, 'openFitnessAnswer')">
                        <p>{{ 'generic.SEE' | translate }}</p>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="section sct-d d-flex flex-row flex-md-column justify-content-md-between gap-3 gap-md-1">
            <!--
        <div class="me-4 me-md-0">
        <div class="test-title ">
          <p class="fw-normal text-nowrap">Test SHL</p>
        </div>
        <div class="test-result d-flex mb-1" *ngIf="false; else testNotDone">
          <p>{{ test.invitedAt | date:'dd/MM/yyyy' }}</p>
          <img class="ms-2 mb-1 img" *ngIf="test.invitedAt" src="assets/img/icons/mail.svg">
          <img class="ms-2 mb-1 img" *ngIf="test?.currentStatus === 'COMPLETED'" src="assets/img/icons/check.svg">
        </div>
      </div>
      <div class="me-4 me-md-0">
        <div class="test-title ">
          <p class="fw-normal text-nowrap">Test ENG</p>
        </div>
        <div class="test-result d-flex mb-1" *ngIf="false; else testNotDone">
          <p>{{ test.invitedAt | date:'dd/MM/yyyy' }}</p>
          <img class="ms-2 mb-1 img" *ngIf="test.invitedAt" src="assets/img/icons/mail.svg">
          <img class="ms-2 mb-1 img" *ngIf="test?.currentStatus === 'COMPLETED'" src="assets/img/icons/check.svg">
        </div>

        <ng-template #testNotDone>
          <p class="my-1">-</p>
        </ng-template>
      </div>
    -->
            <!-- I TEST SARANNO SEMPRE 2 PER IL MOMENTO, QUESTA SEZIONE TORNARA' UTILE IN FUTURE IMPLEMENTAZIONI QUANDO CI SARANNO PIU' TEST DISPONIBILI -->
            <!-- firstScreeningTestInstancesOfType -->
            <!-- <ng-container>
                {{jobApplication | json }}
            </ng-container> -->
            <ng-container *ngFor="let test of jobApplication?.screeningTestInstances | groupedTestInstanceMaxDate">
                <div class="d-flex flex-column gap-custom-5">
                    <div class="test-title">
                        <p class="title-col">{{ test?.shortTitle || test?.title }}</p>
                    </div>
                    <ng-container>
                        <div class="test-result d-flex flex-row align-items-center gap-1">
                            <ng-container *ngIf="test?.currentStatus == 'COMPLETED'">
                                <p>{{ test.takenAt | date:'dd/MM/yyyy' }}</p>
                                <img class="img" src="assets/img/icons/check.svg">
                            </ng-container>
                            <ng-container *ngIf="test?.currentStatus == 'STARTED'">
                                <p>{{ test.invitedAt | date:'dd/MM/yyyy' }}</p>
                                <img class="img" src="assets/img/icons/mail.svg">
                            </ng-container>
                            <ng-container *ngIf="test?.currentStatus == 'NOT_STARTED'">
                                <p>-</p>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </ng-container>

        </div>
        <div class="section sct-e d-flex flex-column gap-1">
            <div class="interview-title">
                <p class="title-col">{{ jobApplication?.interviewInstancesCount }} {{ 'detail.APPLICANT' | translate }}
                </p>
            </div>
            <ng-container *ngFor="let interview of jobApplication?.interviewInstancesPreview">
                <div *ngIf="interview.interviewUserRole  !== 'BUSINESS_INTERVIEWER'"
                    class="interviewer text-nowrap d-flex align-items-center">
                    <!-- Nome solo se presente -->
                    <p class="interviewer-name" *ngIf="interview.interviewUser?.forename">
                        {{ interview.interviewUser?.forename?.[0] }}. {{ interview.interviewUser?.surname }}
                    </p>

                    <!-- Ruolo -->
                    <p class="interviewer-role px-1" *ngIf="interview.interviewUser?.forename">
                        ({{ interview.interviewUserRole === 'LINE_MANAGER' ? 'MNG' : interview.interviewUserRole }})
                    </p>
                    <p class="interviewer-role" *ngIf="!interview.interviewUser?.forename">
                        {{ interview.interviewUserRole }}
                    </p>
                    <generali-interview-status class="ms-1" *ngIf="interview.currentStatus == 'DONE'"
                        [interviewStatus]="interview.finalValuationResult">
                    </generali-interview-status>
                    <generali-interview-status class="ms-1" *ngIf="interview.currentStatus == 'PLANNED'"
                        [scheduledAt]="interview.scheduledAt" interviewStatus="ORGANIZE">
                    </generali-interview-status>
                    <generali-interview-status class="ms-1" *ngIf="interview.currentStatus == 'TO_BE_PLANNED'"
                        interviewStatus="TBD">
                    </generali-interview-status>
                    <ng-template #FormNotCompiled>
                        <!-- L'evaluation form è stato salvato in bozza ed è stata fissata una data per il colloquio -->
                        <generali-interview-status *ngIf="interview.scheduledAt" class="ms-1" interviewStatus="ORGANIZE"
                            [scheduledAt]="interview.scheduledAt">
                        </generali-interview-status>
                        <!-- L'evaluation form è stato salvato in bozza e non è stata fissata una data per il colloquio-->
                        <generali-interview-status *ngIf="!interview.scheduledAt" class="ms-1" interviewStatus="TBD">
                        </generali-interview-status>
                    </ng-template>
                </div>
            </ng-container>
        </div>
        <div class="section sct-f d-flex align-items-center flex-row flex-md-column gap-md-0 gap-4"
            *ngIf="!compactMode">
            <div class="total-application-title">
                <p>{{ 'position.card.TOTAL_APPLICATION' | translate }}</p>
            </div>
            <div class="total-application-count">
                <p>{{ jobApplication?.numberOfTotalApplications ?? 0}}</p>
            </div>
            <div class="total-application-info ms-auto ms-md-0" (click)="onCardClick($event, 'openRealizedApplicants')">
                <p>{{ 'generic.SEE' | translate }}</p>
            </div>
        </div>
    </div>

</div>
<!-- FITNESS KILLER QUESTION -->
<generali-drawer [showDrawer]="showFitnessAnswer" drawerTitle="{{ 'position.card.FITNES_DRAWER_TITLE' | translate }}"
    drawerSubTitle="<b class='fs-5'>{{ 'position.card.FITNES_DRAWER_SUBTITLE' | translate }} {{ correctAnswer }}/{{ fitnessAnswer?.length }} - {{ jobApplication?.jobFitnessPercentage | parseInt }}%</b>"
    (onClosingDrawerTab)="onToggleFitnessAnswer()">
    <ng-container main>
        <!-- jobApplication?.screeningTestInstances -->
        <ng-container *ngFor="let test of fitnessAnswer">
            <div [class.correct]="test.answerValue !== 0" [class.error]="test.answerValue === 0"
                class="question-wrapper d-flex justify-content-between">
                <div class="question">
                    <p class="question-txt mb-2">{{ test.questionText }}</p>
                    <p class="answer">{{ test.answerText }}</p>
                </div>
                <div class="question-status align-self-end">
                    <img *ngIf="test.answerValue !== 0" src="assets/img/icons/check.svg">
                    <img *ngIf="test.answerValue === 0" src="assets/img/icons/exclude-red.svg">
                </div>
            </div>
        </ng-container>
    </ng-container>
</generali-drawer>