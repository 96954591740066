import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { JobApplication, ScreeningTestInstance, ScreeningTestStatusEnumTypes } from "applicanttrackingsystem-cl";
import { BaseComponent } from "src/ancestors/base/base.component";

@Component({
  selector: "card-application-detail",
  templateUrl: "./card-application-detail.component.html",
  styleUrls: ["./card-application-detail.component.scss"]
})
export class CardApplicationDetailComponent extends BaseComponent implements OnChanges {
  constructor() {
    super();
  }

  /** Espone la checkbox di selezione se true */
  @Input() isSelectable?: boolean = true;
  /** Job Application */
  @Input() jobApplication?: JobApplication;
  /** Carta selezionata */
  @Input() isSelected?: boolean;
  /** Se settata a true la selezione avverà attraverso radio button */
  @Input() singleSelectMode?: boolean;
  /** Name da attribuire al gruppo di select */
  @Input() bindName?: string;
  /** Visualizza solo i campi principali (section 1,3,4,5) */
  @Input() compactMode?: boolean;

  @Input() screeningTest?: ScreeningTestInstance[];

  @Input() canAccessDetails?: boolean;

  @Output() onClick = new EventEmitter<string>();
  /** Carta selezionata */
  @Output() cardSelected = new EventEmitter();
  /** Vedi candidature effettuate */
  @Output() onRealizedApplicants = new EventEmitter();
  /** Vedi killer question */
  @Output() onCheckKillerQuestion = new EventEmitter();

  firstScreeningTests?: ScreeningTestInstance[];

  protected showFitnessAnswer?: boolean;
  protected showRealizedApplicants?: boolean;
  protected jobApplicationHistory?: JobApplication[];
  protected fitnessAnswer?: { questionText?: string, answerText?: string, answerValue?: number }[];

  ngOnChanges() {
    // Tengo solo i primi due test creati e mostro solo quelli nella card
    if (this.screeningTest && this.screeningTest.length >= 3) {
      this.firstScreeningTests = [this.screeningTest[0], this.screeningTest[1]];
      for (let i = 0; i <= this.screeningTest.length - 1; i++) {
        if (this.screeningTest[i].screeningTestType == "TEST_SHL") {
          if (this.screeningTest[i].currentStatus == ScreeningTestStatusEnumTypes.COMPLETED) {
            if (new Date(this.screeningTest[i].takenAt!) > new Date(this.firstScreeningTests[0].takenAt!)) {
              this.firstScreeningTests[1] = this.screeningTest[i];
            }
          } else if (this.screeningTest[i].currentStatus == ScreeningTestStatusEnumTypes.STARTED) {
            if (new Date(this.screeningTest[i].invitedAt!) > new Date(this.firstScreeningTests[1].invitedAt!)) {
              this.firstScreeningTests[1] = this.screeningTest[i];
            }
          }
        } else {
          if (this.screeningTest[i].currentStatus == ScreeningTestStatusEnumTypes.COMPLETED) {
            if (new Date(this.screeningTest[i].takenAt!) > new Date(this.firstScreeningTests[0].takenAt!)) {
              this.firstScreeningTests[0] = this.screeningTest[i];
            }
          } else if (this.screeningTest[i].currentStatus == ScreeningTestStatusEnumTypes.STARTED) {
            if (new Date(this.screeningTest[i].invitedAt!) > new Date(this.firstScreeningTests[0].invitedAt!)) {
              this.firstScreeningTests[0] = this.screeningTest[i];
            }
          }
        }
      }
    } else {
      this.firstScreeningTests = this.screeningTest;
    }
  }

  public onToggleFitnessAnswer() {
    this.showFitnessAnswer = !this.showFitnessAnswer;
  }

  public onToggleRealizedApplicants() {
    this.showRealizedApplicants = !this.showRealizedApplicants;
  }

  public onSelectCard() {
    this.cardSelected.emit();
  }

  public realizedApplicants() {
    this.onRealizedApplicants.emit();
  }

  public checkKillerQuestion() {
    this.showFitnessAnswer = !this.showFitnessAnswer;
    this.onCheckKillerQuestion.emit();
  }

  protected async onCardClick(event: MouseEvent, drawerOpt?: string) {
    event.stopPropagation();

    if (drawerOpt === "openRealizedApplicants") {
      this.onRealizedApplicants.emit(true);
    }

    if (drawerOpt === "openFitnessAnswer") {
      this.showFitnessAnswer = !this.showFitnessAnswer;

      await this.setFitnessAnswer();
    }

    if (!drawerOpt) {
      this.onClick.emit(this.jobApplication?.jobApplicationId);
    }
  }

  protected async setFitnessAnswer() {
    if (!this.jobApplication?.jobApplicationId) {
      return;
    }

    const fitnessAnswer = await this.backendService.surveyGetByApplication(this.jobApplication?.jobApplicationId);
    const res: { questionText?: string, answerText?: string, answerValue?: number }[] = [];

    fitnessAnswer.questions?.forEach(question => {
      const r: { questionText?: string, answerText?: string, answerValue?: number } = {};
      r.questionText = question.questionText;
      question.possibileAnswers.forEach(answer => {
        if (answer.selectedAnswer) {
          r.answerText = answer.answerText;
          r.answerValue = answer.answerValue;
        }
      });
      res.push(r);
    });

    this.fitnessAnswer = res;
  }

  protected get correctAnswer() {
    const getCorrectAnswer = this.fitnessAnswer?.filter(answer => answer.answerValue === 1);
    return getCorrectAnswer?.length;
  }

  protected get jobFitness() {
    if (!this.jobApplication?.jobFitnessPercentage) {
      return undefined;
    }

    if (typeof this.jobApplication.jobFitnessPercentage === "string") {
      return parseInt(this.jobApplication.jobFitnessPercentage, 10);
    }
    return this.jobApplication.jobFitnessPercentage;
  }

  /**
 * 
 * Apre/chiude il drawer candidature realizzate
 */
  protected toggleRealizedApplicants() {
    this.onRealizedApplicants.emit();
  }
}
