import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ActionSelect } from "src/app/pages/position-detail/position-detail.component";

@Component({
  selector: "actions-dropdown",
  templateUrl: "./actions-dropdown.component.html",
  styleUrls: ["./actions-dropdown.component.scss"]
})
export class ActionsDropdownComponent {
  constructor() { }

  @Input() actions?: ActionSelect.ActionInterface[];

  @Output() onClick = new EventEmitter<string>();

  protected showActions?: boolean;

  protected onToggleActionsDropdown() {
    this.showActions = !this.showActions;
  }

  protected onSelectedAction(action: string) {
    this.showActions = false;
    this.onClick.emit(action);
  }
}
