  <div class="header" *ngIf="appService.showHeader">
    <!-- <genehe -->
    <generali-header (onLogoClick)="redirectToHomepage()" headerInputImg="../assets/img/icons/search.svg"
      (onUserOptions)="openUserOption()" (onClickOutside)="showUserOption = false" [navTabs]="navbarTabsMobile">
    </generali-header>
    <div class="user-option" *ngIf="showUserOption">
      <div class="user d-flex align-items-center pb-3">
        <div class="user-img">
          <img *ngIf="usrService.user" [src]="usrService.user.userOptions?.avatarImage">
        </div>
        <div class="user-name">
          <p class="mb-0 ms-3">Ciao, <span class="fw-semibold">{{ usrService.user.forename }}</span></p>
        </div>
      </div>
      <div class="pt-3 d-flex align-items-center justify-content-between logout" role="button" (click)="doLogout()">
        <p class="mb-0">Esci</p>
        <img src="assets/img/icons/arrow-right.svg">
      </div>
    </div>
  </div>

  <div class="menu" *ngIf="appService.showNavbar" hasPermission>
    <generali-navbar (onHelperClick)="onHelperSelection()" (onTabClick)="onNavTabClick($event)" [navTabs]="navbarTabs"
      [url]="router.url" [helperActiveString]="activeFaq">
    </generali-navbar>
  </div>

  <main class="container-fluid"
    [ngClass]="[appService.showNavbar ? 'show-nav':'hide-nav', appService.showHeader ? 'show-header':'hide-header', mobileMode ? 'hide-nav' : 'show-nav']">
    <ng-container>
      <router-outlet (activate)="appService.setHeaderVisibility(); appService.setNavbarVisibility()"></router-outlet>
    </ng-container>
  </main>

  <generali-toast [hide]="3000"></generali-toast>
