import { Injectable, inject } from "@angular/core";
import { WindowRef } from "../service/windowRef.service";
import { UserService } from "src/app/global-service/user.service";

export enum EventNameEnum {
  changeStatusJobPosition = "VirtualChangeStatusJobPosition",
  changeStatusJobApplication = "VirtualChangeStatusJobApplication",
  createPositionForward = "VirtualCreatePositionForward",
  candidateFollowing = "VirtualCandidateFollowing",
  candidatePrevious = "VirtualCandidatePrevious",
  submitEvaluationForm = "VirtualSubmitEvaluationForm",
  addTalent = "VirtualAddTalent",
  removeTalent = "VirtualRemoveTalent",
  requestAssessment = "VirtualRequestAssessment",
  assignOtherSelection = "VirtualAssignOtherSelection",
  addComment = "VirtualAddComment",
  filterJobPositionStatus = "VirtualFilterJobPositionStatus",
  filterJobPositionCompany = "VirtualFilterJobPositionCompany",
  filterJobPositionHRBP = "VirtualFilterJobPositionHRBP",
  filterJobPositionTypeContract = "VirtualFilterJobPositionTypeContract",
  filterJobPositionLocation = "VirtualFilterJobPositionLocation",
  filterJobPositionJobFields = "VirtualFilterJobPositionJobFields",
  filterJobApplicationKeyword = "VirtualFilterJobApplicationKeyword",
  filterJobApplicationStatus = "VirtualFilterJobApplicationStatus",
  filterJobApplicationReassigned = "VirtualFilterJobApplicationReassigned",
  filterJobApplicationReferral = "VirtualFilterJobApplicationReferral",
  filterJobApplicationTalent = "VirtualFilterJobApplicationTalent",
  filterJobApplicationNewItalian = "VirtualFilterJobApplicationNewItalian",
  filterJobApplicationLegallyProtected = "VirtualFilterJobApplicationLegallyProtected",
  filterJobApplicationFeedbackMailSent = "VirtualFilterJobApplicationFeedbackMailSent",
  filterJobApplicationAgeRange = "VirtualFilterJobApplicationAgeRange",
  filterJobApplicationGender = "VirtualFilterJobApplicationGender",
  order = "VirtualOrder"
}
@Injectable({
  providedIn: "root"
})
export class AnalyticsService {

  private windowRef = inject(WindowRef);
  private userService = inject(UserService);

  /**
 * Push new item into array window.dataLayer
 * @param eventName  string with name of event
 * @param page  string with name of page
 * @param pageTitle  string with name of page Title
 * @param obj Objects with values and key to save
 */
  public sendVirtualEvent(eventName: EventNameEnum, page: string, pageTitle: string, obj?: any) {
    this.windowRef.nativeWindow.window.dataLayer.push({
      event: eventName,
      page: page,
      title: pageTitle,
      ...obj,
      anonymousId: this.userService.user.anonymousId
    });
  }

  /**
  * Push new item into array window.dataLayer
  * @param page  string with name of page
  * @param pageTitle  string with name of page Title
  */
  public sendVirtualPageViewEvent(page: string, pageTitle: string) {
    this.windowRef.nativeWindow.window.dataLayer.push({
      event: "VirtualPageView",
      page: page,
      title: pageTitle,
      anonymousId: this.userService.user.anonymousId
    });
  }

}