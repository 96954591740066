import { ChangeDetectorRef, inject, Injectable } from "@angular/core";
import { Validators, FormBuilder, FormGroup, FormControl, FormArray } from "@angular/forms";

import { Company, CompanyPerimeter, ContractType, EmploymentLevel, JobField, JobPosition, JobPositionStatusEnum, JobPositionStatusEnumTypes, JobPositionsWithCountersResponse, Location, ScreeningTest, ScreeningTestCollection, SimpleUser } from "applicanttrackingsystem-cl";
import { GeneraliBadge, GeneraliStatuTab } from "generali-shared-components/dist";
import { CreatePosition } from "./create-position-routing.module";
import { FormService } from "src/app/shared/util/form.service";
import { FormCommonClasses as FormCL } from "src/app/shared/util/form.classes";
import { BaseDataService } from "src/ancestors/base-data.service";
import { UserService } from "src/app/global-service/user.service";
import { TranslateService } from "src/app/shared/util/translate.service";
import { getCurrencySymbol, getLocaleCurrencyCode } from "@angular/common";

export interface PositionStatus {
  badgeLabel?: string;
  badgeType?: GeneraliBadge.BadgeType
}

@Injectable()
export class CreatePositionService extends BaseDataService {
  private translateService = inject(TranslateService);
  private cdr = inject(ChangeDetectorRef);
  private usrService = inject(UserService);
  private fb = inject(FormBuilder);
  private fs = inject(FormService);

  constructor() {
    super();
  }
  public positionIsOpen = false;
  /** Valore per simulare una posizione che non è mai stata apera */
  public hasBeenOpen?: boolean;

  /** JOB_POSITION */
  public jobPosition?: JobPosition;
  /** Valore responsabile dell'apertura e chiusura della tab di cambiamento di status */
  public showChangeStatusDrawer?: boolean;
  /** Rende visibile la textarea all'interno del drawer di cambio status */
  public textAreaIsVisible?: boolean;
  /** Rende visibili bottoni conferma/annulla all'interno del drawer di cambio status */
  public actionButtonsIsVisible?: boolean;
  /** Valore probabile per il progressive code. NB: non è una generazione effettiva, ma solo un'ipotesi */
  public hypoteticalProgressivePositionNumber?: string;
  /** Valore responsabile dell'attivazione del campo referral nel form (Proces) */
  public referralEnabled?: boolean;
  /** Valore responsabile dell'attivazione del campo retribuzione nel form (Proces) */
  public retributionEnabled?: boolean;
  /** Valore responsabile dell'attivazione del campo Headhunter nel form (Stakeholder) */
  public headHunterEnabled?: boolean;
  /** Valore responsabile dell'attivazione del campo compagnia nel form (Proces) */
  public isPerimeterSelected?: boolean;
  /** Data selezionata */
  public expectedStartDateSelected?: Date;
  /** Data pre-selezionata */
  public preselectedExpectedStartDateSelected?: Date;
  /** Stringa che determina il messaggio di avvertimento nella tab di cambio dello stato della creazione della posizione */
  public positionWarnMessage?: string;
  /** Status selezionato della posizione di default impostato su DRAFT(Bozza) */
  public _statusSelected: JobPositionStatusEnum = JobPositionStatusEnumTypes.DRAFT;
  /**  */
  public onSaveLoading = false;
  /**  */
  public onChangeStatusLoading = false;
  /** */
  public mobileActiveStep?: number = 0;
  /** Chiave di salvataggio dati temporanei nel session storage */
  public sessioKey = "CREATE_POSITION_PARAMS";
  /** Determina in che modalità di visualizzazione ci si trova */
  public mode?: "edit" | "clone" | "create";
  /** Controllo per il campo commenti del drawer di cambio status */
  public changeStatusComment = this.fb.control(null);

  /** Controllo se mostrare EBRM e screening Test */
  public includeRowByPerimeter = true;

  get statusSelected() {
    return this._statusSelected;
  }
  set statusSelected(status: JobPositionStatusEnum) {
    this._statusSelected = status;
  }
  /** String nella quale viene salvato il valore dello stato attivo della posizione */
  // public _activePositionStatus?: PositionStatus = { badgeType: this.jobPosition?.currentStatus ?? JobPositionStatusEnumTypes.DRAFT };
  // get activePositionStatus(): PositionStatus | undefined {
  //   return this._activePositionStatus;
  // }

  /** STAUS ABILITABILI */
  public status = {
    statusDraft: false,
    statusToBeAproved: false,
    statusOpen: false,
    statusPublished: false,
    statusCloseFilled: false,
    statusCloseEmpty: false,
    statusSuspended: false
  };

  /** Creazione del Process form */
  public processForm: FormGroup = this.fb.group({
    [FormCL.TITLE]: [null, Validators.required],
    [FormCL.OPEN_POSITION]: [null, [Validators.required, Validators.max(99), Validators.min(1), Validators.pattern("^[0-9]*$")]],
    [FormCL.ORGANIZATION_UNIT]: [null, Validators.required],
    [FormCL.RETRIBUTION_FROM]: [{ value: null, disabled: true }],
    [FormCL.RETRIBUTION_TO]: [{ value: null, disabled: true }],
    [FormCL.REFERRAL]: [{ value: null, disabled: true }, Validators.pattern("^[0-9]*$")],
    searchSite: [null],
    [FormCL.EXPECTED_START_DATE]: [null],

    [FormCL.PERIMETER]: this.fb.array([], [Validators.required]),
    [FormCL.COMPANY]: this.fb.array([], Validators.required),
    [FormCL.JOB_FIELD]: this.fb.array([]),
    [FormCL.CONTRACT_TYPE]: this.fb.array([], Validators.required),
    [FormCL.LOCATION]: this.fb.array([], Validators.required),
    [FormCL.EMPLOYMENT_LEVEL]: this.fb.array([])
  });

  /** Creazione dello Stakeholder form */
  public stakeholderForm: FormGroup = this.fb.group({
    [FormCL.HRBP]: this.fb.array([], Validators.required),
    [FormCL.LINE_MANAGER]: this.fb.array([], Validators.required),
    [FormCL.LINE_MANAGER_COMPETENCE]: this.fb.group({
      [FormCL.COMPETENCE_1]: [null],
      [FormCL.COMPETENCE_2]: [null],
      [FormCL.COMPETENCE_3]: [null],
      [FormCL.COMPETENCE_4]: [null]
    }),
    [FormCL.HEADHUNTER]: [{ value: null, disabled: true }]
  });
  /** Creazione del Job Description form */
  public jobDescriptionForm = this.fb.group({
    [FormCL.COMPANY_PROFILE_DESCRIPTION]: [null, Validators.required],
    [FormCL.JOB_DESCRIPTION]: [null, Validators.required],
    [FormCL.COMPETENCE_DESCRIPTION]: [null, Validators.required]
  });

  /** Creazione dello Screening form */
  public questionScreeningForm: FormGroup = this.fb.group({
    [FormCL.SCREENING_TEST_COLLECTION]: this.fb.array([], Validators.required)
  });

  /** Stepper creazione del processo */
  public tabItems: GeneraliStatuTab.TabStruct[] = [
    { redirect: CreatePosition.process, isActive: true, isCompiled: false, name: $localize`:@@position.step.PROCESS:position.step.PROCESS` },
    { redirect: CreatePosition.stakeholder, isActive: false, isCompiled: false, name: $localize`:@@position.step.STAKEHOLDER:position.step.STAKEHOLDER` },
    { redirect: CreatePosition.jobDescription, isActive: false, isCompiled: false, name: $localize`:@@position.step.JOB_DESCRIPTION:position.step.JOB_DESCRIPTION` },
    { redirect: CreatePosition.qEs, isActive: false, isCompiled: false, name: $localize`:@@position.step.QUESTION_SCREENING:position.step.QUESTION_SCREENING` },
    { redirect: CreatePosition.channel, isActive: false, isCompiled: false, name: $localize`:@@position.step.PUBLICATION:position.step.PUBLICATION` }
  ];

  /**
   * Setta il badge con lo status della posizione
   */
  // public setActivePositionStatus() {
  //   const STATUS = this.jobPosition?.currentStatus ?? JobPositionStatusEnumTypes.DRAFT;
  //   this._activePositionStatus = { badgeType: STATUS, badgeLabel: this.translateService.instant(`position.status.label. + ${STATUS}`) };
  // }

  /**
   * In caso ci siano dei campi obbligatori non compilati, mostra il messaggio di errore
   *
   * @param formGroup
   */
  public onSaveSetErrorOnInvalidFields(formGroup: FormGroup) {
    for (const field in formGroup.controls) {
      const control = formGroup.get(field);

      if (control instanceof FormArray) {
        control.markAsTouched();
      }
      if (control instanceof FormControl) {
        control.markAsTouched();
        control.markAsDirty();
      }
    }
  }

  /**
   * Setta la tab attuale come completata se True
   *
   * @param isCompiled Boolean
   */
  public setFormCompiled(isCompiled: boolean, form?: string) {
    /** Recupera l'indice della pagina attualmente visualizzata */

    let actualStep;
    if (!form) {
      actualStep = this.getActiveStepperIdxBasedOnUrl(this.router.url);
    } else {
      actualStep = this.getActiveStepperIdxBasedOnUrl(form);
    }

    if (isCompiled) {
      /** Impostare la pagina come completata */
      this.setStepCompiled(actualStep);
    } else {
      /** Impostare la pagina come non completata */
      this.setStepNotCompiled(actualStep);
    }
  }

  /**
   * Controlla la validità di ogni form
   *
   * @returns True ci sono campi obbligatori non compilati altrimenti false se il form è valido
   */
  public get formsAreInvalids() {
    return !(this.processForm.valid && this.stakeholderForm.valid && this.jobDescriptionForm.valid && this.questionScreeningForm.valid);
  }

  /**
   * Setta lo step del processo in cui ci si trova in base all'idx
   *
   * @param idxActiveStep index processo
   */
  public setActivaStep(idxActiveStep: number) {
    this.tabItems.forEach(item => item.isActive = false);
    this.tabItems[idxActiveStep].isActive = true;
    this.cdr.detectChanges();
  }

  /**
   * @param stepIdx
   *
   * Setta lo step compiled dopo il salvataggio
   */
  public setStepCompiled(stepIdx: number) {
    this.tabItems[stepIdx].isCompiled = true;
  }

  /**
   * @param stepIdx
   *
   * Setta lo step compiled dopo il salvataggio
   */
  public setStepNotCompiled(stepIdx: number) {
    this.tabItems[stepIdx].isCompiled = false;
  }

  /**
   * @param url
   *
   * Ritorna l'idx del processo attivo
   */
  public getActiveStepperIdxBasedOnUrl(url: string): number {
    if (url.includes(CreatePosition.process)) {
      return 0;
    }
    if (url.includes(CreatePosition.stakeholder)) {
      return 1;
    }
    if (url.includes(CreatePosition.jobDescription)) {
      return 2;
    }
    if (url.includes(CreatePosition.qEs)) {
      return 3;
    }
    if (url.includes(CreatePosition.channel)) {
      return 4;
    }

    return 0;
  }

  /**
   * Ritorna lo step attuale
   *
   */
  public getCurrentStep() {
    return this.tabItems.find((el) => el.isActive);
  }

  /**
   * Ritorna lo step successivo se esiste altrimenti null
   *
   * @param actualStepIdx
   */
  public getNextStep(actualStepIdx: number) {
    if (this.checkStepExistance(actualStepIdx + 1)) {
      return this.tabItems[actualStepIdx + 1];
    }
    return null;
  }

  /**
   * Apertura/chiusura della tab di cambiamento di status
   *
   * Settaggio elementi visualizabili nel drawer
   *
   * Settaggio status attivi/disattivi
   */
  public onChangeStatus() {
    this.showChangeStatusDrawer = !this.showChangeStatusDrawer;

    if (this.showChangeStatusDrawer) {
      this.setChangeStatusDrawerActions(this.statusSelected);

      this.setSelectableStatus();
    }
  }

  // /**
  //  * Controlla se ci sono parametri salvati nel session storage
  //  */
  // public chekSessionSavedParams(): boolean {
  //   const param = sessionStorage.getItem(this.sessioKey);
  //   return param ? true : false;
  // }

  // /**
  //  * Ritorna JobPosition
  //  */
  // public getSessionSavedParams() {
  //   const param = sessionStorage.getItem(this.sessioKey);

  //   return param ? JSON.parse(param) as JobPosition : undefined;
  // }

  // public saveSessionSavedParams(param: JobPosition) {

  //   sessionStorage.setItem(this.sessioKey, this.appService.stringify(param));
  // }

  public setFormsFromsFields(formFields?: JobPosition) {
    this.fs.setValueOnControl(this.title, formFields?.title);
    this.fs.setValueOnControl(this.openPosition, formFields?.openedPositionsCount);
    this.fs.setValueOnArray(this.perimeter, formFields?.company?.perimeter);
    if (!this.isEmpty(formFields?.company?.perimeter)) {
      this.isPerimeterSelected = true;
    }

    this.fs.setValueOnArray(this.company, formFields?.company);
    this.fs.setValueOnControl(this.organizationUnit, formFields?.organizationUnit);
    this.fs.setValueOnArray(this.jobField, formFields?.jobField);
    this.fs.setValueOnArray(this.contractType, formFields?.contractType);
    this.fs.setMultiValuesOnArray(this.location, formFields?.locations);

    if (formFields?.referralEnabled) {
      this.referralEnabled = true;
      this.referral.enable();
      this.referral.setValidators(Validators.required);
      this.fs.setValueOnControl(this.referral, formFields?.referralAmount);
    }

    if (formFields?.retributionEnabled) {
      this.retributionEnabled = true;
      this.employmentLevel.enable();
      this.retributionFrom.enable();
      this.retributionTo.enable();
      this.employmentLevel.setValidators(Validators.required);

      this.fs.setValueOnArray(this.employmentLevel, formFields?.employmentLevel);
      this.fs.setValueOnControl(this.retributionFrom, formFields?.retributionFrom);
      this.fs.setValueOnControl(this.retributionTo, formFields?.retributionTo);
    }

    //this.fs.setValueOnControl(this.expectedStartDate, formFields?.expectedStartDate);

    const usrHRBPs = formFields?.hrbps?.map(v => {
      return {
        ...v,
        fullname: `${v.forename} ${v.surname}`
      };
    });
    this.fs.setMultiValuesOnArray(this.hrbps, usrHRBPs);


    const usrLINEs = formFields?.lineManagers?.map(v => {
      return {
        ...v,
        fullname: `${v.forename} ${v.surname}`
      };
    });
    this.fs.setMultiValuesOnArray(this.lineManagers, usrLINEs);

    this.fs.setValueOnControl(this.competence1, formFields?.lineManagerCompetences?.at(0));
    this.fs.setValueOnControl(this.competence2, formFields?.lineManagerCompetences?.at(1));
    this.fs.setValueOnControl(this.competence3, formFields?.lineManagerCompetences?.at(2));
    this.fs.setValueOnControl(this.competence4, formFields?.lineManagerCompetences?.at(3));

    if (formFields?.headHunterEnabled) {
      this.headHunterEnabled = true;
      this.fs.setValueOnControl(this.headHunter, formFields?.headHunter);
      this.headHunter.enable();
    }

    this.fs.setValueOnControl(this.companyProfileDescription, formFields?.companyProfileDescription);
    this.fs.setValueOnControl(this.jobDescription, formFields?.jobDescription);
    this.fs.setValueOnControl(this.competencesDescription, formFields?.competencesDescription);

    this.fs.setValueOnArray(this.screeningTest, formFields?.screeningTestCollection);


    /** Se il form non è completo, viene eseguita la navigazione ma la pagina non verrà segnata come completata */
    if (!this.processForm.valid) {
      this.setFormCompiled(false, CreatePosition.process);
    } else {
      this.setFormCompiled(true, CreatePosition.process);
    }

    if (!this.stakeholderForm.valid) {
      this.setFormCompiled(false, CreatePosition.stakeholder);
    } else {
      this.setFormCompiled(true, CreatePosition.stakeholder);
    }

    if (!this.jobDescriptionForm.valid) {
      this.setFormCompiled(false, CreatePosition.jobDescription);
    } else {
      this.setFormCompiled(true, CreatePosition.jobDescription);
    }

    if (!this.questionScreeningForm.valid) {
      this.setFormCompiled(false, CreatePosition.qEs);
    } else {
      this.setFormCompiled(true, CreatePosition.qEs);
    }

  }

  /**
   * Attiva/disattiva campi selezionabili nel drawer del cambio status
   */
  public setSelectableStatus() {
    const DISABLE = true;
    const ENABLE = false;
    /** Se anche un solo form non è valido l'unico status selezionabile è bozza */
    if (this.formsAreInvalids) {
      /** Disabilita tutti i campi tranne bozza */
      this.status.statusOpen = DISABLE;
      this.status.statusPublished = DISABLE;
      this.status.statusToBeAproved = DISABLE;
      this.status.statusCloseEmpty = DISABLE;
      this.status.statusCloseFilled = DISABLE;
      this.status.statusSuspended = DISABLE;

      if (this.mode == "edit") {
        this.status.statusDraft = DISABLE;
      }
      return;
    }

    /** utente EBRM --> “Sospesa”, “Aperta”, “Pubblicata”  */
    if (this.usrService.isEBRM) {
      /** Posizione mai messa in status aperta */
      if (!this.hasBeenOpen) {
        this.status.statusToBeAproved = ENABLE;
      }
      /** Se la posizione è bozza */
      if (this.jobPosition?.currentStatus === JobPositionStatusEnumTypes.DRAFT) {
        this.status.statusDraft = ENABLE;
        this.status.statusToBeAproved = DISABLE;
        this.status.statusCloseEmpty = DISABLE;
        this.status.statusCloseFilled = DISABLE;
      }

      /** Se lo status non è ne bozza ne sospeso vuol dire che la posizione è già in uno stato di approvazione/aperta/pubblicata e non può tornare ad un precedente status */
      if (this.jobPosition?.currentStatus !== JobPositionStatusEnumTypes.DRAFT && this.jobPosition?.currentStatus !== JobPositionStatusEnumTypes.SUSPENDED) {
        this.status.statusToBeAproved = DISABLE;
        this.status.statusDraft = DISABLE;
      }

      if (this.jobPosition?.currentStatus === JobPositionStatusEnumTypes.PUBLISHED || this.jobPosition?.currentStatus === JobPositionStatusEnumTypes.CLOSED_EMPTY || this.jobPosition?.currentStatus === JobPositionStatusEnumTypes.CLOSED_FILLED) {
        this.status.statusToBeAproved = DISABLE;
        this.status.statusPublished = ENABLE;
        this.status.statusCloseEmpty = ENABLE;
        this.status.statusCloseFilled = ENABLE;
      }

      if (this.jobPosition?.currentStatus === JobPositionStatusEnumTypes.TO_BE_APPROVED) {
        this.status.statusSuspended = DISABLE;
      }

      if (this.jobPosition?.currentStatus === JobPositionStatusEnumTypes.OPEN) {
        this.status.statusCloseEmpty = ENABLE;
        this.status.statusCloseFilled = ENABLE;
      }

      if (this.jobPosition?.currentStatus === JobPositionStatusEnumTypes.CLOSED_FILLED) {
        this.status.statusCloseEmpty = DISABLE;
      }
      if (this.jobPosition?.currentStatus === JobPositionStatusEnumTypes.CLOSED_EMPTY) {
        this.status.statusCloseFilled = DISABLE;
      }
      if (!this.formsAreInvalids) {
        this.status.statusPublished = ENABLE;
        this.status.statusOpen = ENABLE;
      }
    }


    /** utente HRBP --> “Da approvare” e “Sospesa” */
    if (this.usrService.isHRBP) {
      /** Abilita i suddetti campi se sei un utente HRBP */
      this.status.statusToBeAproved = ENABLE;
      this.status.statusSuspended = ENABLE;
      /** Disabilita gli altri */
      this.status.statusOpen = DISABLE;
      this.status.statusPublished = DISABLE;
      this.status.statusCloseEmpty = DISABLE;
      this.status.statusCloseFilled = DISABLE;

      if (this.jobPosition?.currentStatus === JobPositionStatusEnumTypes.TO_BE_APPROVED
        || this.jobPosition?.currentStatus === JobPositionStatusEnumTypes.OPEN
        || this.jobPosition?.currentStatus === JobPositionStatusEnumTypes.PUBLISHED
        || this.jobPosition?.currentStatus === JobPositionStatusEnumTypes.CLOSED_EMPTY
        || this.jobPosition?.currentStatus === JobPositionStatusEnumTypes.CLOSED_FILLED) {
        this.status.statusToBeAproved = DISABLE;
      }
      if (this.jobPosition?.currentStatus === JobPositionStatusEnumTypes.TO_BE_APPROVED) {
        this.status.statusSuspended = DISABLE;
      }
    }
  }

  /**
   * Settaggio dinamico in base allo status di textarea buttoni e messaggi di avvertimento
   *
   * @param positionStatus Selezione dell'utente
   */
  public setChangeStatusDrawerActions(positionStatus: JobPositionStatusEnum) {
    /** Salva lo status selezionato */
    this.statusSelected = positionStatus;
    /** Gestisce la visualizzazione della textarea all'interno del tab del cambio di status */
    this.showChangeStatusTextArea(positionStatus);
    /** Gestisce la visualizzazione dei bottoni all'interno del tab del cambio di status */
    this.showChangeStausButtons(positionStatus);
    /** Gestisce la visualizzazione dei messaggio di avvertimento all'interno del tab del cambio di status */
    this.showChangeStatusWarn(positionStatus);
  }

  /**
   * Setta nel service il messaggio di avvertimento relativo alla selezione
   *
   * @param positionStatus
   */
  showChangeStatusWarn(positionStatus: JobPositionStatusEnum) {
    if (this.formsAreInvalids) {
      this.positionWarnMessage = $localize`:@@position.status.INVALID:position.status.INVALID`;
      return;
    }

    if (positionStatus === JobPositionStatusEnumTypes.TO_BE_APPROVED && this.usrService.isHRBP) {
      this.positionWarnMessage = $localize`:@@position.status.TO_APPROVE:position.status.TO_APPROVE`;
      return;
    }
    if (positionStatus === JobPositionStatusEnumTypes.OPEN && this.usrService.isHRBP) {
      this.positionWarnMessage = $localize`:@@position.status.OPEN:position.status.OPEN`;
      return;
    }
    if (positionStatus === JobPositionStatusEnumTypes.CLOSED_FILLED) {
      this.positionWarnMessage = $localize`:@@position.status.FILLED:position.status.FILLED`;
      return;
    }
    if (positionStatus === JobPositionStatusEnumTypes.CLOSED_EMPTY) {
      this.positionWarnMessage = $localize`:@@position.status.EMPTY:position.status.EMPTY`;
      return;
    }
    this.positionWarnMessage = "";
    return;
  }

  /**
   * Setta nel service un valore booleano responsabile della visualizzazione della textarea
   *
   * @param positionStatus
   */
  showChangeStatusTextArea(positionStatus: string) {
    const { valid: P } = this.processForm;
    const { valid: S } = this.stakeholderForm;
    const { valid: J } = this.jobDescriptionForm;
    const { valid: Q } = this.questionScreeningForm;
    /** True quando tutti i campi obbligatori dei form sono complilati */
    const valid = [P, S, J, Q].every(Boolean);

    // JobPosition in TO_BE_APPROVED & user semplice & form valido -> disattivare
    if (this.jobPosition?.currentStatus === JobPositionStatusEnumTypes.TO_BE_APPROVED && valid && this.usrService.isHRBP) {
      this.textAreaIsVisible = false;
      return;
    }
    // Posizione OPEN & user semplice & form valido -> attivare
    if (positionStatus === JobPositionStatusEnumTypes.OPEN && valid) {
      this.textAreaIsVisible = true;
      return;
    }
    // Posizione PUBLISHED & super user & form valido -> attivare
    if (positionStatus === JobPositionStatusEnumTypes.PUBLISHED && this.usrService.isEBRM && valid) {
      this.textAreaIsVisible = true;
      return;
    }
    // Posizione in DRAFT | TO_BE_APPROVED | CLOSED_FILLED | CLOSED_EMPTY | SUSPENDED | -> attivare
    if (valid &&
      (positionStatus === JobPositionStatusEnumTypes.DRAFT
        || positionStatus === JobPositionStatusEnumTypes.TO_BE_APPROVED
        || positionStatus === JobPositionStatusEnumTypes.CLOSED_FILLED
        || positionStatus === JobPositionStatusEnumTypes.CLOSED_EMPTY
        || positionStatus === JobPositionStatusEnumTypes.SUSPENDED)) {
      this.textAreaIsVisible = true;
      return;
    }
    this.textAreaIsVisible = false;
  }

  /**
   * Setta nel service un valore booleano responsabile della visualizzazione dei bottoni di conferma/annullamento
   *
   * @param positionStatus
   */
  showChangeStausButtons(positionStatus: string) {
    const { valid: P } = this.processForm;
    const { valid: S } = this.stakeholderForm;
    const { valid: J } = this.jobDescriptionForm;
    const { valid: Q } = this.questionScreeningForm;
    /** True quando tutti i campi obbligatori dei form sono complilati  */
    const valid = [P, S, J, Q].every(Boolean);

    // Posizione in DRAFT o posizione selezionata DRAFT -> attivare
    if (positionStatus === JobPositionStatusEnumTypes.DRAFT) {
      this.actionButtonsIsVisible = true;
      return;
    }
    // JobPosition in TO_BE_APPROVED & utene semplice & form valido -> disattivare

    // Posizione PUBLISHED & super user & form valido -> attivare
    if (positionStatus === JobPositionStatusEnumTypes.PUBLISHED && this.usrService.isEBRM && valid) {
      this.actionButtonsIsVisible = true;
      return;
    }

    //user EBRM & form invalido -> attivare
    if (positionStatus === JobPositionStatusEnumTypes.PUBLISHED && this.usrService.isEBRM && !valid) {
      this.actionButtonsIsVisible = true;
      return;
    }

    // Posizione non ancora messa in TO_BE_APPROVED & utene semplice & form valido -> attivare
    if (positionStatus === JobPositionStatusEnumTypes.TO_BE_APPROVED && this.usrService.isHRBP && valid) {
      this.actionButtonsIsVisible = true;
      return;
    }
    // Posizione OPEN & super user & form valido -> attivare
    if (positionStatus === JobPositionStatusEnumTypes.OPEN && this.usrService.isEBRM && valid) {
      this.actionButtonsIsVisible = true;
      return;
    }
    // Posizione PUBLISHED & super user & form valido -> attivare
    if (positionStatus === JobPositionStatusEnumTypes.PUBLISHED && this.usrService.isEBRM && valid) {
      this.actionButtonsIsVisible = true;
      return;
    }
    // Posizione CLOSED_FILLED & user qualunque & form valido -> attivare
    if (positionStatus === JobPositionStatusEnumTypes.CLOSED_FILLED) {
      this.actionButtonsIsVisible = true;
      return;
    }
    // Posizione CLOSED_EMPTY & user qualunque & form valido -> attivare
    if (positionStatus === JobPositionStatusEnumTypes.CLOSED_EMPTY) {
      this.actionButtonsIsVisible = true;
      return;
    }
    // Posizione SUSPENDED & user qualunque & form valido -> attivare
    if (positionStatus === JobPositionStatusEnumTypes.SUSPENDED) {
      this.actionButtonsIsVisible = true;
      return;
    }

    this.actionButtonsIsVisible = false;
  }

  /**
   * @returns Form relativo alla pagina in cui ci si trova
   *
   * @param url
   */
  public getFormBasedOnUrl(url: string): FormGroup {
    const idx = this.getActiveStepperIdxBasedOnUrl(url);

    switch (idx) {
      case 1:
        return this.stakeholderForm;
      case 2:
        return this.jobDescriptionForm;
      case 3:
        return this.questionScreeningForm;
      default:
        return this.processForm;
    }
  }

  /**
   * Ritorna true se viene trovato lo step altrimenti false
   *
   * @param nextStepIdx
   * @returns { boolean }
   */
  checkStepExistance(nextStepIdx: number): boolean {
    return this.tabItems.some((_, idx: number) => idx === nextStepIdx);
  }

  /**
   * Per poter salvare La posizione BISOGNA inserire almeno il titolo, se non viene inserito ritorna false altrimenti true
   *
   * @returns { boolean }
   */
  public canUpdateCreationProcess(): boolean {
    const title = this.fs.getSingleValueOutOfControl<string>({ control: this.title });

    if (!title) {
      return false;
    }
    return true;
  }

  /**
   * Rimuove il simbolo della valuta e trasforma in numero l'importo inserito in un numero
   *
   * @param currency
   * @returns
   */
  formatCurrency(currency: string) {
    const locale = navigator.language;
    const CODE = getLocaleCurrencyCode(locale);
    if (CODE) {
      const sym = getCurrencySymbol(CODE, "narrow", locale);

      const importWithoutSym: string = currency.replace(sym, "").replace(/\./g, "").trim();
      const parseToNumber = parseFloat(importWithoutSym);

      return parseToNumber;
    }
    return;
  }

  /**
   * Compara tutti i valori e ritorna true se anche solo un valore è cambiato altrimenti false
   */
  public get formsValuesIsChanged() {
    const FORM_CHANGE: boolean[] = [
      /** PROCESS DATI OBBLIGATORI */
      this.fs.compareControlSingleValue({ control: this.title, compared: this.jobPosition, target: FormCL.TITLE }),
      this.fs.compareControlSingleValue({ control: this.openPosition, compared: this.jobPosition, target: FormCL.OPEN_POSITION }),
      this.fs.compareControlSingleValue({ control: this.organizationUnit, compared: this.jobPosition, target: FormCL.ORGANIZATION_UNIT }),
      this.jobPosition?.company ? this.fs.compareArraySingleValue({ control: this.perimeter, compared: this.jobPosition, target: [FormCL.COMPANY, FormCL.PERIMETER] }) : true,
      this.fs.compareArraySingleValue({ control: this.company, target: FormCL.COMPANY, compared: this.jobPosition }),
      this.fs.compareArraySingleValue({ control: this.contractType, target: FormCL.CONTRACT_TYPE, compared: this.jobPosition }),
      this.fs.compareMultiValues({ control: this.location, compared: this.jobPosition, target: FormCL.LOCATION }),
      this.castFalsyValue(this.jobPosition?.retributionEnabled) === this.castFalsyValue(this.retributionEnabled),
      this.castFalsyValue(this.jobPosition?.referralEnabled) === this.castFalsyValue(this.referralEnabled),

      /** OPZIONALI */
      this.fs.compareArraySingleValue({ control: this.jobField, target: FormCL.JOB_FIELD, compared: this.jobPosition }),
      this.fs.compareControlSingleValue({ control: this.referral, compared: this.jobPosition, target: FormCL.REFERRAL }),
      this.fs.compareArraySingleValue({ control: this.employmentLevel, compared: this.jobPosition, target: FormCL.EMPLOYMENT_LEVEL }),
      this.fs.compareControlSingleValue({ control: this.retributionFrom, compared: this.jobPosition, target: FormCL.RETRIBUTION_FROM }),
      this.fs.compareControlSingleValue({ control: this.retributionTo, compared: this.jobPosition, target: FormCL.RETRIBUTION_TO }),
      this.fs.compareDate({ date: this.expectedStartDateSelected, compareDate: this.jobPosition?.expectedStartDate }),

      /** STAKEHOLDER DATI OBBLIGATORI */
      this.fs.compareMultiValues({ control: this.hrbps, compared: this.jobPosition, target: FormCL.HRBP, bindValue: FormCL.USER_ID }),
      this.fs.compareMultiValues({ control: this.lineManagers, compared: this.jobPosition, target: FormCL.LINE_MANAGER, bindValue: FormCL.USER_ID }),

      /** OPZIONALI */
      this.fs.compareList(this.competences, this.jobPosition?.lineManagerCompetences),
      this.fs.compareControlSingleValue({ control: this.headHunter, compared: this.jobPosition, target: FormCL.HEADHUNTER }),
      this.castFalsyValue(this.jobPosition?.headHunterEnabled) === this.castFalsyValue(this.headHunterEnabled),

      /** JOBDESCRIPTION DATI OBBLIGATORI */
      this.fs.compareControlSingleValue({ control: this.companyProfileDescription, compared: this.jobPosition, target: FormCL.COMPANY_PROFILE_DESCRIPTION }),
      this.fs.compareControlSingleValue({ control: this.competencesDescription, compared: this.jobPosition, target: FormCL.COMPETENCE_DESCRIPTION }),
      this.fs.compareControlSingleValue({ control: this.jobDescription, compared: this.jobPosition, target: FormCL.JOB_DESCRIPTION }),

      /** QUESTION & SCREENING */
      this.fs.compareArraySingleValue({ control: this.screeningTest, compared: this.jobPosition, target: FormCL.SCREENING_TEST_COLLECTION })
    ];

    return FORM_CHANGE.some(b => !b);
  }

  /**
   * Esegui un cast dei valori falsy (e.g undefined | null) restituendo un booleano confrontabile più agevolmente
   *
   * @param value
   * @returns {boolean}
   */
  private castFalsyValue(value: boolean | undefined | null): boolean {
    if (!value) {
      return false;
    }
    return true;
  }

  /**
   * Crea un oggetto con i campi del form riempiti.
   *
   * L' oggetto viene poi passato al servizio che creerà una nuova job position se la job position non esiste altrimenti verrà eseguito l' update
   */
  public get creationProcess(): JobPosition {
    let updateParamms;

    if (this.jobPosition) {
      updateParamms = {
        headHunterEnabled: this.headHunterEnabled ?? false,
        jobPositionId: this.jobPosition.jobPositionId,
        progressiveCodeId: this.jobPosition.progressiveCodeId,
        version: this.jobPosition.version
      };
    }

    const FORMS_FIELDS: JobPosition = {

      /** PROCESS */
      title: this.fs.getSingleValueOutOfControl<string>({ control: this.title }),

      openedPositionsCount: this.fs.getSingleValueOutOfControl<number>({ control: this.openPosition, formatter: parseInt }),

      currentStatus: JobPositionStatusEnumTypes.DRAFT,

      company: this.setCompany(),

      jobFieldId: this.fs.getSingleValueOutOfArray<string>(this.jobField, FormCL.JOB_FIELD_ID),
      jobField: this.setJobField(),

      contractTypeId: this.fs.getSingleValueOutOfArray<string>(this.contractType, FormCL.CONTRACT_TYPE_ID),
      contractType: this.setContractType(),

      locationIds: this.fs.getMultiValuesOutOfArray<string[]>(this.location, FormCL.LOCATION_ID),
      locations: this.setLocation(),

      referralAmount: this.fs.getSingleValueOutOfControl<number>({ control: this.referral, formatter: this.formatCurrency }),

      employmentLevelId: this.fs.getSingleValueOutOfArray<string>(this.employmentLevel, FormCL.EMPLOYMENT_ID),
      employmentLevel: this.setEmploymentLevel(),

      retributionFrom: this.fs.getSingleValueOutOfControl<number>({ control: this.retributionFrom, formatter: this.formatCurrency }),
      retributionTo: this.fs.getSingleValueOutOfControl<number>({ control: this.retributionTo, formatter: this.formatCurrency }),

      expectedStartDate: this.expectedStartDateSelected,

      organizationUnit: this.fs.getSingleValueOutOfControl<string>({ control: this.organizationUnit }),

      referralEnabled: this.referralEnabled ?? false,
      retributionEnabled: this.retributionEnabled ?? false,

      /** STAKEHOLDER */
      hrbpIds: this.fs.getMultiValuesOutOfArray<string[]>(this.hrbps, FormCL.USER_ID),
      hrbps: this.setHrbps(),

      lineManagerIds: this.fs.getMultiValuesOutOfArray<string[]>(this.lineManagers, FormCL.USER_ID),
      lineManagers: this.setLineManager(),

      lineManagerCompetences: this.setLineManagerCompetences(),

      headHunterEnabled: this.headHunterEnabled ?? false,
      headHunter: this.fs.getSingleValueOutOfControl<string>({ control: this.headHunter }),

      /** JOB DESCRIPTION */
      companyProfileDescription: this.fs.getSingleValueOutOfControl<string>({ control: this.companyProfileDescription }),
      jobDescription: this.fs.getSingleValueOutOfControl<string>({ control: this.jobDescription }),
      competencesDescription: this.fs.getSingleValueOutOfControl<string>({ control: this.competencesDescription }),

      /** QUESTION & SCREENING */
      screeningTestCollectionId: this.fs.getSingleValueOutOfArray<string>(this.screeningTest, FormCL.SCREENING_TEST_ID),
      screeningTestCollection: this.setScreeningTestCollection()

    } as JobPosition;

    if (this.jobPosition?.surveyTemplate) {
      Object.assign(FORMS_FIELDS, this.jobPosition?.surveyTemplate);
    }
    if (this.jobPosition?.surveyTemplateId) {
      Object.assign(FORMS_FIELDS, this.jobPosition?.surveyTemplateId);
    }



    Object.assign(FORMS_FIELDS, updateParamms);

    return FORMS_FIELDS;
  }

  /**Setta il campo ScreeningTestCollection
   */
  setScreeningTestCollection() {
    const screeningTestCollectionCl: ScreeningTestCollection = {
      screeningTestCollectionId: "",
      collectionName: "",
      screeningTests: []
    };

    const screeningTestCollectionId = this.fs.getSingleValueOutOfArray<string>(this.screeningTest, FormCL.SCREENING_TEST_ID);
    const collectionName = this.fs.getSingleValueOutOfArray<string>(this.screeningTest, FormCL.COLLECTION_NAME);
    const screeningTests = this.fs.getSingleValueOutOfArray<ScreeningTest[]>(this.screeningTest, FormCL.SCREENING_TEST_LIST);

    if (screeningTestCollectionId) {
      screeningTestCollectionCl.screeningTestCollectionId = screeningTestCollectionId;
    }

    if (collectionName) {
      screeningTestCollectionCl.collectionName = collectionName;
    }

    if (screeningTests) {
      screeningTestCollectionCl.screeningTests = screeningTests;
    }

    return screeningTestCollectionCl;
  }

  /**
   * Setta il campo LineManagerCompetences
   */
  private setLineManagerCompetences() {
    const lineManagerCompetencesCl: string[] = [
      this.fs.getSingleValueOutOfControl<string>({ control: this.competence1 }),
      this.fs.getSingleValueOutOfControl<string>({ control: this.competence2 }),
      this.fs.getSingleValueOutOfControl<string>({ control: this.competence3 }),
      this.fs.getSingleValueOutOfControl<string>({ control: this.competence4 })
    ];

    return lineManagerCompetencesCl.filter(Boolean);
  }

  /**
   * Setta il campo LineManager
   */
  private setLineManager() {
    let lineManagerCl: SimpleUser[] = [];
    const lineManager = this.fs.getMultiValuesOutOfArray<SimpleUser[]>(this.lineManagers);
    if (!lineManager) {
      return undefined;
    }

    lineManagerCl = lineManager;

    return lineManagerCl;
  }

  /**
   * Setta il campo Hrbps
   */
  private setHrbps() {
    let hrbpsCl: SimpleUser[] = [];
    const hrbps = this.fs.getMultiValuesOutOfArray<SimpleUser[]>(this.hrbps);
    if (!hrbps) {
      return undefined;
    }

    hrbpsCl = hrbps;

    return hrbpsCl;
  }

  /**
   * Setta il campo EmploymentLevel
   */
  private setEmploymentLevel() {
    let employmentLevelCl: EmploymentLevel = {
      employmentLevelId: "",
      name: ""
    };

    const employmentLevel = this.fs.getSingleValueOutOfArray<EmploymentLevel>(this.employmentLevel);
    if (!employmentLevel) {
      return undefined;
    }

    employmentLevelCl = employmentLevel;

    return employmentLevelCl;
  }

  /**
   * Setta il campo JobField
   */
  private setJobField() {
    let jobFieldCl: JobField = {
      jobFieldId: "",
      name: ""
    };

    const jobField = this.fs.getSingleValueOutOfArray<JobField>(this.jobField);
    if (!jobField) {
      return undefined;
    }

    jobFieldCl = jobField;

    return jobFieldCl;
  }

  /**
   * Setta il campo Company
   */
  private setCompany() {
    const companyCl: Company = {
      companyId: "",
      perimeterId: "",
      perimeter: {
        name: "",
        perimeterId: ""
      },
      name: ""
    };

    const company = this.fs.getSingleValueOutOfArray<Company>(this.company);
    const perimeter = this.fs.getSingleValueOutOfArray<CompanyPerimeter>(this.perimeter);
    const perimeterId = this.fs.getSingleValueOutOfArray<string>(this.perimeter, FormCL.PERIMETER_ID) ?? "";

    if (!company && !perimeter) {
      return undefined;
    }

    if (!perimeter) {
      companyCl.perimeter = undefined;
    }

    if (company) {
      companyCl.name = company.name;
      companyCl.companyId = company.companyId;
    }

    if (perimeter) {
      companyCl.perimeter = perimeter;
      companyCl.perimeterId = perimeterId;
    }

    return companyCl;
  }

  /**
   * Setta il campo ContractType
   */
  private setContractType() {
    let contractTypeCl: ContractType = {
      contractTypeId: "",
      name: ""
    };

    const contractType = this.fs.getSingleValueOutOfArray<ContractType>(this.contractType);

    if (!contractType) {
      return undefined;
    }

    contractTypeCl = contractType;

    return contractTypeCl;
  }

  public isEmpty(obj: unknown) {
    if (Array.isArray(obj)) {
      return obj.length === 0 ? true : false;
    }

    return JSON.stringify(obj) === JSON.stringify({});
  }

  /**
   * Setta il campo Location
   */
  private setLocation() {
    let locationsCl: Location[] = [];

    const locations = this.fs.getMultiValuesOutOfArray<Location[]>(this.location);
    if (!locations?.length) {
      return undefined;
    }

    locationsCl = locations;

    return locationsCl;
  }


  /** FORMSGROUP GETTER */
  public get title() {
    return this.processForm.get(FormCL.TITLE) as FormControl;
  }

  public get formCl() {
    return FormCL;
  }

  public get openPosition() {
    return this.processForm.get(FormCL.OPEN_POSITION) as FormControl;
  }

  public get perimeter() {
    return this.processForm.get(FormCL.PERIMETER) as FormArray;
  }

  public get company() {
    return this.processForm.get(FormCL.COMPANY) as FormArray;
  }

  public get jobField() {
    return this.processForm.get(FormCL.JOB_FIELD) as FormArray;
  }

  public get contractType() {
    return this.processForm.get(FormCL.CONTRACT_TYPE) as FormArray;
  }

  public get location() {
    return this.processForm.get(FormCL.LOCATION) as FormArray;
  }

  public get employmentLevel() {
    return this.processForm.get(FormCL.EMPLOYMENT_LEVEL) as FormArray;
  }

  public get retributionFrom() {
    return this.processForm.get(FormCL.RETRIBUTION_FROM) as FormControl;
  }

  public get retributionTo() {
    return this.processForm.get(FormCL.RETRIBUTION_TO) as FormControl;
  }

  public get organizationUnit() {
    return this.processForm.get(FormCL.ORGANIZATION_UNIT) as FormControl;
  }

  public get expectedStartDate() {
    return this.processForm.get(FormCL.EXPECTED_START_DATE) as FormControl;
  }

  public get referral() {
    return this.processForm.get(FormCL.REFERRAL) as FormControl;
  }

  public get hrbps() {
    return this.stakeholderForm.get(FormCL.HRBP) as FormArray;
  }

  public get lineManagers() {
    return this.stakeholderForm.get(FormCL.LINE_MANAGER) as FormArray;
  }
  public get lineManagerscompetence() {
    return this.stakeholderForm.get(FormCL.LINE_MANAGER_COMPETENCE) as FormGroup;
  }

  public get competence1() {
    return this.lineManagerscompetence.get(FormCL.COMPETENCE_1) as FormControl;
  }

  public get competence2() {
    return this.lineManagerscompetence.get(FormCL.COMPETENCE_2) as FormControl;
  }

  public get competence3() {
    return this.lineManagerscompetence.get(FormCL.COMPETENCE_3) as FormControl;
  }

  public get competence4() {
    return this.lineManagerscompetence.get(FormCL.COMPETENCE_4) as FormControl;
  }

  public get competences() {
    return [
      this.appService.stringify(this.competence1.value),
      this.appService.stringify(this.competence2.value),
      this.appService.stringify(this.competence3.value),
      this.appService.stringify(this.competence4.value)
    ];
  }

  public get headHunter() {
    return this.stakeholderForm.get(FormCL.HEADHUNTER) as FormControl;
  }

  public get companyProfileDescription() {
    return this.jobDescriptionForm.get(FormCL.COMPANY_PROFILE_DESCRIPTION) as FormControl;
  }

  public get jobDescription() {
    return this.jobDescriptionForm.get(FormCL.JOB_DESCRIPTION) as FormControl;
  }

  public get competencesDescription() {
    return this.jobDescriptionForm.get(FormCL.COMPETENCE_DESCRIPTION) as FormControl;
  }

  public get screeningTest() {
    return this.questionScreeningForm.get(FormCL.SCREENING_TEST_COLLECTION) as FormArray;
  }

  public async checkPerimeter() {
    this.includeRowByPerimeter = !await this.checkisFDD();
    if (!this.includeRowByPerimeter) {
      this.screeningTest.clearValidators();
      this.screeningTest.clear();
    } else {
      this.screeningTest?.setValidators([Validators.required]);
    }
    this.screeningTest?.updateValueAndValidity();
  }

  public checkAndSetFormQes() {
    if (this.getCurrentStep()?.redirect == CreatePosition.qEs) {
      this.setFormCompiled(this.questionScreeningForm.valid, CreatePosition.qEs);
    }
  }

  public async checkisFDD(): Promise<boolean> {
    let bool = false;
    const companyListPerimeter = await this.backendService.companyListPerimeter();
    const perimeterFromApi: CompanyPerimeter = companyListPerimeter.find((item: CompanyPerimeter) => item.isFDD) ?? { name: "", perimeterId: "", isFDD: false };
    const perimeter: CompanyPerimeter[] = this.perimeter?.value as CompanyPerimeter[];
    perimeter.forEach((el: CompanyPerimeter) => {
      bool = (perimeterFromApi.isFDD == el.isFDD) ? true : false;
    });
    return bool;
  }

}
