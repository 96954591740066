import { inject, Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApplicationDataService } from "src/app/global-service/application-data.service";
import { BackendService } from "src/app/global-service/backend-api.service";
import { EnvironmentLoaderService, GeneralConfig } from "./env-config.service";

Injectable();
export class BaseDataService {
  protected router: Router = inject(Router);
  protected route: ActivatedRoute = inject(ActivatedRoute);
  protected appService: ApplicationDataService = inject(ApplicationDataService);
  protected backendService: BackendService = inject(BackendService);
  protected envService: EnvironmentLoaderService = inject(EnvironmentLoaderService);
  protected env: GeneralConfig = this.envService.getEnvConfig();
  protected tokenKey = this.env.loginType === "local" ? this.env.localTokenKey : this.env.ssoTokenKey;
}