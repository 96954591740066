import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
interface Image {
  star1: string,
  star2: string,
  star3: string,
  star4: string,
  star5: string
}
@Component({
  selector: "star-rating",
  templateUrl: "./star-rating.component.html",
  styleUrls: ["./star-rating.component.scss"]
})
export class StarRatingComponent implements OnChanges {

  @Input() label!: string;
  @Input() name!: string;
  @Input() value!: number;
  @Input() readOnly!: boolean;
  @Input() error!: boolean;
  @Output() onClickStar = new EventEmitter<any>();


  ngOnChanges(changes: SimpleChanges) {
    if (changes["value"] !== undefined)
      this.selectStar(this.value, true);
  }

  image: Image = {
    star1: "assets/img/icons/icon_star_empty.svg",
    star2: "assets/img/icons/icon_star_empty.svg",
    star3: "assets/img/icons/icon_star_empty.svg",
    star4: "assets/img/icons/icon_star_empty.svg",
    star5: "assets/img/icons/icon_star_empty.svg"
  };

  selectStar(value: number, force?: boolean): void {

    if (this.readOnly && force != true)
      return;

    if (this.error)
      this.error = false;

    for (let i = 1; i <= 5; i++) {
      this.image[`star${i}` as keyof Image] = "assets/img/icons/icon_star_" + (i <= value ? "full" : "empty") + ".svg";
    }
    this.onClickStar.emit({ "value": value, "property": this.name });
  }


}