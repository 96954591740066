import { Injectable } from "@angular/core";
import { HomepageSearchFilters } from "applicanttrackingsystem-cl";
import { BaseDataService } from "src/ancestors/base-data.service";
import { Filter } from "src/app/pages/homepage/homepage.component";

@Injectable()
export class JobPositionOrderService extends BaseDataService {
  constructor() {
    super();
  }

  /** Ordinamento applicato */
  public appliedHomeOrders: HomepageSearchFilters = {};
  /** Ordinamento selezionato */
  public selectedOrder?: Filter;
  /** Opzioni di ordinamento disponibili */
  public positionOrdersOptions: Filter[] = [
    { sortingField: "currentStatusDate", applied: false, sortingDesc: false },
    { sortingField: "title", applied: false, sortingDesc: false },
    { sortingField: "companyName", applied: false, sortingDesc: false },
    { sortingField: "countAll", applied: false, sortingDesc: false },
    { sortingField: "countToEvaluate", applied: false, sortingDesc: false },
    { sortingField: "countStop", applied: false, sortingDesc: false },
    { sortingField: "countLongList", applied: false, sortingDesc: false },
    { sortingField: "countShortList", applied: false, sortingDesc: false },
    { sortingField: "countOffer", applied: false, sortingDesc: false },
    { sortingField: "countRefuse", applied: false, sortingDesc: false },
    { sortingField: "countAccept", applied: false, sortingDesc: false }
  ];

  /**
   * Seleziona ordinamento ascendente
   */
  public selectedUp(filter: Filter) {
    this.selectedOrder = filter;
    this.selectedOrder.sortingDesc = false;
  }

  /**
   * Seleziona ordinamento discendente
  */
  public selectedDown(filter: Filter) {
    this.selectedOrder = filter;
    this.selectedOrder.sortingDesc = true;
  }

  public onApplyOrder() {
    if (!this.selectedOrder) {
      return;
    }

    this.selectedOrder.applied = true;
    this.appliedHomeOrders = {
      sortingDesc: this.selectedOrder.sortingDesc,
      sortingField: this.selectedOrder.sortingField
    };
  }

  public removeSelectedOnlyOrder() {
    /** Il filtro non applicato viene eliminato */
    if (this.selectedOrder && !this.selectedOrder.applied) {
      this.selectedOrder = undefined;
    }
  }

  /**
   * Resetta (se selezionato) campo applicato selectedOrder
   */
  public resetSelectedOrder() {
    this.appliedHomeOrders = {};
    this.selectedOrder = undefined;
  }

  public onResetOrder() {
    this.appliedHomeOrders = {};
    this.selectedOrder = undefined;
  }

  public get ordersToSave() {
    return {
      sortingDesc: this.appliedHomeOrders?.sortingDesc,
      sortingField: this.appliedHomeOrders?.sortingField
    };

  }
}
