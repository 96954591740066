import { inject, Injectable } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Filter } from "../homepage/homepage.component";
import { ActionSelect } from "./position-detail.component";
import { JobApplication, JobApplicationsByPositionFilters, JobApplicationStatusEnum, JobApplicationStatusEnumTypes, JobPositionStatus, JobPositionStatusEnum, JobPositionStatusEnumTypes, JobPositionsWithCountersResponse } from "applicanttrackingsystem-cl";
import { FormCommonClasses as FormCL } from "src/app/shared/util/form.classes";
import { UserService } from "src/app/global-service/user.service";
import { BaseDataService } from "src/ancestors/base-data.service";
import { FormService } from "src/app/shared/util/form.service";
import { TranslateService } from "src/app/shared/util/translate.service";

export interface PositionDetaiLocalStoragelFilter {
    currentStatus: JobPositionStatusEnum[];
    keyword: string[];
    reassignmet: boolean;
    referral: boolean;
    talent: boolean;
    feedbackEmail: boolean;
    userDetails: {
        genre: string;
        newItalian: boolean;
        protectedCategory: boolean;
        age: string[];
    }
}

export interface PositionFilter {
    genre?: string;
    ageRange?: string[];
    jobPositionStatus?: string[];
    keyword?: string;
    protectedCategory?: boolean;
    reassigned?: boolean;
    referral?: boolean;
    talent?: boolean;
    feedbackEmail?: boolean;
    newItalian?: boolean;
}

export interface BaseStatus {
    applicationBadge?: JobApplicationStatusEnum;
    positionBadge?: JobPositionStatusEnum;
    label?: string;
}
@Injectable()
export class PositionDetailService extends BaseDataService {
    constructor() {
        super();
    }

    private fb = inject(FormBuilder);
    private fs = inject(FormService);
    public usrService = inject(UserService);
    public translate = inject(TranslateService);

    public detailPositionKey = "DETAIL_POSITION_FILTER";

    /** STAUS ABILITABILI */
    public _status = {
        statusDraft: false,
        statusToBeAproved: false,
        statusOpen: false,
        statusPublished: false,
        statusCloseFilled: false,
        statusCloseEmpty: false,
        statusSuspended: false
    };

    /** FILTRI APPLICATI */
    /** True se ci sono filtri applicati */
    public filterIsApplied?: boolean;
    /** Oggetto che contiene tutti i filtri applicati */
    public appliedPositionFilter: JobApplicationsByPositionFilters = {};
    /** Array di jobApplicationId delle card selezionate */
    public cardsSelected: string[] = [];
    /** Rende visibile la textarea all'interno del drawer di cambio status */
    public textAreaIsVisible?: boolean;
    /** Rende visibili bottoni conferma/annulla all'interno del drawer di cambio status */
    public actionButtonsIsVisible?: boolean;
    /** Stringa che determina il messaggio di avvertimento nella tab di cambio dello stato della creazione della posizione */
    public positionWarnMessage?: string;
    /** Selezione status della posizione */
    public selectedPositionStatus?: JobPositionStatusEnum;
    /** Selezione status della persona relativa ad un determinata posizione */
    public selectedPersonaStatus?: JobPositionStatusEnum;
    /** Ordinamento selezionato */
    public selectedOrder?: Filter;
    /** Ordinamento applicato */
    public appliedOrder?: Filter;

    /** Status selezionati */
    public selectetStatus: {
        /** Status della posizione selezionato */
        jobPositionStatus?: BaseStatus,
        /** Status della posizione per determinata persona selezionato */
        jobApplicationStaus?: BaseStatus
    } = {};

    /** Oggetto contenente tutte le response delle chiamate API da mostrare in pagina*/
    public fetchData: {
        /** Lista dei candidati per determinata job position */
        jobApplicationList?: JobApplication[],
        /** Conteggio dei candidati per determinata job position */
        jobApplicationCount?: number,
        /** Lista dei job position status che vanno a riempire la timeline */
        allStatusForTimeline?: JobPositionStatus[],
        /** Job position ricercata */
        filteredJobPosition?: JobPositionsWithCountersResponse,
        /** Storicon candidature */
        jobApplicationHistory?: JobApplication[]
    } = {};
    /** Nome di un determinato candidato che ha effettuato le candidature */
    public jobApplicationHistoryOwner = "";
    /** DRAWER */
    public drawer: {
        /** */
        showRealizedApplicants?: boolean;
        /** Toggle menu oridinamento */
        showPositionOrders?: boolean;
        /** Toggle menu dei filtri */
        showPositionFilter?: boolean;
        /** Toggle status */
        showPositionStatus?: boolean;
        /** Toggle status */
        showPersonaStatus?: boolean;
        /** Toggle timeline */
        showTimeline?: boolean;
    } = {};
    /** Ricavo i nomi dei JobPositionStatus e li salvo in un array per poterli usare in pagina */
    public jobPositionEnumMapped = Object.keys(JobPositionStatusEnumTypes).map(status => status);
    public jobApplicationEnumMapped = Object.keys(JobApplicationStatusEnumTypes).map(status => status);

    /** Struttura per recuperare e settare i filtri selezionati dall'utente */
    public positionFilter: FormGroup = this.fb.group({
        [FormCL.STATUS]: this.fb.array([]),
        [FormCL.KEYWORD]: [null],
        [FormCL.REASSIGNMENT]: [null],
        [FormCL.REFERRAL]: [null],
        [FormCL.TALENT]: [null],
        [FormCL.NEW_ITALIAN]: [null],
        [FormCL.GENRE]: [null],
        [FormCL.PROTECTED_CATEGORY]: [null],
        [FormCL.AGE_RANGE]: this.fb.array([]),
        [FormCL.FEEDBACK_EMAIL]: [null]
    });

    public perPageOption = [
        { numRecord: 5 },
        { numRecord: 10 },
        { numRecord: 20 },
        { numRecord: 50 }
    ];

    protected jobComment = this.fb.group({
        jobApplicationComment: [null],
        jobPositionComment: [null]
    });

    public genericSetup: FormGroup = this.fb.group({
        pagination: this.fb.array([
            this.fb.group({
                numRecord: [this.perPageOption[0].numRecord]
            })
        ]),
        dateFrom: [],
        dateTo: [],
        currentPage: [1]
    });

    public set currentPage(page: number) {
        this.genericSetup.get("currentPage")?.setValue(page);
    }
    public get currentPage() {
        return this.genericSetup.get("currentPage")?.value as number;
    }
    public get numRecord() {
        return (this.genericSetup.get("pagination") as FormArray).controls.at(0)?.get("numRecord")?.value as number;
    }

    public positionOrdersOptions: Filter[] = [
        { sortingField: "surname", applied: false, sortingDesc: false },
        { sortingField: "lastStatusDate", applied: false, sortingDesc: false },
        { sortingField: "jobFitnessPercentage", applied: false, sortingDesc: false }
    ];

    public genreDropDown = [
        { label: "Uomo", value: "M" },
        { label: "Donna", value: "F" },
        { label: "Tutti", value: null }
    ];

    public actionOptions: ActionSelect.ActionInterface[] = [
        { action: ActionSelect.STATUS, label: this.translate.instant<string>("position.detail.CHANGE_STATUS_TITLE"), icon: "assets/img/icons/direction.svg" },
        { action: ActionSelect.SHOW, label: this.translate.instant<string>("generic.VISUALIZE"), icon: "assets/img/icons/view.svg" },
        { action: ActionSelect.TIMELINE, label: this.translate.instant<string>("position.detail.TIMELINE"), icon: "assets/img/icons/timeline.svg" },
        { action: ActionSelect.CLONE, label: this.translate.instant<string>("position.detail.DUPLICATE"), icon: "assets/img/icons/assign.svg" }
    ];

    public newDropdown = [
        { label: "Si", value: true },
        { label: "No", value: false }
    ];

    public protectedCategoryDropdown = [
        { label: "Si", value: true },
        { label: "No", value: false }
    ];

    public ageDropdown = [
        //{ label: "Tutti", value: undefined },
        { label: "Under 27", value: "0-27" },
        { label: "Tra 28 e 30", value: "28-30" },
        { label: "Tra 31 e 35", value: "31-35" },
        { label: "Tra 36 e 40", value: "36-40" },
        { label: "Tra 41 e 45", value: "41-45" },
        { label: "Tra 46 e 50", value: "46-50" },
        { label: "Over 50", value: "50-99" }
    ];

    public statusChip: string[] = [
        "Tutti gli status",
        "Bozza",
        "In approvazione",
        "Aperta",
        "Pubblicata",
        "Chiusa - vuota",
        "Chiusa - filled",
        "Sospesa"
    ];


    public personaStatus: BaseStatus[] = [
        { applicationBadge: "STOP", label: "STOP" },
        { applicationBadge: "TO_EVALUATE", label: "DA VALUTARE" },
        { applicationBadge: "LONG_LIST", label: "LONG LIST" },
        { applicationBadge: "SHORT_LIST", label: "SHORT LIST" },
        { applicationBadge: "OFFER", label: "OFFERTA" },
        { applicationBadge: "ACCEPT", label: "ACCETTA" },
        { applicationBadge: "REFUSE", label: "RIFIUTA" }
    ];

    public positionStatus: BaseStatus[] = [
        { positionBadge: "DRAFT" },
        { positionBadge: "TO_BE_APPROVED" },
        { positionBadge: "OPEN" },
        { positionBadge: "PUBLISHED" },
        { positionBadge: "CLOSED_FILLED" },
        { positionBadge: "CLOSED_EMPTY" },
        { positionBadge: "SUSPENDED" }
    ];


    /** Valore per simulare una posizione che non è mai stata apera */
    public hasBeenOpen?: boolean;
    /** Valore per simulare una posizione approvata dal un EBRM */
    public positionApprove = false;
    /** Valore per simulare posizione aperta */
    public positionIsOpen = true;



    public async canAddModifyOptionToActions(jobPositionId: string) {

        const jobPositionEditable = await this.backendService.jobPositionEditable(jobPositionId);

        if (jobPositionEditable) {
            this.actionOptions.push({ action: ActionSelect.MODIFY, label: this.translate.instant<string>("generic.UPDATE"), icon: "assets/img/icons/edit-pen.svg" });
        }

        if (this.fetchData.filteredJobPosition?.currentStatus === JobPositionStatusEnumTypes.PUBLISHED) {
            this.actionOptions.push({ action: ActionSelect.SHARE, label: this.translate.instant<string>("generic.SHARE"), icon: "assets/img/icons/share.svg" });
        }

        this.actionOptions.push({ action: ActionSelect.ONBOARDING, label: this.translate.instant<string>("position.detail.ONBOARDING"), icon: "assets/img/icons/icon-open.svg" });

    }
    /**
    * Attiva/disattiva campi selezionabili nel drawer del cambio status
    */
    public setPositionStatus() {
        const DISABLE = true;
        const ENABLE = false;

        if (!this.fetchData.filteredJobPosition?.hasAllMandatoryFieldsForPublish) {
            /** Disabilita tutti i campi tranne bozza */
            this._status.statusOpen = DISABLE;
            this._status.statusPublished = DISABLE;
            this._status.statusToBeAproved = DISABLE;
            this._status.statusCloseEmpty = DISABLE;
            this._status.statusCloseFilled = DISABLE;
            this._status.statusSuspended = DISABLE;

            return;
        }

        /** utente EBRM --> “Sospesa”, “Aperta”, “Pubblicata”  */
        if (this.usrService.isEBRM) {
            /** Posizione mai messa in status aperta */
            if (!this.hasBeenOpen) {
                this._status.statusToBeAproved = ENABLE;
                this._status.statusDraft = ENABLE;
            }
            /** Se la posizione è bozza */
            if (this.fetchData.filteredJobPosition?.currentStatus === JobPositionStatusEnumTypes.DRAFT) {
                this._status.statusDraft = ENABLE;
                this._status.statusToBeAproved = DISABLE;
                this._status.statusCloseEmpty = DISABLE;
                this._status.statusCloseFilled = DISABLE;
            }

            /** Se lo status non è ne bozza ne sospeso vuol dire che la posizione è già in uno stato di approvazione/aperta/pubblicata e non può tornare ad un precedente status */
            if (this.fetchData.filteredJobPosition?.currentStatus !== JobPositionStatusEnumTypes.DRAFT && this.fetchData.filteredJobPosition?.currentStatus !== JobPositionStatusEnumTypes.SUSPENDED) {
                this._status.statusToBeAproved = DISABLE;
                this._status.statusDraft = DISABLE;
            }

            if (this.fetchData.filteredJobPosition?.currentStatus !== JobPositionStatusEnumTypes.SUSPENDED && this.hasBeenOpen) {
                this._status.statusToBeAproved = DISABLE;
            }

            if (this.fetchData.filteredJobPosition?.currentStatus === JobPositionStatusEnumTypes.PUBLISHED || this.fetchData.filteredJobPosition?.currentStatus === JobPositionStatusEnumTypes.CLOSED_EMPTY || this.fetchData.filteredJobPosition?.currentStatus === JobPositionStatusEnumTypes.CLOSED_FILLED) {
                this._status.statusToBeAproved = DISABLE;
                this._status.statusPublished = ENABLE;
                this._status.statusCloseEmpty = ENABLE;
                this._status.statusCloseFilled = ENABLE;
            }

            if (this.fetchData.filteredJobPosition?.currentStatus === JobPositionStatusEnumTypes.TO_BE_APPROVED) {
                this._status.statusSuspended = DISABLE;
            }

            if (this.fetchData.filteredJobPosition?.currentStatus === JobPositionStatusEnumTypes.OPEN) {
                this._status.statusCloseEmpty = ENABLE;
                this._status.statusCloseFilled = ENABLE;
            }

            if (this.fetchData.filteredJobPosition?.currentStatus === JobPositionStatusEnumTypes.CLOSED_FILLED) {
                this._status.statusCloseEmpty = DISABLE;
            }
            if (this.fetchData.filteredJobPosition?.currentStatus === JobPositionStatusEnumTypes.CLOSED_EMPTY) {
                this._status.statusCloseFilled = DISABLE;
            }
        }


        /** utente HRBP --> “Da approvare” e “Sospesa” */
        if (this.usrService.isHRBP) {
            /** Abilita i suddetti campi se sei un utente HRBP e la posizione non è stata ancora aperta */
            if (this.hasBeenOpen) {
                this._status.statusToBeAproved = DISABLE;
                this._status.statusSuspended = DISABLE;
                this._status.statusDraft = DISABLE;
            } else {
                this._status.statusToBeAproved = ENABLE;
                this._status.statusSuspended = ENABLE;
            }
            /** Disabilita gli altri */
            this._status.statusOpen = DISABLE;
            this._status.statusPublished = DISABLE;
            this._status.statusCloseEmpty = DISABLE;
            this._status.statusCloseFilled = DISABLE;

            if (this.fetchData.filteredJobPosition?.currentStatus === JobPositionStatusEnumTypes.TO_BE_APPROVED
                || this.fetchData.filteredJobPosition?.currentStatus === JobPositionStatusEnumTypes.OPEN
                || this.fetchData.filteredJobPosition?.currentStatus === JobPositionStatusEnumTypes.PUBLISHED
                || this.fetchData.filteredJobPosition?.currentStatus === JobPositionStatusEnumTypes.CLOSED_EMPTY
                || this.fetchData.filteredJobPosition?.currentStatus === JobPositionStatusEnumTypes.CLOSED_FILLED) {
                this._status.statusToBeAproved = DISABLE;
            }
            if (this.fetchData.filteredJobPosition?.currentStatus === JobPositionStatusEnumTypes.TO_BE_APPROVED) {
                this._status.statusSuspended = DISABLE;
            }
        }

        // Questo sovrascrive tutti i precedenti, perchè si applica a tutti. In caso di HasBeenOpen true vanno disabilitati Bozza e da Approvare per tutti.
        if (this.hasBeenOpen) {
            this._status.statusToBeAproved = DISABLE;
            this._status.statusDraft = DISABLE;
        }
    }

    /**
     *
     * @param positionStatus Selezione dell'utente
     */
    setChangeStatusDrawer(position: { positionStatus?: JobPositionStatusEnum, personaStatus?: JobPositionStatusEnum }) {
        if (position.positionStatus) {
            this.selectedPositionStatus = position.positionStatus;
        }

        if (position.personaStatus) {
            this.selectedPersonaStatus = position.personaStatus;
        }

        /** Gestisce la visualizzazione della textarea all'interno del tab del cambio di status */
        this.showChangeStatusTextArea(position.positionStatus ?? position.personaStatus);
        /** Gestisce la visualizzazione dei bottoni all'interno del tab del cambio di status */
        this.showChangeStausButtons(position.positionStatus ?? position.personaStatus);
        /** Gestisce la visualizzazione dei messaggio di avvertimento all'interno del tab del cambio di status */
        this.showChangeStatusWarn(position.positionStatus ?? position.personaStatus);
    }

    /**
     * Imposta i campi dei filtri nel caso in cui siano stati applicati dei filtri sulla posizione.
     */
    public setFiltersFields() {
        const appliedFilter = this.appliedPositionFilter;

        // Imposta il campo di ordinamento selezionato se presente nel filtro applicato
        if (appliedFilter?.sortingField) {
            this.selectedOrder = {
                applied: true,
                sortingDesc: appliedFilter.sortingDesc ?? false,
                sortingField: appliedFilter.sortingField
            };
        }
        // Imposta i valori dei campi dei filtri utilizzando i valori presenti nel filtro applicato
        appliedFilter.jobApplicationStatus?.forEach(status => {
            this.fs.setValueOnArray(this.status, { label: status });
        });
        appliedFilter.ageRange?.forEach(ageRange => {
            this.fs.setValueOnArray(this.ageRange, { label: ageRange });
        });

        this.fs.setValueOnControl(this.reassignment, appliedFilter.reassigned);
        this.fs.setValueOnControl(this.referral, appliedFilter.referral);
        this.fs.setValueOnControl(this.talent, appliedFilter.talent);
        this.fs.setValueOnControl(this.newIta, appliedFilter.newItalian);
        this.fs.setValueOnControl(this.genre, appliedFilter.gender);
        this.fs.setValueOnControl(this.protectedCategory, appliedFilter.legallyProtected);
        this.fs.setValueOnControl(this.feedbackEmail, appliedFilter.feedbackMailSent);
        this.fs.setValueOnControl(this.keyword, appliedFilter.keyword);
    }

    /**
     * @param positionStatus
     *
     * Setta nel service il messaggio di avvertimento relativo alla selezione
     */
    showChangeStatusWarn(positionStatus?: JobPositionStatusEnum) {
        const notValid = !this.fetchData.filteredJobPosition?.hasAllMandatoryFieldsForPublish;
        /** Setta lo status selezionato come status attivo per poterlo applicare successivamente */
        // PUBLISHED non ha messaggi di avvertimento
        // if (positionStatus === JobPositionStatusEnumTypes.PUBLISHED) {
        //   this.positionWarnMessage = $localize`:@@position.status.TO_APPROVE:position.status.TO_APPROVE`;
        //   return;
        // }
        if (positionStatus === JobPositionStatusEnumTypes.DRAFT && notValid) {
            this.positionWarnMessage = $localize`:@@position.status.INVALID:position.status.INVALID`;
            return;
        }
        if (positionStatus === JobPositionStatusEnumTypes.TO_BE_APPROVED && this.usrService.isHRBP) {
            this.positionWarnMessage = $localize`:@@position.status.TO_APPROVE:position.status.TO_APPROVE`;
            return;
        }
        if (positionStatus === JobPositionStatusEnumTypes.OPEN && this.usrService.isHRBP) {
            this.positionWarnMessage = $localize`:@@position.status.OPEN:position.status.OPEN`;
            return;
        }
        if (positionStatus === JobPositionStatusEnumTypes.CLOSED_FILLED) {
            this.positionWarnMessage = $localize`:@@position.status.FILLED:position.status.FILLED`;
            return;
        }
        if (positionStatus === JobPositionStatusEnumTypes.CLOSED_EMPTY) {
            this.positionWarnMessage = $localize`:@@position.status.EMPTY:position.status.EMPTY`;
            return;
        }
        this.positionWarnMessage = "";
        return;
    }

    /**
     * @param positionStatus
     *
     * Setta nel service un valore booleano responsabile della visualizzazione della textarea
     */
    showChangeStatusTextArea(positionStatus?: string) {
        if (positionStatus === JobPositionStatusEnumTypes.DRAFT) {
            this.textAreaIsVisible = true;
            return;
        }
        if (positionStatus === JobPositionStatusEnumTypes.OPEN && this.usrService.isEBRM) {
            this.textAreaIsVisible = true;
            return;
        }
        if (positionStatus === JobPositionStatusEnumTypes.CLOSED_FILLED || positionStatus === JobPositionStatusEnumTypes.CLOSED_EMPTY || positionStatus === JobPositionStatusEnumTypes.SUSPENDED || positionStatus === JobPositionStatusEnumTypes.PUBLISHED) {
            this.textAreaIsVisible = true;
            return;
        }
        this.textAreaIsVisible = false;
    }

    /**
     * Setta nel service un valore booleano responsabile della visualizzazione dei bottoni di conferma/annullamento
     *
     * @param positionStatus
     */
    showChangeStausButtons(positionStatus?: string) {
        if (positionStatus === JobPositionStatusEnumTypes.DRAFT) {
            this.actionButtonsIsVisible = true;
            return;
        }
        if (positionStatus === JobPositionStatusEnumTypes.OPEN && this.usrService.isEBRM) {
            this.actionButtonsIsVisible = true;
            return;
        }
        if (positionStatus === JobPositionStatusEnumTypes.PUBLISHED && this.usrService.isEBRM) {
            this.actionButtonsIsVisible = true;
            return;
        }
        if (positionStatus === JobPositionStatusEnumTypes.CLOSED_FILLED) {
            this.actionButtonsIsVisible = true;
            return;
        }
        if (positionStatus === JobPositionStatusEnumTypes.CLOSED_EMPTY) {
            this.actionButtonsIsVisible = true;
            return;
        }
        if (positionStatus === JobPositionStatusEnumTypes.SUSPENDED) {
            this.actionButtonsIsVisible = true;
            return;
        }
        this.actionButtonsIsVisible = false;
    }


    /**Apre la tab di ordinamento
     */
    openOrderTab() {
        this.drawer.showPositionOrders = true;
    }

    /**
     * Seleziona ordinamento ascendente
     */
    selectedUp(filter: Filter) {
        this.selectedOrder = filter;
        this.selectedOrder.sortingDesc = false;
    }

    /**
     * Seleziona ordinamento discendente
     */
    selectedDown(filter: Filter) {
        this.selectedOrder = filter;
        this.selectedOrder.sortingDesc = true;
    }


    /**
    * Apre la tab dei filtri
    */
    openFilterTab() {
        this.drawer.showPositionFilter = true;
    }

    onOpenMassiveStausChange() {
        this.drawer.showPersonaStatus = true;
    }

    public get positionDetailOnLocalStorage() {
        const positionFilterSaved = localStorage.getItem(this.detailPositionKey);

        return positionFilterSaved ? JSON.parse(positionFilterSaved) as PositionDetaiLocalStoragelFilter : {} as PositionDetaiLocalStoragelFilter;
    }

    public set positionDetailOnLocalStorage(filterToSave: PositionDetaiLocalStoragelFilter) {
        localStorage.setItem(this.detailPositionKey, JSON.stringify(filterToSave));
    }

    public get status() {
        return this.positionFilter.get(FormCL.STATUS) as FormArray;
    }

    public get keyword() {
        return this.positionFilter.get(FormCL.KEYWORD) as FormControl;
    }

    public get reassignment() {
        return this.positionFilter.get(FormCL.REASSIGNMENT) as FormControl;
    }

    public get referral() {
        return this.positionFilter.get(FormCL.REFERRAL) as FormControl;
    }

    public get talent() {
        return this.positionFilter.get(FormCL.TALENT) as FormControl;
    }

    public get feedbackEmail() {
        return this.positionFilter.get(FormCL.FEEDBACK_EMAIL) as FormControl;
    }

    public get genre() {
        return this.positionFilter.get(FormCL.GENRE) as FormControl;
    }

    public get newIta() {
        return this.positionFilter.get(FormCL.NEW_ITALIAN) as FormControl;
    }

    public get protectedCategory() {
        return this.positionFilter.get(FormCL.PROTECTED_CATEGORY) as FormControl;
    }

    public get ageRange() {
        return this.positionFilter.get(FormCL.AGE_RANGE) as FormArray;
    }

    public get jobPositionComment() {
        return this.jobComment.get("jobPositionComment") as FormControl;
    }

    public get jobApplicationComment() {
        return this.jobComment.get("jobApplicationComment") as FormControl;
    }
}
