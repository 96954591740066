<div class="row">
  <!-- BREAD CRUMBS -->
  <div class="col-12">
    <p class="bread-crumb">
      <span (click)="homeRedirect()" class="me-1" i18n="@@position.HOME" role="button">position.HOME</span>
      <img class="align-baseline mx-1" src="assets/img/icons/chevron_right.svg">
      <span *ngIf="createPositionService.mode" class="me-1" i18n="@@detail.NAME" role="button"
        (click)="onNavBack()">detail.NAME</span>
    </p>
  </div>
</div>

<div class="d-flex flex-column flex-md-row justify-content-lg-between align-items-lg-center">
  <!-- TITOLO -->
  <div class="d-flex flex-column flex-sm-row align-items-sm-center">
    <p class="position-title">
      <span class="trunc max-title-w" *ngIf="createPositionService.jobPosition">
        <!-- {{ createPositionService.currentYear }}- -->
        {{ createPositionService.jobPosition.progressiveCode?.generatedCode ??
        createPositionService.hypoteticalProgressivePositionNumber }}
        {{ createPositionService.jobPosition.title }}
      </span>
      <span *ngIf="!createPositionService.jobPosition" i18n="@@position.DEFAULT">position.DEFAULT</span>
    </p>
    <generali-badge class="ms-0 ms-sm-1 mt-3 mt-sm-0 mb-2 mb-sm-0"
      [badgeType]="createPositionService.jobPosition?.currentStatus || 'DRAFT'" badgeCategory="FULL">
    </generali-badge>
  </div>

  <div class="d-flex flex-column flex-sm-row action-button pe-0 pe-xl-4 mt-2 mt-lg-0">
    <generali-button btnItem="{{ 'create.CHANGE_STATUS' | translate }}" btnSize="auto" btnStyle="green"
      (onClick)="onChangeStatus()" [loading]="createPositionService.onSaveLoading">
    </generali-button>
    <div class="mx-0 mx-sm-2 my-2 my-sm-0">
      <generali-button btnItem="{{ 'create.PREVIEW' | translate }}" btnSize="auto" btnStyle="ghost"
        (onClick)="onPreview()" [loading]="createPositionService.onSaveLoading">
      </generali-button>
    </div>
    <generali-button [disabled]="false" btnItem="{{ 'create.SAVE' | translate }}" btnSize="auto" btnStyle="red"
      (onClick)="onSave()" [loading]="createPositionService.onSaveLoading">
    </generali-button>
  </div>
</div>

<div class="tab-row d-md-flex flex-row w-100 mb-4 mt-4 d-none">
  <generali-status-tab *ngFor="let item of createPositionService.tabItems" [item]="item"
    (onStepClick)="onStepClick($event)">
  </generali-status-tab>
</div>

<div style="max-width: 222px;" class="my-4 d-md-none d-block">
  <generali-simple-select [dropdownData]="createPositionService.tabItems" bindLabel="name" bindValue="redirect"
    placeholder="{{ 'position.SELECTION' | translate }}" [setSelected]="getCurrentStepIdx()"
    (onTabClick)="onStepClick($event)" [setSelected]="createPositionService.mobileActiveStep">
  </generali-simple-select>
</div>

<router-outlet></router-outlet>

<div class="divider mb-4" *ngIf="!router.url.includes('publishing-channels')"></div>

<div class="row">
  <div class="col-12 col-md-6 col-lg-3 mb-5" *ngIf="!router.url.includes('publishing-channels')">
    <generali-button btnSize="auto" btnStyle="red" btnItem="AVANTI" (onClick)="goToNextStep()"></generali-button>
  </div>
</div>

<!-- CAMBIO STATUS -->
<generali-drawer [showDrawer]="createPositionService.showChangeStatusDrawer"
  drawerTitle="{{ 'position.drawer.TITLE' | translate }}" drawerSubTitle="{{ 'position.drawer.SUBTITLE' | translate}}"
  (onClosingDrawerTab)="onChangeStatus()">
  <ng-container main>
    <div class="ps-2">
      <div class="d-flex">
        <generali-radio bindName="status" [isDisabled]="createPositionService.status.statusDraft"
          [isSelected]="createPositionService.statusSelected === positionStatusOptions.DRAFT"
          (onSelectRadio)="createPositionService.setChangeStatusDrawerActions(positionStatusOptions.DRAFT)">
          <generali-badge class="ms-3" badgeCategory="FULL" [badgeType]="positionStatusOptions.DRAFT"></generali-badge>
        </generali-radio>
      </div>

      <div class="d-flex">
        <generali-radio bindName="status" [isDisabled]="createPositionService.status.statusToBeAproved"
          [isSelected]="createPositionService.statusSelected === positionStatusOptions.TO_BE_APPROVED"
          (onSelectRadio)="createPositionService.setChangeStatusDrawerActions(positionStatusOptions.TO_BE_APPROVED)">
          <generali-badge class="ms-3" badgeCategory="FULL" [badgeType]="positionStatusOptions.TO_BE_APPROVED">
          </generali-badge>
        </generali-radio>
      </div>

      <div class="d-flex">
        <generali-radio bindName="status" [isDisabled]="createPositionService.status.statusOpen"
          [isSelected]="createPositionService.statusSelected === positionStatusOptions.OPEN"
          (onSelectRadio)="createPositionService.setChangeStatusDrawerActions(positionStatusOptions.OPEN)">
          <generali-badge class="ms-3" badgeCategory="FULL" [badgeType]="positionStatusOptions.OPEN"></generali-badge>
        </generali-radio>
      </div>

      <div class="d-flex">
        <generali-radio bindName="status" [isDisabled]="createPositionService.status.statusPublished"
          [isSelected]="createPositionService.statusSelected === positionStatusOptions.PUBLISHED"
          (onSelectRadio)="createPositionService.setChangeStatusDrawerActions(positionStatusOptions.PUBLISHED)">
          <generali-badge class="ms-3" badgeCategory="FULL" [badgeType]="positionStatusOptions.PUBLISHED">
          </generali-badge>
        </generali-radio>
      </div>

      <div class="d-flex">
        <generali-radio bindName="status" [isDisabled]="createPositionService.status.statusCloseFilled"
          [isSelected]="createPositionService.statusSelected === positionStatusOptions.CLOSED_FILLED"
          (onSelectRadio)="createPositionService.setChangeStatusDrawerActions(positionStatusOptions.CLOSED_FILLED)">
          <generali-badge class="ms-3" badgeCategory="FULL" [badgeType]="positionStatusOptions.CLOSED_FILLED">
          </generali-badge>
        </generali-radio>
      </div>

      <div class="d-flex">
        <generali-radio bindName="status" [isDisabled]="createPositionService.status.statusCloseEmpty"
          [isSelected]="createPositionService.statusSelected === positionStatusOptions.CLOSED_EMPTY"
          (onSelectRadio)="createPositionService.setChangeStatusDrawerActions(positionStatusOptions.CLOSED_EMPTY)">
          <generali-badge class="ms-3" badgeCategory="FULL" [badgeType]="positionStatusOptions.CLOSED_EMPTY">
          </generali-badge>
        </generali-radio>
      </div>

      <div class="d-flex">
        <generali-radio bindName="status" [isDisabled]="createPositionService.status.statusSuspended"
          [isSelected]="createPositionService.statusSelected === positionStatusOptions.SUSPENDED"
          (onSelectRadio)="createPositionService.setChangeStatusDrawerActions(positionStatusOptions.SUSPENDED)">
          <generali-badge class="ms-3" badgeCategory="FULL" [badgeType]="positionStatusOptions.SUSPENDED">
          </generali-badge>
        </generali-radio>
      </div>

      <div class="my-5 mx-1" *ngIf="createPositionService.textAreaIsVisible">
        <generali-text-area txtAreaTitle="{{ 'position.comment.TITLE' | translate }}"
          [txtAreaControl]="createPositionService.changeStatusComment"
          placeholder="{{ 'position.comment.PLACEHOLDER' | translate }}" [rows]="2"></generali-text-area>
      </div>
    </div>
  </ng-container>

  <!-- WARN  -->
  <ng-container warnMessage>
    <div class="warn">
      <generali-warn icon="assets/img/icons/warning.svg" *ngIf="createPositionService.positionWarnMessage">
        <ng-container warn>
          <p class="m-0 fw-bold" i18n="@@generic.WARNING">generic.WARNING</p>
        </ng-container>
        <ng-container message>
          <p class="m-0" [innerHTML]="createPositionService.positionWarnMessage"></p>
        </ng-container>
      </generali-warn>
    </div>
  </ng-container>

  <ng-container footer>
    <div class="d-flex justify-content-center" *ngIf="createPositionService.actionButtonsIsVisible">
      <generali-button class=" w-100 pe-1" btnItem="{{ 'generic.CANCEL' | translate }}" btnSize="auto" btnStyle="ghost"
        (onClick)="onCancel()"></generali-button>
      <generali-button class="w-100 ps-1" btnItem="{{ 'generic.CONFIRM' | translate }}" btnSize="auto" btnStyle="red"
        (onClick)="onStatusConfirm()">
      </generali-button>
    </div>
  </ng-container>
</generali-drawer>

<confirm-cancel-modal id="navigate" modalText="{{ 'modal.NO_SAVE_DATA' | translate }}"
  (onCloseNavigateAwayModal)="closeNavigateAwayModal()" (onConfirm)="onConfirmLeavingCreationProcessSave()"
  (onCancel)="onConfirmLeavingCreationProcessWithoutSave()" btnApply="{{ 'modal.APPLY' | translate }}"
  btnCancel="{{ 'modal.CANCEL' | translate }}">
</confirm-cancel-modal>

<confirm-cancel-modal id="mandatory-param-missing" modalText="{{ 'modal.UNDERSTAND' | translate }}"
  (onCloseNavigateAwayModal)="onUnderstand()" (onConfirm)="onUnderstand()"
  btnApply="{{ 'genric.UNDERSTAND' | translate }}">
</confirm-cancel-modal>

<confirm-cancel-modal id="confirm-closing-position" modalText="{{ confirmCloseModalInfo | confirmCloseModalTxt }}"
  (onCloseNavigateAwayModal)="onCloseModal('confirm-closing-position')" (onConfirm)="onStatusConfirm(true)"
  (onCancel)="onCloseModal('confirm-closing-position')" btnApply="{{ 'generic.CONTINUE' | translate }}"
  btnCancel="{{ 'generic.CANCEL' | translate }}" [alignLeft]="true">
</confirm-cancel-modal>
