/**
 * Exception di applicazione.
 * Questa classe crea un oggetto ApplicationError per standardizzare ed estendere le eccezioni.
 *
 * Per utilizzarlo in un componente:
 * 1. Importarlo
 * 2. Per utilizzarlo usare throw new ApplicationError('exception message');
 */

import { ErrorHandler, Injectable, Injector, inject } from "@angular/core";
import { LoggerService } from "./logger.service";
import { GeneraliToastService } from "generali-shared-components/dist";
import { TranslateService } from "src/app/shared/util/translate.service";

export class HTTPError extends Error {
    /** Testo esteso contenente tutte le info dell'errore */
    public defaultHumanMessage: string;
    /** Codice di errore */
    public httpErrorCode?: number;

    constructor(method: string, serviceName: string, httpErrorCode?: number) {
        super();

        this.httpErrorCode = httpErrorCode;
        this.defaultHumanMessage = `${method.toUpperCase()} -- ${serviceName} -- ${httpErrorCode ?? ""}`;
    }
}

export class ApplicationError extends Error {

    /** Codice di errore */
    public httpErrorCode?: number;
    /** Descrizione errore */
    public error?: string;
    /** Id errore */
    public messageFromError?: string;
    /** Nome del servizio dalla quale proviene l'errore */
    public serviceName?: string;
    /** Nome del metodo dalla quale proviene l'errore */
    public method?: string;
    /** Testo esteso contenente tutte le info dell'errore */
    public defaultHumanMessage?: string;
    /** Testo contenente solo le informazioni essenziali dell'errore */
    public defaultShortHumanMessage?: string;

    constructor(method?: string, serviceName?: string, httpErrorCode?: number, error?: string, messageFromError?: string) {
        super();

        this.method = method;
        this.serviceName = serviceName;
        this.httpErrorCode = httpErrorCode;
        this.error = error;
        this.messageFromError = messageFromError;

        this.defaultHumanMessage = this.messageBuilder(httpErrorCode, error, messageFromError);
        this.defaultShortHumanMessage = this.messageBuilder(httpErrorCode, error, messageFromError, true);
    }

    /**
     * 
     * Costruisce il messaggio da inserire nel logger per risalire all'errore
     * 
     * @param err 
     * @returns {string}
     */
    private messageBuilder(httpErrorCode?: number, error?: string, messageFromError?: string, short?: boolean): string {
        // const httpErrorCode = err.httpErrorCode;
        // const error = err.error;
        // const messageFromError = err.messageFromError;

        const message = [];

        if (this.method && !short) {
            message.push(`ERROR: ${this.method.toUpperCase()}`);
        }

        if (this.serviceName && !short) {
            message.push(`Service Name: ${this.serviceName}`);
        }

        if (httpErrorCode) {
            message.push(`HTTP Error Code: ${httpErrorCode}`);
        }

        if (messageFromError) {
            message.push(`Error From: ${typeof messageFromError == "string" ? messageFromError : JSON.stringify(messageFromError)}`);
        }

        if (error) {
            message.push(`Error: ${error}`);
        }

        return message.join(" -- ");
    }
}

@Injectable()
export class GlobalError implements ErrorHandler {
    private injector = inject(Injector);

    constructor() { }

    /**
     * 
     * Gestore centralizzato degli errori
     * 
     * @param error 
     */
    public handleError(error: unknown) {

        const logger = this.injector.get(LoggerService);
        const toast = this.injector.get(GeneraliToastService);
        const translate = this.injector.get(TranslateService);

        if (error instanceof ApplicationError) {
            const message = this.messageBuilder(error);
            logger.error(error, translate.instant<string | undefined>(`error.CODE.${error.httpErrorCode ?? ""}`));
            toast.error(message);
        } else if (error instanceof HTTPError) {
            const message = this.messageBuilder(error);
            logger.error(error, translate.instant<string | undefined>(`error.CODE.${error.httpErrorCode ?? ""}`));
            toast.error(message);
        } else if (error instanceof Error) {
            logger.error(error);
            toast.error(error.message);
        }
    }

    /**
     * 
     * Costruisce il messaggio da inserire nel toast per risalire all'errore
     * 
     * @param err 
     * @returns {string}
     */
    private messageBuilder(err: ApplicationError | HTTPError): string {
        const translate = this.injector.get(TranslateService);
        const httpErrorCodeHuman = translate.instant<string | undefined>(`error.CODE.${err.httpErrorCode ?? ""}`);
        let error, messageFromError;

        if (err instanceof ApplicationError) {
            error = err.error;
            messageFromError = err.messageFromError;
        }

        const message = [];

        if (err.httpErrorCode && httpErrorCodeHuman) {
            message.push(`Error Code: ${err.httpErrorCode} - ${httpErrorCodeHuman}`);
        }

        if (messageFromError) {
            message.push(`Error From: ${messageFromError}`);
        }

        if (error) {
            message.push(`Error: ${error}`);
        }

        if (!err.httpErrorCode && !messageFromError && !error) {
            message.push(err.defaultHumanMessage);
        }

        return message.join(" -- ");
    }
}
