export namespace FormCommonClasses {
  export const STATUS = "currentStatus";
  export const HRBP = "hrbps";
  export const COMPANY = "company";
  export const DATE = "DATE";
  export const DATE_STATUS = "DATE_STATUS";
  export const DATE_FROM = "DATE_FROM";
  export const DATE_TO = "DATE_TO";
  export const CONTRACT_TYPE = "contractType";
  export const SITE = "SITE";
  export const JOB_FIELD = "jobField";
  export const KEYWORD = "KEYWORD";
  export const REASSIGNMENT = "REASSIGNMENT";
  export const REFERRAL = "referralAmount";
  export const TALENT = "TALENT";
  export const FEEDBACK_EMAIL = "FEEDBACK_EMAIL";
  export const USER_DETAILS = "USER_DETAILS";
  export const GENRE = "GENRE";
  export const NEW_ITALIAN = "NEW_ITALIAN";
  export const PROTECTED_CATEGORY = "PROTECTEDCATEGORY";
  export const AGE = "AGE";
  export const TITLE = "title";
  export const OPEN_POSITION = "openedPositionsCount";
  export const ORGANIZATION_UNIT = "organizationUnit";
  export const RETRIBUTION_FROM = "retributionFrom";
  export const RETRIBUTION_TO = "retributionTo";
  export const EXPECTED_START_DATE = "expectedStartDate";
  export const PERIMETER = "perimeter";
  export const LOCATION = "locations";
  export const EMPLOYMENT_LEVEL = "employmentLevel";
  export const LINE_MANAGER = "lineManagers";
  export const LINE_MANAGER_COMPETENCE = "lineManagerCompetences";
  export const COMPETENCE_1 = "COMPETENCE_1";
  export const COMPETENCE_2 = "COMPETENCE_2";
  export const COMPETENCE_3 = "COMPETENCE_3";
  export const COMPETENCE_4 = "COMPETENCE_4";
  export const HEADHUNTER = "headHunter";
  export const COMPANY_PROFILE_DESCRIPTION = "companyProfileDescription";
  export const JOB_DESCRIPTION = "jobDescription";
  export const COMPETENCE_DESCRIPTION = "competencesDescription";
  export const SCREENING_TEST_COLLECTION = "screeningTestCollection";

  export const COMPANY_ID = "companyId";
  export const PERIMETER_ID = "perimeterId";
  export const USER_ID = "userId";
  export const EMPLOYMENT_ID = "employmentLevelId";
  export const LOCATION_ID = "locationId";
  export const JOB_FIELD_ID = "jobFieldId";
  export const CONTRACT_TYPE_ID = "contractTypeId";
  export const STATUS_ID = "statusId";
  export const FULLNAME = "fullname";
  export const APPLICATION_SITE = "applicationSite";

  export const SCREENING_TEST_ID = "screeningTestCollectionId";
  export const COLLECTION_NAME = "collectionName";
  export const SCREENING_TEST_LIST = "screeningTests";

  export const BIRTHDAY = "BIRTHDAY";
  export const AGE_RANGE = "AGE_RANGE";

  export const NAME = "name";

  //** -- EvaluationForm -- */
}