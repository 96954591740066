import { Pipe, PipeTransform, inject } from "@angular/core";
import { TranslateService } from "../util/translate.service";
import { ConfirmCloseModalInfoInterface } from "../components/confirm-cancel-modal/confirm-cancel-modal.component";

@Pipe({ name: "confirmCloseModalTxt" })
export class ConfirmCloseModalTxtPipe implements PipeTransform {
  i18n = inject(TranslateService);
  public transform(confirmCloseModalInfo: ConfirmCloseModalInfoInterface) {


    //TODOAF: trasformare tutti i testi sotto in traduzioni

    // Info da mostrare nella modale
    const nameToShow = confirmCloseModalInfo?.nameToShow ?? "[nome mancante]";
    const positionName = confirmCloseModalInfo?.positionName ?? "[titolo posizione mancante]";

    const countLongList = confirmCloseModalInfo?.countLongList ?? 0;
    const countShortList = confirmCloseModalInfo?.countShortList ?? 0;
    const countToEvaluate = confirmCloseModalInfo?.countToEvaluate ?? 0;
    const totalUsersToChangeStatus = countLongList + countShortList + countToEvaluate;

    let responseTxt: string;

    if (confirmCloseModalInfo.isAcceptingApplication) {
      // Caso in cui sto accettando una candidatura

      responseTxt = `
        Assegnando lo status <em>accetta</em> a <strong>${nameToShow}</strong>
        la posizione <strong>${positionName}</strong> sarà contrassegnata come <em>chiusa filled</em>.
      `;

      if (totalUsersToChangeStatus) {
        responseTxt += `
          <br><br>
          Inoltre, ${totalUsersToChangeStatus} ${totalUsersToChangeStatus === 1 ? "candidato sarà impostato" : "candidati saranno impostati"} su status <em>stop</em>
          e ${totalUsersToChangeStatus === 1 ? "riceverà" : "riceveranno"} email di feedback negativo:
          <ul>
        `;

        responseTxt += countToEvaluate > 0 ? `<li><strong>${countToEvaluate} ${countToEvaluate === 1 ? "candidato" : "candidati"} da <em>valutare</em></strong></li>` : "";
        responseTxt += countLongList > 0 ? `<li><strong>${countLongList} ${countLongList === 1 ? "candidato" : "candidati"} <em>long list</em></strong></li>` : "";
        responseTxt += countShortList > 0 ? `<li><strong>${countShortList} ${countShortList === 1 ? "candidato" : "candidati"} <em>short list</em></strong></li>` : "";

        responseTxt += "</ul>";

      }

    } else {
      // In questo caso sto chiudendo una posizione (sia filled che vuota) senza accettare una candidatura specifica, il messaggio quindi è diverso.

      responseTxt = `
        Cambiando in <em>chiusa</em> lo status della posizione <strong>${positionName}</strong> ${totalUsersToChangeStatus} ${totalUsersToChangeStatus === 1 ? "candidato sarà impostato" : "candidati saranno impostati"} su status <em>stop</em>
        e ${totalUsersToChangeStatus === 1 ? "riceverà" : "riceveranno"} email di feedback negativo:
        <ul>
      `;
      responseTxt += countToEvaluate > 0 ? `<li><strong>${countToEvaluate} ${countToEvaluate === 1 ? "candidato" : "candidati"} da <em>valutare</em></strong></li>` : "";
      responseTxt += countLongList > 0 ? `<li><strong>${countLongList} ${countLongList === 1 ? "candidato" : "candidati"} <em>long list</em></strong></li>` : "";
      responseTxt += countShortList > 0 ? `<li><strong>${countShortList} ${countShortList === 1 ? "candidato" : "candidati"} <em>short list</em></strong></li>` : "";

      responseTxt += "</ul>";

    }

    return responseTxt;
  }
}
