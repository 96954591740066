import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: "generali-radio-for-filter",
  templateUrl: "./generali-radio-for-filter.component.html",
  styleUrls: ["./generali-radio-for-filter.component.scss"]
})
export class GeneraliRadioForFilterComponent {

  @Input() accordionName?: string;
  @Input() selectedValue?: boolean | string;
  @Input() group!: FormGroup;
  @Input() control!: string;
  @Input() type!: "talent" | "newItalian" | "gender" | "reassigned" | "referral" | "feedbackMailSent" | "legallyProtected";

  @Output() onClick = new EventEmitter();

  protected onSelection(valueToEmit?: string | boolean) {
    (this.group.get(this.control) as FormControl).setValue(valueToEmit);

    this.onClick.emit(valueToEmit);
  }
}
