import { ErrorHandler, inject, Injectable } from "@angular/core";

import { LoggerService } from "./logger.service";
import { ApplicationError, HTTPError } from "./exception.service";
import { GeneralConfig, EnvironmentLoaderService } from "src/ancestors/env-config.service";
import { ApplicationHttpResponse } from "sharedclasses";
import { ATSLoginInfo } from "applicanttrackingsystem-cl";

export type RequestMethod = "get" | "post" | "put" | "delete";

export type ApplicationResponse<T> = [T | undefined, ApplicationError | undefined];

export namespace API_REQUEST_METHODS {
  export const GET = "get";
  export const POST = "post";
  export const PUT = "put";
  export const DELETE = "delete";
}

export interface Params {
  urlParam?: string[];
  queryParam?: Record<string, unknown> | unknown;
  bodyParam?: Record<string, unknown> | unknown;
  head?: Record<string, string>;
  withFormData?: boolean
}

export interface Options {
  timeout?: number;
  numOfRetry?: number;
}

@Injectable({
  providedIn: "root"
})
export abstract class APICallService {
  constructor() {
    this.logger.setCaller("APICallService");
  }
  protected envConfig: EnvironmentLoaderService = inject(EnvironmentLoaderService);
  protected logger: LoggerService = inject(LoggerService);
  protected errorHandler = inject(ErrorHandler);
  protected env: GeneralConfig = this.envConfig.getEnvConfig();
  protected tokenKey: string = this.env.localTokenKey;

  /**
   * 
   * Fa una chiamata REST, aggiungendo tutto quello che serve (token nell'header se esiste, urlencode, ecc..)
   * 
   * @param method Tipo di chiamata (get, post, put, delete, ecc...)
   * @param baseURL Url di base
   * @param serviceName Servizio da chiamare (completa il path combinandosi con il baseURL)
   * @param params Parametri da passare alla chiamata (è un oggetto che può contenere urlParam: string[], queryParam: {[key: string]: string}, bodyParam: {[key: string]: string} | string )
   * @param options Opzioni aggiuntive (timeout, tentativi)
   */
  protected async callAPI<T>(method: RequestMethod, baseURL: string, serviceName: string, params?: Params): Promise<ApplicationHttpResponse<T> | undefined> {
    /** Prepara il path */
    const path = this.createPath(baseURL, serviceName, params);

    /** Prepara gli headers */
    const headers = this.createHeaders(params?.head, params?.withFormData);

    switch (method) {
      case API_REQUEST_METHODS.POST:
        try {
          const applicationHttpResponse = await this.postData<T>(path, headers, params?.bodyParam, params?.withFormData);

          /** 
           * 
           * In caso ci sia un errore viene lanciato un errore, con corpo il messaggio del problema riscontrato
           * 
           * Questo causa l'interruzione della richiesta che andrà automaticamente in catch
           */
          if (applicationHttpResponse?.error || (applicationHttpResponse?.httpErrorCode && applicationHttpResponse.httpErrorCode >= 300) || applicationHttpResponse.messageFromError) {
            throw new ApplicationError(API_REQUEST_METHODS.POST, serviceName, applicationHttpResponse.httpErrorCode, applicationHttpResponse?.error, applicationHttpResponse.messageFromError);
          }

          return applicationHttpResponse;

        } catch (err: unknown) {

          /**
           * 
           * Vengono intercettati eventuali messaggi di errore
           * 
           * La gestione viene poi affidata al service GlobalError dove viene centralizzata la gestione di tutti gli errori dell'applicazione
           */
          this.errorHandler.handleError(err);
        }

        break;

      case API_REQUEST_METHODS.PUT:
        try {
          const applicationHttpResponse = await this.putData<T>(path, headers, params?.bodyParam);

          /** 
           * 
           * In caso ci sia un errore viene lanciato un errore, con corpo il messaggio del problema riscontrato
           * 
           * Questo causa l'interruzione della richiesta che andrà automaticamente in catch
           */
          if (applicationHttpResponse?.error || (applicationHttpResponse?.httpErrorCode && applicationHttpResponse.httpErrorCode >= 300 || applicationHttpResponse.messageFromError)) {
            throw new ApplicationError(API_REQUEST_METHODS.PUT, serviceName, applicationHttpResponse.httpErrorCode, applicationHttpResponse?.error, applicationHttpResponse.messageFromError);
          }

          return applicationHttpResponse;

        } catch (err: unknown) {

          /**
           * 
           * Vengono intercettati eventuali messaggi di errore
           * 
           * La gestione viene poi affidata al service GlobalError dove viene centralizzata la gestione di tutti gli errori dell'applicazione
          */
          this.errorHandler.handleError(err);
        }

        break;

      case API_REQUEST_METHODS.DELETE:
        try {
          const applicationHttpResponse = await this.deleteData<T>(path, headers);

          /** 
           * 
           * In caso ci sia un errore viene lanciato un errore, con corpo il messaggio del problema riscontrato
           * 
           * Questo causa l'interruzione della richiesta che andrà automaticamente in catch
           */
          if (applicationHttpResponse?.error || (applicationHttpResponse?.httpErrorCode && applicationHttpResponse.httpErrorCode >= 300 || applicationHttpResponse.messageFromError)) {
            throw new ApplicationError(API_REQUEST_METHODS.DELETE, serviceName, applicationHttpResponse.httpErrorCode, applicationHttpResponse?.error, applicationHttpResponse.messageFromError);
          }


          return applicationHttpResponse;

        } catch (err: unknown) {

          /**
           * 
           * Vengono intercettati eventuali messaggi di errore
           * 
           * La gestione viene poi affidata al service GlobalError dove viene centralizzata la gestione di tutti gli errori dell'applicazione
           */
          this.errorHandler.handleError(err);
        }

        break;

      default: {
        try {
          const applicationHttpResponse = await this.getData<T>(path, headers);

          /** 
           * 
           * In caso ci sia un errore viene lanciato un errore, con corpo il messaggio del problema riscontrato
           * 
           * Questo causa l'interruzione della richiesta che andrà automaticamente in catch
           */
          if (applicationHttpResponse?.error || (applicationHttpResponse?.httpErrorCode && applicationHttpResponse.httpErrorCode >= 300 || applicationHttpResponse.messageFromError)) {
            throw new ApplicationError(API_REQUEST_METHODS.GET, serviceName, applicationHttpResponse.httpErrorCode, applicationHttpResponse?.error, applicationHttpResponse.messageFromError);
          }


          return applicationHttpResponse;

        } catch (err: unknown) {

          /**
           * 
           * Vengono intercettati eventuali messaggi di errore
           * 
           * La gestione viene poi affidata al service GlobalError dove viene centralizzata la gestione di tutti gli errori dell'applicazione
           */
          this.errorHandler.handleError(err);
        }

        break;
      }
    }

    return;
  }

  /** GET */
  private async getData<T>(url: string, headers: Record<string, string>): Promise<ApplicationHttpResponse<T>> {
    const params: Record<string, unknown> = {};
    let response;

    Object.assign(params, { method: API_REQUEST_METHODS.GET });
    Object.assign(params, { headers: headers });

    try {
      response = await fetch(url, params);
      return await response.json() as ApplicationHttpResponse<T>;
    } catch (error: unknown) {
      throw new HTTPError(API_REQUEST_METHODS.GET, url.replace(this.env.apiBaseUrl, ""), response?.status);
    }
  }

  /** POST */
  private async postData<T>(url: string, headers: Record<string, string>, body: Record<string, unknown> | unknown, withFormData?: boolean): Promise<ApplicationHttpResponse<T>> {
    const params: Record<string, unknown> = {};
    let response;

    if (this.isString(body) || withFormData) {
      Object.assign(params, { body: body });
    } else {
      Object.assign(params, { body: JSON.stringify(body) });
    }

    Object.assign(params, { method: API_REQUEST_METHODS.POST });
    Object.assign(params, { headers: headers });

    try {
      response = await fetch(url, params);
      return await response.json() as ApplicationHttpResponse<T>;
    } catch (error: unknown) {
      throw new HTTPError(API_REQUEST_METHODS.POST, url.replace(this.env.apiBaseUrl, ""), response?.status);
    }
  }

  /** PUT */
  private async putData<T>(url: string, headers: Record<string, string>, body: Record<string, unknown> | unknown): Promise<ApplicationHttpResponse<T>> {
    const params: Record<string, unknown> = {};
    let response;

    if (this.isString(body)) {
      Object.assign(params, { body: body });
    } else {
      Object.assign(params, { body: JSON.stringify(body) });
    }

    Object.assign(params, { method: API_REQUEST_METHODS.PUT });
    Object.assign(params, { headers: headers });

    try {
      response = await fetch(url, params);
      return await response.json() as ApplicationHttpResponse<T>;
    } catch (error: unknown) {
      throw new HTTPError(API_REQUEST_METHODS.PUT, url.replace(this.env.apiBaseUrl, ""), response?.status);
    }
  }

  /** DELETE */
  private async deleteData<T>(url: string, headers: Record<string, string>): Promise<ApplicationHttpResponse<T>> {
    const params: Record<string, unknown> = {};
    let response;

    Object.assign(params, { method: API_REQUEST_METHODS.DELETE });
    Object.assign(params, { headers: headers });

    try {
      response = await fetch(url, params);
      return await response.json() as ApplicationHttpResponse<T>;
    } catch (error: unknown) {
      throw new HTTPError(API_REQUEST_METHODS.DELETE, url.replace(this.env.apiBaseUrl, ""), response?.status);
    }
  }

  /**
   * 
   * Setta l'URL della chiamata in base ai parametri passati.
   * 
   * @param baseURL 
   * @param serviceName 
   * @param params 
   */
  private createPath(baseURL: string, serviceName: string, params?: Params): string {
    /** Crea la prima parte del path, controllando se il baseURL ha già lo slash finale e in caso lo aggiunge */
    let path = baseURL + (baseURL.endsWith("/") ? "" : "/") + serviceName;

    /** Aggiunge al path eventuali urlParam, controllando se c'è già lo slash finale */
    if (params?.urlParam) {
      path += (path.endsWith("/") ? "" : "/") + params.urlParam.join("/");
    }

    /** Aggiunge al path eventuali queryParam */
    if (!params?.queryParam) {
      return path;
    }

    const Q_PARAMS = new URLSearchParams();
    /** Esegue il parse di ogni valore non string in tipo string */
    for (let [key, value] of Object.entries(params.queryParam)) { // eslint-disable-line

      /** Se undefine non viene aggiunto il parametro al path */
      if (value === undefined) {
        continue;
      }

      /** Se viene passato un array */
      if (Array.isArray(value)) {
        /** Elimina tutti i valori falsy */
        const arr = value.filter(Boolean);

        for (const field of arr) {

          if (this.isDate(field)) {
            /** Nel caso sia una data viene convertita in UTC */
            Q_PARAMS.append(key, field.toISOString());
          }

          if (this.isString(field)) {
            /** Nel caso sia una stringa aggiunge semplicemente il valore al path */
            Q_PARAMS.append(key, field);
          }

          if (!this.isDate(field) && !this.isString(field)) {
            /** In tutti gli altri casi converti in stringa il valore */
            Q_PARAMS.append(key, JSON.stringify(field));
          }
        }

        continue;
      }

      if (this.isDate(value)) {
        /** Nel caso sia una data viene convertita in UTC */
        Q_PARAMS.append(key, value.toISOString());
      }

      if (this.isString(value)) {
        /** Nel caso sia una stringa aggiunge semplicemente il valore al path */
        Q_PARAMS.append(key, value);
      }

      if (!this.isDate(value) && !this.isString(value)) {
        /** In tutti gli altri casi converti in stringa il valore */
        Q_PARAMS.append(key, JSON.stringify(value));
      }


    }
    path += "?" + Q_PARAMS.toString();

    return path;
  }

  /**
   * 
   * Setta l'header della chiamata.
   * 
   * Di default se presente viene anche il Bearer token se presente un token di autenticazione 
   * 
   * @param header 
   */
  private createHeaders(header?: Record<string, string>, withFormData?: boolean): Record<string, string> {
    const headersObj: Record<string, string> = {
      ...header
    };

    if (!withFormData) {
      headersObj["Content-Type"] = "application/json";
    }

    const token: string | null = sessionStorage.getItem(this.env.loginType === "local" ? this.env.localTokenKey : this.env.ssoTokenKey);
    // const token: string | undefined = document.cookie.split(";").find((item) => item.trim().startsWith(`${this.tokenKey}=`))?.split(`${this.tokenKey}=`)[1];
    if (!token) {
      return headersObj;
    }

    const loginInfo: unknown = JSON.parse(token);
    if (this.isLoginInfo(loginInfo)) {
      Object.assign(headersObj, { Authorization: `Bearer ${loginInfo.token}` }); // eslint-disable-line
    }

    return headersObj;
  }

  /**
   * 
   * Controlla se il parametro è una data
   * 
   * @param date 
   */
  private isDate(date: unknown): date is Date {
    return toString.call(date) === "[object Date]";
  }

  /**
   * 
   * Controlla se il parametro è una string
   * 
   * @param value 
   */
  private isString(value: unknown): value is string {
    return typeof value === "string";
  }

  /**
   * 
   * Controlla se l'oggetto passato è del tipo LoginInfo
   * 
   * @param obj 
   * @returns {boolean}
   */
  private isLoginInfo(obj: unknown): obj is ATSLoginInfo {
    if (typeof obj === "object" && obj)
      return obj && typeof obj === "object" && "expireDate" in obj && "jwtPayload" in obj && "token" in obj;
    return false;
  }

  protected isError(obj: unknown): obj is ApplicationError {
    return Object.prototype.toString.call(obj) === "[object Error]";
  }
}
