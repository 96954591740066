import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AbortPositionCreationGuard } from "./global-service/abort-position-creation.guard";
import { CreatePosition } from "./pages/create-position/create-position-routing.module";
import { CreatePositionComponent } from "./pages/create-position/create-position.component";
import { CandidateSheetComponent } from "./pages/candidate-sheet/candidate-sheet.component";
import { AuthenticationGuard } from "./global-service/authentication.guard";
import { PreviewComponent } from "./pages/preview/preview.component";
import { SearchComponent } from "./pages/search/search.component";
import { DeleteSessionDataGuard } from "./global-service/delete-session-data.guard";
import { ForbiddenComponent } from "./pages/forbidden/forbidden.component";

export namespace UrlCollection {
  export const generalHomepage = "homepage";
  export const localAuthentication = "local-authentication";
  export const samlSso = "Saml-sso";
  export const notAuthorized = "not-authorized";
  export const notFound = "not-found";
  export const redirectTo = "";
  export const redirectRequest = "redirectRequest";
  export const positionDetail = "position-detail";
  export const candidateSheet = "candidate-sheet";
  export const faq = "faq";
  export const preview = "preview";
  export const detail = "detail";
  export const search = "search";
  export const forbidden = "403";
}

const routes: Routes = [
  { path: "", redirectTo: UrlCollection.generalHomepage, pathMatch: "full" },
  {
    path: UrlCollection.generalHomepage,
    canActivate: [AuthenticationGuard],
    loadChildren: async () => {
      const m = await import("./pages/homepage/hompage.module");
      return m.HomepageModule;
    }
  },
  {
    path: UrlCollection.faq,
    canActivate: [AuthenticationGuard],
    loadChildren: async () => {
      const m = await import("./pages/faq/faq.routing.module");
      return m.FaqModule;
    }
  },
  {
    path: UrlCollection.localAuthentication,
    loadChildren: async () => {
      const m = await import("./pages/local-authentication/local-authentication.module");
      return m.LocalAuthenticationModule;
    }
  },
  {
    path: UrlCollection.samlSso,
    loadChildren: async () => {
      const m = await import("./pages/saml-sso/saml-sso.module");
      return m.SamlSsoModule;
    }
  },
  {
    path: CreatePosition.createPosition,
    component: CreatePositionComponent,
    canActivate: [AuthenticationGuard, AbortPositionCreationGuard],
    canDeactivate: [DeleteSessionDataGuard],
    children: [
      {
        path: "",

        loadChildren: async () => {
          const m = await import("./pages/create-position/create-position.module");
          return m.CreatePositionModule;
        }
      }
    ]
  },
  {
    path: UrlCollection.preview,
    component: PreviewComponent,
    canActivate: [AuthenticationGuard],
    canDeactivate: [DeleteSessionDataGuard],
    children: [
      {
        path: ":jobPositionId",
        loadChildren: async () => {
          const m = await import("./pages/preview/preview.module");
          return m.PreviewModule;
        }
      }
    ]
  },
  {
    path: UrlCollection.positionDetail,
    canActivate: [AuthenticationGuard],
    loadChildren: async () => {
      const m = await import("./pages/position-detail/position-detail.module");
      return m.PositionDetailModule;
    }
  },
  {
    path: `${UrlCollection.candidateSheet}/:jobApplicationId`,
    canActivate: [AuthenticationGuard],
    component: CandidateSheetComponent,
    children: [
      {
        path: "",
        loadChildren: async () => {
          const m = await import("./pages/candidate-sheet/candidate-sheet.module");
          return m.PersonDetailModule;
        }
      }
    ]
  },
  {
    path: UrlCollection.search,
    canActivate: [AuthenticationGuard],
    component: SearchComponent,
    children: [
      {
        path: "",
        loadChildren: async () => {
          const m = await import("./pages/search/search.module");
          return m.SearchModule;
        }
      }
    ]
  },
  { path: "**", redirectTo: UrlCollection.notFound },
  { path: UrlCollection.forbidden, component: ForbiddenComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: "top" })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
