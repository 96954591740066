<div class="row mb-4">
  <div class="title col-12">
    <p i18n="@@channel.TITLE">channel.TITLE</p>
  </div>
</div>

<!-- Visibile quando i campi obbligatori dei form non sono compilati -->
<div class="row mb-4" *ngIf="this.createPositionService.formsAreInvalids; else userAction">
  <div class="col">
    <generali-warn icon="assets/img/icons/warning.svg">
      <ng-container warn>
        <p i18n="@@generic.WARNING" class="fw-semibold">generic.WARNING</p>
      </ng-container>
      <ng-container message>
        <p i18n="@@channel.WARNING.MANDATORY_FORMS_FIELDS">channel.WARNING.MANDATORY_FORMS_FIELDS</p>
      </ng-container>
    </generali-warn>
  </div>
</div>

<!-- Azioni visibili solo quando tutti i campi obbligatori dei form sono compilati  -->
<ng-template #userAction>
  <!-- EBRM -->
  <div class="row mb-4" *ngIf="this.usrService.isEBRM">
    <div class="col"
      *ngIf="createPositionService.jobPosition?.currentStatus === 'DRAFT' || createPositionService.jobPosition?.currentStatus === 'OPEN'">
      <generali-warn icon="assets/img/icons/warning.svg">
        <ng-container warn>
          <p i18n="@@generic.WARNING" class="fw-semibold">generic.WARNING</p>
        </ng-container>
        <ng-container message>
          <p i18n="@@channel.WARNING.PUBLISHED">channel.WARNING.PUBLISHED</p>
        </ng-container>
        <ng-container btn>
          <p class="text-decoration-underline" i18n="@@generic.PUBLISH" (click)="openPublishDrawer()" role="button">
            generic.PUBLISH
          </p>
        </ng-container>
      </generali-warn>
    </div>
  </div>

  <!-- HBRP -->
  <div class="row mb-4" *ngIf="this.usrService.isHRBP">
    <div class="col" *ngIf="createPositionService.jobPosition?.currentStatus === 'DRAFT'">
      <generali-warn icon="assets/img/icons/warning.svg">
        <ng-container warn>
          <p i18n="@@generic.WARNING" class="fw-semibold">generic.WARNING</p>
        </ng-container>
        <ng-container message>
          <p i18n="@@channel.WARNING.REQUEST_APPROVAL">channel.WARNING.REQUEST_APPROVAL</p>
        </ng-container>
        <ng-container btn>
          <p class="text-decoration-underline" i18n="@@channel.WARNING.APPROVAL" (click)="openApprovalDrawer()"
            role="button">
            channel.WARNING.APPROVAL
          </p>
        </ng-container>
      </generali-warn>
    </div>
  </div>
</ng-template>


<div class="row g-3">
  <div class="col-12 col-sm-8 col-lg-6" *ngFor="let card of publicationChannelList">
    <div class="g-card d-flex">
      <div class="disabled-overlay" *ngIf="this.usrService.isHRBP"></div>
      <div class="g-card_img" role="button">
        <img src="assets/img/generali-logo.png">
      </div>
      <div class="g-card_content d-flex justify-content-center flex-column" role="button">
        <p class="mb-0" i18n="@@channel.CARD.TITLE">{{ card.name }}</p>
        <p class="mb-0" *ngIf="card.default" i18n="@@channel.CARD.STATUS">channel.CARD.STATUS</p>
      </div>
    </div>
  </div>