import { Component, inject, OnInit } from "@angular/core";

import { Company, CompanyPerimeter, ContractType, EmploymentLevel, JobField, Location } from "applicanttrackingsystem-cl";
import { BaseComponent } from "src/ancestors/base/base.component";
import { CreatePositionService } from "../create-position.service";
import { FormCommonClasses as FormCl } from "src/app/shared/util/form.classes";
import { FormService } from "src/app/shared/util/form.service";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { Validators } from "@angular/forms";

@Component({
  selector: "app-process",
  templateUrl: "./process.component.html",
  styleUrls: ["./process.component.scss"]

})
export class ProcessComponent extends BaseComponent implements OnInit {
  protected createPositionService: CreatePositionService = inject(CreatePositionService);
  protected fs = inject(FormService);

  constructor() {
    super();
  }

  protected companyListPerimeter: CompanyPerimeter[] = [];
  protected companyList: Company[] = [];
  protected jobFieldList: JobField[] = [];
  protected contractTypeList: ContractType[] = [];
  protected locationList: Location[] = [];
  protected employLevelList: EmploymentLevel[] = [];


  override async ngOnInit(): Promise<void> {
    const activeTab = this.createPositionService.getActiveStepperIdxBasedOnUrl(this.router.url);
    this.createPositionService.setActivaStep(activeTab);

    const [companyListPerimeter, jobFieldList, contractTypeList, locationList, employLevelList] = await Promise.all([
      this.backendService.companyListPerimeter(),
      this.backendService.jobFieldList(),
      this.backendService.contractTypeList(),
      this.backendService.locationList(),
      this.backendService.employLevelList()
    ]);

    /** Ordina alfabeticamente i risultati di companyListPerimeter */
    this.companyListPerimeter = companyListPerimeter
      .sort((a: CompanyPerimeter, b: CompanyPerimeter) => a.name < b.name ? -1 : 1);

    this.jobFieldList = jobFieldList;
    this.contractTypeList = contractTypeList;
    this.locationList = locationList;
    this.employLevelList = employLevelList;

    /** In caso di perimetro selezionato esegui la chiamata per la lista delle company */
    if (this.createPositionService.isPerimeterSelected) {
      const PERIMETER_ID = this.fs.getSingleValueOutOfArray<string>(this.createPositionService.perimeter, FormCl.PERIMETER_ID);
      try {
        this.companyList = await this.backendService.companyList({ perimeterId: PERIMETER_ID });
      } catch (error: unknown) {
        this.errorHandler.handleError(error);
      }
    }

    /** Controllo se impostato perimetro specifico, se FDD question&screening diventa opzionale */
    await this.createPositionService.checkPerimeter();
  }



  /**
   * Quando il perimetro viene selezionato oltre ad eseguire la chiamata abilita il campo company che altrimenti sarebbe disabilitato
   *
   * Allo stesso modo lo disabilita in caso di deselezione
   */
  async onPerimeterSelect() {
    const PERIMETER_ID = this.fs.getSingleValueOutOfArray<string>(this.createPositionService.perimeter, FormCl.PERIMETER_ID);

    try {
      /** Ogni volta che viene eseguita per sicurezza il campo company verrá svuotato per eliminare possibili dati vecchi */
      this.createPositionService.company.clear();

      if (PERIMETER_ID) {
        this.createPositionService.company.enable();
        this.companyList = await this.backendService.companyList({ perimeterId: PERIMETER_ID });
        //Seleziona automaticamente la compagnia se c'è una sola opzione
        if (this.companyList.length === 1) {
          this.createPositionService.company.patchValue([this.companyList[0]]);
          this.fs.setValueOnArray(this.createPositionService.company, this.companyList[0]);
        }
      } else {
        this.createPositionService.company.disable();
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }
  }

  /**
   * Attiva/disattiva il campo referral
   *
   * Se attivo il campo diventa obbligatorio
   */
  onSwitchReferral(event: boolean) {
    this.createPositionService.referralEnabled = event;

    if (this.createPositionService.referralEnabled) {
      this.createPositionService.referral.enable();
      this.createPositionService.referral.setValidators(Validators.required);
    } else {
      this.createPositionService.referral.disable();
      this.createPositionService.referral.reset();
      this.createPositionService.referral.removeValidators(Validators.required);
    }

    this.createPositionService.referral.updateValueAndValidity();
  }

  /**
   * Attiva/disattiva il campo retribuzione
   *
   * Se attivo il campo diventa obbligatorio
   */
  onSwitchRetribution(switchValue: boolean) {
    /* Quando il campo retribuzione viene attivato viene aggiunto il validatore required e viene levato quando il campo viene disattivato */
    this.createPositionService.retributionEnabled = switchValue;
    if (switchValue) {
      this.createPositionService.employmentLevel.enable();
      this.createPositionService.retributionFrom.enable();
      this.createPositionService.retributionTo.enable();

      this.createPositionService.employmentLevel.setValidators(Validators.required);
    } else {
      /** Altrimenti li disattiva e in caso siano state fatte delle selezioni le elimina */
      this.createPositionService.employmentLevel.disable();
      this.createPositionService.retributionFrom.disable();
      this.createPositionService.retributionTo.disable();

      this.createPositionService.retributionFrom.reset();
      this.createPositionService.retributionTo.reset();
      this.createPositionService.employmentLevel.clear();

      this.createPositionService.employmentLevel.removeValidators(Validators.required);
    }
    /* Aggiungendo dinamicamente un validatore bisogna fare l'update manuale in modo che il form percepisca l'aggiunta */
    this.createPositionService.employmentLevel.updateValueAndValidity();
  }

  /**
   * Quando si seleziona una data, questa viene salvata nel formato Date che verrà poi convertito in UTC dal JSON.stringify al momento della chiamata
   *
   * @param date {NgbDateStruct}
   */
  protected onDateSelect(date?: NgbDateStruct) {
    if (!date) {
      this.createPositionService.expectedStartDateSelected = undefined;
      return;
    }
    this.createPositionService.expectedStartDateSelected = new Date(date.year, date.month - 1, date.day);
  }

  /**
   * Applica il filtro inserito
   *
   * @param name
   */
  async onSearchLocation(name: string) {
    try {
      this.locationList = await this.backendService.locationList({ name });
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }
  }
}



