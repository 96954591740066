<div class="star-rating w-100 d-flex flex-column flex-sm-row d-flex align-items-center align-items-sm-start gap-2">
  <div class="d-flex w-100 justify-content-start">
    <p class="text d-flex">{{label | translate }}</p>
  </div>
  <div class="d-flex flex-column align-items-center">
    <div class="d-flex gap-2">
      <img class="star" [src]="image.star1" alt="star1" (click)="selectStar(1)" />
      <img class="star" [src]="image.star2" alt="star2" (click)="selectStar(2)" />
      <img class="star" [src]="image.star3" alt="star3" (click)="selectStar(3)" />
      <img class="star" [src]="image.star4" alt="star4" (click)="selectStar(4)" />
      <img class="star" [src]="image.star5" alt="star5" (click)="selectStar(5)" />
    </div>

    <div class="text-indication mt-1" [ngSwitch]="value">
      <span *ngSwitchCase="1" i18n="@@candidate.evaluationform.RARE" >candidate.evaluationform.RARE</span>
      <span *ngSwitchCase="2" i18n="@@candidate.evaluationform.MODERATE" >candidate.evaluationform.MODERATE</span>
      <span *ngSwitchCase="3" i18n="@@candidate.evaluationform.ENOUGH" >candidate.evaluationform.ENOUGH</span>
      <span *ngSwitchCase="4" i18n="@@candidate.evaluationform.GOOD" >candidate.evaluationform.GOOD</span>
      <span *ngSwitchCase="5" i18n="@@candidate.evaluationform.EXCELLENT" >candidate.evaluationform.EXCELLENT</span>
      <span *ngSwitchDefault  i18n="@@generic.INDICATION">generic.INDICATION</span>
    </div>
  </div>
</div>
<br *ngIf="error">
<div *ngIf="error" class="w-100 d-flex flex-column">
  <p class="err">Compila il campo obbligatorio!</p>
</div>