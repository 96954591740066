import { Directive, ElementRef, Renderer2, inject } from "@angular/core";
import { UserService } from "src/app/global-service/user.service";

@Directive({
  selector: "[hasPermission]"
})
export class PermissionDirective {
  constructor() {
    this.validateAccess();
  }

  private renderer = inject(Renderer2);
  private usrService = inject(UserService);
  private elRef = inject(ElementRef);

  private validateAccess() {
    if (this.usrService.isUnprofiled) {
      this.renderer.setStyle(this.elRef.nativeElement, "display", "none");
    }
  }
}
