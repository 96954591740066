import { Injectable } from "@angular/core";
import { HomepageSearchFilters } from "applicanttrackingsystem-cl";
import { BaseDataService } from "src/ancestors/base-data.service";
import { Filter } from "src/app/pages/homepage/homepage.component";

@Injectable()
export class JobApplicationOrderService extends BaseDataService {
  constructor() {
    super();
  }

  /** Ordinamento applicato */
  public appliedApplicationOrders: HomepageSearchFilters = {};
  /** Ordinamento selezionato */
  public selectedOrder?: Filter;
  /** Opzioni di ordinamento disponibili */
  public applicationOrdersOptions: Filter[] = [
    { sortingField: "surname", applied: false, sortingDesc: false },
    { sortingField: "lastStatusDate", applied: false, sortingDesc: false },
    { sortingField: "jobFitnessPercentage", applied: false, sortingDesc: false }
  ];

  /**
   * Seleziona ordinamento ascendente
   */
  public selectedUp(filter: Filter) {
    this.selectedOrder = filter;
    this.selectedOrder.sortingDesc = false;
  }

  /**
   * Seleziona ordinamento discendente
  */
  public selectedDown(filter: Filter) {
    this.selectedOrder = filter;
    this.selectedOrder.sortingDesc = true;
  }

  public onApplyOrder() {
    if (!this.selectedOrder) {
      return;
    }

    this.selectedOrder.applied = true;
    this.appliedApplicationOrders = {
      sortingDesc: this.selectedOrder.sortingDesc,
      sortingField: this.selectedOrder.sortingField
    };
  }

  public removeSelectedOnlyOrder() {
    /** Il filtro non applicato viene eliminato */
    if (this.selectedOrder && !this.selectedOrder.applied) {
      this.selectedOrder = undefined;
    }
  }

  /**
   * Resetta (se selezionato) campo applicato selectedOrder
   */
  public resetSelectedOrder() {
    this.appliedApplicationOrders = {};
    this.selectedOrder = undefined;
  }

  public onResetOrder() {
    this.appliedApplicationOrders = {};
    this.selectedOrder = undefined;
  }

  public get ordersToSave() {
    return {
      sortingDesc: this.appliedApplicationOrders?.sortingDesc,
      sortingField: this.appliedApplicationOrders?.sortingField
    };
  }
}
