<div class="flex-row ps-1 pe-1" *ngIf="tableList.length; else noData">
  <div class="table-responsive rounded rounded-3">
    <table class="table table-light mb-0" [class.table-striped]="isStripped" [class.table-hover]="isSelectable">
      <thead>
        <tr>
          <th class="th-sm table-status" i18n="@@home.table.STATUS">home.table.STATUS</th>
          <th class="th-lg text-start" i18n="@@home.table.POSITION">home.table.POSITION</th>
          <th class="th-sm th-max-sm ps-0" i18n="@@home.table.COMPANY">home.table.COMPANY</th>
          <th class="text-center px-2" i18n="@@home.table.APPLY">home.table.APPLY</th>
          <th class="text-center px-2" i18n="@@home.table.TO_EVALUATE">home.table.TO_EVALUATE
          </th>
          <th class="text-center">
            <generali-badge badgeCategory="DOT" badgeType="LONG_LIST"></generali-badge>
          </th>
          <th class="text-center">
            <generali-badge badgeCategory="DOT" badgeType="STOP"></generali-badge>
          </th>
          <th class="text-center">
            <generali-badge badgeCategory="DOT" badgeType="SHORT_LIST"></generali-badge>
          </th>
          <th class="text-center">
            <generali-badge badgeCategory="DOT" badgeType="OFFER"></generali-badge>
          </th>
          <th class="text-center">
            <generali-badge badgeCategory="DOT" badgeType="REFUSE"></generali-badge>
          </th>
          <th class="text-center">
            <generali-badge badgeCategory="DOT" badgeType="ACCEPT"></generali-badge>
          </th>
        </tr>
      </thead>

      <tbody class="text-center">
        <tr *ngFor="let row of tableList" [class.cursor-pointer]="role === 'pointer'" (click)="onTableClick(row)">
          <td class="first-cell bg-white">
            <div class="mb-1">
              <generali-badge badgeCategory="FULL" *ngIf="row?.currentStatus"
                [badgeType]="row?.currentStatus || 'DRAFT'">
              </generali-badge>
            </div>
            <p class="text-start m-0">
              {{ row?.currentStatusDate | date:'dd/MM/yyyy'}}
            </p>
          </td>
          <td class="table-title trunc text-start ps-2 pe-1 th-max-lg bg-white">
            <p class="code-text trunc">
              {{ row?.progressiveCodeDecoded }}
            </p>
            <p class="trunc">{{ row?.title }}</p>
          </td>
          <td class="table-company trunc th-max-sm text-start bg-white">
            {{ row?.companyName }}
          </td>
          <td class="trunc font-number bg-white">
            {{ row?.counters?.all }}
          </td>
          <td class="trunc font-number bg-white">
            {{ row?.counters?.toEvaluate }}
          </td>
          <td class="trunc font-number bg-white">
            <div class="color-bar long-list" [class.single-row]="!multiRow" [class.multi-row]="multiRow"></div>
            {{ row?.counters?.longList }}
          </td>
          <td class="trunc font-number bg-white">
            <div class="color-bar stop" [class.single-row]="!multiRow" [class.multi-row]="multiRow"></div>
            {{ row?.counters?.stop }}
          </td>
          <td class="trunc font-number bg-white">
            <div class="color-bar short-list" [class.single-row]="!multiRow" [class.multi-row]="multiRow"></div>
            {{ row?.counters?.shortList }}
          </td>
          <td class="trunc font-number bg-white">
            <div class="color-bar offer" [class.single-row]="!multiRow" [class.multi-row]="multiRow"></div>
            {{ row?.counters?.offer }}/{{
            row?.openedPositionsCount ?? 0 }}
          </td>
          <td class="trunc font-number bg-white">
            <div class="color-bar reject" [class.single-row]="!multiRow" [class.multi-row]="multiRow"></div>
            {{ row?.counters?.refuse }}
          </td>
          <td class="trunc font-number bg-white last-cell">
            <div class="color-bar accept" [class.single-row]="!multiRow" [class.multi-row]="multiRow"></div>
            {{ row?.counters?.accept }}/{{
            row?.openedPositionsCount ?? 0 }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #noData>
  <div class="d-flex justify-content-center align-items-center flex-column p-5">
    <img src="assets/img/icons/no-table-data.svg" alt="">
    <p class="mt-4 no-data-found" i18n="@@home.NO_TABLE_DATA">home.NO_TABLE_DATA</p>
  </div>
</ng-template>