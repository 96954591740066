import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ChannelComponent } from "./channel/channel.component";
import { JobDescriptionComponent } from "./job-description/job-description.component";

import { ProcessComponent } from "./process/process.component";
import { QuestionScreeningComponent } from "./question-screening/question-screening.component";
import { StakeholderComponent } from "./stakeholder/stakeholder.component";

export namespace CreatePosition {
  export const createPosition = "create-position";
  export const process = "process";
  export const stakeholder = "stakeholder";
  export const jobDescription = "job-description";
  export const qEs = "question-screening";
  export const channel = "publishing-channels";
  export const JOB_POSITION_ID = "JOB_POSITION_ID";
}

const positionRoutes: Routes = [
  { path: CreatePosition.process, component: ProcessComponent },
  { path: CreatePosition.process, component: ProcessComponent },
  { path: CreatePosition.stakeholder, component: StakeholderComponent },
  { path: CreatePosition.jobDescription, component: JobDescriptionComponent },
  { path: CreatePosition.qEs, component: QuestionScreeningComponent },
  { path: CreatePosition.channel, component: ChannelComponent }
];

@NgModule({
  imports: [RouterModule.forChild(positionRoutes)],
  exports: [RouterModule]
})
export class CreatePositionRoutingModule { }
