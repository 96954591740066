import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { EnvironmentLoaderService } from "../ancestors/env-config.service";
import { BaseComponent } from "../ancestors/base/base.component";
import { LoggerService } from "src/ancestors/logger.service";
import { BaseModule } from "../ancestors/base/base.module";
// import { SamlSsoComponent } from "./pages/saml-sso/saml-sso.component";
import { UserService } from "./global-service/user.service";
import { TokenManagerService } from "./global-service/token-manager.service";
import { i18nModule } from "../ancestors/i18n/i18n.module";
import { BackendService } from "src/app/global-service/backend-api.service";
import { ApplicationDataService } from "./global-service/application-data.service";
import { GeneraliSharedComponentsV1Module } from "generali-shared-components";
import { SharedModule } from "./shared/shared.module";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { TokenStorageService } from "./global-service/token-storage.service";
import { GlobalError } from "src/ancestors/exception.service";
import { TranslateService } from "./shared/util/translate.service";
import { ForbiddenComponent } from "./pages/forbidden/forbidden.component";
import { AbortPositionCreationGuard } from "./global-service/abort-position-creation.guard";

/**
 *
 * @param envService Service responsabile del caricamento del file di configurazione
 * @returns File di configurazione.
 * Angular fornisce la possibilità di eseguire delle logiche di configurazione prima che l'applicazione venga inizializzata,
 * in questo modo possiamo caricare il file di configurazione delle variabili globali senza dover ricompilare l'applicazione.
 *
 */
const initAppFn = (envService: EnvironmentLoaderService) => {
    return () => envService.setAppConfig();
};

const translationInitializer = (translation: TranslateService) => {
    return () => {
        translation.loadTranslation();
    };
};

@NgModule({
    declarations: [
        AppComponent,
        BaseComponent,
        ForbiddenComponent
    ],
    imports: [
        NgSelectModule,
        CommonModule,
        FormsModule,
        SharedModule,
        HttpClientModule,
        NgbModule,
        BrowserModule,
        AppRoutingModule,
        BaseModule,
        GeneraliSharedComponentsV1Module,
        ReactiveFormsModule
    ],
    providers: [
        BackendService,
        TokenStorageService,
        ApplicationDataService,
        TokenManagerService,
        UserService,
        LoggerService,
        EnvironmentLoaderService,
        {
            provide: APP_INITIALIZER,
            useFactory: initAppFn,
            multi: true,
            deps: [EnvironmentLoaderService]
        },
        {
            provide: APP_INITIALIZER,
            useFactory: translationInitializer,
            multi: true,
            deps: [TranslateService]
        },
        { provide: ErrorHandler, useClass: GlobalError },
        BackendService,
        i18nModule.setLocale(),
        i18nModule.setLocaleId(),
        AbortPositionCreationGuard
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
