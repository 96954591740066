import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
    imports: [CommonModule, FormsModule, NgbModule]
})
export class BaseModule { }
