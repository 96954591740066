import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "confirm-cancel-modal",
  templateUrl: "./confirm-cancel-modal.component.html",
  styleUrls: ["./confirm-cancel-modal.component.scss"]
})
export class GenericModalComponent {

  @Input() modalText?: string;
  @Input() id!: string;

  @Output() onCloseNavigateAwayModal = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  @Output() onConfirm = new EventEmitter();

  @Input() btnCancel!: string;
  @Input() btnApply!: string;

  @Input() alignLeft!: boolean;

  closeNavigateAwayModal() {
    this.onCloseNavigateAwayModal.emit();
  }

  cancel() {
    this.onCancel.emit();
  }

  confirm() {
    this.onConfirm.emit();
  }

}

export interface ConfirmCloseModalInfoInterface {
  /* Indica se si sta accettando una candidatura (quindi deve mostrare il nome del candidato nameToShow). Se è false sto solo cambiando status in chiusa. */
  isAcceptingApplication?: boolean;
  /* Nome del candidato. Verrà mostrato solo se isAcceptingApplication è true. */
  nameToShow?: string;
  positionName?: string;
  countToEvaluate?: number;
  countLongList?: number;
  countShortList?: number;
}
