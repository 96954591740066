<form [formGroup]="createPositionService.processForm">
  <div class="row mb-4">
    <div class="col-12 col-md-8 col-lg-6 mb-3 mb-sm-0 position-name position-title d-flex flex-column flex-sm-row">
      <label class="lbl-info nowrap">
        <!-- {{ createPositionService.currentYear }}- -->
        {{ createPositionService.jobPosition?.progressiveCode?.generatedCode ??
        createPositionService.hypoteticalProgressivePositionNumber }}
      </label>
      <generali-input titleLabel="{{ 'process.POSITION_NAME' | translate }}" class="w-100"
        placeholder="{{ 'process.pl.POSITION_NAME' | translate }}"
        [inputControl]="createPositionService.title"></generali-input>
    </div>
    <div class="col-8 col-md-4 col-lg-2 mb-4 position-name">
      <generali-input titleLabel="{{ 'process.POSITION_OPEN' | translate }}"
        [inputControl]="createPositionService.openPosition" type="number"
        placeholder="{{ 'process.pl.POSITION_OPEN' | translate }}"></generali-input>
    </div>
    <div class="col-0 col-lg-4"></div>
  </div>

  <div class="row mb-4">
    <div class="col-12 subtitle">
      <p class="mb-4" i18n="@@process.STRUCTURE">position.STRUCTURE</p>
    </div>
    <div class="col-12 col-md-6 col-lg-4 mb-3 mb-sm-0 position-name">
      <generali-select title="{{ 'process.PERIMETER' | translate }}" [group]="createPositionService.processForm"
        [dropdown]="companyListPerimeter" bindLabel="name" bindValue="perimeterId"
        [control]="createPositionService.formCl.PERIMETER" (onGetValue)="onPerimeterSelect()"
        (click)="createPositionService.processForm.markAsDirty()"
        placeholder="{{ 'process.pl.PERIMETER' | translate }}">
      </generali-select>

    </div>
    <div class="col-12 col-md-6 col-lg-4 mb-3 mb-sm-0 position-name">
      <generali-select title="{{ 'process.COMPANY' | translate }}" [group]="createPositionService.processForm"
        [control]="createPositionService.formCl.COMPANY" [dropdown]="companyList" bindLabel="name"
        textDisabled="{{ 'process.txt_disabled.COMPANY' | translate }}"
        placeholder="{{ 'process.pl.COMPANY' | translate }}">
      </generali-select>
    </div>
    <div class="col-0 col-lg-4"></div>
  </div>

  <div class="row mb-4">
    <div class="col-12 col-md-6 col-lg-4 mb-3 mb-sm-0 position-name">
      <generali-input [inputControl]="createPositionService.organizationUnit"
        titleLabel="{{ 'process.ORGANIZATION_UNIT' | translate }}"
        placeholder="{{ 'process.pl.ORGANIZATION_UNIT' | translate }}"></generali-input>
    </div>
    <div class="col-12 col-md-6 col-lg-4 mb-3 mb-sm-0 position-name">
      <generali-select bindLabel="name" bindValue="name" placeholder="Scegli il job field"
        [group]="createPositionService.processForm" [multiSelection]="false"
        placeholder="{{ 'process.pl.JOB_FIELD' | translate }}" [control]="createPositionService.formCl.JOB_FIELD"
        [dropdown]="jobFieldList" title="{{ 'process.JOB_FIELD' | translate }}">
      </generali-select>
    </div>
    <div class="col-0 col-lg-4"></div>
  </div>

  <div class="row mb-4">
    <div class="col-12 col-md-6 col-lg-4 mb-3 mb-sm-0 position-name">
      <generali-select [control]="createPositionService.formCl.CONTRACT_TYPE" [dropdown]="contractTypeList"
        bindLabel="name" [group]="createPositionService.processForm"
        placeholder="{{ 'process.pl.CONTRACT_TYPE' | translate }}" title="{{ 'process.CONTRACT_TYPE' | translate }}">
      </generali-select>
    </div>
    <div class="col-12 col-md-6 col-lg-4 mb-3 mb-sm-0 position-name">
      <generali-select [control]="createPositionService.formCl.LOCATION" title="{{ 'process.LOCATION' | translate }}"
        [multiSelection]="true" [group]="createPositionService.processForm" [dropdown]="locationList"
        [searchable]="true" bindLabel="name" subTxt="Puoi selezionare più di una sede"
        (onChangeValue)="onSearchLocation($event)" infoText="{{ 'process.sub_txt.LOCATION' | translate }}"
        placeholder="{{ 'process.pl.LOCATION' | translate }}">
      </generali-select>
    </div>
    <div class="col-0 col-lg-4"></div>
  </div>

  <div class="break-line mb-4"></div>

  <div class="referral abs mb-4">
    <div class="d-flex flex-wrap align-items-center mb-4">
      <div class="subtitle me-4">
        <p class="" i18n="@@process.REFERRAL">position.REFERRAL</p>
      </div>
      <div class="switcher">
        <generali-switcher [position]="createPositionService.referralEnabled"
          (onSwitch)="onSwitchReferral($event)"></generali-switcher>
      </div>
      <p class="desc w-auto ms-md-4 mt-3 mt-md-0 ms-0" i18n="@@process.REFERRAL_DESC">position.REFERRAL_DESC</p>
    </div>
    <div class="position-name d-flex w-100">
      <label class="lbl-info">€</label>
      <generali-input class="col-12 col-md-6 col-lg-4" [inputControl]="createPositionService.referral" type="currency"
        titleLabel="{{ 'process.REFERRAL' | translate }}"
        placeholder="{{ 'process.pl.REFERRAL' | translate }}"></generali-input>
    </div>
  </div>

  <div class="break-line mb-4"></div>

  <div class="retribution row mb-4">
    <div class="col-12 d-flex flex-wrap align-items-center mb-4">
      <div class="subtitle">
        <p class="me-4 ti" i18n="@@process.RETRIBUTION">position.RETRIBUTION</p>
      </div>
      <div class="switcher">
        <generali-switcher [position]="createPositionService.retributionEnabled"
          (onSwitch)="onSwitchRetribution($event)"></generali-switcher>
      </div>
      <p class="desc ms-md-4 mt-3 mt-md-0 ms-0 w-auto" i18n="@@process.RETRIBUTION_DESC">position.RETRIBUTION_DESC</p>
    </div>
    <div class="col-12 col-md-6 col-lg-4 mb-3 mb-sm-0 position-name">
      <generali-select [control]="createPositionService.formCl.EMPLOYMENT_LEVEL"
        [group]="createPositionService.processForm" placeholder="{{ 'process.pl.CONTRACT_TYPE' | translate }}"
        title="{{ 'process.EMPLOYMENT_LEVEL' | translate }}" bindLabel="name" [dropdown]="employLevelList">
      </generali-select>
    </div>
    <div class="col-12 col-md-3 col-lg-2 mb-3 mb-sm-0 position-name">
      <generali-input titleLabel="{{ 'process.RETRIBUTION_FROM' | translate }}" type="currency"
        placeholder="{{ 'process.pl.POSITION_OPEN' | translate }}"
        [inputControl]="createPositionService.retributionFrom"></generali-input>
    </div>
    <div class="col-12 col-md-3 col-lg-2 position-name">
      <generali-input titleLabel="{{ 'process.RETRIBUTION_TO' | translate }}" type="currency"
        placeholder="{{ 'process.pl.POSITION_OPEN' | translate }}"
        [inputControl]="createPositionService.retributionTo"></generali-input>
    </div>
  </div>

  <div class="break-line mb-4"></div>

  <div class="row mb-4">
    <div class="col-12 subtitle d-flex">
      <p class="mb-4" i18n="@@process.DATE">position.DATE</p>
    </div>
    <div class="col-12 col-md-6 col-lg-4 position-name">
      <p>
        <label class="nowrap" i18n="@@process.INSERTION_DATE">position.INSERTION_DATE</label>
      </p>

      <generali-datapicker [disableBeforeTooday]="createPositionService.mode ? false : true"
        (onDateSelect)="onDateSelect($event)" placeholder="{{ 'generic.DATE' | translate }}"
        *ngIf="createPositionService.jobPosition?.expectedStartDate"
        [preselectedDate]="createPositionService.jobPosition?.expectedStartDate">
      </generali-datapicker>

      <generali-datapicker [disableBeforeTooday]="createPositionService.mode ? false : true"
        (onDateSelect)="onDateSelect($event)" placeholder="{{ 'generic.DATE' | translate }}"
        *ngIf="!createPositionService.jobPosition?.expectedStartDate" [preselectedDate]="undefined">
      </generali-datapicker>

    </div>
  </div>
</form>