import { Injectable, inject } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Company, ContractType, HomepageSearchFilters, JobField, SimpleUser, Location } from "applicanttrackingsystem-cl";
import { BaseDataService } from "src/ancestors/base-data.service";
import { isEqual } from "lodash";
import { FormService } from "../../util/form.service";
import { FormCommonClasses } from "../../util/form.classes";
import { FullName, JobPositioEnum } from "src/app/pages/homepage/homepage.component";

@Injectable()
export class JobPositionFilterService extends BaseDataService {
  constructor() {
    super();
  }

  private fb = inject(FormBuilder);
  private fs = inject(FormService);


  /** */
  public applieJobPositionFilter: HomepageSearchFilters = {};
  /** */
  public someFilterIsApplied?: boolean;
  /** Struttura per recuperare e settare i filtri selezionati dall'utente */
  public jobPositionFiltersGroup: FormGroup = this.fb.group({
    jobPositionStatus: this.fb.array([]),
    companies: this.fb.array([]),
    hrbps: this.fb.array([]),
    locations: this.fb.array([]),
    jobFields: this.fb.array([]),
    contractTypes: this.fb.array([]),
    fromDate: [],
    toDate: []
  });

  public dropdown: {
    hrbp?: (SimpleUser & FullName)[]
    company?: Company[]
    jobField?: JobField[]
    jobPositionStatus?: JobPositioEnum[]
    location?: Location[],
    contractType?: ContractType[],
  } = {};



  /**
   * In caso ci siano dei filtri applicati in pagina setta "someSelected" a true in modo da visualizzare la sezione dei filtri con la lista dei filtri attivi
   *
   * Il filtro "toDate" e "fromDate" sono esclusi dal controllo in quanto la selezione di questi filtri non è rappresentata graficamente tramite chip
   */
  public activateFilterSectionIfActiveFilter() {
    this.someFilterIsApplied = false;

    for (const filter of Object.values(this.applieJobPositionFilter)) {
      if (filter === "toDate" || filter === "fromDate") {
        continue;
      }

      if (this.appService.isEmpty(filter) || !filter) {
        continue;
      } else {
        this.someFilterIsApplied = true;
        break;
      }
    }
  }

  public onApplyFilter(filters: Record<string, unknown>) {
    const onlyFill: Record<string, unknown> = {};
    if (this.fromDate.value && this.toDate.value) {
      onlyFill["fromDate"] = this.fromDate.value;
      onlyFill["toDate"] = this.toDate.value;
    }

    for (const [key, value] of Object.entries(filters)) {
      if (!this.appService.isEmpty(value)) {
        onlyFill[key] = value;
      }
    }

    this.applieJobPositionFilter = onlyFill;
    this.activateFilterSectionIfActiveFilter();
  }

  /**
   * Rimuove i filtri di ricerca non applicati se applicati.
   * Per ogni filtro applicato, verifica se è un FormArray e confronta i valori inseriti nel form con quelli applicati.
   * Se i valori sono diversi, rimuove il filtro e aggiunge i nuovi valori applicati.
   */
  public dropNotAppliedFilter() {
    if (this.appService.isEmpty(this.applieJobPositionFilter)) {
      this.onResetFilters();
    }

    for (const [filterKey, values] of Object.entries(this.applieJobPositionFilter)) {
      const formField = this.jobPositionFiltersGroup.get(filterKey);
      if (formField instanceof FormArray) {
        if (!Array.isArray(values)) {
          return;
        }

        const appliedFilter = formField.controls.filter((applied, idx) => isEqual(applied.value, values[idx]));
        formField.clear();
        appliedFilter.forEach(filter => formField.push(filter));
      }
    }
  }

  /**
   * Rimuove un filtro di ricerca specifico in base ai parametri forniti.
   *
   * @param {Object} filterParam - Oggetto che contiene i seguenti parametri:
   *   - chipValue: Il valore del filtro da rimuovere.
   *   - control: La chiave di controllo corrispondente al filtro da rimuovere.
   */
  public onRemoveFilter(filterParam: { chipValue: unknown, control: string }) {
    const filterForm = this.jobPositionFiltersGroup.get(filterParam.control);
    const filterApplied = this.applieJobPositionFilter[filterParam.control as keyof HomepageSearchFilters];

    if (Array.isArray(filterApplied) && filterForm instanceof FormArray) {
      this.handleRemoveAppliedFilterFromArray(filterForm, filterApplied, filterParam);
    }

    if (filterForm instanceof FormControl) {
      filterForm.reset();
      this.applieJobPositionFilter[filterParam.control as keyof HomepageSearchFilters] = undefined;
    }

    this.activateFilterSectionIfActiveFilter();
  }

  private handleRemoveAppliedFilterFromArray(filterForm: FormArray, filterApplied: unknown[], param: { chipValue: unknown, control: string }) {
    const findIdxToRemoveFromForm = filterForm.controls.findIndex(filterToRemove => isEqual(filterToRemove.value, param.chipValue));
    const findIdxToRemoveFromApplied = filterApplied.findIndex(filterToRemove => isEqual(filterToRemove, param.chipValue));

    if (findIdxToRemoveFromApplied === -1 || findIdxToRemoveFromForm === -1) {
      return;
    }

    filterApplied.splice(findIdxToRemoveFromApplied, 1);
    filterForm.removeAt(findIdxToRemoveFromForm);
  }

  public onResetFilters() {
    this.applieJobPositionFilter = {};

    Object.keys(this.jobPositionFiltersGroup.controls).forEach(key => {
      const formField = this.jobPositionFiltersGroup.get(key);

      if (formField instanceof FormArray) {
        formField?.clear();
      }

      if (formField instanceof FormControl) {
        formField?.reset();
      }
    });

    this.activateFilterSectionIfActiveFilter();
  }

  /**
   * @returns ID di tutti i filtri dell'homepage selezionati da passare alla chiamata per la lista
   */
  public get appliedFiltersForList(): HomepageSearchFilters | any {
    return {
      toDate: this.toDate.value as Date,
      fromDate: this.fromDate.value as Date,
      companyId: this.fs.getMultiValuesOutOfArray<string[]>(this.companies, FormCommonClasses.COMPANY_ID),
      hrbpId: this.fs.getMultiValuesOutOfArray<string[]>(this.hrbps, FormCommonClasses.USER_ID),
      locationId: this.fs.getMultiValuesOutOfArray<string[]>(this.locations, FormCommonClasses.LOCATION_ID),
      jobFieldId: this.fs.getMultiValuesOutOfArray<string>(this.jobFields, FormCommonClasses.JOB_FIELD_ID),
      contractTypeId: this.fs.getMultiValuesOutOfArray<string>(this.contractTypes, FormCommonClasses.CONTRACT_TYPE_ID),
      jobPositionStatus: this.fs.getMultiValuesOutOfArray<string[]>(this.jobPositionStatus)
    } as HomepageSearchFilters;
  }

  public set appliedFiltersForList(appliedFilter: HomepageSearchFilters) {
    this.applieJobPositionFilter = appliedFilter;
  }

  public set jobPositionFilters(appliedFilter: HomepageSearchFilters) {
    this.applieJobPositionFilter = appliedFilter;
    for (const [key, value] of Object.entries(appliedFilter)) {
      if (!Array.isArray(value)) {
        continue;
      }

      const strinArrayOnly = value.every(v => value.length && typeof v === "string");
      if (strinArrayOnly) {
        value
          ?.map(v => this.fb.control(v))
          ?.forEach(v => (this.jobPositionFiltersGroup.get(key) as FormArray).push(v));
        continue;
      }

      this.fs.setMultiValuesOnArray(this.jobPositionFiltersGroup.get(key) as FormArray, value);
    }
  }

  public get jobPositionFilters(): HomepageSearchFilters {
    return this.jobPositionFiltersGroup.value as HomepageSearchFilters;
  }

  public get jobPositionStatus() {
    return this.jobPositionFiltersGroup.get("jobPositionStatus") as FormArray;
  }
  public get hrbps() {
    return this.jobPositionFiltersGroup.get("hrbps") as FormArray;
  }
  public get companies() {
    return this.jobPositionFiltersGroup.get("companies") as FormArray;
  }
  public get locations() {
    return this.jobPositionFiltersGroup.get("locations") as FormArray;
  }
  public get jobFields() {
    return this.jobPositionFiltersGroup.get("jobFields") as FormArray;
  }
  public get contractTypes() {
    return this.jobPositionFiltersGroup.get("contractTypes") as FormArray;
  }
  public get fromDate() {
    return this.jobPositionFiltersGroup.get("fromDate") as FormControl;
  }
  public get toDate() {
    return this.jobPositionFiltersGroup.get("toDate") as FormControl;
  }
}
