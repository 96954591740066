<ng-container *ngIf="typeofArray(chipValue); else valueIsString">
  <generali-chip [class.d-none]="chipToIgnore?.includes(type)" [labelItem]="chip" [bindLabel]="bindLabel"
    [isSelected]="true" [clearable]="true" (onChipExclude)="onChipRemove(chip)" *ngFor="let chip of chipValue">
  </generali-chip>
</ng-container>

<ng-template #valueIsString>
  <generali-chip [class.d-none]="chipToIgnore?.includes(type)" *ngIf="chipValue !== undefined"
    labelItem="{{ additionalLabel }} {{ chipValue | chipValue }}" [bindLabel]="bindLabel" [isSelected]="true"
    [clearable]="true" (onChipExclude)="onChipRemove(chipValue)">
  </generali-chip>
</ng-template>