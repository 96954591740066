<generali-drawer translate [drawerImg]="drawerImg" [showDrawer]="showDrawer" (onClosingDrawerTab)="onClosingDrawerTab()"
  drawerTitle="{{ 'drawerFiltri.FILTER' | translate }}">

  <ng-container main>
    <div class="filters-list">
      <ng-content></ng-content>
    </div>
  </ng-container>



  <ng-container footer>
    <div class="actions">
      <div class="actions-btn">
        <generali-button btnSize="sm" btnStyle="red" btnItem="{{ 'drawerFiltri.APPLY' | translate }}"
          (onClick)="onApplyFilter()"></generali-button>
      </div>
      <div class="actions-btn" (click)="onResetFilters()">
        <p>{{ 'drawerFiltri.RESET' | translate }}</p>
      </div>
    </div>
  </ng-container>
</generali-drawer>