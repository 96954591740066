import { ChangeDetectorRef, inject, Injectable } from "@angular/core";
import { GeneraliStatuTab } from "generali-shared-components/dist";
import { BaseDataService } from "src/ancestors/base-data.service";
import { BaseStatus } from "../position-detail/position-detail.data.service";
import { ActionSelect } from "../position-detail/position-detail.component";
import {
  CanSendCommunicationAndDefaultTextResponse,
  InterviewInstance,
  InterviewType,
  JobApplication,
  JobApplicationComment,
  JobApplicationStatus,
  JobApplicationStatusEnum,
  JobApplicationStatusEnumTypes,
  ScreeningTestCollection,
  ScreeningTestInstance,
  SearchReassignableJobPositionsResponse,
  SimpleUser
} from "applicanttrackingsystem-cl";
import { FormGroup, FormArray, FormControl, FormBuilder } from "@angular/forms";
import { FormCommonClasses as FormCL } from "src/app/shared/util/form.classes";
import { TranslateService } from "src/app/shared/util/translate.service";
import { FullName } from "../homepage/homepage.component";
import { FormService } from "src/app/shared/util/form.service";
import { UserService } from "src/app/global-service/user.service";
import { AnalyticsService, EventNameEnum } from "src/app/shared/service/analytics.service";

export namespace PersonaDetailUrls {
  export const CV = "cv";
  export const COVER_LETTER = "cover-letter";
  export const PERSONAL_DATA = "personal-data";
}

export namespace actionsOptions {
  export const TALENT = "talent";
  export const ASSESSMENT = "assessment";
  export const TIMELINE = "timeline";
  export const OTHER = "other";
}

interface FilterJobPositionInterface {
  sortingField?: string,
  sortingDesc?: boolean,
  id?: string,
  jobApplicationStatus?: string[],
  keyword?: string,
  reassigned?: boolean,
  referral?: boolean,
  talent?: boolean,
  newItalian?: boolean,
  legallyProtected?: boolean,
  feedbackMailSent?: boolean,
  ageRange?: string[],
  gender?: string
}
@Injectable()
export class CandidateSheetService extends BaseDataService {
  private cdr = inject(ChangeDetectorRef);
  private fb = inject(FormBuilder);
  private fs = inject(FormService);
  private translateService = inject(TranslateService);
  private usrService = inject(UserService);
  private analyticsService = inject(AnalyticsService);
  downloadableCV = "../../../assets/files/CV-f-Analista.pdf";
  constructor() {
    super();
  }

  public drawer: {
    /** Mostra drawer timeline */
    showTimeline?: boolean;
    /** Mostra drawer assegna a altra posizione */
    showMoveToOtherSession?: boolean;
    /** Mostra drawer di dettaglio dei colloqui */
    showInterviewDetail?: boolean;
    /** Mostra drawer di dettaglio dei test */
    showTestsDetail?: boolean;
    /** Mostra drawer candidature effettuate */
    showRealizedApplicants?: boolean;
    /** Mostra drawer evaluationForm */
    showEvaluationForm?: boolean;
    /** Mostra drawer di stop candidatura */
    showInterruptApplication?: boolean;
  } = {};

  public accordion: {
    /** Mostra accordition dei commenti */
    showComments?: boolean;
    /** Mostra accordition dei colloqui */
    showInterviews?: boolean;
    /** Mostra accordition test */
    showTests?: boolean;
  } = {
      showComments: true,
      showInterviews: true,
      showTests: true
    };

  public fetchData: {
    commentCount?: number;
    /** Conteggio totale commenti */
    commentList?: JobApplicationComment[];
    /** Lista commenti */
    testList?: ScreeningTestCollection[];
    /** Lista dei test per il dropdown */
    jobApplicationHistory?: JobApplication[];
    /** Elenco delle possibili job positions a cui la candidatura può essere riassegnata */
    interviewersCount?: number;
    /** */
    reassignableJobPositionList?: SearchReassignableJobPositionsResponse[]
  } = {
      reassignableJobPositionList: []
    };

  public editComment: {
    /** Attivazione della modalità di modifica se true */
    editMode?: boolean;
    /** Campo commento visibile solo in edit mode */
    control: FormControl;
    /** Id del commento da modificare usato per visualizzare la text area per un determinato commento */
    commentToUpdateId?: string;
  } = {
      control: this.fb.control("")
    };

  /** Form per recuperare commento e email dal drower relativo allo stato STOP della candidatura */
  public interruptionForm: FormGroup = this.fb.group({
    comment: [""],
    defaultEmail: [""],
    defaultEmailEn: [""]
  });
  /** Campo di ricerca nel drawer assegna a altra posizione */
  public searchPosition = this.fb.control("");
  /** Campi del form nel drawer dei colloqui */
  public interviewDetail: FormGroup = this.fb.group({
    interviewer: this.fb.array([]),
    evaluationFormType: this.fb.array([])
  });
  /** Campi del form nel drawer dei test */
  public testDetail: FormGroup = this.fb.group({
    name: this.fb.array([]),
    date: null
  });
  /** Nome di un determinato candidato che ha effettuato le candidature */
  public jobApplicationHistoryOwner = "";
  /** Dettaglio Job Application   */
  public jobApplication?: JobApplication;
  /** Lista di tutti gli status relativi ad una jobApplication visualizzabili nella timeline */
  public listAllStatus?: JobApplicationStatus[];
  /**  */
  public interviewInstance?: InterviewInstance;
  /** */
  public interviewTypeDropdown: InterviewType[] = [];
  public interviewerDropdown: (SimpleUser & FullName)[] = [];
  public jobAplicationIds: string[] = [];
  public selectedJobPositionToReasign: SearchReassignableJobPositionsResponse[] = [];
  /** Se settato a true impedisce di navigare verso il precedente candidato */
  public noPreviousApplicants?: boolean;
  /** Se settato a true impedisce di navigare verso il prossimo candidato */
  public noMoreApplicants?: boolean;
  /** Numero di record visualizzabili */
  public visibleRecordCount = 100;
  /** Determina se lo switcher del drawer showInterruptApplication è settato su true o false */
  public interruptApplicationSwitch?: boolean;
  public valueApplicationSwitch?: boolean;
  public screeningTestCard!: ScreeningTestInstance[];
  public _commentField: FormControl = this.fb.control(null);
  /** DROPDOWN */
  public genreDropdown = [
    { label: "Uomo", value: "M" },
    { label: "Donna", value: "F" }
  ];

  public newItaDropdown = [
    { label: "Si", value: true },
    { label: "No", value: false }
  ];

  public protectedCategoryDropdown = [
    { label: "Si", value: true },
    { label: "No", value: false }
  ];

  public ageDropdown = [
    { label: "Tutti", value: undefined },
    { label: "Under 27", value: "0-27" },
    { label: "Tra 28 e 30", value: "28-30" },
    { label: "Tra 31 e 35", value: "31-35" },
    { label: "Tra 36 e 40", value: "36-40" },
    { label: "Tra 41 e 45", value: "41-45" },
    { label: "Tra 46 e 50", value: "46-50" },
    { label: "Over 50", value: "50-99" }
  ];

  public positionStatus: BaseStatus[] = [
    { applicationBadge: JobApplicationStatusEnumTypes.STOP, label: this.translateService.instant("home.table.STOP") },
    { applicationBadge: JobApplicationStatusEnumTypes.TO_EVALUATE, label: this.translateService.instant("home.table.TO_EVALUATE") },
    { applicationBadge: JobApplicationStatusEnumTypes.LONG_LIST, label: this.translateService.instant("home.table.LONG_LIST") },
    { applicationBadge: JobApplicationStatusEnumTypes.SHORT_LIST, label: this.translateService.instant("home.table.SHORT_LIST") },
    { applicationBadge: JobApplicationStatusEnumTypes.OFFER, label: this.translateService.instant("home.table.OFFER") },
    { applicationBadge: JobApplicationStatusEnumTypes.ACCEPT, label: this.translateService.instant("home.table.ACCEPT") },
    { applicationBadge: JobApplicationStatusEnumTypes.REFUSE, label: this.translateService.instant("home.table.REFUSED") }
  ];

  public stepper: GeneraliStatuTab.TabStruct[] = [
    { isActive: true, isCompiled: false, name: this.translateService.instant("candidate.CV"), redirect: PersonaDetailUrls.CV },
    { isActive: false, isCompiled: false, name: this.translateService.instant("candidate.COVER_LETTER"), redirect: PersonaDetailUrls.COVER_LETTER, isDisabled: true },
    { isActive: false, isCompiled: false, name: this.translateService.instant("candidate.DATAS"), redirect: PersonaDetailUrls.PERSONAL_DATA }
  ];

  private canSendCommunicationAndText: CanSendCommunicationAndDefaultTextResponse | undefined;

  public activateCoverLetter() {
    const stepF = this.stepper.find(step => step.name === this.translateService.instant("candidate.COVER_LETTER"));
    if (stepF) {
      stepF.isDisabled = false;
    }
  }

  public actionOptions: ActionSelect.ActionInterface[] = [
    { label: "Richiedi assessment", icon: "assets/img/icons/send-stroke.svg", action: ActionSelect.ASSESSMENT },
    { label: "Timeline", icon: "assets/img/icons/timeline.svg", action: ActionSelect.TIMELINE },
    { label: "Assegna a altra selezione", icon: "assets/img/icons/assign.svg", action: ActionSelect.OTHER }
  ];

  public personalDataFormReadOnly = false;

  public setAssessment() {
    const assesmentIdx = this.actionOptions.findIndex(action => action.action === ActionSelect.ASSESSMENT);

    this.actionOptions.splice(assesmentIdx, 1, { label: this.translateService.instant("candidate.label.full.ASSESSMENT"), icon: "assets/img/icons/sent-full.svg", action: ActionSelect.ASSESSMENT });
  }

  public setNotAssessment() {
    const assesmentIdx = this.actionOptions.findIndex(action => action.action === ActionSelect.ASSESSMENT);

    this.actionOptions.splice(assesmentIdx, 1, { label: this.translateService.instant("candidate.label.stroke.ASSESSMENT"), icon: "assets/img/icons/send-stroke.svg", action: ActionSelect.ASSESSMENT });
  }

  public setTalent() {
    /** Prima di settare l'icon Talent rimuovi quella attuale */
    const talentIdx = this.actionOptions.findIndex(action => action.action === ActionSelect.TALENT);
    if (talentIdx !== -1) {
      this.actionOptions.splice(talentIdx, 1);
    }

    this.actionOptions.unshift({ label: this.translateService.instant("candidate.label.full.TALENT"), icon: "assets/img/icons/star.svg", action: ActionSelect.TALENT });
  }

  public setNotTalent() {
    /** Prima di settare l'icon Talent rimuovi quella attuale */
    const talentIdx = this.actionOptions.findIndex(action => action.action === ActionSelect.TALENT);
    if (talentIdx !== -1) {
      this.actionOptions.splice(talentIdx, 1);
    }

    this.actionOptions.unshift({ label: this.translateService.instant("candidate.label.stroke.TALENT"), icon: "assets/img/icons/star-stroke.svg", action: ActionSelect.TALENT });
  }

  clearForm() {
    this.personalData = this.fb.group({
      [FormCL.APPLICATION_SITE]: [null],
      [FormCL.GENRE]: this.fb.array([]),
      [FormCL.NEW_ITALIAN]: this.fb.array([]),
      [FormCL.PROTECTED_CATEGORY]: this.fb.array([]),
      [FormCL.BIRTHDAY]: [null],
      [FormCL.AGE_RANGE]: [null]
    });
  }

  public setUserDatas() {
    let genre: string | undefined;
    switch (this.jobApplication?.gender) {
      case "M":
        genre = this.translateService.instant("generic.MAN");
        break;
      case "F":
        genre = this.translateService.instant("generic.WOMAN");
        break;
      default:
        break;
    }
    // Prevalorizza il campo Source
    this.fs.setValueOnControl(this.applicationSite, this.jobApplication?.applicationSite);
    // Prevalorizza il campo genere
    this.fs.setValueOnArray(this.genre, { label: genre }, true);
    // Prevalorizza il campo Categorie protette
    if (this.jobApplication?.legallyProtected !== undefined) {
      this.fs.setValueOnArray(this.protectedCategory, { label: this.jobApplication?.legallyProtected ? "Si" : "No" }, true);
    }
    // Prevalorizza il campo Nuovo italiano
    if (this.jobApplication?.newItalian !== undefined) {
      this.fs.setValueOnArray(this.newIta, { label: this.jobApplication?.newItalian ? "Si" : "No" }, true);
    }
    // Prevalorizza il campo Fascia d'eta
    if (this.jobApplication?.ageRange) {
      this.fs.setValueOnControl(this.ageRange, this.appService.mapAgeRange(this.jobApplication?.ageRange ?? "").label);
    }

  }

  public updateUserAgeRange(newAgeRange: string) {
    this.fs.setValueOnControl(this.ageRange, this.appService.mapAgeRange(newAgeRange).label);
  }

  /**
   * Controlla se ci sono candidati successivi a quello attuale
   * Se non ci sono più candidati setta noMoreApplicants a true un modo da impedire la navigazione tra i candidati
   *
   * @param currentJobApplicationId
   */
  public checkNextJobApplicationIdExist(currentJobApplicationId: string) {
    const currentIdx = this.jobAplicationIds?.findIndex(jobAplicationIds => jobAplicationIds === currentJobApplicationId);

    if (currentIdx === -1) {
      return;
    }

    /** Se sei al penultimo risultato setta il paramtro a true in modo da bloccare la possibilità di navigazione tra i candidati */
    if (!this.jobAplicationIds[currentIdx + 1]) {
      this.noMoreApplicants = true;
    } else {
      this.noMoreApplicants = false;
    }

    this.noPreviousApplicants = currentIdx == 0 ? true : false;
  }

  /**
   * Controlla se ci sono candidati successivi a quello attuale
   *
   * Se ci sono ritorna l'index del candidato successivo, altimenti undefined
   *
   * Se non ci sono più candidati setta noMoreApplicants a true un modo da impedire la navigazione tra i candidati
   *
   * @param currentJobApplicationId
   * @returns
   */
  public getNextJobApplicationId(currentJobApplicationId: string) {
    const currentIdx = this.jobAplicationIds?.findIndex(jobAplicationIds => jobAplicationIds === currentJobApplicationId);

    if (currentIdx === -1) {
      return;
    }
    this.noPreviousApplicants = false;
    /** Se sei al penultimo risultato setta il paramtro a true in modo da bloccare la possibilità di navigazione tra i candidati */
    if (!this.jobAplicationIds[currentIdx + 2]) {
      this.noMoreApplicants = true;
    }

    /** Ritorna l'id del prossimo candidato */
    if (this.jobAplicationIds[currentIdx + 1]) {
      return this.jobAplicationIds[currentIdx + 1];
    }

    return;
  }

  /**
 * Controlla se ci sono candidati precedenti a quello attuale
 * Se non ci sono più candidati setta noPreviousApplicants a true un modo da impedire la navigazione tra i candidati
 *
 * @param currentJobApplicationId
 */
  public checkPreviousJobApplicationIdExist(currentJobApplicationId: string) {
    const currentIdx = this.jobAplicationIds?.findIndex(jobAplicationIds => jobAplicationIds === currentJobApplicationId);

    if (currentIdx === -1) {
      return;
    }

    /** Se sei al penultimo risultato setta il paramtro a true in modo da bloccare la possibilità di navigazione tra i candidati */
    if (!this.jobAplicationIds[currentIdx - 1]) {
      this.noPreviousApplicants = true;
    } else {
      this.noPreviousApplicants = false;
    }
  }

  /**
  * Controlla se ci sono candidati precedenti a quello attuale
  * Se non ci sono più candidati setta noMoreApplicants a true un modo da impedire la navigazione tra i candidati
  *
  * @param currentJobApplicationId
  * @returns
  */
  public getPreviousJobApplicationId(currentJobApplicationId: string) {
    const currentIdx = this.jobAplicationIds?.findIndex(jobAplicationIds => jobAplicationIds === currentJobApplicationId);

    if (currentIdx === -1) {
      return;
    }

    if (!this.jobAplicationIds[currentIdx - 2]) {
      this.noPreviousApplicants = true;
    }

    /** Ritorna l'id del prossimo candidato */
    if (this.jobAplicationIds[currentIdx - 1]) {
      return this.jobAplicationIds[currentIdx - 1];
    }

    return;
  }

  /**
   * Gestisce il cambio dell'icona di talento nella scheda del candidato.
   * Se la candidatura è contrassegnata come talento, imposta l'icona di talento.
   * Altrimenti, imposta l'icona di non talento.
   */
  public switchTalentIcon() {
    if (this.jobApplication?.isTalent) {
      this.setTalent();
    } else {
      this.setNotTalent();
    }
  }

  /**
   * Gestisce l'interruzione dell'applicazione.
   *
   * @param jobApplicationId - ID della candidatura.
   */
  public async handleInterruptApplication(jobApplicationId: string) {

    this.drawer.showInterruptApplication = true;

    this.canSendCommunicationAndText = await this.backendService.canSendCommunicationAndDefaultText(jobApplicationId);
    if (!this.canSendCommunicationAndText) {
      return;
    }
    this.interruptionDefaultEmail.setValue(this.canSendCommunicationAndText.defaultText);

    this.valueApplicationSwitch = true;
    this.interruptApplicationSwitch = true;
    this.interruptApplicationSwitch = this.canSendCommunicationAndText.canSendCommunication;

    if (this.canSendCommunicationAndText.isJobApplicationReassigned) {
      this.valueApplicationSwitch = false;
    } else if (this.canSendCommunicationAndText.wasInStopStatus) {
      this.valueApplicationSwitch = false;
    }

    this.interruptionDefaultEmail.setValue(this.canSendCommunicationAndText.defaultText);
    this.interruptionDefaultEmailEn.setValue(this.canSendCommunicationAndText.defaultTextEn);

    await this.loadDataUser(jobApplicationId);
  }

  public updateInterruptApplicationSwitch(value: boolean) {
    this.valueApplicationSwitch = value;

    if (this.canSendCommunicationAndText?.isJobApplicationReassigned) {
      return;
    }
  }

  /**
   * Effettua il cambio di stato della candidatura.
   *
   * @param jobApplicationId - ID della candidatura.
   * @param newStatus - Tipo di badge corrispondente al nuovo stato della candidatura.
   */
  public async changeJobApplicationStatus(jobApplicationId: string, newStatus: JobApplicationStatusEnum) {
    const statusIsChanged = await this.backendService.jobApplicationChangeStatus({
      jobApplicationId: jobApplicationId,
      newStatus: newStatus
    });

    if (!statusIsChanged) {
      return;
    }

    await this.loadDataUser(jobApplicationId);
  }

  /**
   * Aggiorna l'oggetto JobApplication corrente.
   * @param jobApplicationId L'ID della Job Application da aggiornare.
   */
  public async loadDataUser(jobApplicationId: string) {
    this.jobApplication = await this.backendService.jobApplicationGetById(jobApplicationId);
    await this.updateCommentListAndCount();

    if (this.jobApplication.isTalent) {
      this.setTalent();
    } else {
      this.setNotTalent();
    }

    if (this.jobApplication?.askForAssessmentSentDate) {
      this.setAssessment();
    } else {
      this.setNotAssessment();
    }

    this.setUserDatas();

    if (!this.usrService.isUnprofiled) {
      this.screeningTestCard = this.jobApplication?.screeningTestInstances ?? [];
    } else {
      this.personalData.disable();
      this.personalData.updateValueAndValidity();
    }

    this.checkNextJobApplicationIdExist(jobApplicationId);
  }

  public getFilterListByPosition(): FilterJobPositionInterface | undefined {
    return Object.values(this.route.snapshot.queryParamMap)[0] as FilterJobPositionInterface;
  }

  /**
   * Esegue le chiamate ai servizi per recuperare il conteggio dei commenti e la lista dei commenti relativi a una determinata Job Position.
   * Imposta i commenti e il conteggio dei commenti nella pagina.
   */
  public async updateCommentListAndCount() {
    if (!this.jobApplication) {
      return;
    }

    const jobApplicationId = this.jobApplication.jobApplicationId;

    const commentCountPromise = this.backendService.jobApplicationCountComments(jobApplicationId);
    const commentListPromise = this.backendService.jobApplicationListComments(jobApplicationId, { sortingDesc: true });

    const [commentCount, commentList] = await Promise.all([commentCountPromise, commentListPromise]);
    this.fetchData.commentCount = commentCount;
    this.fetchData.commentList = commentList;
  }

  protected get interruptionDefaultEmail() {
    return this.interruptionForm.get("defaultEmail") as FormControl;
  }

  protected get interruptionDefaultEmailEn() {
    return this.interruptionForm.get("defaultEmailEn") as FormControl;
  }

  public activateCurrentStep(stepIdx: number) {
    this.stepper.forEach(step => {
      step.isActive = false;
    });

    this.stepper[stepIdx].isActive = true;
    this.cdr.detectChanges();
  }

  public getCurrentStepIdx(step: string) {
    switch (step) {
      case PersonaDetailUrls.CV:
        return 0;
      case PersonaDetailUrls.COVER_LETTER:
        return 1;
      case PersonaDetailUrls.PERSONAL_DATA:
        return 2;
      default:
        return 0;
    }
  }

  /**
   * @param url
   *
   * Ritorna l'idx del processo attivo in base all'url fornito
   */
  public getTabstructIdxBasedOnUrl(url: string): number {

    return this.stepper.findIndex(step => step.isActive);
  }

  public personalData: FormGroup = this.fb.group({
    [FormCL.APPLICATION_SITE]: [null],
    [FormCL.GENRE]: this.fb.array([]),
    [FormCL.NEW_ITALIAN]: this.fb.array([]),
    [FormCL.PROTECTED_CATEGORY]: this.fb.array([]),
    [FormCL.BIRTHDAY]: [null],
    [FormCL.AGE_RANGE]: [null]
  });


  public get genre() {
    return this.personalData.get([FormCL.GENRE]) as FormArray;
  }
  public get newIta() {
    return this.personalData.get([FormCL.NEW_ITALIAN]) as FormArray;
  }
  public get protectedCategory() {
    return this.personalData.get([FormCL.PROTECTED_CATEGORY]) as FormArray;
  }
  public get ageRange() {
    return this.personalData.get([FormCL.AGE_RANGE]) as FormControl;
  }
  public get applicationSite() {
    return this.personalData.get([FormCL.APPLICATION_SITE]) as FormControl;
  }
  public get commentField() {
    return this.appService.stringify(this._commentField.value);
  }

  /**
   * Disabilita Form PersonalData
   */
  public checkDisablePersonalDataForm() {
    if (this.usrService.isUnprofiled) {
      this.personalData.disable();
      this.personalDataFormReadOnly = true;
    }
  }
}
