import { Component, inject } from "@angular/core";
import { GeneraliStatuTab } from "generali-shared-components/dist";
import { BaseComponent } from "src/ancestors/base/base.component";
import { SearchService } from "./search.data.service";
import { JobPositionFilterService } from "src/app/shared/service/filter/job-position-filter.service";
import { JobPositionOrderService } from "src/app/shared/service/order/job-position-order.service";
import { JobApplicationFilterService } from "src/app/shared/service/filter/job-application-filter.service";
import { JobApplicationOrderService } from "src/app/shared/service/order/job-application-order.service";
import { HomepageSearchFilters, JobApplicationsByPositionFilters, UserFiltersTypeEnumTypes } from "applicanttrackingsystem-cl";
import { AnalyticsService } from "src/app/shared/service/analytics.service";

export interface Filter {
  applied: boolean;
  sortingField: string;
  sortingDesc: boolean;
}

export interface CountInterface {
  application: number,
  position: number
}

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
  providers: [SearchService, JobPositionFilterService, JobApplicationFilterService, JobPositionOrderService, JobApplicationOrderService, AnalyticsService]
})
export class SearchComponent extends BaseComponent {
  constructor() {
    super();
    this.analyticsService.sendVirtualPageViewEvent(this.router.url, this.pageTitle);

  }

  protected searchService = inject(SearchService);
  protected positionFilterService = inject(JobPositionFilterService);
  protected applicationFilterService = inject(JobApplicationFilterService);
  protected orderService = inject(JobApplicationOrderService);
  protected jobPositionOrderService = inject(JobPositionOrderService);


  protected analyticsService = inject(AnalyticsService);

  private pageTitle = "Ricerca | ATS";

  /**
   * Gestione click sulla tab
   * 
   * @param step 
   */
  public async onStepClick(step: GeneraliStatuTab.TabStruct) {
    await this.router.navigate([step.redirect], { relativeTo: this.route });

    this.searchService.tabItems.forEach(tab => tab.isActive = false);
    step.isActive = true;
  }

  protected async onSearch() {
    this.searchService.isLoading = true;
    // if (!this.appService.stringify(this.searchService.searchedText.value).length) {
    //   this.searchService.isLoading = false;
    //   return;
    // }

    
    sessionStorage.setItem("searchedText", this.searchService.searchedText.value as string);

    const [appliedJobApplicationFilterPromise, appliedApplicationOrderPromise, appliedJobPositionFilterPromise, appliedJobPositionOrderPromise] = await Promise.all([
      this.backendService.jobPositionFiltersGet<JobApplicationsByPositionFilters>(UserFiltersTypeEnumTypes.SEARCHPAGE_JOB_APPLICATIONS_SEARCH),
      this.backendService.jobPositionFiltersGet<JobApplicationsByPositionFilters>(UserFiltersTypeEnumTypes.SEARCHPAGE_JOB_APPLICATIONS_SORT),
      this.backendService.jobPositionFiltersGet<HomepageSearchFilters>(UserFiltersTypeEnumTypes.SEARCHPAGE_JOB_POSITIONS_SEARCH),
      this.backendService.jobPositionFiltersGet<HomepageSearchFilters>(UserFiltersTypeEnumTypes.SEARCHPAGE_JOB_POSITIONS_SORT)
    ]);

    this.applicationFilterService.jobApplicationFilters = appliedJobApplicationFilterPromise;
    this.applicationFilterService.onApplyFilters(appliedJobApplicationFilterPromise as Record<string, unknown>);

    if (appliedApplicationOrderPromise.sortingField) {
      this.orderService.selectedOrder = {
        applied: true,
        sortingDesc: appliedApplicationOrderPromise.sortingDesc,
        sortingField: appliedApplicationOrderPromise.sortingField
      };
    }

    this.applicationFilterService.activateFilterSectionIfActiveFilter();

    this.positionFilterService.jobPositionFilters = appliedJobPositionFilterPromise;

    if (appliedJobPositionOrderPromise.sortingField) {
      this.jobPositionOrderService.selectedOrder = {
        applied: true,
        sortingDesc: appliedJobPositionOrderPromise.sortingDesc,
        sortingField: appliedJobPositionOrderPromise.sortingField
      };
    }

    this.positionFilterService.activateFilterSectionIfActiveFilter();

    await this.searchService.updateJobApplicationList();
    await this.searchService.updateJobPositionList();
    
    this.searchService.lastSerchText = this.searchService.searchedText.value as string;
    this.searchService.isLoading = false;
  }

  /**
   * Ripristina i valori standard del campo di input e aggiorna le liste delle job application e position.
   * Se nessun filtro di ricerca è applicato per le job application e/o le job position, il metodo aggiorna le rispettive liste e termina l'esecuzione.
   * Altrimenti, aggiorna le liste delle job application e position.
   */
  protected async onSearchClear() {
    this.searchService.isLoading = true;
    let shouldReturn = false;
    this.searchService.searchedText.reset();

    sessionStorage.removeItem("searchedText");

    if (!this.applicationFilterService.someFilterIsApplied) {
      await this.updateJobApplicationList();
      shouldReturn = true;
    }

    if (!this.positionFilterService.someFilterIsApplied) {
      await this.updateJobPositionList();
      shouldReturn = true;
    }

    if (shouldReturn) {
      this.searchService.isLoading = false;
      return;
    }

    await this.searchService.updateJobApplicationList();
    await this.searchService.updateJobPositionList();
    this.searchService.isLoading = false;
  }

  /**
   * Aggiorna la lista delle job application.
   * 
   * - Resetta il conteggio delle job application.
   * - Resetta la lista delle job application.
   * - Aggiorna la lista delle posizioni di lavoro.
   * - Aggiorna il contatore della scheda "job-application".
   */
  private async updateJobApplicationList() {
    this.searchService.applicationCount = 0;
    this.searchService.applicationList = [];
    await this.searchService.updateJobPositionList();
    this.searchService.updateTabCounter("job-application", 0);
  }

  /**
   * Aggiorna la lista delle job position.
   *
   * - Resetta il conteggio delle job position.
   * - Resetta la lista delle job position.
   * - Aggiorna la lista delle job position.
   * - Aggiorna il contatore della scheda "job-position".
   */
  private async updateJobPositionList() {
    this.searchService.positionCount = 0;
    this.searchService.positionList = [];
    await this.searchService.updateJobApplicationList();
    this.searchService.updateTabCounter("job-position", 0);
  }

  /**
   * Apre la tab dei filtri e precarica la lista delle compagnie
   */
  public async onOpenFilterTab() {
    if (this.router.url.includes("job-position")) {
      this.searchService.drawer.positionFilter = true;
      if (!this.appService.isEmpty(this.positionFilterService.dropdown.company)) {
        return;
      }

      this.positionFilterService.dropdown.company = await this.backendService.companyList();
    }

    if (this.router.url.includes("job-application")) {
      this.searchService.drawer.applicationFilter = true;
    }
  }

  protected orderIsDisabled() {
    if (this.router.url.includes("job-application")) {
      if (!this.searchService.applicationCount) {
        return true;
      }
      return false;
    }

    if (this.router.url.includes("job-position")) {
      if (!this.searchService.positionCount) {
        return true;
      }
    }
    return false;
  }

  /**
   * Apre la tab di ordinamento
   */
  public onOpenOrderTab() {
    if (this.router.url.includes("job-position")) {
      this.searchService.drawer.positionOrders = true;
    }

    if (this.router.url.includes("job-application")) {
      this.searchService.drawer.applicationOrders = true;
    }
  }
}
