import { Component, inject, OnInit, resolveForwardRef } from "@angular/core";
import { ScreeningTestCollection } from "applicanttrackingsystem-cl";

import { BaseComponent } from "src/ancestors/base/base.component";
import { CreatePositionService } from "../create-position.service";
import { CreatePosition } from "../create-position-routing.module";

@Component({
  selector: "app-question-screening",
  templateUrl: "./question-screening.component.html",
  styleUrls: ["./question-screening.component.scss"]

})
export class QuestionScreeningComponent extends BaseComponent implements OnInit {
  protected createPositionService = inject(CreatePositionService);

  constructor() {
    super();
  }
  protected screeningDropdown: ScreeningTestCollection[] = [];
  override async ngOnInit() {
    const activeTab = this.createPositionService.getActiveStepperIdxBasedOnUrl(this.router.url);
    this.createPositionService.setActivaStep(activeTab);

    await this.formReady();

    /** Controllo se impostato perimetro specifico, se FDD question&screening diventa opzionale */
    await this.createPositionService.checkPerimeter();

    if (!this.createPositionService.includeRowByPerimeter) {
      this.createPositionService.setFormCompiled(true, CreatePosition.qEs);
      return;
    }

    try {
      const screeningTestCollection = await this.backendService.screeningTestListCollections();

      /** Ordina alfabeticamente i risultati di screeningDropdown */
      this.screeningDropdown = screeningTestCollection.sort((a: ScreeningTestCollection, b: ScreeningTestCollection) => {
        if (a.collectionName && b.collectionName && a.collectionName < b.collectionName) {
          return -1;
        }
        return 1;
      });
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }
  }

  /**
   * Esegue l'upload del file
   * 
   * @param uploadedFile 
   */
  protected async onUploadSurveyTemplate(uploadedFile: Event) {
    const file = (uploadedFile?.target as HTMLInputElement).files?.item(0);
    if (file) {
      const formData = new FormData();
      formData.append("jobPositionId", this.createPositionService.jobPosition?.jobPositionId ?? "");
      formData.append("surveyTemplateFile", file);

      const jobPosition = await this.backendService.uploadSurveyTemplate(formData);
      if (jobPosition) {
        this.createPositionService.jobPosition = jobPosition;

        // this.createPositionService.saveSessionSavedParams(jobPosition);
      }
    }
  }

  /**
   * Cancellazione del file caricato
   */
  protected async onRemoveSurveyTemplate() {
    if (this.createPositionService.jobPosition) {
      const jobPosition = await this.backendService.removeSurveyTemplate({ jobPositionId: this.createPositionService.jobPosition.jobPositionId });
      if (jobPosition) {
        this.createPositionService.jobPosition = jobPosition;
      }
    }
  }

  /**
   * Download del questionario di esempio
   */
  protected onDownloadSurveyTemplate() {
    window.open("assets/files/Template-questionari-ATS.xlsx", "_self");
  }

  private formReady() {
    return new Promise((resolve) => {
      setTimeout(() => resolve(null), 0);
    });
  }
}
