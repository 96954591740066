import { Component, EventEmitter, Input, Output, inject } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { JobApplicationStatusEnumTypes, JobPositionStatusEnumTypes } from "applicanttrackingsystem-cl";

@Component({
  selector: "generali-chip-for-filter",
  templateUrl: "./generali-chip-for-filter.component.html",
  styleUrls: ["./generali-chip-for-filter.component.scss"]
})
export class GeneraliChipForFilterComponent {
  protected jobPositionStatus = Object.values(JobPositionStatusEnumTypes).map(status => status);
  protected jobApplicationStatus = Object.values(JobApplicationStatusEnumTypes).map(status => status);
  private fb = inject(FormBuilder);

  @Input() accordionName?: string;
  @Input() control!: string;
  @Input() group!: FormGroup;
  @Input() type!: "jobApplicationStatus" | "jobPositionStatus" | "locations" | "companies";


  @Input() list?: unknown[];
  @Input() bindLabel!: string;

  @Output() onRemove = new EventEmitter<{ filterKey: unknown, control: string }>();

  protected isSelected(jobStatusKey: unknown) {
    if (typeof jobStatusKey === "string") {
      return this.formArray.controls.some(jobStatus => jobStatus.value === jobStatusKey);
    } else {
      if (typeof jobStatusKey === "object" && jobStatusKey && this.bindLabel in jobStatusKey) {
        return this.formArray.controls.some(jobStatus =>
          (jobStatus.value as Record<string, unknown>)?.[this.bindLabel] === (jobStatusKey as Record<string, unknown>)[this.bindLabel]);
      }
    }
    return false;
  }

  protected onSelection(jobStatusKey: unknown) {
    const duplicateStatus = this.isSelected(this.bindLabel ? (jobStatusKey as Record<string, unknown>)[this.bindLabel] : jobStatusKey);
    if (typeof jobStatusKey === "string") {
      if (!duplicateStatus) {
        this.handleSetJobStatus(jobStatusKey);
        return;
      }

      this.handleRemoveJobStatus(jobStatusKey);
    }

    if (typeof jobStatusKey === "object" && jobStatusKey && this.bindLabel in jobStatusKey) {
      // Controllo se il jobStatusKey esiste dentro il formArray
      const duplicateStatusForObject = this.formArray.controls.some(jobStatus =>
        (jobStatus.value as Record<string, unknown>)?.[this.bindLabel] === (jobStatusKey as Record<string, unknown>)[this.bindLabel]);

      if (!duplicateStatusForObject) {
        // Imposto il jobStatusKey
        this.handleSetJobStatus(jobStatusKey);
        return;
      }
      // Rimuovo il jobStatusKey
      this.handleRemoveJobStatus((jobStatusKey as Record<string, unknown>)[this.bindLabel] as string);
    }
  }

  private handleSetJobStatus(jobStatusKey: unknown) {
    if (typeof jobStatusKey === "string") {
      this.formArray.push(new FormControl(jobStatusKey));
    }

    if (typeof jobStatusKey === "object") {
      /** Crea il formGroup di ritorno */
      const formGroup: FormGroup = this.fb.group({});
      if (typeof jobStatusKey === "object") {
        for (const item in jobStatusKey) {
          /** Per ogni campo passato in input crea un form control da aggiungere al form group */
          const formControl: FormControl = this.fb.control({});
          formControl.setValue((jobStatusKey as Record<string, unknown>)[item]);
          formGroup.addControl(item, formControl);
        }

        /** Pusha il formGroup nel formArray */
        this.formArray?.push(formGroup);
      }
    }
  }

  private handleRemoveJobStatus(jobStatusKey: string | Record<string, unknown>) {
    // Recupero l'index del jobStatusKey
    const duplicateStatusIdx = this.bindLabel ?
      this.formArray.controls.findIndex(jobStatus => (jobStatus.value as Record<string, unknown>)?.[this.bindLabel] === jobStatusKey) :
      this.formArray.controls.findIndex(job => job.value === jobStatusKey);
    // Rimuovo dai filtri
    if (duplicateStatusIdx >= 0) {
      this.formArray.removeAt(duplicateStatusIdx);
    }
  }

  get formArray() {
    return (this.group.get(this.control) as FormArray);
  }

  get jobStatus() {
    if (this.type === "jobApplicationStatus") {

      return this.jobApplicationStatus;
    }

    if (this.type === "jobPositionStatus") {
      return this.jobPositionStatus;
    }

    if (this.type === "locations") {
      return this.list ?? [];
    }

    if (this.type === "companies") {
      return this.list ?? [];
    }

    throw new Error(`ERROR FROM: ${this.control} (chip) -- MESSAGE: Devi scegliere che tipo di chip visualizzare`);
  }

  protected onChipRemove(params: { filterKey: unknown, control: string }) {
    this.onRemove.emit(params);
  }
}
